import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  CreateTokenBankAccountData,
  PaymentMethodCreateParams,
} from "@stripe/stripe-js";
import * as React from "react";

import {
  useTenantGroupPaymentMethods,
  useTenantGroupSources,
} from "../../hooks/useTenantGroupWallet";
import notify from "../../utils/notify";
import AddPaymentMethod from "../PaymentMethods/AddPaymentMethod";

interface Props {
  tenantGroupId: string;
  onSuccess: () => void;
  onError?: (err: Error) => void;
  CancelButton?: React.ReactElement;
}

export default function AddTenantGroupPaymentMethod(props: Readonly<Props>) {
  const stripe = useStripe();
  const elements = useElements();

  const { create: createPaymentMethod } = useTenantGroupPaymentMethods(
    props.tenantGroupId
  );
  const { create: createSource } = useTenantGroupSources(props.tenantGroupId);

  async function createCardPayment(
    name: string,
    description: string,
    billingAddress: PaymentMethodCreateParams.BillingDetails
  ) {
    if (stripe === null) {
      throw new Error("BUG: stripe is null");
    }

    const cardElement = elements?.getElement(CardElement);
    if (!cardElement) {
      throw new Error("BUG: could not find stripe card element");
    }

    let paymentMethod;
    try {
      const res = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingAddress,
      });
      if (res.error) {
        notify.error("Failed to create payment method on Stripe", res.error);
        return;
      }
      paymentMethod = res.paymentMethod;
    } catch (err) {
      notify.error("Failed to create payment method on Stripe", err);
      return;
    }

    try {
      await createPaymentMethod({
        name,
        description,
        stripe_payment_method: {
          data: paymentMethod,
        },
      });
      notify.success(`Successfully added "${name}" to your wallet`);
      props.onSuccess();
    } catch (err) {
      notify.error("Failed to add the card to your wallet", err);
      if (props.onError) {
        props.onError(err as Error);
      }
    }
  }

  async function createBankPayment(
    name: string,
    description: string,
    bankData: CreateTokenBankAccountData
  ) {
    if (stripe === null) {
      throw new Error("BUG: stripe is null");
    }

    try {
      const res = await stripe.createToken("bank_account", bankData);
      if (res.error) {
        notify.error("Failed to create bank account on Stripe", res.error);
        return;
      }
      await createSource({
        name,
        description,
        token: res.token!,
      });
      notify.success(`Successfully added "${name}" to your wallet`);
      props.onSuccess();
    } catch (err) {
      notify.error("Failed to create bank account on Stripe", err);
      if (props.onError) {
        props.onError(err as Error);
      }
    }
  }

  return (
    <AddPaymentMethod
      onCreateCardPayment={createCardPayment}
      onCreateBankPayment={createBankPayment}
      CancelButton={props.CancelButton}
    />
  );
}
