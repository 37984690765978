import useSwr from "swr";

import useIdsFetch from "./useIdsFetch";
import { ParkingProduct } from "./useParkingProduct";
import { PaymentMethod, Source } from "./useWallet";

export interface AuthorizedPayment {
  id: string;
  name: string;
  description: string;
  payment_method?: PaymentMethod;
  source?: Source;
  parking_product: ParkingProduct;
  isDeleted?: boolean;
}

interface Invitation {
  id: string;
  name: string;
  email: string;
}

const listPath = (tenantGroupId: string) =>
  `/tenant-groups/${tenantGroupId}/authorized-payments/`;
const itemPath = (tenantGroupId: string, authorizedPaymentId: string) =>
  `/tenant-groups/${tenantGroupId}/authorized-payments/${authorizedPaymentId}/`;

export const useAuthorizedPayments = ({
  tenantGroupId,
}: {
  tenantGroupId?: string;
} = {}) => {
  const idsFetch = useIdsFetch();
  const {
    data: authorizedPayments,
    error,
    mutate: mutateList,
  } = useSwr<AuthorizedPayment[]>(
    tenantGroupId ? listPath(tenantGroupId) : null
  );
  const create = async (data: Partial<AuthorizedPayment>, id?: string) => {
    if (!tenantGroupId && !id) throw new Error("tenantGroupId is required");
    const path = listPath(tenantGroupId || id!);
    const created = await idsFetch(path, { method: "POST", body: data });
    mutateList();
    return created;
  };
  return {
    authorizedPayments,
    error,
    create,
    revalidate: () => mutateList(),
  };
};

export const useAuthorizedPayment = ({
  authorizedPayment,
  tenantGroupId,
  authorizedPaymentId,
  onError = () => null,
}: {
  onError?: (err: Error) => void;
  authorizedPayment?: AuthorizedPayment;
  tenantGroupId: string;
  authorizedPaymentId: string;
}) => {
  const idsFetch = useIdsFetch();
  const path = itemPath(tenantGroupId, authorizedPaymentId);
  const { data, error, mutate } = useSwr<AuthorizedPayment>(path, {
    revalidateOnFocus: !authorizedPayment,
    revalidateOnReconnect: !authorizedPayment,
    onError,
  });
  const modify = async (data: Partial<AuthorizedPayment>) => {
    const modified = await idsFetch(path, { method: "PUT", body: data });
    mutate(modified);
    return modified;
  };
  const remove = async ({ revalidate = false } = {}) => {
    await idsFetch(path, { method: "DELETE" });
    mutate();
  };
  return {
    authorizedPayment: data,
    error,
    modify,
    remove,
  };
};

export const useAuthorizedPaymentInvitations = ({
  tenantGroupId,
  authorizedPaymentId,
}: {
  tenantGroupId: string;
  authorizedPaymentId: string;
}) => {
  const idsFetch = useIdsFetch();
  const path = `${itemPath(tenantGroupId, authorizedPaymentId)}invitations/`;
  const create = async (data: Partial<Invitation>) => {
    return await idsFetch(path, { method: "POST", body: data });
  };
  return { create };
};
