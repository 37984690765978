import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import HeaderSidebarContent from "./Layouts/HeaderSidebarContent";
import ParkerSidebar from "./Layouts/ParkerSidebar";
import NavBar from "./NavBar";
import NotFoundContent from "./NotFoundContent";
import Organizations from "./Organizations";
import ParkerHistory from "./ParkerHistory";
import ParkerSettings from "./ParkerSettings";
import ParkerTenantGroups from "./ParkerTenantGroups";
import ParkerVehicles from "./ParkerVehicles";
import ParkerWallet from "./ParkerWallet";
import ParkingPass from "./ParkingPass";

export const parkerViewPath = "/me";

export default function ParkerView() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/organizations`}>
        <Organizations />
      </Route>
      <Route path={`${path}/tenant-groups`}>
        <ParkerTenantGroups />
      </Route>
      <HeaderSidebarContent
        navbar={<NavBar />}
        sidebar={<ParkerSidebar baseUrl={parkerViewPath} />}
      >
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/passes`} />
          </Route>
          <Route path={`${path}/passes`}>
            <ParkingPass />
          </Route>
          <Route path={`${path}/wallet`}>
            <ParkerWallet />
          </Route>
          <Route path={`${path}/vehicles`}>
            <ParkerVehicles />
          </Route>
          <Route exact path={`${path}/history`}>
            <ParkerHistory />
          </Route>
          <Route exact path={`${path}/settings`}>
            <ParkerSettings />
          </Route>
          <Route>
            <NotFoundContent />
          </Route>
        </Switch>
      </HeaderSidebarContent>
    </Switch>
  );
}
