import { PlusCircleOutlined } from "@ant-design/icons";
import { Form, Select, Space } from "antd";
import _ from "lodash";
import * as React from "react";

import { Vehicle } from "../../hooks/useVehicles";
import VehicleInfoFormItems from "./VehicleInfoFormItems";

const NEW_VEHICLE_ID = "__new_vehicle";

interface Props {
  savedVehicles: Vehicle[] | undefined;
  selectedVehicleId: string | undefined; // undefined for new vehicle
  vehicleData: Omit<Vehicle, "id" | "user_id"> | undefined; // undefined if no data yet
  onChange: (
    vehicleId: string | undefined,
    vehicleData: Omit<Vehicle, "id" | "user_id"> | undefined
  ) => void;
}

export default function SelectVehicleFormItems(props: Readonly<Props>) {
  function handleSelectedVehicleChange(value: string) {
    if (value === NEW_VEHICLE_ID) {
      props.onChange(undefined, undefined);
      return;
    }
    const vehicle = _.find(props.savedVehicles, { id: value });
    if (!vehicle) {
      throw new Error(
        `Couldn't find the selected vehicle with id ${value}. This shouldn't happen and is a bug.`
      );
    }
    props.onChange(vehicle.id, undefined);
  }

  function handleNewVehicleInfoChange(values: Omit<Vehicle, "id" | "user_id">) {
    props.onChange(undefined, values);
  }

  if (props.savedVehicles && props.savedVehicles.length > 0) {
    return (
      <>
        <Form.Item
          label="Select a vehicle"
          required
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please select a vehicle or add a new one",
            },
          ]}
        >
          <Select
            value={props.selectedVehicleId || NEW_VEHICLE_ID}
            onChange={handleSelectedVehicleChange}
          >
            {props.savedVehicles.map((vehicle) => (
              <Select.Option key={vehicle.id} value={vehicle.id}>
                {vehicle.name} - {vehicle.license_plate_number}
              </Select.Option>
            ))}
            <Select.Option key={NEW_VEHICLE_ID} value={NEW_VEHICLE_ID}>
              <Space>
                <PlusCircleOutlined />
                Add a new vehicle
              </Space>
            </Select.Option>
          </Select>
        </Form.Item>
        {props.selectedVehicleId === undefined && (
          <VehicleInfoFormItems
            values={props.vehicleData}
            onValuesChange={handleNewVehicleInfoChange}
          />
        )}
      </>
    );
  }

  return (
    <VehicleInfoFormItems
      values={props.vehicleData}
      onValuesChange={handleNewVehicleInfoChange}
    />
  );
}
