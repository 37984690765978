import { useEffect, useState } from "react";
import useSwr from "swr";

import notify from "../utils/notify";
import useIdsFetch from "./useIdsFetch";
import { PaymentMethod, Source, Wallet } from "./useWallet";

function getWalletUrl(tenantGroupId: string) {
  return `/tenant-groups/${tenantGroupId}/wallet`;
}

export const useTenantGroupWallet = ({
  tenantGroupId,
}: {
  tenantGroupId: string;
}) => {
  const idsFetch = useIdsFetch();
  const path = `${getWalletUrl(tenantGroupId)}/`;
  const key = path ? path : null;
  const [created, setCreated] = useState<Wallet>();
  const { data: wallet, error, mutate } = useSwr<Wallet>(key);

  let finalError;
  if (!tenantGroupId) {
    finalError = new Error("tenantGroupId is required.");
  } else if (error?.response?.status !== 404) {
    finalError = error;
  }

  useEffect(() => {
    const createWallet = async () => {
      try {
        const created = await idsFetch(path, { method: "POST" });
        setCreated(created);
        mutate();
      } catch (err) {
        notify.error("Failed to create wallet", err);
      }
    };
    if (error?.response?.status === 404) {
      createWallet();
    }
  }, [path, error, idsFetch, mutate]);
  return {
    wallet: wallet || created,
    error: finalError,
  };
};

export function useTenantGroupPaymentMethods(tenantGroupId: string) {
  return useTenantGroupPayments<PaymentMethod>(
    tenantGroupId,
    `${getWalletUrl(tenantGroupId)}/payment-methods/`
  );
}

export function useTenantGroupSources(tenantGroupId: string) {
  return useTenantGroupPayments<Source>(
    tenantGroupId,
    `${getWalletUrl(tenantGroupId)}/sources/`
  );
}

function useTenantGroupPayments<T>(tenantGroupId: string, apiPath: string) {
  const idsFetch = useIdsFetch();
  const { wallet, error: errorWallet } = useTenantGroupWallet({
    tenantGroupId,
  });
  const { data, error, mutate } = useSwr<T[]>(wallet ? apiPath : null);

  const create = async (data: object): Promise<T> => {
    const created = await idsFetch(apiPath, {
      method: "POST",
      body: data,
    });
    mutate();
    return created;
  };

  const remove = async (paymentId: string) => {
    await idsFetch(`${apiPath}${paymentId}/`, { method: "DELETE" });
    mutate();
  };

  return {
    data,
    error: error || errorWallet,
    create,
    remove,
  };
}

export function useTenantGroupPaymentMethod(
  tenantGroupId: string,
  paymentMethodId: string
) {
  return useTenantGroupPayment<PaymentMethod>(
    tenantGroupId,
    paymentMethodId,
    `${getWalletUrl(tenantGroupId)}/payment-methods/${paymentMethodId}/`
  );
}

export function useTenantGroupSource(tenantGroupId: string, sourceId: string) {
  return useTenantGroupPayment<Source>(
    tenantGroupId,
    sourceId,
    `${getWalletUrl(tenantGroupId)}/sources/${sourceId}/`
  );
}

function useTenantGroupPayment<T>(
  tenantGroupId: string,
  paymentId: string,
  apiPath: string
) {
  const idsFetch = useIdsFetch();
  const {
    data: payment,
    error,
    mutate,
  } = useSwr<T>(tenantGroupId && paymentId ? apiPath : null);

  const modify = async (data: Record<"name" | "description", string>) => {
    const updated = await idsFetch(apiPath, {
      method: "PUT",
      body: data,
    });
    mutate();
    return updated;
  };

  const verify = async (data: Record<"amounts", string[]>): Promise<T> => {
    const updated = await idsFetch(`${apiPath}verify/`, {
      method: "POST",
      body: data,
    });
    mutate();
    return updated;
  };

  const remove = async () => {
    await idsFetch(apiPath, { method: "DELETE" });
    mutate();
  };

  return { payment, error, modify, verify, remove };
}
