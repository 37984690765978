import useSwr from "swr";

import { ParkerProfile } from "../components/ParkersList";
import useIdsFetch from "./useIdsFetch";
import { Purchase } from "./useUserPurchases";

const pathList = (tenantGroupId: string, selectedState: string) =>
  `/tenant-groups/${tenantGroupId}/parkers/?status=${selectedState}`;
const pathPurchaseCancel = (
  tenantGroupId: string,
  profileId: string,
  purchaseId: string
) =>
  `/tenant-groups/${tenantGroupId}/parkers/${profileId}/purchases/${purchaseId}/cancel/`;
const pathParkerPurchase = (tenantGroupId: string, profileId: string) =>
  `/tenant-groups/${tenantGroupId}/parkers/${profileId}/purchases/`;
const useTenantGroupParkers = ({
  tenantGroupId,
  selectedState,
  onError = () => {},
}: {
  tenantGroupId: string;
  selectedState: string;
  onError?: (err: Error) => void;
}) => {
  const { data: parkers, mutate } = useSwr<ParkerProfile[]>(
    pathList(tenantGroupId, selectedState),
    { onError }
  );
  const revalidate = () => mutate();
  return {
    parkers,
    revalidate,
  };
};

export function useTenantGroupsParkersPurchases(
  tenantGroupId: string,
  profileId: string
) {
  const {
    data: parkerPurchases,
    error,
    mutate,
  } = useSwr<Purchase[]>(pathParkerPurchase(tenantGroupId, profileId));
  const idsFetch = useIdsFetch();
  const revalidate = () => mutate();
  const deactivate = async (purchaseId: string) => {
    await idsFetch(pathPurchaseCancel(tenantGroupId, profileId, purchaseId), {
      method: "POST",
    });
  };
  return {
    parkerPurchases,
    error,
    revalidate,
    deactivate,
  };
}

export default useTenantGroupParkers;
