import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  PageHeader,
  Radio,
  Result,
  Row,
  Skeleton,
  Space,
  Switch as InputSwitch,
  Typography,
} from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Invitation, Membership } from "../hooks/useMembers";
import {
  Location,
  useOrganizationLocations,
} from "../hooks/useOrganizationLocations";
import {
  organizationMembershipPath,
  useOrganizationInvitation,
  useOrganizationInvitations,
  useOrganizationMembership,
  useOrganizationMemberships,
} from "../hooks/useOrganizationMemberships";
import LocationTable from "./LocationTable";
import { Done, Invite, Members, Setup, Steps } from "./Memberships";
import NotFoundContent from "./NotFoundContent";

const OrganizationMembersList = ({
  organizationId,
  onCreateClick,
  onRoleManageClick,
}: {
  organizationId: string;
  onCreateClick: () => void;
  onRoleManageClick: (id: string) => void;
}) => {
  const { memberships, revalidate } = useOrganizationMemberships({
    organizationId,
  });
  const { remove } = useOrganizationMembership({ organizationId });
  return (
    <Members
      members={memberships}
      isLoading={memberships ? false : true}
      onDeactivate={async (membershipId: string) => {
        await remove(organizationMembershipPath(organizationId, membershipId));
        await revalidate();
      }}
      onCreate={onCreateClick}
      onManageRole={onRoleManageClick}
    />
  );
};

const roleNamesGrouping: Record<string, string> = {
  admin: "Settings",
  viewer: "Settings",
  accounting_admin: "Accounting",
  accounting_viewer: "Accounting",
  location_admin: "Locations",
  location_viewer: "Locations",
  membership_admin: "Members",
  membership_viewer: "Members",
  parker_admin: "Parkers",
  parker_viewer: "Parkers",
  parking_product_admin: "Parking Products",
  parking_product_viewer: "Parking Products",
  payout_account_admin: "Payout Accounts",
  payout_account_viewer: "Payout Accounts",
  tenant_group_admin: "Tenant Groups",
  tenant_group_viewer: "Tenant Groups",
  shared_resource_admin: "Shared Resources",
  shared_resource_viewer: "Shared Resources",
  enforcement_admin: "Enforcement",
  enforcement_viewer: "Enforcement",
};
const groupedRoleNames = _.groupBy(
  _.keys(roleNamesGrouping),
  (key) => roleNamesGrouping[key]
);

export const RoleForm = ({
  updateRoles,
  roleNames = [],
  isLoading = false,
  onCancel,
  cancelButtonText = "Cancel",
}: {
  updateRoles: (roleNames: string[]) => void;
  roleNames: string[];
  isLoading?: boolean;
  onCancel?: () => void;
  cancelButtonText?: string;
}) => {
  const initialValues = _.mapValues(groupedRoleNames, (names) =>
    _.first(_.intersection(names, roleNames))
  );
  return (
    <Form
      initialValues={initialValues}
      onFinish={(values) =>
        updateRoles(_(values).values().filter(Boolean).value())
      }
    >
      <Row justify="center">
        {_(groupedRoleNames)
          .toPairs()
          .map(([label, [adminValue, viewerValue]]) => {
            return (
              <Form.Item
                name={label}
                label={label}
                colon
                key={label}
                labelCol={{ span: 8 }}
              >
                <Radio.Group>
                  <Radio value={adminValue} disabled={isLoading}>
                    Admin
                  </Radio>
                  <Radio value={viewerValue} disabled={isLoading}>
                    Viewer
                  </Radio>
                  <Radio value={undefined} disabled={isLoading}>
                    No Access
                  </Radio>
                </Radio.Group>
              </Form.Item>
            );
          })
          .chunk(_.ceil(_.divide(_.keys(groupedRoleNames).length, 2)))
          .map((items) => {
            return (
              <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                {items}
              </Col>
            );
          })
          .value()}
      </Row>
      <Row justify="start">
        <Col offset={1}>
          <Space direction="horizontal">
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              Update
            </Button>
            {onCancel && (
              <Button type="default" onClick={onCancel}>
                {cancelButtonText}
              </Button>
            )}
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

const OrganizationMemberManagement = ({
  organizationId,
  membershipId,
  onBackClick,
}: {
  organizationId: string;
  membershipId: string;
  onBackClick: () => void;
}) => {
  const [error, setError] = useState<Error>();
  const [errorLocations, setErrorLocations] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasLocationsFiltered, setHasLocationsFiltered] = useState<boolean>();
  const [filteredLocations, setFilteredLocations] =
    useState<Pick<Location, "id">[]>();
  const { locations } = useOrganizationLocations({
    organizationId,
    onError: setErrorLocations,
  });
  const { membership, update } = useOrganizationMembership({
    organizationId,
    membershipId,
    onError: setError,
  });
  const localChanges = !!_.xorBy(
    filteredLocations,
    membership?.allowed_locations,
    "id"
  ).length;
  useEffect(() => {
    if (membership?.allowed_locations)
      setFilteredLocations((existing) =>
        _.isUndefined(existing) ? membership.allowed_locations : existing
      );
    if (membership?.has_locations_filtered)
      setHasLocationsFiltered((existing) =>
        _.isUndefined(existing) ? membership.has_locations_filtered : existing
      );
  }, [membership, localChanges]);
  const updateMembership = async (
    data: Pick<Membership, "role_names" | "allowed_locations">
  ) => {
    setIsLoading(true);
    try {
      await update(data);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };
  if (error)
    return (
      <Card>
        <Result
          title="Could not update roles"
          status="error"
          extra={
            <Button type="primary" onClick={() => setError(undefined)}>
              Try Again
            </Button>
          }
        />
      </Card>
    );
  if (membership) {
    const { role_names: roleNames, user_profile: userProfile } = membership;

    return (
      <Card
        title={
          <PageHeader
            title={`Member Settings`}
            subTitle={userProfile.email}
            onBack={onBackClick}
          />
        }
      >
        <Row>
          <Col offset={1}>
            <Typography.Title level={3}>Roles </Typography.Title>
          </Col>
        </Row>
        <RoleForm
          roleNames={roleNames}
          updateRoles={(role_names) =>
            updateMembership({ ...membership, role_names })
          }
          isLoading={isLoading}
          cancelButtonText="Done"
          onCancel={onBackClick}
        />
        <Divider />
        <Row>
          <Col offset={1}>
            <Typography.Title level={3}>Locations</Typography.Title>
          </Col>
        </Row>
        <Form
          onFinish={() => {
            filteredLocations &&
              updateMembership({
                ...membership,
                allowed_locations: filteredLocations,
              });
          }}
        >
          <Row justify="center">
            <Col span={22}>
              <Form.Item label="Filter Locations">
                <InputSwitch
                  checked={hasLocationsFiltered}
                  onChange={(checked) => {
                    setHasLocationsFiltered(checked);
                    setFilteredLocations((existing) =>
                      checked ? existing : []
                    );
                  }}
                />
              </Form.Item>
            </Col>
            {hasLocationsFiltered ? (
              <Col span={22}>
                <LocationTable
                  selectedLocationIds={_.map(filteredLocations, "id")}
                  locations={locations}
                  error={errorLocations}
                  isLoading={!locations}
                  onSelectionChange={async (locationIds: string[]) =>
                    setFilteredLocations(_.map(locationIds, (id) => ({ id })))
                  }
                />
              </Col>
            ) : null}
            <Col span={22}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={!localChanges}
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }
  return (
    <Card>
      <Skeleton />
    </Card>
  );
};

export const OrganizationMemberInvite = ({
  create,
  update,
  onDone,
}: {
  create: (data: Partial<Invitation>) => Promise<Invitation>;
  update: (
    data: Partial<Invitation>,
    invitationId: string
  ) => Promise<Invitation>;
  onDone: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [invitation, setInvitation] = useState<Invitation>();
  const [roleNames, setRoleNames] = useState<boolean>();
  const [done, setDone] = useState<boolean>();
  const createInvitation = async (data: Partial<Invitation>) => {
    setIsLoading(true);
    try {
      const invitation = await create(data);
      setInvitation(invitation);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };
  const updateInvitation = async (
    roleNames: string[],
    invitationId: string
  ) => {
    setIsLoading(true);
    try {
      const invitation = await update({ role_names: roleNames }, invitationId);
      setInvitation(invitation);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };
  if (error)
    return (
      <Result
        status="error"
        title="Something went wrong"
        extra={<Button onClick={() => setError(undefined)}>Try Again</Button>}
      ></Result>
    );
  if (done)
    return (
      <Done
        onDone={onDone}
        onNewInvitation={() => {
          setInvitation(undefined);
          setDone(undefined);
          setRoleNames(undefined);
        }}
      />
    );
  if (invitation && !roleNames)
    return (
      <Steps currentStep={0} isLoading={isLoading}>
        <RoleForm
          roleNames={invitation.role_names}
          isLoading={isLoading}
          updateRoles={(roleNames) =>
            updateInvitation(roleNames, invitation.id)
          }
          cancelButtonText="Next"
          onCancel={() => setRoleNames(true)}
        />
      </Steps>
    );
  if (invitation)
    return <Invite invitation={invitation} onNext={() => setDone(true)} />;

  return <Setup isLoading={isLoading} onFinish={createInvitation} />;
};

const OrganizationMembers = ({
  organizationId,
}: {
  organizationId: string;
}) => {
  const { path, url } = useRouteMatch();
  const { create } = useOrganizationInvitations({ organizationId });
  const { update } = useOrganizationInvitation({ organizationId });
  return (
    <Switch>
      <Route
        exact
        path={path}
        render={({ history }) => (
          <OrganizationMembersList
            organizationId={organizationId}
            onCreateClick={() => history.push(`${url}/invite`)}
            onRoleManageClick={(id: string) => history.push(`${url}/${id}`)}
          />
        )}
      />
      <Route
        exact
        path={`${path}/invite`}
        render={({ history }) => (
          <Card
            title={
              <PageHeader
                title="Invite Member"
                onBack={() => history.push(url)}
              />
            }
          >
            <Row>
              <Col xl={18} lg={24} md={24} sm={24} xs={24}>
                <OrganizationMemberInvite
                  create={create}
                  update={update}
                  onDone={() => history.push(url)}
                />
              </Col>
            </Row>
          </Card>
        )}
      />
      <Route
        exact
        path={`${path}/:membershipId`}
        render={({ match, history }) => (
          <OrganizationMemberManagement
            organizationId={organizationId}
            membershipId={match.params.membershipId}
            onBackClick={() => history.push(url)}
          />
        )}
      ></Route>
      <Route>
        <NotFoundContent />
      </Route>
    </Switch>
  );
};

export default OrganizationMembers;
