import useSWR from "swr";
import { ParkingSessionEvent } from "./useOrganizationLocationIntegration";
import { ParkingPolicy, Purchase } from "./useUserPurchases";

export interface ParkingSession {
  id: string;
  location: Location | null;
  purchase: Purchase;
  parking_policy: ParkingPolicy;
  parking_session_events: ParkingSessionEvent[];
}

const locationParkingEventsPath = (
  organizationId: string,
  startEpoch: number,
  endEpoch: number
) =>
  `/organizations/${organizationId}/locations/parking-session-events/?start_epoch=${startEpoch}&end_epoch=${endEpoch}`;

export const useOrganizationLocationEvents = ({
  organizationId,
  startEpoch,
  endEpoch,
  onError,
}: {
  organizationId: string;
  startEpoch: number;
  endEpoch: number;
  onError?: (err: Error) => void;
}) => {
  const { data, error, mutate } = useSWR<ParkingSession[]>(
    locationParkingEventsPath(organizationId, startEpoch, endEpoch),
    { onError }
  );

  return {
    parkingSessions: data,
    error,
    revalidate: () => mutate,
  };
};
