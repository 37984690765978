export const organizationViewPath = "/organizations/:organizationId";
export function getOrganizationViewUrl(organizationId: string) {
  return `/organizations/${organizationId}`;
}

export const tenantGroupViewPath = "/tenant-groups/:tenantGroupId";
export function getTenantGroupViewUrl(tenantGroupId: string) {
  return `/tenant-groups/${tenantGroupId}`;
}

export const invitationClaimPath = "/invitations/:invitationId";
