import * as React from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import NotFoundContent from "../NotFoundContent";
import PassesView from "./PassesView";
import PassUpdate from "./PassUpdate";

export default function ParkingPasses() {
  const { path } = useRouteMatch();
  const history = useHistory();
  return (
    <Switch>
      <Route exact path={path}>
        <PassesView
          onViewPass={(id: string) => history.push(`${path}/${id}`)}
          onMakeChangesClick={(id: string) =>
            history.push(`${path}/${id}/update`)
          }
          onCancelSuccess={() => history.push(path)}
        />
      </Route>
      <Route
        exact
        path={`${path}/:passId`}
        render={({ match }) => (
          <PassesView
            passId={match.params.passId}
            onViewPass={(id: string) => history.push(`${path}/${id}`)}
            onMakeChangesClick={(id: string) =>
              history.push(`${path}/${id}/update`)
            }
            onCancelSuccess={() => history.push(path)}
          />
        )}
      />
      <Route
        exact
        path={`${path}/:passId/update`}
        render={({ match }) => (
          <PassUpdate
            passId={match.params.passId}
            onUpdate={() => history.push(`${path}/${match.params.passId}`)}
          />
        )}
      />
      <Route>
        <NotFoundContent />
      </Route>
    </Switch>
  );
}
