import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Result,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import _ from "lodash";
import * as React from "react";

import {
  ConnectionDataField,
  useLocationGateAccess,
  useVendors,
} from "../../hooks/useGateAccess";
import notify from "../../utils/notify";

interface Props {
  organizationId: string;
  locationId: string;
  next: () => void;
}

export default function LocationAccess(props: Readonly<Props>) {
  const { vendors, error: vendorsError } = useVendors();
  const {
    locationGateAccess,
    updateLocationGateAccess,
    mutate,
    error: locationVendorError,
  } = useLocationGateAccess(props.organizationId, props.locationId);
  const [selectedVendorId, setSelectedVendorId] = React.useState<string>();
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    if (locationGateAccess) {
      setSelectedVendorId(locationGateAccess.vendor_id);
    } else {
      setSelectedVendorId(undefined);
    }
  }, [locationGateAccess, setSelectedVendorId]);

  async function handleSubmit(values: any) {
    try {
      setIsFetching(true);
      await updateLocationGateAccess({
        ...values,
        vendor_id: selectedVendorId,
      });
      setIsFetching(false);
      props.next();
    } catch (err) {
      notify.error("Failed to save location access information", err);
      setIsFetching(false);
    } finally {
      mutate();
    }
  }

  if (
    vendorsError ||
    (locationVendorError && locationVendorError.response?.status !== 404)
  ) {
    return (
      <Result
        status="error"
        title="Failed to load location access information"
        subTitle={(vendorsError || locationVendorError).message}
      />
    );
  }

  const selectedVendor = _.find(vendors, { id: selectedVendorId });
  return (
    <Spin spinning={vendors === undefined || isFetching}>
      <Form
        key={selectedVendorId}
        initialValues={locationGateAccess}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          required
          label="System"
          rules={[{ required: true, message: "Please select a vendor" }]}
        >
          <Select value={selectedVendorId} onChange={setSelectedVendorId}>
            {vendors?.map((vendor) => (
              <Select.Option key={vendor.id} value={vendor.id}>
                {vendor.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {vendors &&
          _.filter(
            selectedVendor?.connection_data_fields,
            "is_location_specific"
          ).map((field) => (
            <Form.Item
              key={field.id}
              required
              name={["connection_data", field.field_key]}
              label={
                field.field_value_type === "boolean"
                  ? undefined
                  : field.field_label
              }
              valuePropName={
                field.field_value_type === "boolean" ? "checked" : undefined
              }
              rules={[
                { required: true, message: `${field.field_label} is required` },
              ]}
            >
              {getInputForConnectionDataField(field)}
            </Form.Item>
          ))}
        {selectedVendor?.documentation_url && (
          <Typography.Paragraph>
            <Typography.Text type="secondary">Instructions:</Typography.Text>{" "}
            <Typography.Link
              href={selectedVendor.documentation_url}
              target="_blank"
            >
              {selectedVendor.documentation_url}
            </Typography.Link>
          </Typography.Paragraph>
        )}
        <p>
          <Typography.Text type="secondary">
            If you do not know your access information for{" "}
            {selectedVendor?.name}, please contact your vendor representative
            and smartpass@smarking.com to grant Smarking access to the location.
            Feel free to skip and return to this page to enter this information
            later.
          </Typography.Text>
        </p>
        <div style={{ textAlign: "right" }}>
          <Space>
            <Button htmlType="submit" type="primary" disabled={isFetching}>
              Next
            </Button>
            <Button onClick={props.next}>Skip</Button>
          </Space>
        </div>
      </Form>
    </Spin>
  );
}

function getInputForConnectionDataField(field: ConnectionDataField) {
  if (field.field_value_type === "options") {
    return (
      <Select>
        {field.field_value_configs.options?.map(
          (option: { label: string; value: any }) => (
            <Select.Option value={option.value}>{option.label}</Select.Option>
          )
        )}
      </Select>
    );
  }
  if (field.field_value_type === "boolean") {
    return <Checkbox>{field.field_label}</Checkbox>;
  }
  if (field.field_value_type === "number") {
    return <InputNumber style={{ width: "100%" }} />;
  }
  if (field.field_value_type === "secret") {
    return <Input.Password />;
  }
  return <Input />;
}
