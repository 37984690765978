import moment from "moment-timezone";
import { ParkingSession } from "../hooks/useOrganizationLocationEvents";
import ResponsiveList from "./ResponsiveList";

export interface DisplayParkingSession extends ParkingSession {
  entryTime: moment.Moment;
  exitTime: moment.Moment;
}

const timeRender = (time: moment.Moment) =>
  time.isValid() ? time.format("LLL") : "";
const caluclateDuration = (id: string, session: DisplayParkingSession) =>
  session.entryTime.isValid()
    ? session.exitTime.isValid()
      ? `${session.exitTime.diff(session.entryTime, "minutes")} minutes`
      : "No exit time"
    : "No entry time";

const ParkerHistoryList = ({
  parkingSessions,
}: {
  parkingSessions: DisplayParkingSession[];
}) => {
  return (
    <ResponsiveList<DisplayParkingSession>
      columns={[
        {
          title: "Name",
          dataIndex: ["parking_policy", "purchase", "user_profile", "name"],
          colSpan: 2,
        },
        {
          title: "Email",
          dataIndex: ["parking_policy", "purchase", "user_profile", "email"],
          colSpan: 2,
        },
        {
          title: "Product",
          dataIndex: [
            "parking_policy",
            "purchase",
            "price",
            "parking_product",
            "name",
          ],
          colSpan: 3,
        },
        {
          title: "Location",
          dataIndex: ["parking_policy", "location", "name"],
          colSpan: 3,
        },
        {
          title: "License Plate",
          dataIndex: ["parking_policy", "vehicle", "license_plate_number"],
          colSpan: 3,
        },
        {
          title: "Entry",
          dataIndex: ["entryTime"],
          colSpan: 4,
          render: timeRender,
        },
        {
          title: "Exit",
          dataIndex: ["exitTime"],
          colSpan: 4,
          render: timeRender,
        },
        {
          title: "Duration",
          colSpan: 2,
          render: caluclateDuration,
        },
      ]}
      dataSource={parkingSessions}
      rowKey="id"
    />
  );
};

export default ParkerHistoryList;
