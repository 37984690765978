import { Button, Card, Form, Input, Spin } from "antd";
import * as React from "react";

import { Source } from "../../hooks/useWallet";
import notify from "../../utils/notify";

interface Props {
  source: Source | undefined;
  onVerify: (amount1: string, amount2: string) => Promise<void>;
}

export default function VerifyBankPayment(props: Readonly<Props>) {
  const [isFetching, setIsFetching] = React.useState(false);

  async function save(values: { amount1: string; amount2: string }) {
    setIsFetching(true);
    try {
      await props.onVerify(
        dollarsToCents(values.amount1),
        dollarsToCents(values.amount2)
      );
      notify.success("Successfully verified the bank account");
    } catch (err) {
      notify.error("Failed to verify the account", err);
    } finally {
      setIsFetching(false);
    }
  }
  return (
    <div style={{ maxWidth: "42rem", margin: "0 auto" }}>
      <Spin spinning={props.source === undefined || isFetching}>
        <Card
          key={props.source ? props.source.id : "loading"}
          title={`Verify Bank Account: ${props.source?.name}`}
          style={{ width: "100%" }}
        >
          <p>
            Please check your bank account for two small deposits from Stripe.
            Input the amounts below to verify your account.
          </p>
          <Form layout="vertical" initialValues={props.source} onFinish={save}>
            <Form.Item
              label="Bank"
              name={["stripe_source", "data", "bank_name"]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="First amount"
              name="amount1"
              rules={[{ required: true, message: "Amount is required" }]}
            >
              <Input prefix="$" autoFocus />
            </Form.Item>
            <Form.Item
              label="Second amount"
              name="amount2"
              rules={[{ required: true, message: "Amount is required" }]}
            >
              <Input prefix="$" />
            </Form.Item>
            <div style={{ textAlign: "center", marginTop: "2rem" }}>
              <Button type="primary" htmlType="submit">
                Verify
              </Button>
            </div>
          </Form>
        </Card>
      </Spin>
    </div>
  );
}

function dollarsToCents(val: string): string {
  return (Number(val) * 100).toFixed();
}
