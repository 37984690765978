import React from "react";

import HeaderContent from "./Layouts/HeaderContent";
import NavBar from "./NavBar";
import NotFoundContent from "./NotFoundContent";

export default function NotFoundFullPage() {
  return (
    <HeaderContent navbar={<NavBar />}>
      <NotFoundContent />
    </HeaderContent>
  );
}
