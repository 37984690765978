import { useCallback } from "react";
import useSWR from "swr";

import useIdsFetch from "./useIdsFetch";
import { Organization } from "./useOrganization";

export const useUserOrganizations = ({
  onError = () => {},
}: {
  onError?: (err: Error) => void;
} = {}) => {
  const idsFetch = useIdsFetch();
  const { data, mutate } = useSWR<Organization[]>(`/me/organizations/`, {
    onError,
  });
  const create = useCallback(
    async (data: Partial<Organization>) => {
      return await idsFetch(`/me/organizations/`, {
        method: "POST",
        body: data,
      });
    },
    [idsFetch]
  );
  const revalidate = useCallback(() => mutate(), [mutate]);
  return { organizations: data, create, revalidate };
};
