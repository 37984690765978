import { Button, Result, Space } from "antd";
import * as React from "react";

import InvitationFrame from "./InvitationFrame";

interface Props {
  onInviteSomeoneElse: () => void;
  onBackToParkingProducts: () => void;
}

export default function Done(props: Props) {
  return (
    <InvitationFrame currentStep={2} isLoading={false}>
      <Result
        status="success"
        title="You are done!"
        extra={
          <Space>
            <Button type="primary" onClick={props.onInviteSomeoneElse}>
              Invite Someone Else
            </Button>
            <Button onClick={props.onBackToParkingProducts}>
              Back to Parking Products
            </Button>
          </Space>
        }
      />
    </InvitationFrame>
  );
}
