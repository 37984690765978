import * as React from "react";

import { useOrganizationAccounting } from "../hooks/useAccounting";
import AccountingList from "./AccountingList";

interface Props {
  organizationId: string;
}

export default function OrganizationAccounting(props: Props) {
  const { getAccountingItemsForTimeRange, requestRefund } =
    useOrganizationAccounting(props.organizationId);
  return (
    <AccountingList
      getAccountingItemsForTimeRange={getAccountingItemsForTimeRange}
      onRefund={requestRefund}
    />
  );
}
