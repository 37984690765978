import { BankOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Result, Row, Spin } from "antd";
import _ from "lodash";
import * as React from "react";
import { useHistory } from "react-router-dom";

import useIdsFetch from "../../hooks/useIdsFetch";
import {
  useUserPaymentMethods,
  useUserSources,
} from "../../hooks/useUserWallet";
import { PaymentMethod, Source } from "../../hooks/useWallet";
import BankPaymentPreview from "../PaymentMethods/BankPaymentPreview";
import CardPaymentPreview from "../PaymentMethods/CardPaymentPreview";
import VerticalSpace from "../VerticalSpace";

export default function WalletView() {
  const idsFetch = useIdsFetch();
  const history = useHistory();
  const [error, setError] = React.useState<Error>();

  const { paymentMethods, mutate: mutatePaymentMethods } =
    useUserPaymentMethods({ onError: setError });
  const { sources, mutate: mutateSources } = useUserSources({
    onError: setError,
  });

  if (error) {
    return (
      <Card>
        <Result
          status="error"
          title="Failed to load wallet"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/me")}
            >
              Home
            </Button>,
          ]}
        />
      </Card>
    );
  }

  const allMethods: (PaymentMethod | Source)[] = [
    ...(paymentMethods || []),
    ...(sources || []),
  ];

  if (paymentMethods && sources && allMethods.length === 0) {
    return (
      <Card>
        <Result
          title="Add your first payment method."
          icon={<BankOutlined />}
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/me/wallet/add")}
            >
              Add Payment Method
            </Button>
          }
        />
      </Card>
    );
  }

  return (
    <Spin spinning={paymentMethods === undefined || sources === undefined}>
      <VerticalSpace size="large">
        <Card>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => history.push("/me/wallet/add")}
          >
            Add Payment Method
          </Button>
        </Card>
        <Row gutter={16}>
          {_.sortBy(allMethods, "name").map((method) => (
            <Col
              key={method.id}
              xs={24}
              md={12}
              lg={12}
              xl={8}
              style={{ marginBottom: "2rem" }}
            >
              {_.includes(paymentMethods, method) ? (
                <CardPaymentPreview
                  paymentMethod={method as PaymentMethod}
                  onEdit={(paymentMethod: PaymentMethod) =>
                    history.push(
                      `/me/wallet/card-payment/${paymentMethod.id}/edit`
                    )
                  }
                  onDelete={async (paymentMethod: PaymentMethod) => {
                    await idsFetch(
                      `/me/wallet/payment-methods/${paymentMethod.id}/`,
                      {
                        method: "DELETE",
                      }
                    );
                    mutatePaymentMethods();
                  }}
                />
              ) : (
                <BankPaymentPreview
                  source={method as Source}
                  onEdit={(source: Source) =>
                    history.push(`/me/wallet/bank-payment/${source.id}/edit`)
                  }
                  onVerify={(source: Source) =>
                    history.push(`/me/wallet/bank-payment/${source.id}/verify`)
                  }
                  onDelete={async (source: Source) => {
                    await idsFetch(`/me/wallet/sources/${source.id}/`, {
                      method: "DELETE",
                    });
                    mutateSources();
                  }}
                />
              )}
            </Col>
          ))}
        </Row>
      </VerticalSpace>
    </Spin>
  );
}
