import { Button, Card, Result } from "antd";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";

import { useTenantGroupPaymentMethod } from "../../hooks/useTenantGroupWallet";
import { PaymentMethod } from "../../hooks/useWallet";
import UpdateCardPayment from "../PaymentMethods/UpdateCardPayment";

interface Props {
  tenantGroupId: string;
  paymentMethodId: string;
  baseUrl: string;
}

export default function UpdateTenantGroupCardPayment(props: Readonly<Props>) {
  const history = useHistory();
  const {
    payment: paymentMethod,
    error,
    modify,
  } = useTenantGroupPaymentMethod(props.tenantGroupId, props.paymentMethodId);

  async function updatePaymentMethod(values: Omit<PaymentMethod, "id">) {
    await modify(values);
    setTimeout(() => {
      history.push(props.baseUrl);
    }, 200);
  }

  if (error) {
    return (
      <Card>
        <Result
          status="error"
          title="Failed to load payment method"
          extra={
            <Button type="primary" key="console">
              <Link to="/me/wallet">Go to Wallet</Link>
            </Button>
          }
        />
      </Card>
    );
  }

  return (
    <UpdateCardPayment
      paymentMethod={paymentMethod}
      onUpdate={updatePaymentMethod}
    />
  );
}
