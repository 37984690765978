import { CreateTokenBankAccountData } from "@stripe/stripe-js";
import { Button, Form, Input, Select, Space } from "antd";
import * as React from "react";

const INITIAL_BANK_DATA = {
  country: "US",
  currency: "USD",
};

interface Props {
  onCreateBankPayment: (bankData: CreateTokenBankAccountData) => Promise<void>;
  CancelButton?: React.ReactElement;
}

export default function BankPayment(props: Readonly<Props>) {
  return (
    <Form
      layout="vertical"
      initialValues={INITIAL_BANK_DATA}
      onFinish={props.onCreateBankPayment}
    >
      <Form.Item
        name="routing_number"
        label="Routing Number"
        rules={[{ required: true, message: "Routing Number is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="account_number"
        label="Account Number"
        rules={[{ required: true, message: "Account Number is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="account_holder_name"
        label="Account Holder Name"
        rules={[{ required: true, message: "Account Holder Name is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="account_holder_type"
        label="Account Holder Type"
        rules={[{ required: true, message: "Account Holder Type is required" }]}
      >
        <Select>
          <Select.Option value="company" key="company">
            Company
          </Select.Option>
          <Select.Option value="individual" key="individual">
            Individual
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="country" label="Country">
        <Input disabled />
      </Form.Item>
      <Form.Item name="currency" label="Currency">
        <Input disabled />
      </Form.Item>

      <div style={{ textAlign: "center", marginTop: "2rem" }}>
        <Space size="middle">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          {props.CancelButton && props.CancelButton}
        </Space>
      </div>
    </Form>
  );
}
