import useIdsFetch from "./useIdsFetch";
import { UserProfile } from "./useUserProfile";

interface ChargeBase {
  id: string;
  created: number;
  amount: number;
  currency: string;
}

interface TransferReversal extends ChargeBase {}

export interface Transfer extends ChargeBase {
  reversals: TransferReversal[];
}

export interface Refund extends ChargeBase {
  reason: string;
  status: string;
  metadata?: {
    reason?: string;
    details?: string;
    refunderEmail?: string;
  };
  transfer_reversal: string | null;
}

export interface Charge extends ChargeBase {
  status: string;
  amount_captured?: number;
  amount_refunded?: number;
  application_fee_amount?: number;
  receipt_email: string;
  receipt_url: string | null;
  failure_message: string | null;
  refunds: Refund[];
}

export interface AccountingItem {
  id: string;
  user_profile?: UserProfile | null;
  location: { id: string; name: string } | null;
  parking_product: { id: string; name: string } | null;
  charge_for: string;
  charge: Charge;
  transfer?: Transfer | null;
}

const organizationAccountingPath = (organizationId: string) =>
  `/organizations/${organizationId}/accounting`;

export function useAccountingForAPI(api: string) {
  const idsFetch = useIdsFetch();

  return async (start_epoch: number, end_epoch: number) => {
    return await idsFetch(
      `${api}?start_epoch=${start_epoch}&end_epoch=${end_epoch}`
    );
  };
}

export function useOrganizationAccounting(organizationId: string) {
  const idsFetch = useIdsFetch();

  async function requestRefund(
    chargeId: string,
    amount: number,
    reason: string,
    details: string
  ): Promise<Refund> {
    const refund: Refund = await idsFetch(
      `${organizationAccountingPath(organizationId)}/refund/${chargeId}`,
      {
        method: "POST",
        body: { amount, reason, details },
      }
    );
    return refund;
  }

  return {
    getAccountingItemsForTimeRange: useAccountingForAPI(
      `${organizationAccountingPath(organizationId)}/charges/`
    ),
    requestRefund,
  };
}
