import { Button, Card, Result } from "antd";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import useSWR from "swr";

import useIdsFetch from "../../hooks/useIdsFetch";
import { PaymentMethod } from "../../hooks/useWallet";
import UpdateCardPayment from "../PaymentMethods/UpdateCardPayment";

interface Props {
  paymentMethodId: string;
}

export default function UpdateParkerCardPayment(props: Readonly<Props>) {
  const history = useHistory();
  const idsFetch = useIdsFetch();
  const {
    data: paymentMethod,
    error,
    mutate,
  } = useSWR<PaymentMethod>(
    `/me/wallet/payment-methods/${props.paymentMethodId}/`
  );

  async function updatePaymentMethod(values: Omit<PaymentMethod, "id">) {
    await idsFetch(`/me/wallet/payment-methods/${props.paymentMethodId}/`, {
      method: "PUT",
      body: values,
    });
    mutate();
    setTimeout(() => {
      history.push("/me/wallet");
    }, 200);
  }

  if (error) {
    return (
      <Card>
        <Result
          status="error"
          title="Failed to load payment method"
          extra={
            <Button type="primary" key="console">
              <Link to="/me/wallet">Go to Wallet</Link>
            </Button>
          }
        />
      </Card>
    );
  }

  return (
    <UpdateCardPayment
      paymentMethod={paymentMethod}
      onUpdate={updatePaymentMethod}
    />
  );
}
