import { Button, Card, Col, Form, Grid, Input, Result, Row } from "antd";
import _ from "lodash";
import * as React from "react";
import useSWR from "swr";

import useAddress, { Address } from "../hooks/useAddress";
import useIdsFetch, { ResponseError } from "../hooks/useIdsFetch";
import useOrganization from "../hooks/useOrganization";
import notify from "../utils/notify";
import AddressForm from "./AddressForm";
import ResponsiveInnerCard from "./Layouts/ResponsiveInnerCard";

export default function OrganizationSettings({
  organizationId,
}: {
  organizationId: string;
}) {
  return (
    <Card title="Update Settings">
      <Row justify="start">
        <Col {...ResponsiveInnerCard}>
          <SettingsForm />
        </Col>
      </Row>
    </Card>
  );
}

function SettingsForm() {
  const idsFetch = useIdsFetch();
  const [isFetching, setIsFetching] = React.useState(false);
  const { data: organization, mutate: mutateOrg } = useOrganization();
  const [address, setAddress] = React.useState<Omit<Address, "id">>();
  const [error, setError] = React.useState<Error>();
  const screens = Grid.useBreakpoint();
  const isBlock = !screens.md && (screens.sm || screens.xs);
  const apiUrl = `/organizations/${organization?.id}`;
  const { createDefaultAddress, updateDefaultAddress } = useAddress(apiUrl);

  const {
    data: defaultAddress,
    error: defaultAddressError,
    mutate: mutateAddress,
  } = useSWR<Address>(
    organization ? `/organizations/${organization.id}/default-address` : null
  );

  React.useEffect(() => {
    setAddress(_.omit(defaultAddress, "id"));
  }, [defaultAddress]);

  React.useEffect(() => {
    if (defaultAddressError instanceof ResponseError) {
      if (defaultAddressError.response.status === 404) {
        // Default address not set yet. It's ok.
        return;
      }
    }
    setError(defaultAddressError);
  }, [defaultAddressError]);

  async function handleSubmit(values: object) {
    setIsFetching(true);
    try {
      await idsFetch(apiUrl, { method: "PUT", body: values });
      if (address && !_.isEmpty(address)) {
        if (defaultAddress) {
          await updateDefaultAddress(defaultAddress.id, address);
        } else {
          await createDefaultAddress(address);
        }
      }
      notify.success("Successfully saved.");
    } catch (err) {
      notify.error("Failed to save organization information", err);
    } finally {
      mutateOrg();
      mutateAddress();
      setIsFetching(false);
    }
  }
  if (error)
    return (
      <Result
        status="error"
        title={`Some error occurred: ${error.message}`}
        extra={<Button onClick={() => setError(undefined)}>Back</Button>}
      />
    );

  return (
    <Form
      initialValues={organization}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: "Please input organization name." }]}
      >
        <Input disabled={isFetching} />
      </Form.Item>
      <AddressForm
        disabled={isFetching}
        address={address}
        onChange={setAddress}
      />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={isFetching}
          loading={isFetching}
          block={isBlock}
        >
          Update
        </Button>
      </Form.Item>
    </Form>
  );
}
