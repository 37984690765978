import * as React from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";

import NotFoundContent from "../NotFoundContent";
import AddParkerVehicle from "./AddParkerVehicle";
import AllParkerVehicles from "./AllParkerVehicles";
import EditParkerVehicle from "./EditParkerVehicle";

export default function ParkerVehicles() {
  const { path, url } = useRouteMatch();
  const history = useHistory();

  function gotoAllVehiclesPage() {
    setTimeout(() => {
      history.push(url);
    }, 200);
  }

  return (
    <Switch>
      <Route exact path={path}>
        <AllParkerVehicles />
      </Route>
      <Route exact path={`${path}/add`}>
        <AddParkerVehicle onSuccess={gotoAllVehiclesPage} />
      </Route>
      <Route
        path={`${path}/:vehicleId/edit`}
        render={({ match }) => (
          <EditParkerVehicle
            vehicleId={match.params.vehicleId}
            onError={gotoAllVehiclesPage}
            onSuccess={gotoAllVehiclesPage}
          />
        )}
      />
      <Route>
        <NotFoundContent />
      </Route>
    </Switch>
  );
}
