import useSWR from "swr";
import useIdsFetch from "./useIdsFetch";
import _ from "lodash";

export interface ParkingSessionEvent {
  id: string;
  event_time: string;
  to_status: string;
  from_status: string;
}

export interface IntegrationSession {
  id: string;
  created_at: string;
  code: string;
  parking_session_events: ParkingSessionEvent[];
}

export default function useOrganizationLocationIntegration(
  organizationId: string,
  locationId: string
) {
  const idsFetch = useIdsFetch();

  const basePath = `/organizations/${organizationId}/locations/${locationId}/integration-sessions`;
  const { data, mutate, error } = useSWR<IntegrationSession[]>(`${basePath}/`);

  async function createIntegrationSession() {
    const session = await idsFetch(`${basePath}/`, { method: "POST" });
    await mutate();
    return session as IntegrationSession;
  }

  async function refreshIntegrationSession(integrationSessionId: string) {
    const session = await idsFetch(`${basePath}/${integrationSessionId}`, {
      method: "PUT",
    });
    await mutate();
    return session as IntegrationSession;
  }

  return {
    // integrationSession is undefined when loading, null when no integration session
    integrationSession:
      data === undefined
        ? undefined
        : data.length <= 0
        ? null
        : _.last(_.sortBy(data, "created_at")),
    error,
    createIntegrationSession,
    refreshIntegrationSession,
  };
}
