import { Button, Card, Col, Divider, Form, Result, Row, Spin } from "antd";
import _ from "lodash";
import * as React from "react";

import {
  PricePreview,
  Purchase,
  useUserPurchase,
} from "../../hooks/useUserPurchases";
import {
  useUserAuthorizedPayments,
  useUserPaymentMethods,
  useUserSources,
} from "../../hooks/useUserWallet";
import useVehicles, { Vehicle } from "../../hooks/useVehicles";
import notify from "../../utils/notify";
import { LAYOUT_CONTENT_ID } from "../Layouts/HeaderContent";
import SelectVehicleFormItems from "../ParkerVehicles/SelectVehicleFormItems";
import PaymentDetails from "../PaymentDetails";
import {
  Checkout,
  CustomizeMaxDaysForm,
  CustomizeMaxSessionsForm,
  CustomizeParkingDaysForm,
  rruleFromDaysOfWeek,
} from "../ProductCheckout";

interface Props {
  passId: string;
  initialPurchase?: Purchase;
  onUpdate: () => void;
}

export default function PassUpdate(props: Readonly<Props>) {
  const [error, setError] = React.useState<Error>();
  const [isFetchingUpdate, setIsFetchingUpdate] = React.useState(false);
  const [isFetchingPreviewPrice, setIsFetchingPreviewPrice] =
    React.useState(false);

  const [days, setDays] = React.useState<number[]>();
  const [maxSessions, setMaxSessions] = React.useState<number>();
  const [maxDays, setMaxDays] = React.useState<number>();

  const [selectedVehicleId, setSelectedVehicleId] = React.useState<string>();
  const [vehicleData, setVehicleData] =
    React.useState<Omit<Vehicle, "id" | "user_id">>();
  const [previewPrice, setPreviewPrice] = React.useState<PricePreview>();

  const { vehicles, createVehicle } = useVehicles();
  const { paymentMethods } = useUserPaymentMethods({ onError: setError });
  const { sources } = useUserSources({ onError: setError });
  const { authorizedPayments } = useUserAuthorizedPayments({
    onError: setError,
  });
  const { purchase, pricePreview, update, revalidate } = useUserPurchase({
    purchaseId: props.passId,
    onError: setError,
  });

  React.useEffect(() => {
    const vehicle = purchase?.parking_policy?.vehicle;
    if (vehicle) {
      setSelectedVehicleId(vehicle.id);
      return;
    }
    if (vehicles && vehicles.length > 0) {
      setSelectedVehicleId(vehicles[0].id);
      return;
    }
    setSelectedVehicleId(undefined);
  }, [purchase, vehicles, setSelectedVehicleId]);

  const price = purchase?.price;
  const updatePurchase = async (data: Checkout) => {
    setIsFetchingUpdate(true);

    let vehicle_id = selectedVehicleId;
    if (vehicle_id === undefined) {
      if (
        vehicleData === undefined ||
        _.some(_.values(vehicleData), _.isEmpty)
      ) {
        notify.error(
          "Please input your vehicle information.",
          new Error("Vehicle information is required.")
        );
        setIsFetchingUpdate(false);
        return;
      }
      // Save the vehicle first
      try {
        const savedVehicle = await createVehicle(vehicleData);
        vehicle_id = savedVehicle.id;
        setSelectedVehicleId(vehicle_id);
      } catch (err) {
        notify.error("Failed to save the vehicle", err);
        setIsFetchingUpdate(false);
        return;
      }
    }

    const { payment_method_id, source_id } = data;
    try {
      // Scroll to the top first to make sure people see the payment is processing.
      document
        .getElementById(LAYOUT_CONTENT_ID)
        ?.scrollTo({ top: 0, behavior: "smooth" });
      await update({
        recurrence:
          purchase?.parking_policy.time_of_use_rules.length === 0
            ? rruleFromDaysOfWeek(days)
            : undefined,
        vehicle_id,
        maxDays,
        maxSessions,
        payment_method_id,
        source_id,
      });
      notify.success("Purchase updated successfully.");
      revalidate();
      props.onUpdate();
    } catch (err) {
      notify.error("Failed to update the pass", err);
    } finally {
      setIsFetchingUpdate(false);
      revalidate();
    }
  };

  function updateSelectedVehicle(
    vehicleId: string | undefined,
    vehicleData: Omit<Vehicle, "id" | "user_id"> | undefined
  ) {
    setSelectedVehicleId(vehicleId);
    setVehicleData(vehicleData);
  }

  const getPricePreview = React.useCallback(
    async (newDays: number[], newMaxDays, newMaxSessions) => {
      try {
        setIsFetchingPreviewPrice(true);
        const preview = await pricePreview(
          rruleFromDaysOfWeek(newDays),
          newMaxDays,
          newMaxSessions
        );
        setPreviewPrice(preview);
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsFetchingPreviewPrice(false);
        revalidate();
      }
    },
    [pricePreview, revalidate]
  );

  React.useEffect(() => {
    if (purchase) {
      setDays(purchase.valid_days_of_week);
      setMaxDays(purchase.parking_policy.max_days_per_billing_cycle);
      setMaxSessions(purchase.parking_policy.max_sessions_per_billing_cycle);
    }
  }, [purchase]);

  React.useEffect(() => {
    if (days) {
      getPricePreview(days, maxDays, maxSessions);
    }
  }, [getPricePreview, days, maxDays, maxSessions]);

  if (error) {
    return (
      <Card>
        <Result
          status="error"
          title="Something went wrong"
          subTitle={error.message}
          extra={[
            <Button
              key="try-again"
              type="primary"
              onClick={() => setError(undefined)}
            >
              Try Again
            </Button>,
          ]}
        />
      </Card>
    );
  }

  if (
    purchase &&
    price &&
    paymentMethods &&
    sources &&
    authorizedPayments &&
    days &&
    previewPrice
  ) {
    const initialValues = {
      days,
      maxDays: purchase.parking_policy.max_days_per_billing_cycle,
      maxSessions: purchase.parking_policy.max_sessions_per_billing_cycle,
    };

    const stripeSubscriptionData = purchase.stripe_subscription.data;
    const stripePaymentId =
      stripeSubscriptionData?.default_payment_method ||
      stripeSubscriptionData?.default_source;

    const authorizedPaymentsFiltered = _.filter(authorizedPayments, [
      "parking_product.id",
      purchase.price.parking_product?.id,
    ]);

    const selectedPaymentId = (
      _.find(
        [...paymentMethods, ..._.map(authorizedPayments, "payment_method")],
        (m) => m?.stripe_payment_method.data.id === stripePaymentId
      ) ||
      _.find(
        [...sources, ..._.map(authorizedPayments, "source")],
        (s) => s?.stripe_source.data.id === stripePaymentId
      )
    )?.id;

    const billingIntervalText = `Every ${purchase?.price?.recurring_count} ${purchase?.price?.recurring_interval}(s)`;
    const { base_cost, processing_fee } = previewPrice;

    return (
      <Spin
        spinning={isFetchingUpdate}
        size="large"
        tip={<h1>Processing payment, do not refresh</h1>}
      >
        <Card key={purchase?.id} title="Customize Your Parking">
          {price.is_max_sessions_customizable && (
            <Row>
              <Col span={24}>
                <CustomizeMaxSessionsForm
                  initialValues={initialValues}
                  onValuesChange={({
                    maxSessions,
                  }: {
                    maxSessions?: number;
                  }) => {
                    if (maxSessions) setMaxSessions(maxSessions);
                  }}
                  max={100}
                  disabled={isFetchingUpdate}
                  loading={isFetchingPreviewPrice}
                />
              </Col>
            </Row>
          )}
          {price.is_max_days_customizable && (
            <Row>
              <Col span={24}>
                <CustomizeMaxDaysForm
                  initialValues={initialValues}
                  onValuesChange={({ maxDays }: { maxDays?: number }) => {
                    if (maxDays) setMaxDays(maxDays);
                  }}
                  max={price.days_in_billing_period}
                  disabled={isFetchingUpdate}
                  loading={isFetchingPreviewPrice}
                />
              </Col>
            </Row>
          )}
          {price.is_time_of_use_customizable && (
            <Row>
              <Col span={24}>
                <CustomizeParkingDaysForm
                  allowed_days={price.allowed_days}
                  initialValues={initialValues}
                  onValuesChange={async ({ days }: { days: number[] }) => {
                    if (days) setDays(days);
                  }}
                  buttonDisabled={isFetchingUpdate}
                  buttonLoading={isFetchingPreviewPrice}
                />
              </Col>
            </Row>
          )}
          <p>
            Note: Any updates made will take effect immediately for your current
            subscription period.
          </p>
          <Divider />
          <h3>Vehicle Information</h3>
          <div>
            <Form layout="vertical">
              <SelectVehicleFormItems
                savedVehicles={vehicles}
                selectedVehicleId={selectedVehicleId}
                vehicleData={vehicleData}
                onChange={updateSelectedVehicle}
              />
            </Form>
          </div>
          <Divider />
          <h3>Payment Information</h3>
          <div>
            <PaymentDetails
              paymentMethods={paymentMethods}
              sources={sources}
              authorizedPayments={authorizedPaymentsFiltered}
              selectedPaymentId={selectedPaymentId}
              onFinish={updatePurchase}
              billingIntervalText={billingIntervalText}
              billingAmount={(base_cost + processing_fee) / 100}
              baseAmount={base_cost / 100}
              surchargeAmount={processing_fee / 100}
              recurringIterations={price.recurring_iterations}
              isLoading={!sources}
              isDisabled={isFetchingUpdate || isFetchingPreviewPrice}
            />
          </div>
        </Card>
      </Spin>
    );
  }
  return null;
}
