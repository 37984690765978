import { Button, Card, Result, Spin } from "antd";
import React from "react";
import { useOrganizationLocations } from "../../hooks/useOrganizationLocations";
import { usePayoutAccounts } from "../../hooks/usePayoutAccount";
import notify from "../../utils/notify";
import { Event } from "../../hooks/useOrganizationParkingProducts";
import EventForm from "./EventForm";

interface Props {
  organizationId: string;
  createEvent: (event: Partial<Event>) => Promise<Event>;
  onCreateSuccess: () => void;
}

export default function CreateEvent(props: Readonly<Props>) {
  const [error, setError] = React.useState<Error>();
  const [isFetching, setIsFetching] = React.useState(false);

  async function onCreate(event: Partial<Event>) {
    try {
      setIsFetching(true);
      await props.createEvent(event);
      notify.success("Successfully created new event.");
      setTimeout(() => {
        props.onCreateSuccess();
      }, 200);
    } catch (err) {
      notify.error("Failed to create new event.", err);
    } finally {
      setIsFetching(false);
    }
  }
  const { locations, revalidate: revalidateLocations } =
    useOrganizationLocations({
      organizationId: props.organizationId,
      onError: setError,
    });

  const { payoutAccounts, revalidate: revalidatePayoutAccounts } =
    usePayoutAccounts({
      organizationId: props.organizationId,
      onError: setError,
    });

  if (error) {
    return (
      <Card>
        <Result
          status="warning"
          title="Oops, an error occurred."
          subTitle={error.message}
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => {
                setError(undefined);
                revalidateLocations();
                revalidatePayoutAccounts();
              }}
            >
              Try Again
            </Button>
          }
        />
      </Card>
    );
  }

  return (
    <Spin spinning={!locations || !payoutAccounts || isFetching}>
      <Card>
        <EventForm
          isCreate
          locations={locations || []}
          payoutAccounts={payoutAccounts || []}
          onSubmit={onCreate}
        />
      </Card>
    </Spin>
  );
}
