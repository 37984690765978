import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  Result,
  Row,
  Space,
  Spin,
  Steps,
  Typography,
} from "antd";
import React, { useState } from "react";

import { getInvitationLink } from "../hooks/useInvitation";

interface Invitation {
  id: string;
  email: string;
  name?: string;
}

export function getMailToLink(invitation: Invitation, subject: string) {
  const firstName =
    invitation.name && invitation.name.length > 0
      ? invitation.name.split(" ")[0]
      : null;
  const body = encodeURIComponent(`${firstName ? `Hi ${firstName},` : "Hi,"}

Please use the following link to accept your invitation:

    ${getInvitationLink(invitation.id)}

Reply to this email if you have any questions. Thank you!`);
  return `mailto:${invitation.email}?subject=${subject}&body=${body}`;
}

function getEmailLinks(invitations: Invitation[]) {
  const lines = invitations.map(
    (i: Invitation) => `    ${i.email}    ${getInvitationLink(i.id)}`
  );
  return lines.join("\n");
}

function getMailAllLink(invitations: Invitation[], subject: string) {
  const emails = invitations.map((i) => i.email).join(", ");
  const body = encodeURIComponent(`Hi all,

Please find your email below and use the corresponding link to accept your invitation:

${getEmailLinks(invitations)}

You can only use your email to sign up with your link.

Reply to this email if you have any questions. Thank you!`);
  return `mailto:${emails}?subject=${subject}&body=${body}`;
}

export default function InvitationPage({
  onInviteSubmit,
  onDone,
  emailSubject,
  title,
}: {
  onInviteSubmit: (data: Omit<Invitation, "id">) => Promise<Invitation>;
  onDone: () => void;
  emailSubject: string;
  title: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [invitations, setInvitations] = useState<Invitation[]>();
  const currentStep = !invitations ? 0 : 1;
  const onFinish = async (values: { emails: string }) => {
    const emails = values.emails.split(",").map((email) => email.trim());
    try {
      setIsLoading(true);
      const requests = emails.map((email) => {
        return onInviteSubmit({ email, name: "" });
      });
      const results = await Promise.all(requests);
      setInvitations(results);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Spin spinning={isLoading}>
      <Card title={title}>
        <div style={{ margin: "0 auto", maxWidth: "40rem" }}>
          <Space style={{ width: "100%" }} size="large" direction="vertical">
            <Steps current={currentStep} status={error ? "error" : "process"}>
              <Steps.Step title="Create" description="Invite by email" />
              <Steps.Step title="Share" description="Email your invitation" />
            </Steps>
            {error && (
              <Result
                status="warning"
                title="Sorry, something went wrong"
                extra={
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => setError(undefined)}
                  >
                    Try Again
                  </Button>
                }
              />
            )}
            {!invitations && !error && (
              <InvitationForm
                layout="vertical"
                initialValues={onInviteSubmit}
                onFinish={onFinish}
              />
            )}
            {invitations && !error && (
              <InvitationShare
                invitations={invitations}
                onDone={onDone}
                emailSubject={emailSubject}
              />
            )}
          </Space>
        </div>
      </Card>
    </Spin>
  );
}

const InvitationForm = (props: Record<string, any>) => {
  const emailRegexp = /\S+@\S+\.\S+/;
  function validateEmails(strings: string[]) {
    strings.forEach((oneEmail: string) => {
      if (!emailRegexp.test(oneEmail)) {
        throw Error(`"${oneEmail}" is not a valid email.`);
      }
    });
  }

  return (
    <Form {...props}>
      <Form.Item
        label="Emails (Separate by commas)"
        name="emails"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: "Please provide emails.",
          },
          {
            validator: (_rule, value: string) => {
              const strings = value
                .split(",")
                .map((email) => email.trim())
                .filter((email) => email.length > 0);
              try {
                validateEmails(strings);
                return Promise.resolve();
              } catch (err) {
                return Promise.reject((err as Error).message);
              }
            },
          },
        ]}
      >
        <Input
          autoFocus
          placeholder="person1@example.com, person2@example.com, ..."
        />
      </Form.Item>
      <div style={{ marginTop: "2rem", textAlign: "center" }}>
        <Button htmlType="submit" type="primary">
          Next
        </Button>
      </div>
    </Form>
  );
};

const InvitationShare = ({
  invitations,
  emailSubject,
  onDone,
}: {
  invitations: Invitation[];
  emailSubject: string;
  onDone: () => void;
}) => {
  return (
    <Space style={{ width: "100%", marginTop: "1rem" }} direction="vertical">
      <Row justify="space-between">
        <h1>Invitation Links</h1>
        <Space>
          <Button type="primary">
            <a href={getMailAllLink(invitations, emailSubject)}>Email All</a>
          </Button>
          <Button onClick={() => onDone()}>Done</Button>
        </Space>
      </Row>
      <List
        dataSource={invitations}
        renderItem={(invitation) => (
          <List.Item>
            <Space style={{ width: "100%" }} direction="vertical">
              <Row>
                <Col xs={24} md={3}>
                  <strong>Email:</strong>
                </Col>
                <Col xs={24} md={21}>
                  <a href={getMailToLink(invitation, emailSubject)}>
                    {invitation.email}
                  </a>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={3}>
                  <strong>Link:</strong>
                </Col>
                <Col xs={24} md={21}>
                  <Typography.Text copyable>
                    {getInvitationLink(invitation.id)}
                  </Typography.Text>
                </Col>
              </Row>
            </Space>
          </List.Item>
        )}
      />
    </Space>
  );
};
