import * as React from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { useOrganizationLocations } from "../../hooks/useOrganizationLocations";
import NotFoundContent from "../NotFoundContent";
import AllLocations from "./AllLocations";
import LocationForms from "./EditLocation";

interface OrganizationLocationsProps {
  organizationId: string;
}

export default function OrganizationLocations(
  props: Readonly<OrganizationLocationsProps>
) {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { createLocation, updateLocation, deleteLocation } =
    useOrganizationLocations({ organizationId: props.organizationId });

  async function onDeleteLocation(locationId: string) {
    await deleteLocation(locationId);
    setTimeout(() => {
      history.push(url);
    }, 100);
  }

  return (
    <Switch>
      <Route exact path={path}>
        <AllLocations organizationId={props.organizationId} />
      </Route>
      <Route exact path={`${path}/new`}>
        <LocationForms
          organizationId={props.organizationId}
          onSubmitLocationInfo={async (data) => {
            const location = await createLocation(data);
            setTimeout(() => {
              history.push(`${url}/${location.id}`);
            }, 100);
            return location;
          }}
        />
      </Route>
      <Route
        exact
        path={`${path}/:locationId`}
        render={({ match }) => {
          return (
            <LocationForms
              organizationId={props.organizationId}
              locationId={match.params.locationId}
              onSubmitLocationInfo={(data) =>
                updateLocation(match.params.locationId, data)
              }
              onDeleteLocation={() => onDeleteLocation(match.params.locationId)}
            />
          );
        }}
      />
      <Route>
        <NotFoundContent />
      </Route>
    </Switch>
  );
}
