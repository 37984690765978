import { Button, Card, Col, DatePicker, Row, Select } from "antd";
import _ from "lodash";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router";
import queryString from "query-string";
import { RangeValue } from "rc-picker/lib/interface";

const TIMEZONES = [
  { value: "America/New_York", text: "Eastern Time" },
  { value: "America/Chicago", text: "Central Time" },
  { value: "America/Denver", text: "Mountain Time" },
  { value: "America/Los_Angeles", text: "Pacific Time" },
  { value: "Pacific/Honolulu", text: "Hawaii Time" },
];

const URL_DATETIME_FORMAT = "YYYY-MM-DD";

export interface DateRange {
  start: moment.Moment;
  end: moment.Moment;
  timezone: string;
}

const DateFilter = ({
  dateRange,
  setDateRange,
}: {
  dateRange: DateRange;
  setDateRange: Dispatch<SetStateAction<DateRange>>;
}) => {
  const history = useHistory();

  function handleTimezoneChange(value: string) {
    setDateRange((dateRange: DateRange) => ({
      timezone: value,
      // The "true" param in tz() call changes timezone but keeps local datetime
      // See https://momentjs.com/timezone/docs/#/using-timezones/converting-to-zone/
      start: moment(dateRange.start).tz(value, true),
      end: moment(dateRange.end).tz(value, true),
    }));
  }

  function handleRangePickerChange(values: RangeValue<moment.Moment>) {
    const newStart = values ? values[0] : null;
    const newEnd = values ? values[1] : null;
    if (newStart === null || newEnd === null) {
      // Do not allow empty dates.
      return;
    }
    setDateRange((dateRange) => ({
      ...dateRange,
      start: newStart,
      end: newEnd,
    }));
  }

  function handleSubmit() {
    const start = dateRange.start.format(URL_DATETIME_FORMAT);
    const end = dateRange.end.format(URL_DATETIME_FORMAT);
    const timezone = dateRange.timezone;
    const queries = queryString.parse(history.location.search);
    if (
      start === queries.start &&
      end === queries.end &&
      timezone === queries.timezone
    ) {
      return;
    }
    history.push(
      queryString.stringifyUrl({
        url: history.location.pathname,
        query: { ...queries, start, end, timezone },
      })
    );
  }

  return (
    <Card>
      <Row gutter={[12, 12]} align="middle">
        <Col flex="none">
          <strong>Date Range:</strong>
        </Col>
        <Col flex="none">
          <DatePicker.RangePicker
            allowEmpty={[false, false]}
            clearIcon={false}
            value={[dateRange.start, dateRange.end]}
            onChange={handleRangePickerChange}
          />
        </Col>
        <Col flex="none">
          <Select value={dateRange.timezone} onChange={handleTimezoneChange}>
            {TIMEZONES.map((tz) => (
              <Select.Option key={tz.value} value={tz.value}>
                {tz.text}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col flex="none">
          <Button type="primary" onClick={handleSubmit}>
            Go
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export function getDateRangeFromQueries(queries: {
  start?: string;
  end?: string;
  timezone?: string;
}): DateRange {
  let timezone = queries.timezone || moment.tz.guess();
  if (!_.find(TIMEZONES, { value: timezone })) {
    timezone = TIMEZONES[0].value;
  }

  let end = moment.tz(queries.end || "", URL_DATETIME_FORMAT, timezone);
  if (!end.isValid()) {
    end = moment().tz(timezone);
  }
  end = end.endOf("day");

  let start = moment.tz(queries.start || "", URL_DATETIME_FORMAT, timezone);
  if (!start.isValid() || start.isAfter(end)) {
    // By default get the data one week
    start = moment(end).subtract(1, "week");
  }
  start = start.startOf("day");

  return { start, end, timezone };
}
export default DateFilter;
