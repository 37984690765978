import { Button, Card, Result, Spin } from "antd";
import * as React from "react";

import { useOrganizationLocations } from "../../hooks/useOrganizationLocations";
import {
  ParkingProduct,
  Price,
  useParkingProduct,
} from "../../hooks/useOrganizationParkingProducts";
import { usePayoutAccounts } from "../../hooks/usePayoutAccount";
import notify from "../../utils/notify";
import ParkingProductForm from "./ParkingProductForm";

interface Props {
  organizationId: string;
  parkingProductId: string;
  onUpdateSuccess: () => void;
}

export default function EditParkingProduct(props: Readonly<Props>) {
  const [error, setError] = React.useState<Error>();
  const [isFetching, setIsFetching] = React.useState(false);

  const { locations, revalidate: revalidateLocations } =
    useOrganizationLocations({
      organizationId: props.organizationId,
      onError: setError,
    });

  const { payoutAccounts, revalidate: revalidatePayoutAccounts } =
    usePayoutAccounts({
      organizationId: props.organizationId,
      onError: setError,
    });

  const {
    parkingProduct,
    defaultPrice,
    updateParkingProduct,
    updatePrice,
    revalidate,
  } = useParkingProduct({
    organizationId: props.organizationId,
    parkingProductId: props.parkingProductId,
    onError: setError,
  });

  const onUpdate = async (
    parkingProduct: Partial<ParkingProduct>,
    price: Partial<Price>
  ) => {
    try {
      setIsFetching(true);
      await Promise.all([
        updateParkingProduct(parkingProduct),
        updatePrice({ ...defaultPrice, ...price }),
      ]);
      await revalidate();
      notify.success("Successfully updated the product.");
      setTimeout(props.onUpdateSuccess, 200);
    } catch (err) {
      notify.error("Failed to update the product", err);
    } finally {
      setIsFetching(false);
    }
  };

  if (error) {
    return (
      <Card>
        <Result
          status="warning"
          title="Oops, an error occurred."
          subTitle={error.message}
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => {
                setError(undefined);
                revalidateLocations();
                revalidatePayoutAccounts();
                revalidate();
              }}
            >
              Try again
            </Button>
          }
        />
      </Card>
    );
  }

  return (
    <Spin
      spinning={!locations || !payoutAccounts || !parkingProduct || isFetching}
    >
      <Card>
        <ParkingProductForm
          key={parkingProduct?.id}
          parkingProduct={parkingProduct}
          price={defaultPrice}
          locations={locations || []}
          payoutAccounts={payoutAccounts || []}
          onSubmit={onUpdate}
        />
      </Card>
    </Spin>
  );
}
