import { Button, Card, Result } from "antd";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";

import { useTenantGroupSource } from "../../hooks/useTenantGroupWallet";
import VerifyBankPayment from "../PaymentMethods/VerifyBankPayment";

interface Props {
  tenantGroupId: string;
  sourceId: string;
  baseUrl: string;
}

export default function VerifyTenantGroupBankAccount(props: Readonly<Props>) {
  const history = useHistory();
  const {
    payment: source,
    error,
    verify,
  } = useTenantGroupSource(props.tenantGroupId, props.sourceId);

  async function verifySource(amount1: string, amount2: string) {
    await verify({
      amounts: [amount1, amount2],
    });
    setTimeout(() => {
      history.push(props.baseUrl);
    }, 200);
  }

  if (error) {
    return (
      <Card>
        <Result
          status="error"
          title="Failed to load payment method"
          extra={
            <Button type="primary" key="console">
              <Link to={`${props.baseUrl}`}>Go to Wallet</Link>
            </Button>
          }
        />
      </Card>
    );
  }

  return <VerifyBankPayment source={source} onVerify={verifySource} />;
}
