import { Button, Result, Space } from "antd";
import * as React from "react";
import InviteSteps from "./InviteSteps";

export default function Done({
  onDone,
  onNewInvitation,
}: {
  onDone: () => void;
  onNewInvitation: () => void;
}) {
  return (
    <InviteSteps currentStep={2} isLoading={false}>
      <Result
        status="success"
        title="You are done!"
        subTitle="If you need to invite the same person again, setup a new invitation."
        extra={
          <Space>
            <Button type="primary" onClick={onNewInvitation}>
              Invite Someone Else
            </Button>
            <Button onClick={onDone}>Back to Admin Users</Button>
          </Space>
        }
      />
    </InviteSteps>
  );
}
