import { Button, Card, Result } from "antd";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import useSWR from "swr";

import useIdsFetch from "../../hooks/useIdsFetch";
import { Source } from "../../hooks/useWallet";
import VerifyBankPayment from "../PaymentMethods/VerifyBankPayment";

interface Props {
  sourceId: string;
}

export default function VerifyParkerBankAccount(props: Readonly<Props>) {
  const history = useHistory();
  const idsFetch = useIdsFetch();
  const {
    data: source,
    error,
    mutate,
  } = useSWR<Source>(`/me/wallet/sources/${props.sourceId}/`);

  async function verifySource(amount1: string, amount2: string) {
    await idsFetch(`/me/wallet/sources/${props.sourceId}/verify/`, {
      method: "POST",
      body: {
        amounts: [amount1, amount2],
      },
    });
    mutate();
    setTimeout(() => {
      history.push("/me/wallet");
    }, 200);
  }

  if (error) {
    return (
      <Card>
        <Result
          status="error"
          title="Failed to load bank account"
          extra={
            <Button type="primary" key="console">
              <Link to="/me/wallet">Go to Wallet</Link>
            </Button>
          }
        />
      </Card>
    );
  }

  return <VerifyBankPayment source={source} onVerify={verifySource} />;
}
