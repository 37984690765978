import { useAccountingForAPI } from "../hooks/useAccounting";
import AccountingList from "./AccountingList";

export default function ParkerHistory() {
  return (
    <AccountingList
      getAccountingItemsForTimeRange={useAccountingForAPI(
        "/me/accounting/charges/"
      )}
      ignoreColumns={["Parker", "Net"]}
    />
  );
}
