import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import _ from "lodash";
import * as React from "react";

import { Address } from "../../hooks/useAddress";
import {
  LocationData,
  useOrganizationLocations,
} from "../../hooks/useOrganizationLocations";
import notify from "../../utils/notify";
import AddressForm from "../AddressForm";
import NotFoundContent from "../NotFoundContent";

const NEW_LOCATION: LocationData = {
  name: "",
  capacity: null,
  time_zone: null,
  description: "",
  support_phone_number: "",
  support_email: "",
  default_address: null,
};

const TIMEZONES = [
  { value: "America/New_York", text: "Eastern Time" },
  { value: "America/Chicago", text: "Central Time" },
  { value: "America/Denver", text: "Mountain Time" },
  { value: "America/Los_Angeles", text: "Pacific Time" },
  { value: "Pacific/Honolulu", text: "Hawaii Time" },
];

interface Props {
  organizationId: string;
  locationId: string | undefined;
  next: (data: LocationData) => Promise<void>;
  onDeleteLocation?: () => Promise<void>;
}

export default function LocationInfo(props: Readonly<Props>) {
  const [error, setError] = React.useState<Error>();
  const [location, setLocation] = React.useState<LocationData>();
  const { locations } = useOrganizationLocations({
    organizationId: props.organizationId,
  });
  const [address, setAddress] = React.useState<Omit<Address, "id">>();
  const [isFetching, setIsFetching] = React.useState(false);

  React.useEffect(() => {
    if (props.locationId === undefined) {
      setError(undefined);
      setLocation({ ...NEW_LOCATION });
      return;
    }
    if (locations === undefined) {
      setLocation(undefined);
      return;
    }
    const targetLocation = _.find(locations, { id: props.locationId });
    if (targetLocation === undefined) {
      setError(new Error("Location not found."));
      setLocation(undefined);
      return;
    }
    setError(undefined);
    setLocation(targetLocation);
  }, [locations, props.locationId, setLocation, setError]);

  React.useEffect(() => {
    if (location?.default_address) {
      setAddress(_.omit(location.default_address, "id"));
    } else {
      setAddress(undefined);
    }
  }, [location, location?.default_address]);

  if (error) {
    return <NotFoundContent />;
  }

  if (!location) {
    return <Spin style={{ width: "100%", textAlign: "center" }} size="large" />;
  }

  async function handleSubmit(values: LocationData) {
    try {
      setIsFetching(true);
      await props.next({
        ...values,
        default_address: address || null,
      });
    } catch (err) {
      notify.error("Failed to submit location information", err);
    } finally {
      setIsFetching(false);
    }
  }

  async function deleteLocation(): Promise<void> {
    if (props.locationId === undefined) {
      console.error(
        "Attempting to delete a non-existing location. This should not happen. Skip."
      );
      return;
    }
    if (props.onDeleteLocation === undefined) {
      console.error(
        "No onDeleteLocation function provided. This should not happen. Skip."
      );
      return;
    }
    try {
      setIsFetching(true);
      await props.onDeleteLocation();
      notify.success("Successfully deleted the location.");
    } catch (err) {
      notify.error("Failed to delete the location", err);
    } finally {
      setIsFetching(false);
    }
  }

  return (
    <Spin spinning={location === undefined || isFetching}>
      <Form initialValues={location} onFinish={handleSubmit}>
        <Row gutter={50}>
          <Col xs={24} xl={12}>
            <Form.Item
              label="Location Name"
              name="name"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Location name is required" }]}
            >
              <Input autoFocus />
            </Form.Item>

            <Form.Item
              label="Capacity"
              name="capacity"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Capacity is required" }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Time Zone"
              name="time_zone"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "Time zone is required" }]}
            >
              <Select>
                {TIMEZONES.map((tz) => (
                  <Select.Option key={tz.value} value={tz.value}>
                    {tz.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <AddressForm
              disabled={isFetching}
              required
              address={address}
              onChange={setAddress}
            />
          </Col>

          <Col xs={24} xl={12}>
            <Form.Item
              label="Description"
              name="description"
              labelCol={{ span: 24 }}
            >
              <Input.TextArea allowClear rows={12} />
            </Form.Item>
            Customer Support
            <Row style={{ marginTop: "1rem" }} gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Phone Number"
                  name="support_phone_number"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Support phone number is required",
                    },
                  ]}
                >
                  <Input placeholder="xxx-xxx-xxxx" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="support_email"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      type: "email",
                      message: "Please input a valid email address",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <div style={{ textAlign: "right" }}>
          <Space>
            <Button htmlType="submit" type="primary" disabled={isFetching}>
              Next
            </Button>
            {props.locationId && (
              <Button
                danger
                disabled={isFetching}
                onClick={() => {
                  Modal.confirm({
                    title: `Are you sure you want to delete location ${
                      location!.name
                    }?`,
                    onOk: deleteLocation,
                  });
                }}
              >
                Delete Location
              </Button>
            )}
          </Space>
        </div>
      </Form>
    </Spin>
  );
}
