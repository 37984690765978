import useSWR from "swr";
import useIdsFetch from "./useIdsFetch";
import { TenantGroup } from "./useTenantGroup";

const apiUrl = "/me/tenant-groups/";
const useUserTenantGroups = ({
  onError = () => {},
}: {
  onError?: (err: Error) => void;
} = {}) => {
  const idsFetch = useIdsFetch();
  const { data, mutate } = useSWR<TenantGroup[]>(apiUrl, { onError });
  const create = async (values: Partial<TenantGroup>) => {
    const newTenantGroup: TenantGroup = await idsFetch(apiUrl, {
      method: "POST",
      body: {
        ...values,
      },
    });
    return newTenantGroup;
  };
  return { tenantGroups: data, revalidate: () => mutate(), create };
};

export default useUserTenantGroups;
