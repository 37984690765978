import { Layout } from "antd";
import * as React from "react";

interface Props {
  navbar: React.ReactNode;
  children: React.ReactNode;
}

// Some components within the content element need to scroll to top.
// Define a unique ID here for direct reference to the element.
export const LAYOUT_CONTENT_ID = "smartpass-layout-content";

export default function HeaderContent(
  props: Readonly<Props>
): React.FunctionComponentElement<Props> {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Header>{props.navbar}</Layout.Header>
      <Layout.Content
        id={LAYOUT_CONTENT_ID}
        style={{
          height: "calc(100vh - 64px)",
          overflow: "auto",
          padding: "2rem",
        }}
      >
        <div style={{ maxWidth: 992, margin: "0 auto" }}>{props.children}</div>
      </Layout.Content>
    </Layout>
  );
}
