import { Skeleton, Spin } from "antd";
import * as React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";

import { Organization } from "../../hooks/useOrganization";
import { ParkingProduct } from "../../hooks/useOrganizationParkingProducts";
import { TenantGroup } from "../../hooks/useTenantGroup";
import { AuthorizedPayment } from "../../hooks/useTenantGroupAuthorizedPayments";
import HeaderContent from "../Layouts/HeaderContent";
import NavBar from "../NavBar";
import NotFoundContent from "../NotFoundContent";
import AuthorizedPaymentClaim from "./AuthorizedPaymentClaim";
import ErrorResult from "./ErrorResult";
import OrganizationClaim from "./OrganizationClaim";
import ParkingProductClaim from "./ParkingProductClaim";
import TenantGroupClaim from "./TenantGroupClaim";

interface Association {
  tenant_group: TenantGroup | null;
  organization: Organization | null;
  parking_product: ParkingProduct | null;
  authorized_payment: AuthorizedPayment | null;
}

export type Invitation = {
  id: string;
  email: string;
  name: string;
  claimed_at: string | null;
} & Association;

export type Member = {
  id: string;
  email: string;
  name: string;
  user_id: string;
} & Association;

export default function InvitationClaim() {
  return (
    <HeaderContent navbar={<NavBar />}>
      <div style={{ maxWidth: "32rem", margin: "1rem auto" }}>
        <ClaimResult />
      </div>
    </HeaderContent>
  );
}

function ClaimResult() {
  const { invitationId } = useParams<{ invitationId: string }>();
  const { data: invitation, error } = useSWR<Invitation>(
    `/me/invitations/${invitationId}`
  );

  if (error) {
    return <ErrorResult error={error} />;
  }

  if (invitation === undefined) {
    return (
      <Spin spinning>
        <Skeleton active />
      </Spin>
    );
  }

  if (invitation.organization) {
    return (
      <OrganizationClaim
        invitationId={invitationId}
        organizationId={invitation.organization.id}
      />
    );
  }

  if (invitation.tenant_group) {
    return (
      <TenantGroupClaim
        invitationId={invitationId}
        tenantGroupId={invitation.tenant_group.id}
      />
    );
  }

  if (invitation.parking_product) {
    return (
      <ParkingProductClaim
        invitationId={invitationId}
        parkingProduct={invitation.parking_product}
      />
    );
  }

  if (invitation.authorized_payment) {
    return (
      <AuthorizedPaymentClaim
        invitationId={invitationId}
        parkingProduct={invitation.authorized_payment.parking_product}
      />
    );
  }

  return <NotFoundContent />;
}
