import {
  CarOutlined,
  EnvironmentOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  InputNumber,
  Result,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { RRule } from "rrule";

import { useCart, useCheckout } from "../hooks/useCheckout";
import { ResponseError } from "../hooks/useIdsFetch";
import {
  centsToDollars,
  ParkingProduct,
  Price,
} from "../hooks/useOrganizationParkingProducts";
import { useParkingProduct } from "../hooks/useParkingProduct";
import { Purchase, TimeOfUseRule } from "../hooks/useUserPurchases";
import {
  useUserAuthorizedPayments,
  useUserPaymentMethods,
  useUserSources,
} from "../hooks/useUserWallet";
import useVehicles, { Vehicle } from "../hooks/useVehicles";
import notify from "../utils/notify";
import HeaderContent, { LAYOUT_CONTENT_ID } from "./Layouts/HeaderContent";
import NavBar from "./NavBar";
import NotFoundContent from "./NotFoundContent";
import SelectVehicleFormItems from "./ParkerVehicles/SelectVehicleFormItems";
import PaymentDetails from "./PaymentDetails";

export interface Checkout {
  payment_id: string;
  payment_method_id?: string;
  stripe_payment_method_id?: string;
  save_to_wallet?: boolean;
  source_id?: string;
  recurrence: string;
  days: number[];
}
export const daysOfTheWeek = [
  { name: "Mondays", value: RRule.MO.weekday },
  { name: "Tuesdays", value: RRule.TU.weekday },
  { name: "Wednesdays", value: RRule.WE.weekday },
  { name: "Thursdays", value: RRule.TH.weekday },
  { name: "Fridays", value: RRule.FR.weekday },
  { name: "Saturdays", value: RRule.SA.weekday },
  { name: "Sundays", value: RRule.SU.weekday },
];
export const rruleFromDaysOfWeek = (days?: number[]) => {
  if (days?.length) {
    return new RRule({ freq: RRule.WEEKLY, byweekday: days });
  } else {
    return new RRule({ freq: RRule.DAILY });
  }
};
export const rruleFromTimestamp = (timestamp: number) => {
  return new RRule({ freq: RRule.DAILY, dtstart: new Date(timestamp) });
};
const ProductCheckoutRouter = ({ productId }: { productId: string }) => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { state } = useLocation<{ purchase: Purchase }>();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  return (
    <HeaderContent navbar={<NavBar />}>
      <Switch>
        <Route
          exact
          path={path}
          render={({ match, location }) =>
            isAuthenticated ? (
              <ProductCheckout
                productId={productId}
                onCheckoutSuccess={(purchase: Purchase) =>
                  history.push(`${url}/success`, { purchase })
                }
              />
            ) : (
              <ProductCheckoutPreview
                productId={productId}
                onLoginClick={() =>
                  loginWithRedirect({
                    appState: { targetUrl: location.pathname },
                  })
                }
              />
            )
          }
        ></Route>
        <Route
          path={`${path}/success`}
          render={() => {
            if (state?.purchase) {
              return <CheckoutSuccess purchase={state.purchase} />;
            } else {
              return <Redirect to={url} />;
            }
          }}
        ></Route>
        <Route>
          <NotFoundContent />
        </Route>
      </Switch>
    </HeaderContent>
  );
};

const ProductCheckoutPreview = ({
  productId,
  onLoginClick,
}: {
  productId: string;
  onLoginClick: () => void;
}) => {
  const [errorProduct, setErrorProduct] = useState<ResponseError>();
  const { parkingProduct, defaultPrice } = useParkingProduct(
    productId,
    setErrorProduct
  );
  if (errorProduct?.response?.status === 404)
    return (
      <Card>
        <NotFoundContent />
      </Card>
    );
  if (errorProduct)
    return (
      <Card>
        <Result
          status="error"
          title="Could not load product"
          extra={
            <Button type="primary" onClick={() => setErrorProduct(undefined)}>
              Try Again
            </Button>
          }
        />
      </Card>
    );
  if (parkingProduct && !parkingProduct.is_purchaseable) {
    return (
      <Card>
        <Result status="warning" title="Product is not purchasable" />
      </Card>
    );
  }
  if (parkingProduct && defaultPrice)
    return (
      <Space
        direction="vertical"
        style={{ width: "100%", marginBottom: "1em" }}
      >
        <Typography.Title>{parkingProduct.name}</Typography.Title>
        <Row>
          <Col span={24}>
            <Card title={`Location: ${parkingProduct.location.name}`}>
              <Typography style={{ whiteSpace: "pre-wrap" }}>
                <p>{parkingProduct.location.description}</p>
              </Typography>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card
              title={`Product: ${parkingProduct.name}`}
              style={{ width: "100%" }}
            >
              <ProductDetails
                parkingProduct={parkingProduct}
                defaultPrice={defaultPrice}
              />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card title={`Purchase`}>
              <Typography style={{ whiteSpace: "pre-wrap" }}>
                <p>Please login or sign up to purchase parking.</p>
              </Typography>
              <Button type="primary" onClick={() => onLoginClick()}>
                Login or Sign up
              </Button>
            </Card>
          </Col>
        </Row>
      </Space>
    );
  return null;
};

export const ProductCheckout = ({
  productId,
  onCheckoutSuccess,
}: {
  productId: string;
  onCheckoutSuccess: (purchase: Purchase) => void;
}) => {
  const [isFetchingCheckout, setIsFetchingCheckout] = useState<boolean>(false);
  const [isFetchingUpdate, setIsFetchingUpdate] = useState<boolean>(false);
  const [errorProduct, setErrorProduct] = useState<ResponseError>();
  const [errorPayment, setErrorPayment] = useState<Error>();
  const [errorCheckout, setErrorCheckout] = useState<Error>();
  const [days, setDays] = useState<number[]>();
  const [maxDays, setMaxDays] = useState<number>();
  const [maxSessions, setMaxSessions] = useState<number>();
  const [selectedVehicleId, setSelectedVehicleId] = useState<string>();
  const [vehicleData, setVehicleData] =
    useState<Omit<Vehicle, "id" | "user_id">>();
  const { vehicles, createVehicle } = useVehicles();
  const { parkingProduct: product, defaultPrice: price } = useParkingProduct(
    productId,
    setErrorProduct
  );
  const {
    cart,
    revalidate: revalidateCart,
    update: updateCart,
  } = useCart(
    product?.is_purchaseable ? price?.id : undefined,
    setErrorProduct
  );
  const { paymentMethods } = useUserPaymentMethods({
    onError: setErrorPayment,
  });
  const { sources } = useUserSources({ onError: setErrorPayment });
  const { authorizedPayments } = useUserAuthorizedPayments({
    onError: setErrorPayment,
  });
  const history = useHistory();

  useEffect(() => {
    const maxDaysCart =
      cart?.price_item.parking_policy.max_days_per_billing_cycle;
    const maxSessionsCart =
      cart?.price_item.parking_policy.max_sessions_per_billing_cycle;
    const allowed_days = cart?.price_item.parking_policy.allowed_days;
    if (allowed_days) {
      setDays(allowed_days);
    }
    if (maxDaysCart) setMaxDays(maxDaysCart);
    if (maxSessionsCart) setMaxSessions(maxSessionsCart);
  }, [cart]);

  useEffect(() => {
    // Choose the first vehicle by default, otherwise set to undefined
    setSelectedVehicleId(_.first(vehicles)?.id);
  }, [vehicles, setSelectedVehicleId]);

  const checkoutCart = useCheckout(cart?.id);
  const checkoutProduct = async (data: Checkout) => {
    setIsFetchingCheckout(true);

    let vehicle_id = selectedVehicleId;
    if (vehicle_id === undefined) {
      if (
        vehicleData === undefined ||
        _.some(_.values(vehicleData), _.isEmpty)
      ) {
        notify.error("Please input your vehicle information.");
        setIsFetchingCheckout(false);
        return;
      }
      // Save the vehicle first
      try {
        const savedVehicle = await createVehicle(vehicleData);
        vehicle_id = savedVehicle.id;
        setSelectedVehicleId(vehicle_id);
      } catch (err) {
        notify.error("Failed to save the vehicle", err);
        setIsFetchingCheckout(false);
        return;
      }
    }

    try {
      // Scroll to the top first to make sure people see the payment is processing.
      document
        .getElementById(LAYOUT_CONTENT_ID)
        ?.scrollTo({ top: 0, behavior: "smooth" });
      const purchase = await checkoutCart({ ...data, vehicle_id });
      onCheckoutSuccess(purchase);
    } catch (err) {
      setErrorCheckout(err as Error);
    } finally {
      setIsFetchingCheckout(false);
    }
  };

  function updateSelectedVehicle(
    vehicleId: string | undefined,
    vehicleData: Omit<Vehicle, "id" | "user_id"> | undefined
  ) {
    setSelectedVehicleId(vehicleId);
    setVehicleData(vehicleData);
  }

  const update = useCallback(
    async (
      days?: number[],
      maxDays?: number,
      maxSessions?: number,
      dtStart?: number
    ) => {
      let recurrence = rruleFromDaysOfWeek(days);
      if (dtStart && dtStart > 0) {
        recurrence = rruleFromTimestamp(dtStart);
      }

      try {
        setIsFetchingUpdate(true);
        await updateCart(recurrence.toString(), maxDays, maxSessions);
        revalidateCart();
      } catch (err) {
        notify.error("Failed to update cart", err);
      } finally {
        setMaxDays(maxDays);
        setMaxSessions(maxSessions);
        setDays(days);
        setIsFetchingUpdate(false);
      }
    },
    [updateCart, revalidateCart]
  );
  if (errorProduct?.response?.status === 404)
    return (
      <Card>
        <NotFoundContent />
      </Card>
    );
  if (errorProduct || errorPayment)
    return (
      <Card>
        <Result
          status="error"
          title="Could not load product"
          extra={
            <Button
              type="primary"
              onClick={() => {
                setErrorProduct(undefined);
                setErrorPayment(undefined);
              }}
            >
              Try Again
            </Button>
          }
        />
      </Card>
    );
  if (errorCheckout)
    return (
      <Card>
        <Result
          status="error"
          title="Purchase Unsuccessful"
          subTitle={errorCheckout.message}
          extra={
            <Button type="primary" onClick={() => setErrorCheckout(undefined)}>
              Try Again
            </Button>
          }
        />
      </Card>
    );
  if (product && price && price.has_avaliable_inventory === false) {
    const locationContact = product?.location?.support_email
      ? `Please contact ${product.location.support_email} for more parking options.`
      : null;
    return (
      <Row>
        <Col span={24}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Typography.Title>{product.name}</Typography.Title>
            <Card>
              <Result
                icon={<CarOutlined />}
                title="This product is currently sold out."
                subTitle={locationContact}
                extra={
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => history.push("/me")}
                  >
                    View Your Passes
                  </Button>
                }
              />
            </Card>
          </Space>
        </Col>
      </Row>
    );
  }

  if (product && !product.is_purchaseable) {
    return (
      <Card>
        <Result status="warning" title="Product cannot be purchased" />
      </Card>
    );
  }

  if (
    product &&
    price &&
    paymentMethods &&
    sources &&
    authorizedPayments &&
    cart &&
    days
  ) {
    const authorizedPaymentsFiltered = _.filter(authorizedPayments, [
      "parking_product.id",
      product.id,
    ]);
    let discountText;
    const creation_kwargs = price.discounted_phase?.coupon?.creation_kwargs;
    if (creation_kwargs?.percent_off) {
      discountText = `The first billing period will be discounted by ${creation_kwargs.percent_off}%.`;
    }
    if (creation_kwargs?.amount_off) {
      discountText = `The first billing period will be discounted by ${(
        creation_kwargs.amount_off / 100
      ).toLocaleString("en-US", {
        style: "currency",
        currency: creation_kwargs.currency,
      })}.`;
    }
    const [base, surcharge] = cart.price_item.subscription_amount;
    let dtStart: number = 0;
    for (let rule of price.allowed_time_of_use) {
      if (rule.recurrence?.includes("DTSTART")) {
        if (rule.start_at) {
          dtStart = Date.parse(rule.start_at);
        }
      }
    }
    return (
      <Spin
        spinning={isFetchingCheckout}
        size="large"
        tip={<h1>Processing payment, do not refresh</h1>}
      >
        <Card>
          <Typography.Title>{product.name}</Typography.Title>
          <Space>
            <EnvironmentOutlined />
            {product.location.name}
          </Space>
          <Divider />

          <div>
            <Space direction="vertical">
              <h3>Product Information</h3>
              <ProductDetails parkingProduct={product} defaultPrice={price} />
            </Space>
            <Divider />
          </div>

          {price.is_max_days_customizable && (
            <div>
              <Space direction="vertical">
                <h3>Input the total days you need access</h3>
                <CustomizeMaxDaysForm
                  initialValues={{
                    maxDays: maxDays || price.days_in_billing_period,
                  }}
                  onValuesChange={({ maxDays }: { maxDays?: number }) => {
                    if (maxDays) update(days, maxDays);
                  }}
                  max={price.days_in_billing_period}
                  disabled={isFetchingUpdate || !maxDays}
                  loading={isFetchingUpdate}
                />
              </Space>
              <Divider />
            </div>
          )}

          {price.is_max_sessions_customizable && (
            <div>
              <Space direction="vertical">
                <h3>Input the total parking sessions you need.</h3>
                <CustomizeMaxSessionsForm
                  initialValues={{ maxSessions: maxSessions || 0 }}
                  onValuesChange={({
                    maxSessions,
                  }: {
                    maxSessions?: number;
                  }) => {
                    if (maxSessions)
                      update(days, maxDays, maxSessions, dtStart);
                  }}
                  max={100}
                  disabled={isFetchingUpdate || !maxSessions}
                  loading={isFetchingUpdate}
                />
              </Space>
              <Divider />
            </div>
          )}

          {price.is_time_of_use_customizable && (
            <div>
              <Space direction="vertical">
                <h3>Select the days of week you need access</h3>
                <CustomizeParkingDaysForm
                  allowed_days={price.allowed_days}
                  initialValues={{ days }}
                  onValuesChange={async ({ days }: { days: number[] }) => {
                    if (days) update(days, maxDays);
                  }}
                  buttonDisabled={isFetchingUpdate}
                  buttonLoading={isFetchingUpdate}
                />
              </Space>
              <Divider />
            </div>
          )}

          <div>
            <h3>Vehicle Information</h3>
            <p>
              Your license plate may be verified on site. You can update it
              later at the update parking pass page.
            </p>
            <div>
              <Form layout="vertical">
                <SelectVehicleFormItems
                  savedVehicles={vehicles}
                  selectedVehicleId={selectedVehicleId}
                  vehicleData={vehicleData}
                  onChange={updateSelectedVehicle}
                />
              </Form>
            </div>
          </div>
          <Divider />

          <Space direction="vertical">
            <h3>Select Payment Method</h3>
            <PaymentDetails
              paymentMethods={paymentMethods}
              sources={sources}
              authorizedPayments={authorizedPaymentsFiltered}
              onFinish={checkoutProduct}
              billingIntervalText={`Every ${price.recurring_count} ${price.recurring_interval}(s)`}
              billingAmount={_.sum([base, surcharge]) / 100}
              baseAmount={base / 100}
              surchargeAmount={surcharge / 100}
              recurringIterations={price.recurring_iterations}
              discountText={discountText}
              isLoading={false}
              isDisabled={false}
            />
          </Space>
        </Card>
      </Spin>
    );
  }
  return null;
};

const CheckoutSuccess = ({ purchase }: { purchase: Purchase }) => {
  const history = useHistory();

  function getBillingText() {
    const amount = purchase.stripe_subscription.data.plan.amount;
    const amountFormatted = (amount / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    let text = `You will be billed ${amountFormatted}`;
    const { price } = purchase;
    if (price.recurring_iterations === null || price.recurring_iterations > 1) {
      text += ` every ${price.recurring_count} ${price.recurring_interval}s`;
      if (price.recurring_iterations !== null) {
        text += ` for ${price.recurring_iterations} times`;
      }
    }
    return text + ".";
  }

  return (
    <Card>
      <Result
        status="success"
        title="Purchase Successful"
        subTitle={getBillingText()}
        extra={
          <Button key="home" type="primary" onClick={() => history.push("/me")}>
            Home
          </Button>
        }
      />
    </Card>
  );
};

export const CustomizeMaxSessionsForm = ({
  initialValues,
  onValuesChange,
  max,
  disabled,
  loading,
}: {
  initialValues: { maxSessions?: number };
  onValuesChange: ({ maxSessions }: { maxSessions?: number }) => void;
  max: number;
  disabled: boolean;
  loading: boolean;
}) => {
  return (
    <Form
      layout="vertical"
      scrollToFirstError={true}
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        label="Total Passes"
        name="maxSessions"
        rules={[
          {
            required: true,
            validator: async (rule, maxSessions) => {
              if (maxSessions > 0 && maxSessions <= max) return true;
              throw new Error(`Value must be between 0 and ${max}`);
            },
          },
        ]}
      >
        <InputNumber type="number" min={1} max={max} />
      </Form.Item>
    </Form>
  );
};
export const CustomizeMaxDaysForm = ({
  initialValues,
  onValuesChange,
  max,
  disabled,
  loading,
}: {
  initialValues: { maxDays?: number };
  onValuesChange: ({ maxDays }: { maxDays?: number }) => void;
  max: number;
  disabled: boolean;
  loading: boolean;
}) => {
  return (
    <Form
      layout="vertical"
      scrollToFirstError={true}
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        label="Total Day Passes"
        name="maxDays"
        rules={[
          {
            required: true,
            validator: async (rule, maxDays) => {
              if (maxDays > 0 && maxDays <= max) return true;
              throw new Error(`Value must be between 0 and ${max}`);
            },
          },
        ]}
      >
        <InputNumber type="number" min={1} max={max} />
      </Form.Item>
    </Form>
  );
};

export const CustomizeParkingDaysForm = ({
  allowed_days,
  initialValues,
  onValuesChange,
  buttonDisabled,
  buttonLoading,
}: {
  allowed_days: number[];
  initialValues: {
    days: number[];
  };
  onValuesChange: (values: { days: number[] }) => void;
  buttonDisabled: boolean;
  buttonLoading: boolean;
}) => {
  return (
    <Form
      layout="vertical"
      scrollToFirstError={true}
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        label={
          <Space>
            Parking Days
            <Tooltip title="Unavailable days of week for access are greyed out.">
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        }
        name="days"
        rules={[
          {
            required: true,
            message: "Select at least one day",
          },
        ]}
      >
        <Checkbox.Group>
          <Row>
            {daysOfTheWeek.map(({ name, value }) => (
              <Col key={name} xs={24}>
                <Checkbox
                  value={value}
                  disabled={!allowed_days.includes(value)}
                >
                  {name}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};

const AccessHours = ({ rules }: { rules: Partial<TimeOfUseRule>[] }) => {
  if (!rules.length) {
    return null;
  }
  return (
    <div>
      Access Hours:
      <ul>
        {rules.map((rule: Partial<TimeOfUseRule>) => {
          const start = moment(rule.start_at);
          const end = moment(rule.end_at);
          if (rule.recurrence?.includes("DTSTART")) {
            const startDateFormatted = start
              .utc(true)
              .local(false)
              .format("LLLL");
            const endDateFormatted = end.utc(true).local(false).format("LLLL");
            return (
              <div key={`${startDateFormatted}_${endDateFormatted}`}>
                <li key={startDateFormatted}>
                  Earliest Entry Time: {startDateFormatted}
                </li>
                <li key={endDateFormatted}>
                  Required Exit Time: {endDateFormatted}
                </li>
              </div>
            );
          }
          const daysFormatted = _(rule.days_of_week)
            .map((dayOfWeek) => _.find(daysOfTheWeek, { value: dayOfWeek }))
            .map(_.property("name"))
            .join(", ");
          const startTimeFormatted = start.format("h:mma");
          const endTimeFormatted = end.format("h:mma");
          return (
            <li
              key={`__${daysFormatted}__${startTimeFormatted}__${endTimeFormatted}`}
            >
              {daysFormatted} {startTimeFormatted} - {endTimeFormatted}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const PricingDetails = ({
  title,
  labelAndValues,
}: {
  title: string;
  labelAndValues: Record<string, string>;
}) => {
  const items = _(labelAndValues)
    .toPairs()
    .map(([label, value]) => {
      return <li key={label}>{`${label}: ${value}`}</li>;
    })
    .value();
  return (
    <div>
      {title}
      <ul>{items}</ul>
    </div>
  );
};

export const ProductDetails = ({
  parkingProduct,
  defaultPrice,
}: {
  parkingProduct: ParkingProduct;
  defaultPrice: Price;
}) => {
  const {
    name,
    minimum_amount,
    amount_per_day,
    amount_per_session,
    maximum_session_duration_minutes,
    is_max_sessions_customizable,
    rates,
  } = defaultPrice;
  const sessionPrice = centsToDollars(amount_per_session);
  const expiresAt =
    !parkingProduct.is_disabled && parkingProduct.disabled_at
      ? moment(parkingProduct.disabled_at).format("LL")
      : false;
  const formattedDuration = moment()
    .startOf("day")
    .add(maximum_session_duration_minutes, "minutes")
    .format("H [hours and] m [minutes]");
  const showDailyRates = _(rates)
    .flatMap("time_of_use_freqs")
    .filter((freq) => /DAILY|WEEKLY/.test(freq))
    .reduce((acc, val) => !!val);
  const showMonthlyRates = _(rates)
    .flatMap("time_of_use_freqs")
    .filter((freq) => /MONTHLY/.test(freq))
    .reduce((acc, val) => !!val);
  const amountPerDay = _(amount_per_day)
    .mapKeys((value, key) => {
      const knownDay = _.find(daysOfTheWeek, { value: _.parseInt(key) });
      return knownDay ? _.property("name")(knownDay) : "unknown";
    })
    .mapValues((value) => `$${centsToDollars(value)}`)
    .value();
  const amountPerMonth = _(rates)
    .filter(({ time_of_use_freqs }) => time_of_use_freqs.includes("MONTHLY"))
    .reduce((allRates, { time_of_use_rules, amount, currency }) => {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
      });
      const firstRule = _.first(time_of_use_rules);
      const label = firstRule
        ? _.startCase(RRule.fromString(firstRule.recurrence).toText())
        : "";
      _.set(allRates, label, formatter.format(amount / 100));
      return allRates;
    }, {});
  const sessionPriceDetails = `Single Session Amount for ${formattedDuration} - $${sessionPrice}`;
  return (
    <Typography style={{ whiteSpace: "pre-wrap" }}>
      <p>{name}</p>
      <p>Price:</p>
      <p>
        {is_max_sessions_customizable ? <p>{sessionPriceDetails}</p> : null}
        {showDailyRates ? (
          <PricingDetails title="Cost per Day" labelAndValues={amountPerDay} />
        ) : null}
        {showMonthlyRates ? (
          <PricingDetails
            title="Cost per Month"
            labelAndValues={amountPerMonth}
          />
        ) : null}
        {minimum_amount
          ? `Minimum Purchase Amount: $${centsToDollars(minimum_amount)}`
          : null}
      </p>
      <AccessHours rules={defaultPrice.allowed_time_of_use} />
      <p>Description:</p>
      <p>{parkingProduct.description}</p>
      {parkingProduct.restrictions.trim().length > 0 && (
        <>
          <p>Restrictions:</p>
          <p>{parkingProduct.restrictions}</p>
        </>
      )}
      {expiresAt && <p>This parking product is set to expire on {expiresAt}</p>}
      <p>
        This pass is not eligible for refunds. Any update to your pass that
        results in a reduction of the total cost will be credited to you in your
        next statement.
      </p>
    </Typography>
  );
};

export default ProductCheckoutRouter;
