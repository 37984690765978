import { useAuth0 } from "@auth0/auth0-react";

import useIdsFetch from "./useIdsFetch";

export function getInvitationLink(invitationId: string) {
  return `${window.location.origin}/invitations/${invitationId}`;
}

const useInvitation = ({ invitationId }: { invitationId: string }) => {
  const { getIdTokenClaims } = useAuth0();
  const idsFetch = useIdsFetch();
  async function claim({ tenant_group_id }: { tenant_group_id?: string }) {
    const claims = await getIdTokenClaims();
    if (claims === undefined) {
      throw new Error("Failed to get auth token");
    }
    return await idsFetch(`/me/invitations/${invitationId}/claim`, {
      method: "POST",
      body: {
        id_token: claims.__raw,
        tenant_group_id: tenant_group_id,
      },
    });
  }
  return { claim };
};

export default useInvitation;
