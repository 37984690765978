import { useStripe } from "@stripe/react-stripe-js";
import {
  CreateTokenBankAccountData,
  PaymentMethodCreateParams,
} from "@stripe/stripe-js";
import * as React from "react";
import { useHistory } from "react-router-dom";

import useIdsFetch from "../../hooks/useIdsFetch";
import { useUserPaymentMethods } from "../../hooks/useUserWallet";
import notify from "../../utils/notify";
import AddPaymentMethod from "../PaymentMethods/AddPaymentMethod";

export default function AddParkerPaymentMethod() {
  const history = useHistory();
  const idsFetch = useIdsFetch();
  const stripe = useStripe();
  const { createNewPaymentMethod } = useUserPaymentMethods();

  async function createCardPayment(
    name: string,
    description: string,
    billingAddress: PaymentMethodCreateParams.BillingDetails
  ) {
    try {
      await createNewPaymentMethod(name, description, billingAddress);
      notify.success(`Successfully added "${name}" to your wallet`);
      setTimeout(() => {
        history.push("/me/wallet");
      }, 200);
    } catch (err) {
      notify.error("Failed to add the card to your wallet", err);
    }
  }

  async function createBankPayment(
    name: string,
    description: string,
    bankData: CreateTokenBankAccountData
  ) {
    if (stripe === null) {
      throw new Error("BUG: stripe is null");
    }

    let token;
    try {
      const res = await stripe.createToken("bank_account", bankData);
      if (res.error) {
        notify.error("Failed to create bank account on Stripe", res.error);
        return;
      }
      token = res.token;
    } catch (err) {
      notify.error("Failed to create bank account on Stripe", err);
      return;
    }

    try {
      await idsFetch("/me/wallet/sources/", {
        method: "POST",
        body: { name, description, token },
      });
      notify.success(`Successfully added "${name}" to your wallet`);
      setTimeout(() => {
        history.push("/me/wallet");
      }, 200);
    } catch (err) {
      notify.error("Failed to add the bank account to your wallet", err);
    }
  }

  return (
    <AddPaymentMethod
      onCreateCardPayment={createCardPayment}
      onCreateBankPayment={createBankPayment}
    />
  );
}
