import { useAuth0 } from "@auth0/auth0-react";
import { Button, Space, Typography } from "antd";
import React from "react";
import { Route, Switch } from "react-router-dom";

import NotFoundContent from "./NotFoundContent";

export default function AppWelcome() {
  return (
    <Switch>
      <Route exact path="/">
        <WelcomeContent />
      </Route>
      <Route>
        <NotFoundContent />
      </Route>
    </Switch>
  );
}

function WelcomeContent() {
  return (
    <Space
      style={{ width: "100%", margin: "20vh auto" }}
      align="center"
      direction="vertical"
      size="large"
    >
      <Typography style={{ textAlign: "center" }}>
        <Typography.Title>Welcome to SmartPass</Typography.Title>
      </Typography>
      <LoginSignupButtons />
    </Space>
  );
}

function LoginSignupButtons() {
  const { loginWithRedirect } = useAuth0();
  return (
    <Space size="large">
      <Button
        style={{ width: "7rem" }}
        type="primary"
        size="large"
        onClick={loginWithRedirect}
      >
        Login
      </Button>
      <Button
        style={{ width: "7rem" }}
        size="large"
        onClick={() => loginWithRedirect({ screen_hint: "signup" })}
      >
        Sign Up
      </Button>
    </Space>
  );
}
