import {
  ApartmentOutlined,
  DeploymentUnitOutlined,
  DownOutlined,
  ExclamationOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Row, Spin } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Organization } from "../../hooks/useOrganization";
import { useParkerTenantGroups } from "../../hooks/useParkerTenantGroups";
import { TenantGroup } from "../../hooks/useTenantGroup";
import { useUserOrganizations } from "../../hooks/useUserOrganizations";
import useUserProfile from "../../hooks/useUserProfile";

export default function ParkerDropdownMenu() {
  const match = useRouteMatch<{ id: string }>("/organizations/:id/");
  const [errorProfile, setErrorProfile] = useState<Error>();
  const [errorOrganizations, setErrorOrganizations] = useState<Error>();
  const [errorTenantGroups, setErrorTenantGroups] = useState<Error>();
  const { profile } = useUserProfile({ onError: setErrorProfile });
  const { organizations } = useUserOrganizations({
    onError: setErrorOrganizations,
  });
  const { tenantGroups } = useParkerTenantGroups({
    onError: setErrorTenantGroups,
  });
  return (
    <Dropdown
      overlay={
        <Menu
          selectedKeys={[match?.params?.id || "no-match"]}
          items={[
            {
              key: "organizations",
              label: (
                <>
                  <ApartmentOutlined /> Organizations
                </>
              ),
              children: getOrganizationChildren(
                organizations,
                errorOrganizations
              ),
              type: "group",
            },
            {
              key: "tenant-groups",
              label: (
                <>
                  <DeploymentUnitOutlined /> Tenant Groups
                </>
              ),
              children: getTenantGroupChildren(tenantGroups, errorTenantGroups),
              type: "group",
            },
            {
              key: "home",
              label: <Link to="/me/">Home</Link>,
              icon: <HomeOutlined />,
            },
            {
              key: "logout",
              label: <Link to="/logout">Log out</Link>,
              icon: <LogoutOutlined />,
            },
          ]}
        />
      }
      trigger={["click"]}
    >
      <Row>
        <Col xs={24} sm={0}>
          <MenuOutlined style={{ fontSize: "150%", verticalAlign: "middle" }} />
        </Col>
        <Col xs={0} sm={24}>
          <Button style={{ marginTop: "1rem" }} type="link">
            {errorProfile ? (
              <>
                {"Could not load profile"} <ExclamationOutlined />
              </>
            ) : profile ? (
              <>
                {profile.email || profile.id} <DownOutlined />
              </>
            ) : (
              <Spin />
            )}
          </Button>
        </Col>
      </Row>
    </Dropdown>
  );
}

function getOrganizationChildren(
  organizations: Organization[] | undefined,
  errorOrganizations: Error | undefined
) {
  let organizationChildren: ItemType[] = [];
  if (organizations && organizations.length > 0) {
    organizationChildren = organizations.slice(0, 10).map((org) => ({
      key: org.id,
      label: <Link to={`/organizations/${org.id}/`}>{org.name}</Link>,
    }));
  }
  if (organizations && organizations.length > 10) {
    organizationChildren.push({
      key: "organization-all",
      label: <Link to={`/me/organizations`}>All Organizations</Link>,
      icon: <SelectOutlined />,
    });
  }
  if (errorOrganizations) {
    organizationChildren.push({
      key: "organization-error",
      label: "Could not load organizations.",
      danger: true,
    });
  }
  return organizationChildren;
}

function getTenantGroupChildren(
  tenantGroups: TenantGroup[] | undefined,
  errorTenantGroups: Error | undefined
) {
  let tenantGroupChilden: ItemType[] = [];
  if (tenantGroups && tenantGroups.length > 0) {
    tenantGroupChilden = tenantGroups.slice(0, 10).map((group) => ({
      key: group.id,
      label: <Link to={`/tenant-groups/${group.id}/`}>{group.name}</Link>,
    }));
  }
  if (tenantGroups && tenantGroups.length > 10) {
    tenantGroupChilden.push({
      key: "tenant-all",
      label: <Link to={`/me/tenant-groups`}>All Tenant Groups</Link>,
      icon: <SelectOutlined />,
    });
  }
  if (errorTenantGroups) {
    tenantGroupChilden.push({
      key: "tenant-error",
      label: "Could not load tenant groups.",
      danger: true,
    });
  }
  return tenantGroupChilden;
}
