import {
  ContainerOutlined,
  ExceptionOutlined,
  FileSearchOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Modal,
  Popover,
  Result,
  Row,
  Select,
  Space,
} from "antd";
import { ColumnType } from "antd/lib/table";
import _ from "lodash";
import moment from "moment-timezone";
import queryString from "query-string";
import { RangeValue } from "rc-picker/lib/interface";
import React from "react";
import { useHistory } from "react-router-dom";

import {
  AccountingItem,
  Charge,
  Refund,
  Transfer,
} from "../hooks/useAccounting";
import notify from "../utils/notify";
import RefundForm, { RefundFormValues } from "./RefundForm";
import ResponsiveList from "./ResponsiveList";
import { downloadFile, getCsvContent } from "./ResponsiveList/ResponsiveList";
import VerticalSpace from "./VerticalSpace";

const TIMEZONES = [
  { value: "America/New_York", text: "Eastern Time" },
  { value: "America/Chicago", text: "Central Time" },
  { value: "America/Denver", text: "Mountain Time" },
  { value: "America/Los_Angeles", text: "Pacific Time" },
  { value: "Pacific/Honolulu", text: "Hawaii Time" },
];
const URL_DATETIME_FORMAT = "YYYY-MM-DD";
const REFUND = "refund";
const FULL_WIDTH_SPAN = 24;

interface DateRange {
  start: moment.Moment;
  end: moment.Moment;
  timezone: string;
}

interface RenderItem {
  id: string;
  created: number;
  userName: string;
  locationName: string;
  productName: string;
  chargeType: string;
  paid: number;
  net: number;
  currency: string;
  isRefund: boolean;
  charge: Charge;
  refundReason: string;
  refundDetails: string;
  refundInitiator: string;
}

type AccountingColumn =
  | "Date"
  | "Charge Type"
  | "Parker"
  | "Location"
  | "Product"
  | "Paid"
  | "Net";

interface Props {
  getAccountingItemsForTimeRange: (
    start_epoch: number,
    end_epoch: number
  ) => Promise<AccountingItem[]>;
  ignoreColumns?: AccountingColumn[];
  onRefund?: (
    chargeId: string,
    amount: number,
    reason: string,
    details: string
  ) => Promise<Refund>;
}

export default function AccountingList(props: Props) {
  const history = useHistory();
  const getDateRangeFromUrl = React.useCallback(() => {
    const queries = queryString.parse(history.location.search);
    return getDateRangeFromQueries(queries);
  }, [history.location.search]);
  const [dateRange, setDateRange] = React.useState(getDateRangeFromUrl());
  const [renderItems, setRenderItems] = React.useState<RenderItem[]>();
  const [accountingItems, setAccountingItems] =
    React.useState<AccountingItem[]>();
  const [error, setError] = React.useState<Error>();

  React.useEffect(() => {
    setDateRange(getDateRangeFromUrl());
  }, [getDateRangeFromUrl]);

  React.useEffect(() => {
    refreshAccountingItems();
  }, [dateRange]);

  React.useEffect(() => {
    if (accountingItems === undefined) {
      setRenderItems(undefined);
      return;
    }
    setRenderItems(
      accountingItems
        .sort((i1, i2) => i1.charge.created - i2.charge.created)
        .map((item) => [
          getChargeRenderItem(item),
          ...item.charge.refunds
            .map((refund) => getRefundRenderItem(item, refund))
            .sort((r1, r2) => r1.created - r2.created),
        ])
        .flat()
    );
  }, [accountingItems]);

  async function refreshAccountingItems() {
    try {
      const accountingItems = await props.getAccountingItemsForTimeRange(
        dateRange.start.unix(),
        dateRange.end.unix()
      );
      setAccountingItems(accountingItems);
    } catch (e) {
      setError(e as Error);
      setAccountingItems(undefined);
    }
  }

  function handleRangePickerChange(values: RangeValue<moment.Moment>) {
    const newStart = values ? values[0] : null;
    const newEnd = values ? values[1] : null;
    if (newStart === null || newEnd === null) {
      // Do not allow empty dates.
      return;
    }
    setDateRange((dateRange) => ({
      ...dateRange,
      start: newStart,
      end: newEnd,
    }));
  }

  function handleTimezoneChange(value: string) {
    setDateRange((dateRange) => ({
      timezone: value,
      // The "true" param in tz() call changes timezone but keeps local datetime
      // See https://momentjs.com/timezone/docs/#/using-timezones/converting-to-zone/
      start: moment(dateRange.start).tz(value, true),
      end: moment(dateRange.end).tz(value, true),
    }));
  }

  function handleSubmit() {
    const start = dateRange.start.format(URL_DATETIME_FORMAT);
    const end = dateRange.end.format(URL_DATETIME_FORMAT);
    const timezone = dateRange.timezone;
    const queries = queryString.parse(history.location.search);
    if (
      start === queries.start &&
      end === queries.end &&
      timezone === queries.timezone
    ) {
      return;
    }
    history.push(
      queryString.stringifyUrl({
        url: history.location.pathname,
        query: { ...queries, start, end, timezone },
      })
    );
  }

  function getColumns(): ColumnType<RenderItem>[] {
    const allColumns: ColumnType<RenderItem>[] = [
      {
        key: "action",
        dataIndex: "charge",
        colSpan: 1,
        render: (charge: Charge, item: RenderItem) =>
          !item.isRefund && (
            <ChargeActions
              userName={item.userName}
              charge={charge}
              onShowReceipt={() =>
                charge.receipt_url && window.open(charge.receipt_url)
              }
              onRefund={
                props.onRefund === undefined
                  ? undefined
                  : async (amount: number, reason: string, details: string) => {
                      if (props.onRefund) {
                        await props.onRefund(
                          charge.id,
                          amount,
                          reason,
                          details
                        );
                        refreshAccountingItems();
                      }
                    }
              }
              maxRefundAmount={
                getPaidAmount(charge) - getRefundedAmount(charge)
              }
            />
          ),
      },
      {
        title: "Date",
        dataIndex: "created",
        colSpan: 4,
        render: (created: number) =>
          getFormattedDatetimeString(created, dateRange.timezone),
      },
      {
        title: "Charge Type",
        dataIndex: "chargeType",
        colSpan: 3,
        render: (chargeType: string, item: RenderItem) =>
          item.isRefund ? (
            <RefundActions
              chargeType={chargeType}
              details={item.refundDetails}
              reason={item.refundReason}
              initiator={item.refundInitiator}
            />
          ) : (
            _.startCase(chargeType)
          ),
      },
      {
        title: "Parker",
        dataIndex: "userName",
        colSpan: 3,
      },
      {
        title: "Location",
        dataIndex: "locationName",
        colSpan: 3,
      },
      {
        title: "Product",
        dataIndex: "productName",
        colSpan: 6,
      },
      {
        title: "Paid",
        dataIndex: "paid",
        colSpan: 2,
        render: (paid: number, item: RenderItem) =>
          getFormattedAmount(paid, item.currency),
      },
      {
        title: "Net",
        dataIndex: "net",
        colSpan: 2,
        render: (net: number, item: RenderItem) =>
          getFormattedAmount(net, item.currency),
      },
    ];
    const ignoreColumns = props.ignoreColumns || [];
    const columns = _.reject(allColumns, (c) =>
      ignoreColumns.includes(c.title as AccountingColumn)
    );

    // Re-balance colSpan
    const resizeRatio = FULL_WIDTH_SPAN / _.sum(_.map(columns, "colSpan"));
    const balancedColumns: ColumnType<RenderItem>[] = columns.map((c) => ({
      ...c,
      colSpan: Math.max(Math.round(c.colSpan! * resizeRatio), 1),
    }));
    const colSpanDiff =
      FULL_WIDTH_SPAN - _.sum(_.map(balancedColumns, "colSpan"));
    balancedColumns[balancedColumns.length - 1].colSpan! += colSpanDiff;
    return balancedColumns;
  }

  async function exportCsvFile(items: Readonly<RenderItem[]>): Promise<void> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const data = items.map((row) => ({
          ID: row.id,
          Date: getFormattedDatetimeString(row.created, dateRange.timezone),
          "Charge Type": _.startCase(row.chargeType),
          Parker: row.userName,
          Location: row.locationName,
          Product: row.productName,
          Paid: getFormattedAmount(row.paid, row.currency),
          Net: getFormattedAmount(row.net, row.currency),
          "Refund Reason": row.refundReason,
          "Refund Details": row.refundDetails,
        }));
        const fields = [
          "ID",
          ..._.map(getColumns(), "title").filter((t) => t !== undefined),
          "Refund Reason",
          "Refund Details",
        ];
        downloadFile(
          getDownloadFileName(dateRange),
          getCsvContent(fields as string[], data),
          "data:text/csv;charset=utf-8;"
        );
        resolve();
      }, 500)
    );
  }

  if (error) {
    return (
      <Card>
        <Result status="error" title="Failed to get accounting data." />
      </Card>
    );
  }

  return (
    <VerticalSpace size="large">
      <Card>
        <Row gutter={[12, 12]} align="middle">
          <Col flex="none">
            <strong>Date Range:</strong>
          </Col>
          <Col flex="none">
            <DatePicker.RangePicker
              allowEmpty={[false, false]}
              clearIcon={false}
              value={[dateRange.start, dateRange.end]}
              onChange={handleRangePickerChange}
            />
          </Col>
          <Col flex="none">
            <Select value={dateRange.timezone} onChange={handleTimezoneChange}>
              {TIMEZONES.map((tz) => (
                <Select.Option key={tz.value} value={tz.value}>
                  {tz.text}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col flex="none">
            <Button type="primary" onClick={handleSubmit}>
              Go
            </Button>
          </Col>
        </Row>
      </Card>
      <ResponsiveList<RenderItem>
        loading={renderItems === undefined}
        columns={getColumns()}
        dataSource={renderItems}
        rowKey={(item: RenderItem) => item.id}
        onExport={exportCsvFile}
      />
    </VerticalSpace>
  );
}

function getDateRangeFromQueries(queries: {
  start?: string;
  end?: string;
  timezone?: string;
}): DateRange {
  let timezone = queries.timezone || moment.tz.guess();
  if (!_.find(TIMEZONES, { value: timezone })) {
    timezone = TIMEZONES[0].value;
  }

  let end = moment.tz(queries.end || "", URL_DATETIME_FORMAT, timezone);
  if (!end.isValid()) {
    end = moment().tz(timezone);
  }
  end = end.endOf("day");

  let start = moment.tz(queries.start || "", URL_DATETIME_FORMAT, timezone);
  if (!start.isValid() || start.isAfter(end)) {
    // By default get the data one week
    start = moment(end).subtract(1, "week");
  }
  start = start.startOf("day");

  return { start, end, timezone };
}

function getChargeRenderItem(item: AccountingItem): RenderItem {
  return {
    id: item.charge.id,
    created: item.charge.created,
    userName: item.user_profile?.name || "",
    locationName: item.location?.name || "",
    productName: item.parking_product?.name || "",
    chargeType: item.charge_for,
    paid: getPaidAmount(item.charge),
    net: getNetAmount(item.charge, item.transfer),
    currency: item.charge.currency,
    isRefund: false,
    charge: item.charge,
    refundReason: "",
    refundDetails: "",
    refundInitiator: "",
  };
}

function getRefundRenderItem(item: AccountingItem, refund: Refund): RenderItem {
  return {
    id: refund.id,
    created: refund.created,
    userName: item.user_profile?.name || "",
    locationName: item.location?.name || "",
    productName: item.parking_product?.name || "",
    chargeType: REFUND,
    paid: -refund.amount,
    net: getTransferReversalAmount(refund, item.transfer),
    currency: refund.currency,
    isRefund: true,
    charge: item.charge,
    refundReason: refund.metadata?.reason || "",
    refundDetails: refund.metadata?.details || "",
    refundInitiator: refund.metadata?.refunderEmail || "",
  };
}

function getPaidAmount(charge: Charge): number {
  return charge.amount_captured !== undefined
    ? charge.amount_captured
    : charge.amount;
}

function getNetAmount(charge: Charge, transfer?: Transfer | null): number {
  if (!transfer || !Number.isFinite(transfer.amount)) {
    return 0;
  }
  const applicationFee = charge.application_fee_amount || 0;
  return transfer.amount - applicationFee;
}

function getRefundedAmount(charge: Charge): number {
  return charge.amount_refunded === undefined ? 0 : charge.amount_refunded;
}

function getTransferReversalAmount(
  refund: Refund,
  transfer?: Transfer | null
): number {
  if (!transfer || !refund.transfer_reversal) {
    return 0;
  }
  const reversal = _.find(transfer.reversals, { id: refund.transfer_reversal });
  if (!reversal) {
    return 0;
  }
  return -reversal.amount;
}

function getFormattedDatetimeString(timestamp: number, timezone: string) {
  return moment.unix(timestamp).tz(timezone).format("lll");
}

function getFormattedAmount(amount: number, currency: string = "usd") {
  return (amount / 100).toLocaleString(undefined, {
    style: "currency",
    currency,
  });
}

function getDownloadFileName(dateRange: DateRange) {
  return `smartpass_accounting_${dateRange.start.format(
    URL_DATETIME_FORMAT
  )}_to_${dateRange.end.format(URL_DATETIME_FORMAT)}.csv`;
}

interface RefundActionProps {
  chargeType: string;
  reason: string;
  details: string;
  initiator: string;
}

function RefundActions(props: Readonly<RefundActionProps>) {
  return (
    <Popover
      title={"Refund Details"}
      placement={"leftTop"}
      content={
        <Menu
          selectable={false}
          items={[
            {
              key: "reason",
              icon: "Reason:",
              label: props.reason,
            },
            {
              key: "details",
              icon: "Details:",
              label: props.details,
            },
            {
              key: "initiator",
              icon: "Initiator:",
              label: props.initiator,
            },
          ]}
        />
      }
    >
      <Space>{_.startCase(props.chargeType)}</Space>
    </Popover>
  );
}

interface ChargeActionButtonProps {
  userName: string;
  charge: Charge;
  onShowReceipt: () => void;
  onRefund?: (amount: number, reason: string, details: string) => Promise<void>;
  maxRefundAmount: number;
}

function ChargeActions(props: Readonly<ChargeActionButtonProps>) {
  const [isRefundModalVisible, setIsRefundModalVisible] = React.useState(false);

  function showRefundModal() {
    setIsRefundModalVisible(true);
  }

  function closeRefundModal() {
    setIsRefundModalVisible(false);
  }

  if (!isChargeSucceeded(props.charge)) {
    return (
      <Dropdown
        trigger={["click"]}
        overlay={
          <Menu
            items={[
              {
                key: "not-charged",
                label: (
                  <strong style={{ color: "darkred" }}>Not Charged</strong>
                ),
              },
              {
                key: "reason",
                label: props.charge.failure_message
                  ? `Reason: ${props.charge.failure_message}`
                  : `Status: ${props.charge.status}`,
              },
            ]}
          />
        }
      >
        <ExceptionOutlined style={{ color: "darkred" }} />
      </Dropdown>
    );
  }

  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu
          items={[
            {
              key: "receipt",
              label: (
                <Button
                  type="text"
                  size="small"
                  icon={<ContainerOutlined />}
                  onClick={props.onShowReceipt}
                >
                  Receipt
                </Button>
              ),
            },
            {
              key: "refund",
              label: props.onRefund && (
                <>
                  <Button
                    type="text"
                    size="small"
                    icon={<UndoOutlined rotate={75} />}
                    onClick={showRefundModal}
                    disabled={props.maxRefundAmount <= 0}
                  >
                    {props.maxRefundAmount > 0 ? "Refund" : "Refunded"}
                  </Button>
                  <RefundModal
                    userName={props.userName}
                    maxRefundAmount={props.maxRefundAmount}
                    isVisible={isRefundModalVisible}
                    closeModal={closeRefundModal}
                    onRefund={props.onRefund}
                  />
                </>
              ),
            },
          ]}
        />
      }
    >
      <FileSearchOutlined />
    </Dropdown>
  );
}

function isChargeSucceeded(charge: Charge) {
  return charge.status === "succeeded";
}

interface RefundModalProps {
  userName: string;
  maxRefundAmount: number;
  isVisible: boolean;
  closeModal: () => void;
  onRefund: (amount: number, reason: string, details: string) => Promise<void>;
}

function RefundModal(props: Readonly<RefundModalProps>) {
  const [isRefunding, setIsRefunding] = React.useState(false);
  const [form] = Form.useForm();

  async function handleConfirm() {
    let values: RefundFormValues;
    try {
      values = await form.validateFields();
    } catch (error) {
      return;
    }
    Modal.confirm({
      title: `Are you sure to refund ${props.userName} ${getFormattedAmount(
        values.amount!
      )}?`,
      okText: "Confirm",
      onOk: async () => {
        if (values.amount && values.reason) {
          setIsRefunding(true);
          try {
            await props.onRefund(
              values.amount,
              values.reason,
              values.details || ""
            );
            notify.success("Refund request submitted");
            props.closeModal();
          } catch (err) {
            notify.error("Failed to refund", err);
          } finally {
            setIsRefunding(false);
          }
        }
      },
    });
  }

  return (
    <Modal
      visible={props.isVisible}
      title={
        <Space size="middle">
          <UndoOutlined style={{ color: "#4b5766" }} rotate={75} />
          Refund Charge
        </Space>
      }
      maskClosable
      okText="Refund"
      onOk={handleConfirm}
      onCancel={props.closeModal}
    >
      <RefundForm
        form={form}
        userName={props.userName}
        maxRefundAmount={props.maxRefundAmount}
        isLoading={isRefunding}
      />
    </Modal>
  );
}
