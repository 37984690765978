import { QrcodeOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";
import * as React from "react";

export default function GeneratingPassDisplay() {
  return (
    <Space
      style={{ width: "100%", textAlign: "center" }}
      direction="vertical"
      size="middle"
    >
      <Typography.Text type="warning">
        <QrcodeOutlined style={{ fontSize: 72 }} />
      </Typography.Text>
      <div>
        <Typography.Title level={4}>
          We are generating your pass!
        </Typography.Title>
        <Typography.Text type="secondary">
          If you still don't see your pass in 15 minutes, contact your parking
          manager.
        </Typography.Text>
      </div>
    </Space>
  );
}
