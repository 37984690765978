import { Space, SpaceProps, Spin } from "antd";
import * as React from "react";

interface Props extends SpaceProps {
  isLoading?: boolean;
}

export default function VerticalSpace(props: Readonly<Props>) {
  const spaceProps: SpaceProps = {
    ...props,
    style: { ...props.style, width: "100%" },
    direction: "vertical",
  };
  return (
    <Spin spinning={!!props.isLoading}>
      <Space {...spaceProps}>{props.children}</Space>
    </Spin>
  );
}
