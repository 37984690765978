import * as React from "react";
import { useParams } from "react-router-dom";

import { useGlobalState } from "../App";
import useIdsFetch from "./useIdsFetch";

export interface Organization {
  id: string;
  name: string;
  current_platform_fee_percentage: number;
  children: Organization[];
}

export default function useOrganization(): {
  data: Organization | undefined;
  isLoading: boolean;
  error: Error | undefined;
  mutate: () => Promise<void>;
} {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [organization, setOrganization] = useGlobalState("organization");
  const idsFetch = useIdsFetch();

  if (!organizationId) {
    setOrganization((org) => ({
      ...org,
      error: new Error("Cannot find organization ID from URL."),
    }));
  }

  const mutate = React.useCallback(async (): Promise<void> => {
    setOrganization((org) => ({ ...org, error: undefined, isLoading: true }));
    try {
      const data = await idsFetch(`/organizations/${organizationId}`);
      setOrganization((org) => ({ ...org, data }));
    } catch (err) {
      const error = err as Error;
      setOrganization((org) => ({ ...org, error: error }));
    } finally {
      setOrganization((org) => ({ ...org, isLoading: false }));
    }
  }, [idsFetch, organizationId, setOrganization]);

  React.useEffect(() => {
    if (!organization.data || organization.data.id !== organizationId) {
      mutate();
    }
    return () => {
      setOrganization((org) => ({ ...org, error: undefined }));
    };
  }, [organization.data, organizationId, mutate, setOrganization]);

  return { ...organization, mutate };
}
