import {
  CarOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Modal,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import _ from "lodash";
import * as React from "react";

import { Location } from "../../hooks/useOrganizationLocations";
import {
  PurchaseLite,
  useUserPurchase,
  useUserPurchases,
} from "../../hooks/useUserPurchases";
import notify from "../../utils/notify";
import { ProductDetails } from "../ProductCheckout";
import VerticalSpace from "../VerticalSpace";
import PassCard from "./PassCard";

interface Props {
  passId?: string;
  onViewPass: (id: string) => void;
  onMakeChangesClick: (id: string) => void;
  onCancelSuccess: () => void;
}

export default function PassesView(props: Readonly<Props>) {
  const [error, setError] = React.useState<Error>();
  const { purchases, cancel, revalidate } = useUserPurchases({
    onError: setError,
  });
  const [selectedPass, setSelectedPass] = React.useState<PurchaseLite>();
  const [isCancelling, setIsCancelling] = React.useState(false);

  React.useEffect(() => {
    async function selectPass() {
      if (!purchases || purchases.length <= 0) {
        return;
      }

      if (props.passId) {
        if (props.passId === selectedPass?.id) {
          return;
        }
        const updatedPasses = await revalidate();
        const p = _.find(updatedPasses, { id: props.passId });
        if (p) {
          setSelectedPass(p);
          return;
        }
      }

      const sorted = _.sortBy(purchases, "payment_at");
      for (const p of sorted) {
        if (p.is_valid_now) {
          setTimeout(() => {
            props.onViewPass(p.id);
          }, 100);
          return;
        }
      }

      setTimeout(() => {
        props.onViewPass(sorted[0].id);
      }, 100);
    }
    selectPass();
  }, [props, purchases, revalidate, selectedPass, setSelectedPass]);

  const showConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to cancel?",
      icon: <ExclamationCircleOutlined />,
      onOk: cancelPass,
    });
  };

  const cancelPass = async () => {
    if (!selectedPass) {
      return;
    }
    try {
      setIsCancelling(true);
      await cancel(selectedPass?.id);
      notify.success("Successfully cancelled the pass.");
      revalidate();
      setTimeout(props.onCancelSuccess, 200);
    } catch (err) {
      notify.error("Failed to cancel the pass", err);
    } finally {
      setIsCancelling(false);
    }
  };

  if (error) {
    return (
      <Card>
        <Result status="error" title="Could not load passes" />
      </Card>
    );
  }

  if (purchases && purchases.length <= 0) {
    return (
      <Card>
        <Result
          title="You have no active parking pass."
          icon={<CarOutlined />}
        />
      </Card>
    );
  }

  if (!purchases || !selectedPass) {
    return (
      <Spin spinning size="large">
        <Card loading />
      </Spin>
    );
  }

  return (
    <Spin spinning={isCancelling}>
      <VerticalSpace>
        <Typography.Title level={4}>Passes</Typography.Title>
        {purchases.length > 1 && (
          <Select
            style={{ width: "100%" }}
            value={selectedPass.id}
            onChange={props.onViewPass}
          >
            {getPassesByLocation(purchases).map((locationGroup, idx) => (
              <Select.OptGroup
                key={`${locationGroup.name}|${idx}`}
                label={locationGroup.name}
              >
                {_.map(locationGroup.purchases, (p) => (
                  <Select.Option key={p.id} value={p.id}>
                    {p.parking_product_name}
                    {p.is_one_time_purchase ? " - Day Pass" : ""}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        )}
        <Row key={selectedPass.id} gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <PassCard
              pass={selectedPass}
              onViewPass={props.onViewPass}
              actions={[
                <Button
                  key="change"
                  type="text"
                  onClick={() => props.onMakeChangesClick(selectedPass.id)}
                  disabled={selectedPass.is_one_time_purchase || isCancelling}
                >
                  Make Changes
                </Button>,
                <Button
                  key="cancel"
                  type="link"
                  danger
                  onClick={showConfirm}
                  disabled={selectedPass.is_one_time_purchase || isCancelling}
                >
                  Cancel My Pass
                </Button>,
              ]}
            />
          </Col>
          <Col xs={24} md={12}>
            <Card>
              <ProductInfo pass={selectedPass} />
            </Card>
          </Col>
        </Row>
      </VerticalSpace>
    </Spin>
  );
}

function getPassesByLocation(passes: PurchaseLite[]) {
  return _(passes)
    .groupBy("location_name")
    .map((purchases, name) => ({ name, purchases }))
    .sortBy("name")
    .value();
}

interface ProductInfoProps {
  pass: PurchaseLite;
}

function ProductInfo(props: Readonly<ProductInfoProps>) {
  const [error, setError] = React.useState<Error>();
  const { purchase: fullPurchase } = useUserPurchase({
    purchaseId: props.pass.id,
    onError: setError,
  });

  if (error) {
    return (
      <Result status="error" title="Failed to load product information." />
    );
  }

  if (!fullPurchase) {
    return (
      <div style={{ padding: "5rem", textAlign: "center" }}>
        <Spin spinning size="large" />
      </div>
    );
  }

  const { price } = fullPurchase;
  const { parking_product } = price;
  const { location } = parking_product;
  return (
    <>
      <Typography.Title level={4}>
        Product: {props.pass.parking_product_name}
      </Typography.Title>
      <ProductDetails parkingProduct={parking_product} defaultPrice={price} />
      <Typography.Title level={4}>Location: {location.name}</Typography.Title>
      <Typography style={{ whiteSpace: "pre-wrap" }}>
        <Typography.Paragraph>{location.description}</Typography.Paragraph>
      </Typography>
      <Typography.Title level={4}>Support</Typography.Title>
      <SupportInfo location={location} />
    </>
  );
}

interface SupportInfoProps {
  location: Location;
}

function SupportInfo(props: Readonly<SupportInfoProps>) {
  const { support_phone_number } = props.location;
  const { support_email } = props.location;
  return (
    <Space direction="vertical">
      {support_phone_number.length > 0 && (
        <Space size="middle">
          <PhoneOutlined />
          {support_phone_number}
        </Space>
      )}
      {support_email && support_email.length > 0 && (
        <Space size="middle">
          <MailOutlined />
          <Typography.Link
            href={`mailto:${support_email}?subject=Help with SmartPass at ${props.location.name}`}
          >
            {support_email}
          </Typography.Link>
        </Space>
      )}
    </Space>
  );
}
