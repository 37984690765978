import * as stripe from "@stripe/stripe-js";

import { ParkingProduct } from "./useParkingProduct";

export interface PaymentMethod {
  id: string;
  name: string;
  description: string;
  stripe_payment_method: {
    id: string;
    data: stripe.PaymentMethod;
  };
}

export interface AuthorizedSource {
  name: string;
  description: string;
  id: string;
  source?: Source;
  parking_product: ParkingProduct;
  isDeleted?: boolean;
}

export interface Source {
  id: string;
  name: string;
  description: string;
  stripe_source: StripeSource;
  authorized_source: AuthorizedSource;
}

export interface SourceCreate {
  name: string;
  description: string;
  token: {
    id: string;
  };
}
export interface StripeSource {
  id: string;
  data: stripe.BankAccount;
}

export interface StripeToken {
  id: string;
}

export interface Wallet {
  id: string;
  paymentMethodCount: number;
}

export function getCreditCardPreviewText(paymentMethod: PaymentMethod) {
  const { card } = paymentMethod.stripe_payment_method.data;
  if (card === undefined) {
    return "No card information.";
  }
  return `${card.brand.toUpperCase()} card ending in ${card.last4}`;
}

export function getBankAccountPreviewText(source: Source) {
  const { data } = source.stripe_source;
  return `${data.bank_name || "Bank"} account ending in ${data.last4}`;
}
