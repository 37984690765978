import {
  BankOutlined,
  DeleteOutlined,
  DollarOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Result,
  Row,
  Skeleton,
  Space,
  Steps,
  Typography,
} from "antd";
import _ from "lodash";
import React, { useState } from "react";
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { Organization } from "../hooks/useOrganization";
import {
  PayoutAccount,
  usePayoutAccount,
  usePayoutAccounts,
} from "../hooks/usePayoutAccount";
import notify from "../utils/notify";
import NotFoundContent from "./NotFoundContent";
import VerticalSpace from "./VerticalSpace";

const OrganizationPayoutAccounts = ({
  organization,
}: {
  organization: Organization;
}) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const newPath = `${url}/new`;
  const itemEditPath = `${url}/:payoutAccountId/edit`;
  return (
    <Switch>
      <Route exact path={`${url}`}>
        <PayoutAccountsList
          organization={organization}
          onNewClick={() => history.push(newPath)}
          onEditClick={(payoutAccountId: string) =>
            history.push(generatePath(itemEditPath, { payoutAccountId }))
          }
        />
      </Route>
      <Route exact path={newPath}>
        <Card>
          <PayoutAccountCreate
            organization={organization}
            itemUrl={(payoutAccountId: string) => `${url}/${payoutAccountId}`}
            onCancel={() => history.push(url)}
          />
        </Card>
      </Route>
      <Route
        exact
        path={`${url}/:payoutAccountId`}
        render={({ match }) => (
          <PayoutAccountItem
            organization={organization}
            payoutAccountId={match.params.payoutAccountId}
            showDeleteScreen={true}
            onEditClick={(payoutAccountId: string) =>
              history.push(generatePath(itemEditPath, { payoutAccountId }))
            }
            onNewClick={() => history.push(newPath)}
            onPayoutAccountsClick={() => history.push(url)}
          />
        )}
      />
      <Route
        exact
        path={`${url}/:payoutAccountId/edit`}
        render={({ match }) => (
          <Card>
            <PayoutAccountEdit
              payoutAccountId={match.params.payoutAccountId}
              organization={organization}
            />
          </Card>
        )}
      />
      <Route>
        <Card>
          <NotFoundContent />
        </Card>
      </Route>
    </Switch>
  );
};

export const PayoutAccountCreate = ({
  organization,
  itemUrl,
  onCancel = () => {},
  cancelText = "Cancel",
}: {
  organization: Organization;
  itemUrl: (id: string) => string;
  onCancel?: () => void;
  cancelText?: string;
}) => {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const { create } = usePayoutAccounts({
    organizationId: organization.id,
    onError: setError,
  });
  const { createAccountLink } = usePayoutAccount({
    organizationId: organization.id,
  });
  const createPayoutAccount = async (values: Partial<PayoutAccount>) => {
    setIsLoading(true);
    try {
      const created = await create(values);
      const { url } = await createAccountLink({
        id: created.id,
        type: "account_onboarding",
        refreshUrl: `${window.location.origin}${itemUrl(created.id)}`,
        returnUrl: `${window.location.origin}${itemUrl(created.id)}`,
      });
      window.location.href = url;
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };
  if (error)
    return (
      <Result
        status="warning"
        title="Sorry, could not create payout account."
        extra={
          <Button
            type="primary"
            key="console"
            onClick={() => setError(undefined)}
          >
            Try Again
          </Button>
        }
      />
    );
  return (
    <Form layout="vertical" name="basic" onFinish={createPayoutAccount}>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Name is required." }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true, message: "Add a description." }]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item>
        <Space direction="horizontal">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            Submit
          </Button>
          <Button
            type="default"
            loading={isLoading}
            disabled={isLoading}
            onClick={onCancel}
          >
            {cancelText}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const PayoutAccountEdit = ({
  organization,
  payoutAccountId,
}: {
  organization: Organization;
  payoutAccountId: string;
}) => {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const { payoutAccount: account, update } = usePayoutAccount({
    organizationId: organization.id,
    payoutAccountId,
  });
  const updateAccount = async (values: Partial<PayoutAccount>) => {
    setIsLoading(true);
    try {
      await update(values);
      notify.success("Account updated!");
    } catch (err) {
      notify.error("Failed to update account", err);
    } finally {
      setIsLoading(false);
    }
  };
  if (error)
    return (
      <Result
        status="warning"
        title="Sorry, could not update payout account."
        extra={
          <Button
            type="primary"
            key="console"
            onClick={() => setError(undefined)}
          >
            Try Again
          </Button>
        }
      />
    );
  if (!account) return <Skeleton></Skeleton>;
  return (
    <Form
      layout="vertical"
      name="basic"
      onFinish={updateAccount}
      initialValues={account}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Name is required." }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true, message: "Add a description." }]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          disabled={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const AccountName = ({ account }: { account: PayoutAccount }) => (
  <span>{account.name || account.id}</span>
);

const getOnboardingRibbonStatus = (account: PayoutAccount) => {
  const {
    stripe_account: { data },
  } = account;
  let text;
  let color;
  if (data.charges_enabled) {
    text = "Ready";
    color = "green";
  } else {
    text = "In Progress";
    color = "orange";
  }
  if (data.requirements?.currently_due?.length) {
    text = "Action Needed";
    color = "red";
  }
  return { text, color };
};
const getCurrentOnboardingStep = (account: PayoutAccount) => {
  const {
    stripe_account: { data },
  } = account;
  if (data.requirements?.currently_due?.length === 0) return 2;
  if (data.details_submitted === true) return 1;
  return 0;
};

const PayoutAccountItem = ({
  organization,
  payoutAccountId,
  payoutAccount,
  showDeleteScreen = false,
  onEditClick,
  onNewClick,
  onPayoutAccountsClick,
  onDelete = () => {},
}: {
  organization: Organization;
  payoutAccountId: string;
  payoutAccount?: PayoutAccount;
  showDeleteScreen?: boolean;
  onEditClick: (id: string) => void;
  onNewClick: () => void;
  onPayoutAccountsClick: () => void;
  onDelete?: () => void;
}) => {
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const {
    payoutAccount: account,
    remove,
    createLoginLink,
    createAccountLink,
  } = usePayoutAccount({
    organizationId: organization.id,
    payoutAccountId,
    onError: setError,
  });
  const doAction = async (func: () => Promise<void>) => {
    setIsLoading(true);
    try {
      await func();
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };
  const showDeleteConfirm = () => {
    Modal.confirm({
      title: "Are you sure delete this payout account?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () =>
        doAction(async () => {
          await remove();
          onDelete();
        }),
    });
  };
  if (error) return <NotFoundContent />;
  if (!account)
    return (
      <Skeleton>
        <Card />
      </Skeleton>
    );
  if (account.isDeleted && showDeleteScreen)
    return (
      <Card>
        <Result
          status="success"
          title="Payout Account was deleted"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={onPayoutAccountsClick}
            >
              View All
            </Button>,
            <Button key="add" onClick={onNewClick}>
              Add New Payout Account
            </Button>,
          ]}
        />
      </Card>
    );
  const step = getCurrentOnboardingStep(account);
  const onboardingStatusProps = getOnboardingRibbonStatus(account);
  return (
    <Badge.Ribbon {...onboardingStatusProps}>
      <Card
        style={{ marginTop: "2rem" }}
        title={<AccountName account={account} />}
        actions={[
          <Button
            key="edit"
            type="link"
            onClick={() => onEditClick(account.id)}
          >
            <EditOutlined />
            Edit
          </Button>,
          <Button
            type="link"
            disabled={isLoading}
            loading={isLoading}
            onClick={() =>
              account.stripe_account.data.details_submitted
                ? doAction(async () => {
                    const { url } = await createLoginLink({
                      redirectUrl: `${window.location.href}`,
                    });
                    window.location.href = url;
                  })
                : doAction(async () => {
                    const { url } = await createAccountLink({
                      type: "account_onboarding",
                      refreshUrl: `${window.location.origin}${pathname}`,
                      returnUrl: `${window.location.origin}${pathname}`,
                    });
                    window.location.href = url;
                  })
            }
          >
            <BankOutlined />
            Stripe
          </Button>,
          <Button key="delete" danger type="link" onClick={showDeleteConfirm}>
            <DeleteOutlined />
            Delete
          </Button>,
        ]}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Steps
            direction="vertical"
            current={step}
            size="small"
            status={step === 2 ? "finish" : "process"}
          >
            <Steps.Step title="Onboarding started" />
            <Steps.Step title="Business verified" />
            <Steps.Step title="Done!" />
          </Steps>
          <Typography>
            <Typography.Title level={5}>Description</Typography.Title>
            <Typography.Paragraph>{account.description}</Typography.Paragraph>
          </Typography>
        </Space>
      </Card>
    </Badge.Ribbon>
  );
};

const PayoutAccountsList = ({
  organization,
  onNewClick,
  onEditClick,
}: {
  organization: Organization;
  onNewClick: () => void;
  onEditClick: (id: string) => void;
}) => {
  const [error, setError] = useState<Error>();
  const { payoutAccounts, revalidate } = usePayoutAccounts({
    organizationId: organization.id,
    onError: setError,
  });
  const accountEls = _.map(_.sortBy(payoutAccounts, "id"), (account) => (
    <Col xs={24} lg={12} xl={8}>
      <PayoutAccountItem
        payoutAccount={account}
        payoutAccountId={account.id}
        organization={organization}
        key={account.id}
        onEditClick={onEditClick}
        onNewClick={onNewClick}
        onPayoutAccountsClick={() => {}}
        onDelete={revalidate}
      />
    </Col>
  ));
  if (error)
    return (
      <Result
        status="error"
        title="Oops, an error occurred"
        extra={
          <Button
            type="primary"
            key="console"
            onClick={() => {
              setError(undefined);
              revalidate();
            }}
          >
            Try Again
          </Button>
        }
      />
    );
  if (payoutAccounts?.length)
    return (
      <VerticalSpace size="large">
        <Card>
          <Button type="primary" icon={<PlusOutlined />} onClick={onNewClick}>
            Add Payout Account
          </Button>
        </Card>
        <Row gutter={16}>{accountEls}</Row>
      </VerticalSpace>
    );

  if (payoutAccounts?.length === 0)
    return (
      <Card>
        <Result
          status="info"
          icon={<DollarOutlined />}
          title="Add a payout account to receive revenue from parking products."
          extra={[
            <Button type="primary" onClick={onNewClick}>
              Add payout account
            </Button>,
          ]}
        />
      </Card>
    );
  return null;
};

export default OrganizationPayoutAccounts;
