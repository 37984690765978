import { useCallback } from "react";
import { RRule } from "rrule";
import useSWR from "swr";
import { CodeStatus } from "../utils/code_status";

import { Address } from "./useAddress";
import useIdsFetch from "./useIdsFetch";
import { Location } from "./useOrganizationLocations";
import { Price } from "./useOrganizationParkingProducts";
import { Vehicle } from "./useVehicles";

export interface AccessCode {
  id: string;
  code: string;
  status: CodeStatus;
  updated_at: string;
  valid_to: string;
  valid_from: string;
  parking_policy_valid_to: string;
  parking_policy: ParkingPolicy;
  overstay: Overstay | null;
}

export interface Overstay {
  id: string;
  charge_amount: number;
}

export interface ParkingPolicy {
  id: string;
  location: Location;
  time_of_use_rules: TimeOfUseRule[];
  allowed_days: number[];
  vehicle?: Vehicle;
  access_code: AccessCode | null;
  purchase: {
    price: Price;
  };
  max_days_per_billing_cycle?: number;
  max_sessions_per_billing_cycle?: number;
}

export interface TimeOfUseRule {
  recurrence: string;
  freq: "DAILY" | "WEEKLY" | "MONTHLY";
  start_at: string;
  end_at: string;
  days_of_week: number[];
}

export interface Purchase {
  id: string;
  parking_policy: ParkingPolicy;
  price: Price;
  stripe_subscription: Record<string, any>;
  parking_days_count_for_current_billing_cycle: number;
  max_days_per_billing_cycle: number;
  parking_sessions_count_for_current_billing_cycle: number;
  max_sessions_per_billing_cycle: number;
  has_successful_payment: boolean;
  payment_at?: number;
  is_one_time_purchase: boolean;
  is_event_pass: boolean;
  is_subscription: boolean;
  related_purchases: Pick<
    Purchase,
    "id" | "is_one_time_purchase" | "is_valid_now"
  >[];
  is_valid_now: boolean;
  is_day_pass_available: boolean;
  valid_days_of_week: number[];
  valid_until_date?: string;
}

export interface PurchaseLite {
  id: string;
  is_subscription: boolean;
  is_one_time_purchase: boolean;
  is_event_pass: boolean;
  is_valid_now: boolean;
  payment_at?: number;
  parking_days_count_for_current_billing_cycle: number;
  max_days_per_billing_cycle: number;
  parking_sessions_count_for_current_billing_cycle: number;
  max_sessions_per_billing_cycle: number;
  valid_days_of_week: number[];
  valid_until_date?: string;
  parking_product_name: string;
  location_name: string;
  address: Address;
}

export interface PricePreview {
  base_cost: number;
  processing_fee: number;
}

const listPath = "/me/purchases";
const itemPath = (id: string) => `${listPath}/${id}/`;
const itemCancelPath = (id: string) => `${listPath}/${id}/cancel/`;
const itemPricePreviewPath = (id: string) => `${listPath}/${id}/price-preview/`;
const itemDayPassPath = (id: string) => `${listPath}/${id}/day-pass/`;
const itemStartSessionPath = (id: string) => `${listPath}/${id}/start-session`;
const itemEndSessionPath = (id: string) => `${listPath}/${id}/end-session`;
const itemInvalidateOverstaySessionPath = (id: string) =>
  `${listPath}/${id}/invalidate-overstay-session`;

export const useUserPurchases = ({
  onError = () => {},
}: { onError?: (err: Error) => void } = {}) => {
  const { data, mutate } = useSWR<PurchaseLite[]>(`${listPath}/`, { onError });
  const idsFetch = useIdsFetch();
  const cancel = async (purchaseId: string) => {
    await idsFetch(itemCancelPath(purchaseId), { method: "POST" });
    await mutate();
  };
  return { purchases: data, cancel, revalidate: mutate };
};

export const useUserPurchase = ({
  purchaseId,
  onError = () => {},
}: {
  onError?: (err: Error) => void;
  purchaseId: string;
}) => {
  const idsFetch = useIdsFetch();
  const { data, mutate } = useSWR<Purchase>(itemPath(purchaseId), { onError });
  const purchaseDayPass = async () => {
    return await idsFetch(itemDayPassPath(purchaseId), {
      method: "POST",
    });
  };
  const cancel = async () => {
    await idsFetch(itemCancelPath(purchaseId), { method: "POST" });
    await mutate();
  };
  const update = useCallback(
    async ({
      recurrence,
      vehicle_id,
      maxDays,
      maxSessions,
      payment_method_id,
      source_id,
    }: {
      recurrence: RRule | undefined;
      vehicle_id: string;
      maxDays?: number;
      maxSessions?: number;
      payment_method_id?: string;
      source_id?: string;
    }) => {
      return await idsFetch(itemPath(purchaseId), {
        method: "PUT",
        body: {
          recurrence: recurrence?.toString(),
          vehicle_id,
          max_days_per_billing_cycle: maxDays,
          max_sessions_per_billing_cycle: maxSessions,
          payment_method_id,
          source_id,
        },
      });
    },
    [idsFetch, purchaseId]
  );
  const pricePreview = useCallback(
    async function getPricePreview(
      recurrence: RRule,
      maxDays?: number,
      maxSessions?: number
    ): Promise<PricePreview> {
      return await idsFetch(itemPricePreviewPath(purchaseId), {
        method: "POST",
        body: {
          recurrence: recurrence.toString(),
          max_days_per_billing_cycle: maxDays,
          max_sessions_per_billing_cycle: maxSessions,
        },
      });
    },
    [idsFetch, purchaseId]
  );

  const startSession = useCallback(async () => {
    const purchase = await idsFetch(itemStartSessionPath(purchaseId), {
      method: "POST",
    });
    mutate(purchase);
    return purchase;
  }, [idsFetch, purchaseId, mutate]);

  const endSession = useCallback(async () => {
    const purchase = await idsFetch(itemEndSessionPath(purchaseId), {
      method: "POST",
    });
    mutate(purchase);
    return purchase;
  }, [idsFetch, purchaseId, mutate]);

  const invalidateOverstaySession = useCallback(async () => {
    const purchase = await idsFetch(
      itemInvalidateOverstaySessionPath(purchaseId),
      { method: "POST" }
    );
    mutate(purchase);
    return purchase;
  }, [idsFetch, purchaseId, mutate]);

  return {
    purchase: data,
    revalidate: mutate,
    cancel,
    update,
    pricePreview,
    purchaseDayPass,
    startSession,
    endSession,
    invalidateOverstaySession,
  };
};
