import { useCallback } from "react";
import useSwr from "swr";

import useIdsFetch from "./useIdsFetch";
import { TenantGroup } from "./useTenantGroup";

const path = `/me/tenant-groups/`;
export const useParkerTenantGroups = ({
  onError,
}: {
  onError?: (err: Error) => void;
} = {}) => {
  const idsFetch = useIdsFetch();
  const { data: tenantGroups, mutate } = useSwr<TenantGroup[]>(path, {
    onError,
  });
  const createTenantGroup = async (data: TenantGroup) => {
    const tenantGroup = await idsFetch(path, {
      method: "POST",
      body: data,
    });
    await mutate();
    return tenantGroup;
  };
  const revalidate = useCallback(() => mutate(), [mutate]);
  return {
    tenantGroups,
    revalidate,
    create: createTenantGroup,
  };
};
