import {
  Button,
  Card,
  Col,
  Form,
  Grid,
  Input,
  Result,
  Row,
  Skeleton,
} from "antd";
import * as React from "react";

import useUserProfile, { UserProfile } from "../hooks/useUserProfile";
import notify from "../utils/notify";
import ResponsiveInnerCard from "./Layouts/ResponsiveInnerCard";

export default function ParkerSettings() {
  return (
    <Card title="Update Settings">
      <Row justify="start">
        <Col {...ResponsiveInnerCard}>
          <SettingsForm />
        </Col>
      </Row>
    </Card>
  );
}

function SettingsForm() {
  const [error, setError] = React.useState<Error>();
  const [isSaving, setIsSaving] = React.useState(false);
  const { profile, update, revalidate } = useUserProfile({ onError: setError });

  const screens = Grid.useBreakpoint();
  const isBlock = !screens.md && (screens.sm || screens.xs);

  async function handleSubmit(values: UserProfile) {
    setIsSaving(true);
    try {
      await update(values);
      notify.success("Successfully saved.");
    } catch (err) {
      notify.error("Failed to save", err);
    } finally {
      revalidate();
      setIsSaving(false);
    }
  }

  if (error) {
    return (
      <Result
        status="error"
        title="Failed to get settings."
        subTitle={error.message}
        extra={<Button onClick={() => setError(undefined)}>Back</Button>}
      />
    );
  }

  if (!profile) {
    return <Skeleton />;
  }

  return (
    <Form layout="vertical" initialValues={profile} onFinish={handleSubmit}>
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: "Please input your name." }]}
      >
        <Input disabled={isSaving} />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            type: "email",
            message: "Please provide a valid email.",
          },
        ]}
      >
        <Input disabled={isSaving} type="email" />
      </Form.Item>
      <Form.Item
        name="number"
        label="Number"
        rules={[{ required: true, message: "Please input your phone number." }]}
      >
        <Input disabled={isSaving} type="tel" />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isSaving}
          disabled={isSaving}
          block={isBlock}
        >
          Update
        </Button>
      </Form.Item>
    </Form>
  );
}
