import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Modal, Result } from "antd";
import _ from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import useSWR, { mutate } from "swr";

import useIdsFetch from "../../hooks/useIdsFetch";
import { getCreditCardPreviewText, PaymentMethod } from "../../hooks/useWallet";
import notify from "../../utils/notify";

interface Props {
  paymentMethodId: string;
  showError?: boolean;
  onError?: (err: Error) => void;
}

export default function PaymentMethodItem(props: Readonly<Props>) {
  const idsFetch = useIdsFetch();
  const history = useHistory();
  const paymentMethodPath = `/me/wallet/payment-methods/${props.paymentMethodId}/`;
  const { data: paymentMethod, error } = useSWR(paymentMethodPath);
  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      title: "Are you sure you want to delete this payment method?",
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await idsFetch(paymentMethodPath, { method: "DELETE" });
          notify.success("Successfully deleted payment method");
          mutate("/me/wallet/payment-methods/", (existing: PaymentMethod[]) =>
            _.remove(existing, { id: props.paymentMethodId })
          );
        } catch (err) {
          notify.error("Failed to delete payment method", err);
        }
      },
    });
  };
  if (props.showError && error)
    return (
      <Result
        status="error"
        title="Failed to load payment method"
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => history.push("/me/wallet")}
          >
            Home
          </Button>,
        ]}
      />
    );
  if (paymentMethod) {
    const { id, name } = paymentMethod;
    return (
      <Card
        style={{ width: "100%", minWidth: "300px" }}
        title={name}
        actions={[
          <Button
            key="edit"
            type="link"
            onClick={() =>
              history.push(`/me/wallet/payment-methods/${id}/edit`)
            }
          >
            <EditOutlined />
            Edit
          </Button>,
          <Button key="delete" danger type="link" onClick={showConfirm}>
            <DeleteOutlined />
            Delete
          </Button>,
        ]}
      >
        {getCreditCardPreviewText(paymentMethod)}
      </Card>
    );
  }
  return null;
}
