import { useAuth0 } from "@auth0/auth0-react";
import { Button, Result } from "antd";
import * as React from "react";
import { Link } from "react-router-dom";

import useIdsFetch, { ResponseError } from "../../hooks/useIdsFetch";
import { ParkingProduct } from "../../hooks/useOrganizationParkingProducts";
import ErrorResult from "./ErrorResult";

export default function AuthorizedPaymentClaim({
  invitationId,
  parkingProduct,
}: {
  invitationId: string;
  parkingProduct: ParkingProduct;
}) {
  const [error, setError] = React.useState<Error>();
  const { getIdTokenClaims } = useAuth0();
  const idsFetch = useIdsFetch();
  React.useEffect(() => {
    async function claimInvitation() {
      const claims = await getIdTokenClaims();
      if (claims === undefined) {
        setError(new Error("Failed to get auth token"));
        return;
      }
      try {
        await idsFetch(`/me/invitations/${invitationId}/claim`, {
          method: "POST",
          body: {
            id_token: claims.__raw,
          },
        });
      } catch (err) {
        setError(err as ResponseError);
      }
    }
    claimInvitation();
  }, [invitationId, getIdTokenClaims, idsFetch]);
  if (error) return <ErrorResult error={error} />;
  return (
    <Result
      status="success"
      title={`You can now purchase ${parkingProduct.name}`}
      extra={
        <Button type="primary">
          <Link to={`/products/${parkingProduct.id}`}>Purchase Parking</Link>
        </Button>
      }
    />
  );
}
