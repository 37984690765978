import { useAccountingForAPI } from "../hooks/useAccounting";

import AccountingList from "./AccountingList";

interface Props {
  tenantGroupId: string;
}

export default function TenantGroupAccounting(props: Props) {
  return (
    <AccountingList
      getAccountingItemsForTimeRange={useAccountingForAPI(
        `/tenant-groups/${props.tenantGroupId}/accounting/charges/`
      )}
      ignoreColumns={["Net"]}
    />
  );
}
