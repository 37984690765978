import {
  ApartmentOutlined,
  GlobalOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Card, Form, Input, List, Result, Space, Steps } from "antd";
import React, { useState } from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { useUserOrganizations } from "../hooks/useUserOrganizations";
import { getOrganizationViewUrl } from "../routes";
import notify from "../utils/notify";
import HeaderContent from "./Layouts/HeaderContent";
import NavBar from "./NavBar";
import NotFoundContent from "./NotFoundContent";
import { PayoutAccountCreate } from "./OrganizationPayoutAccounts";

import type { Organization } from "../hooks/useOrganization";
const Organizations = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  return (
    <HeaderContent navbar={<NavBar />}>
      <Switch>
        <Route exact path={`${path}`}>
          <OrganizationsList />
        </Route>
        <Route path={`${path}/new`}>
          <OrganizationOnboard
            onComplete={(created: Organization) =>
              history.push(getOrganizationViewUrl(created.id))
            }
          />
        </Route>
        <Route>
          <NotFoundContent />
        </Route>
      </Switch>
    </HeaderContent>
  );
};

const OrganizationsList = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [error, setError] = useState<Error>();
  const { organizations, revalidate } = useUserOrganizations({
    onError: setError,
  });
  if (error)
    return (
      <Card>
        <Result
          status="warning"
          title="Sorry, could not load your organizations."
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => {
                setError(undefined);
                revalidate();
              }}
            >
              Try Again
            </Button>
          }
        />
      </Card>
    );
  if (organizations?.length === 0)
    return (
      <Card>
        <Result
          icon={<GlobalOutlined />}
          title="You aren't a member of any organizations."
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => history.push(`${url}/new`)}
            >
              Create an organization
            </Button>
          }
        />
      </Card>
    );
  if (organizations?.length)
    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push(`${url}/new`)}
        >
          Add Organization
        </Button>

        <Card>
          <List
            itemLayout="horizontal"
            dataSource={organizations}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta title={item.name} />
                <Link to={`/organizations/${item.id}`}>
                  <Button type="link">
                    <ApartmentOutlined />
                    Admin View
                  </Button>
                </Link>
              </List.Item>
            )}
          />
        </Card>
      </Space>
    );
  return null;
};

export const OrganizationOnboard = ({
  onComplete,
  onCompleteText = "View Dashboard",
}: {
  onComplete: (org: Organization) => void;
  onCompleteText?: string;
}) => {
  const [step, setStep] = useState(0);
  const [org, setOrg] = useState<Organization>();
  return (
    <Card title="Onboard New Organization">
      <Steps current={step} style={{ marginBottom: "2rem" }}>
        <Steps.Step title="Create Organization" />
        <Steps.Step title="Onboard Stripe Account" />
        <Steps.Step title="View Dashboard" />
      </Steps>
      {step === 0 && (
        <OrganizationCreate
          onCreate={(created: Organization) => {
            setOrg(created);
            setStep(1);
          }}
        />
      )}
      {step === 1 && org && (
        <PayoutAccountCreate
          organization={org}
          itemUrl={(id: string) =>
            `/organizations/${org.id}/payout-accounts/${id}`
          }
          onCancel={() => setStep(2)}
          cancelText="Skip"
        />
      )}
      {step === 2 && org && (
        <Result
          title="Organization created"
          status="success"
          extra={
            <Button
              type="primary"
              onClick={() => {
                setStep(0);
                onComplete(org);
              }}
            >
              {onCompleteText}
            </Button>
          }
        />
      )}
    </Card>
  );
};

const OrganizationCreate = ({
  onCreate,
}: {
  onCreate: (org: Organization) => void;
}) => {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const { create } = useUserOrganizations();
  const createOrg = async (values: Partial<Organization>) => {
    setIsLoading(true);
    try {
      const created = await create(values);
      notify.success("Organization created successfully");
      onCreate(created);
    } catch (err) {
      notify.error("Failed to create organization", err);
    } finally {
      setIsLoading(false);
    }
  };
  if (error)
    return (
      <Result
        status="warning"
        title="Sorry, something went wrong."
        extra={
          <Button
            type="primary"
            key="console"
            onClick={() => setError(undefined)}
          >
            Try Again
          </Button>
        }
      />
    );
  return (
    <Form layout="vertical" onFinish={createOrg}>
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: "Organization name is required." }]}
      >
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ xs: { span: 24 } }}>
        <Button
          disabled={isLoading}
          loading={isLoading}
          type="primary"
          htmlType="submit"
        >
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};
export default Organizations;
