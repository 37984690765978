import { Button, Popconfirm, Space, Tooltip, Typography } from "antd";
import QRCode from "qrcode";
import Image from "rc-image";
import * as React from "react";

import { AccessCode } from "../../hooks/useUserPurchases";
import notify from "../../utils/notify";

const QR_CODE_SIZE = 256;

interface Props {
  accessCode: AccessCode | null;
  onEndOverstaySession: () => Promise<void>;
}

export default function QrCodeDisplay(props: Readonly<Props>) {
  const [qrCode, setQrCode] = React.useState<string>();
  const [overstayConfirmed, setOverstayConfirmed] = React.useState(false);

  React.useEffect(() => {
    if (!props.accessCode) {
      setQrCode(undefined);
      return;
    }
    QRCode.toDataURL(
      props.accessCode.code,
      { width: QR_CODE_SIZE, margin: 0 },
      (err, url) => {
        if (err) {
          notify.error("Failed to generate QR code", err);
        } else if (url) {
          setQrCode(url);
        }
      }
    );
  }, [props.accessCode, setQrCode]);

  if (props.accessCode?.overstay && !overstayConfirmed) {
    return (
      <Space
        style={{ width: "100%", textAlign: "center" }}
        direction="vertical"
        size="large"
      >
        By clicking confirm, you agree to be charged above rate if you use the
        QR code to exit.
        <Button type="primary" onClick={() => setOverstayConfirmed(true)}>
          Confirm
        </Button>
        <div>
          <div>If you believe this is an error,</div>
          <Popconfirm
            title={
              <div>
                <div>Are you sure you want to end your session?</div>
                <div>
                  If you are still in the facility, you will not be able to exit
                  through SmartPass.
                </div>
              </div>
            }
            okText="Yes"
            cancelText="No"
            onConfirm={props.onEndOverstaySession}
          >
            <Button style={{ fontSize: "90%" }} type="link">
              Click to end my session now
            </Button>
          </Popconfirm>
        </div>
      </Space>
    );
  }

  return (
    <Space
      style={{ width: "100%", textAlign: "center" }}
      direction="vertical"
      size="large"
    >
      <Typography.Text strong>
        Do not screenshot the QR code.
        <Tooltip title="This QR code changes frequently. You cannot park at the location with an old screenshot.">
          <Button size="small" type="link">
            Why?
          </Button>
        </Tooltip>
      </Typography.Text>
      <Image
        style={{
          width: "75%",
          maxWidth: QR_CODE_SIZE,
          margin: "0 auto",
        }}
        preview={false}
        src={qrCode}
      />
    </Space>
  );
}
