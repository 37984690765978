import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";

interface IFetchOptions {
  method?: string;
  headers?: Record<string, string>;
  body?: any;
}

const API_BASE = process.env.REACT_APP_API_BASE;

const useIdsFetch = _.memoize(() => {
  const { getAccessTokenSilently } = useAuth0();
  return async (
    path: string,
    options: IFetchOptions = { method: "GET", headers: {}, body: null }
  ) => {
    const authorizationHeaders = { Authorization: "" };
    try {
      const token = await getAccessTokenSilently();
      authorizationHeaders["Authorization"] = `Bearer ${token}`;
    } catch (err) {
      // process with unauthorized request.
    }
    const body = options?.body ? JSON.stringify(options.body) : null;
    const resp = await fetch(`${API_BASE}${path}`, {
      mode: "cors",
      credentials: "omit",
      ...options,
      body,
      headers: {
        ...options.headers,
        ...authorizationHeaders,
        "Content-Type": "application/json",
      },
    });
    if (resp.ok) {
      return resp.status === 204 ? null : await resp.json();
    } else {
      const message = await getErrorMessage(resp);
      throw new ResponseError(message, resp);
    }
  };
});

async function getErrorMessage(resp: Response): Promise<string> {
  const failureStatus = `${resp.status} ${resp.statusText}`;
  try {
    const result = await resp.json();
    if (result.message) {
      return `${failureStatus}: ${result.message}`;
    }
    return failureStatus;
  } catch (err) {
    return failureStatus;
  }
}

export default useIdsFetch;

export class ResponseError extends Error {
  public response: Response;

  public constructor(message: string, response: Response) {
    super(message);
    this.response = response;
  }
}
