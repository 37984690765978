import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Divider,
  Result,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import moment from "moment";
import * as React from "react";

import {
  AccessCode,
  Purchase,
  PurchaseLite,
  useUserPurchase,
} from "../../hooks/useUserPurchases";
import notify from "../../utils/notify";
import GeneratingPassDisplay from "./GeneratingPassDisplay";
import NoAccessDisplay from "./NoAccessDisplay";
import QrCodeDisplay from "./QrCodeDisplay";
import SessionDisplay from "./SessionDisplay";

interface Props {
  pass: PurchaseLite;
  onViewPass: (dayPassPurchaseId: string) => void;
  actions: React.ReactNode[];
}

export default function PassCard(props: Readonly<Props>) {
  const [isFetching, setIsFetching] = React.useState(false);
  const {
    purchaseDayPass,
    startSession,
    endSession,
    invalidateOverstaySession,
    revalidate,
  } = useUserPurchase({ purchaseId: props.pass.id });

  const getSingleDayPass = async () => {
    try {
      setIsFetching(true);
      const dayPass = await purchaseDayPass();
      await revalidate();
      setTimeout(() => {
        props.onViewPass(dayPass.id);
      }, 200);
    } catch (err) {
      notify.error("Failed to purchase day pass", err);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Card title={props.pass.parking_product_name} actions={props.actions}>
      <PassCardView
        pass={props.pass}
        isLoading={isFetching}
        getSingleDayPass={getSingleDayPass}
        onViewDayPass={props.onViewPass}
        startSession={startSession}
        endSession={endSession}
        invalidateOverstaySession={invalidateOverstaySession}
      />
    </Card>
  );
}

interface PassCardViewProps {
  pass: PurchaseLite;
  isLoading: boolean;
  getSingleDayPass: () => Promise<void>;
  onViewDayPass: (dayPassId: string) => void;
  startSession: () => Promise<void>;
  endSession: () => Promise<void>;
  invalidateOverstaySession: () => Promise<void>;
}

function PassCardView(props: Readonly<PassCardViewProps>) {
  const [error, setError] = React.useState<Error>();
  const { purchase: purchaseFull } = useUserPurchase({
    purchaseId: props.pass.id,
    onError: setError,
  });

  if (error) {
    return (
      <Result
        status="error"
        title="Failed to get the pass information"
        subTitle={error.message}
      />
    );
  }

  if (!purchaseFull) {
    return (
      <div style={{ padding: "5rem", textAlign: "center" }}>
        <Spin spinning size="large" />
      </div>
    );
  }

  const { access_code } = purchaseFull.parking_policy;
  const relatedDayPass =
    purchaseFull.is_subscription && !purchaseFull.is_valid_now
      ? purchaseFull.related_purchases.find(
          (p) => p.is_one_time_purchase && p.is_valid_now
        )
      : undefined;
  return (
    <Spin spinning={props.isLoading}>
      {relatedDayPass && (
        <DayPassAlert
          onViewPass={() => props.onViewDayPass(relatedDayPass.id)}
        />
      )}
      {access_code?.overstay && (
        <OverstayAlert charge={access_code.overstay.charge_amount} />
      )}
      <PassDisplay
        pass={purchaseFull}
        isDayPassPurchasable={
          relatedDayPass === undefined &&
          purchaseFull.is_day_pass_available &&
          !purchaseFull.is_event_pass
        }
        onGetDayPass={props.getSingleDayPass}
        onStartSession={props.startSession}
        onEndSession={props.endSession}
        onEndOverstaySession={props.invalidateOverstaySession}
      />
      <Divider />
      <PassInfo pass={props.pass} />
    </Spin>
  );
}

interface DayPassAlertProps {
  onViewPass: () => void;
}

function DayPassAlert(props: Readonly<DayPassAlertProps>) {
  return (
    <Alert
      style={{ marginBottom: "2rem" }}
      type="info"
      message=""
      description={
        <Space
          style={{ width: "100%", textAlign: "center" }}
          direction="vertical"
          size="middle"
        >
          You purchased a day pass for today.
          <Button onClick={props.onViewPass}>View Pass</Button>
        </Space>
      }
    />
  );
}

interface OverstayAlertProps {
  charge: number;
}

function OverstayAlert(props: Readonly<OverstayAlertProps>) {
  const formattedCharge = (props.charge / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  return (
    <Alert
      style={{ marginBottom: "2rem" }}
      type="error"
      icon={<ExclamationCircleFilled />}
      message="Overstay Charge"
      description={`You have overstayed your allowed time of use. If you use the QR code to exit, you will be charged ${formattedCharge}.`}
    />
  );
}

interface PassDisplayProps {
  pass: Purchase;
  isDayPassPurchasable: boolean;
  onGetDayPass: () => Promise<void>;
  onStartSession: () => Promise<void>;
  onEndSession: () => Promise<void>;
  onEndOverstaySession: () => Promise<void>;
}

function PassDisplay(props: Readonly<PassDisplayProps>) {
  const { access_code } = props.pass.parking_policy;
  const { reason, explanation } = getNoAccessReason(props.pass, access_code);
  if (reason) {
    return (
      <NoAccessDisplay
        noAccessReason={reason}
        explanation={explanation}
        isDayPassPurchasable={props.isDayPassPurchasable}
        onGetDayPass={props.onGetDayPass}
      />
    );
  }

  if (access_code === null) {
    return <GeneratingPassDisplay />;
  }

  const { parking_product } = props.pass.price;
  if (parking_product.access_mode === "qr_code") {
    return (
      <QrCodeDisplay
        accessCode={access_code}
        onEndOverstaySession={props.onEndOverstaySession}
      />
    );
  }

  if (parking_product.access_mode === "parker_session") {
    if (
      access_code.status !== "READY_FOR_ENTRY" &&
      access_code.status !== "READY_FOR_EXIT"
    ) {
      return (
        <Result
          status="error"
          title="An error occurred"
          subTitle={`Access code is in an invalid status: ${access_code.status}. Please contact your parking manager for more information.`}
        />
      );
    }

    return (
      <SessionDisplay
        accessCodeStatus={access_code.status}
        onStartSession={props.onStartSession}
        onEndSession={props.onEndSession}
      />
    );
  }

  // Unrecognized access mode. This should NOT happen.
  throw new Error(`Unrecognized access mode ${parking_product.access_mode}.`);
}

function getNoAccessReason(
  pass: Purchase,
  accessCode: AccessCode | null
): { reason: string | null; explanation?: string } {
  if (
    pass.parking_days_count_for_current_billing_cycle !== null &&
    pass.max_days_per_billing_cycle !== null &&
    pass.parking_days_count_for_current_billing_cycle >=
      pass.max_days_per_billing_cycle
  ) {
    return {
      reason: "No more parking sessions left.",
    };
  }
  if (
    pass.parking_sessions_count_for_current_billing_cycle !== null &&
    pass.max_sessions_per_billing_cycle !== null &&
    pass.parking_sessions_count_for_current_billing_cycle >=
      pass.max_sessions_per_billing_cycle
  ) {
    return {
      reason: "No more parking days left.",
    };
  }
  if ((!pass.is_valid_now || pass.is_event_pass) && accessCode === null) {
    // Even if a pass is not currently valid, it could be in overstay or transitioning to it,
    // in which case it'll still has an access code. We allow access in this case.
    const { parking_product } = pass.price;
    const reason = "Outside your time of reservation.";
    const explanation =
      parking_product.access_mode === "qr_code"
        ? "You will get a QR code to scan in when your reservation time starts."
        : "You will be able to park when your reservation time starts.";
    return { reason, explanation };
  }
  return { reason: null };
}

interface PassInfoProps {
  pass: PurchaseLite;
}

export function PassInfo(props: Readonly<PassInfoProps>) {
  const paidAt = moment.unix(props.pass.payment_at || Date.now());
  const { address } = props.pass;
  return (
    <Space direction="vertical" size="small">
      {props.pass.is_one_time_purchase ||
        (props.pass.is_event_pass && (
          <div>
            <Typography.Text strong>Single Day Pass</Typography.Text>
          </div>
        ))}
      {props.pass.is_subscription && !props.pass.is_event_pass && (
        <div>
          <Typography.Text strong>Allowed on: </Typography.Text>
          <Typography.Text>
            {getHumanFriendlyDaysOfWeek(props.pass.valid_days_of_week)}
          </Typography.Text>
        </div>
      )}
      {!!props.pass.max_days_per_billing_cycle && (
        <div>
          <Typography.Text strong>Days used: </Typography.Text>
          <Typography.Text>
            {props.pass.parking_days_count_for_current_billing_cycle} of{" "}
            {props.pass.max_days_per_billing_cycle}
          </Typography.Text>
        </div>
      )}
      {!!props.pass.max_sessions_per_billing_cycle && (
        <div>
          <Typography.Text strong>Passes used: </Typography.Text>
          <Typography.Text>
            {props.pass.parking_sessions_count_for_current_billing_cycle} of{" "}
            {props.pass.max_sessions_per_billing_cycle}
          </Typography.Text>
        </div>
      )}
      {props.pass.is_subscription && (
        <div>
          <Typography.Text strong>Purchased on: </Typography.Text>
          <Typography.Text>{paidAt.format("ddd, ll")}</Typography.Text>
        </div>
      )}
      {props.pass.is_one_time_purchase && (
        <div>
          <Typography.Text strong>Active on: </Typography.Text>
          <Typography.Text>{paidAt.format("ddd, ll")}</Typography.Text>
        </div>
      )}
      <div>
        <Typography.Text strong>Location: </Typography.Text>
        <Typography.Text>{props.pass.location_name}</Typography.Text>
      </div>
      {address &&
        address?.city?.length > 0 &&
        address?.postal_code?.length > 0 && (
          <div>
            <Typography.Text strong>Address: </Typography.Text>
            <Row>
              <Typography.Text>
                {address.line1}, {address.line2}
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text>
                {address.city}, {address.state} {address.postal_code}
              </Typography.Text>
            </Row>
          </div>
        )}
    </Space>
  );
}

function getHumanFriendlyDaysOfWeek(daysOfWeek: number[]): string {
  switch (daysOfWeek) {
    case [0, 1, 2, 3, 4, 5, 6]:
      return "Everyday";
    case [0, 1, 2, 3, 4]:
      return "Weekdays";
    case [5, 6]:
      return "Weekend";
    default:
      return daysOfWeek
        .map((d) =>
          moment()
            .weekday(d + 1)
            .format("ddd")
        )
        .join(", ");
  }
}
