import { Tooltip } from "antd";
import _ from "lodash";
import * as React from "react";

const cellPadding = "0 0.5rem";

interface Props {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export default function TooltipCell(props: Readonly<Props>) {
  const [tooltipTitle, setTooltipTitle] = React.useState<string>();
  const cellRef = React.useCallback(
    (current: HTMLDivElement | null) => {
      if (
        _.isString(props.children) &&
        current &&
        current.offsetWidth < current.scrollWidth
      ) {
        setTooltipTitle(props.children);
      } else {
        setTooltipTitle(undefined);
      }
    },
    [props.children]
  );

  if (_.isString(props.children)) {
    return (
      <Tooltip title={tooltipTitle}>
        <div
          ref={cellRef}
          style={{
            padding: cellPadding,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            ...props.style,
          }}
        >
          {props.children}
        </div>
      </Tooltip>
    );
  }
  return (
    <div style={{ padding: cellPadding, ...props.style }}>{props.children}</div>
  );
}
