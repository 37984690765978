import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from "react-router-dom";

export default function Logout() {
  const { isAuthenticated, logout } = useAuth0();

  if (isAuthenticated) {
    logout({ returnTo: window.location.origin });
    return null;
  } else {
    return <Redirect to="/" />;
  }
}
