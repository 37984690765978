import { Button, Form, Input } from "antd";
import React, { useState } from "react";

import { Invitation } from "../../hooks/useOrganizationParkingProducts";
import notify from "../../utils/notify";
import InvitationFrame from "./InvitationFrame";

interface Props {
  onSubmit: (data: { email: string }) => Promise<Invitation>;
  onSuccess: (invitationId: string) => void;
}

export default function InvitationSetup(props: Readonly<Props>) {
  const [isLoading, setIsLoading] = useState(false);

  async function submit(values: { email: string; name: string }) {
    setIsLoading(true);
    try {
      const invitation = await props.onSubmit(values);
      props.onSuccess(invitation.id);
    } catch (err) {
      notify.error("Failed to setup new invitation", err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <InvitationFrame currentStep={0} isLoading={isLoading}>
      <Form layout="vertical" onFinish={submit}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please provide a valid email.",
            },
          ]}
        >
          <Input autoFocus placeholder="hello@example.com" />
        </Form.Item>
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <div style={{ marginTop: "2rem", textAlign: "center" }}>
          <Button htmlType="submit" type="primary">
            Next
          </Button>
        </div>
      </Form>
    </InvitationFrame>
  );
}
