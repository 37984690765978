import { Space } from "antd";
import { Link } from "react-router-dom";
import useBranding from "../../hooks/useBranding";
import DropdownMenu from "./ParkerDropdownMenu";

export default function NavBar({
  name,
  to = "/",
}: {
  name?: string;
  to?: string;
}) {
  const branding = useBranding();
  return (
    <nav>
      {branding && (
        <Link to={to}>
          <Space>
            {branding.logo_url && (
              <img
                style={{ maxHeight: 36 }}
                src={branding.logo_url}
                alt={branding.text}
              />
            )}
            {branding.text || name}
          </Space>
        </Link>
      )}
      <div
        style={{
          float: "right",
          maxWidth: "calc(100vw - 17rem)",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <DropdownMenu />
      </div>
    </nav>
  );
}
