import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Organization } from "../hooks/useOrganization";
import {
  ParkingSession,
  useOrganizationLocationEvents,
} from "../hooks/useOrganizationLocationEvents";
import DateFilter, { getDateRangeFromQueries } from "./DateFilter";
import ParkerHistoryList from "./ParkerHistoryList";
import VerticalSpace from "./VerticalSpace";
import queryString from "query-string";
import moment from "moment";
import NotFoundFullPage from "./NotFoundFullPage";

export interface DisplayParkingSession extends ParkingSession {
  entryTime: moment.Moment;
  exitTime: moment.Moment;
}

const OrganizationParkerHistory = ({
  organization,
}: {
  organization: Organization;
}) => {
  const organizationId = organization.id;
  const history = useHistory();
  const [, setError] = useState<Error>();
  const [dateRange, setDateRange] = useState(
    useCallback(() => {
      const queries = queryString.parse(history.location.search);
      return getDateRangeFromQueries(queries);
    }, [history.location.search])
  );
  const startEpoch = dateRange.start.unix();
  const endEpoch = dateRange.end.unix();
  const { parkingSessions } = useOrganizationLocationEvents({
    organizationId,
    startEpoch,
    endEpoch,
    onError: setError,
  });

  const displayParkingSessions = parkingSessions
    ?.map((parkingSession) => {
      let displayParkingSession: DisplayParkingSession =
        Object.create(parkingSession);
      [displayParkingSession.entryTime, displayParkingSession.exitTime] =
        findEntryAndExit(parkingSession);
      return displayParkingSession;
    })
    .filter(
      (session) => session.entryTime.isValid() || session.exitTime.isValid()
    );

  if (displayParkingSessions)
    return (
      <VerticalSpace size="large">
        <DateFilter dateRange={dateRange} setDateRange={setDateRange} />
        <ParkerHistoryList parkingSessions={displayParkingSessions} />
      </VerticalSpace>
    );

  return <NotFoundFullPage />;
};

const findEntryAndExit = (session: ParkingSession) => {
  let start = null;
  let end = null;
  session.parking_session_events.forEach((event) => {
    if (
      event.from_status === "READY_FOR_ENTRY" &&
      event.to_status === "READY_FOR_EXIT"
    ) {
      start = event.event_time;
    }
    if (
      event.from_status === "READY_FOR_EXIT" &&
      event.to_status === "CLOSED"
    ) {
      end = event.event_time;
    }
  });
  return [moment(start), moment(end)];
};

export default OrganizationParkerHistory;
