import React, { useState } from "react";
import _ from "lodash";
import { Space, Input, Table, Result } from "antd";
import { Location } from "../hooks/useOrganizationLocations";

const LocationTable = ({
  selectedLocationIds,
  disabledLocationIds = [],
  locations,
  error,
  isLoading,
  onSelectionChange,
  emptyText = "No Locations",
}: {
  selectedLocationIds: string[];
  disabledLocationIds?: string[];
  locations?: Location[];
  error?: Error;
  isLoading: boolean;
  onSelectionChange: (locationIds: string[]) => Promise<void>;
  emptyText?: string;
}) => {
  const [nameFilter, setNameFilter] = useState<string>();
  if (error) return <Result status="error" title="Could not load locations" />;
  if (locations) {
    const filteredLocations = nameFilter
      ? _.filter(locations, ({ name }) =>
          name.toLowerCase().includes(nameFilter.toLowerCase())
        )
      : locations;
    return (
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <Input.Search
          allowClear
          placeholder="Filter by Name"
          onChange={({ target: { value } }) => setNameFilter(value)}
        />
        <Table
          locale={{ emptyText }}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selectedLocationIds,
            onChange: (
              selectedRowKeys: React.Key[],
              selectedRows: Location[]
            ) => onSelectionChange(selectedRowKeys as string[]),
            getCheckboxProps: ({ id }) => ({
              disabled: disabledLocationIds.includes(id),
            }),
          }}
          columns={[
            {
              title: "Location Name",
              dataIndex: "name",
              key: "name",
              sorter: (a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
              },
            },
            {
              title: "City",
              dataIndex: ["default_address", "city"],
              key: "city",
              sorter: (a, b) => {
                if (!a.default_address && !b.default_address) return 0;
                if (!a.default_address) return -1;
                if (!b.default_address) return 1;
                if (a.default_address?.city > b.default_address?.city) return 1;
                if (a.default_address?.city < b.default_address?.city)
                  return -1;
                return 0;
              },
            },
            {
              title: "Spaces",
              dataIndex: "capacity",
              key: "capacity",
              sorter: (a, b) => {
                if (!_.isNumber(a.capacity) && !_.isNumber(b.capacity))
                  return 0;
                if (!_.isNumber(a.capacity)) return -1;
                if (!_.isNumber(b.capacity)) return 1;
                if (a.capacity > b.capacity) return 1;
                if (a.capacity < b.capacity) return -1;
                return 0;
              },
            },
          ]}
          dataSource={_.map(filteredLocations, (location) => ({
            key: location.id,
            ...location,
          }))}
        />
      </Space>
    );
  }
  return null;
};

export default LocationTable;
