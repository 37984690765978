import { useState } from "react";

import { Organization } from "../hooks/useOrganization";
import ParkersList from "./ParkersList";
import VerticalSpace from "./VerticalSpace";
import useOrganizationParkers, {
  useOrganizationParkersPurchases,
} from "../hooks/useOrganizationParkers";
import ParkerFilter from "./ParkerFilter";

interface Props {
  organization: Organization;
}

export default function OrganizationParkerDetails(props: Readonly<Props>) {
  const organizationId = props.organization.id;
  const [error, setError] = useState<Error>();
  const [selectedState, setSelectedState] = useState<"active" | "inactive">(
    "active"
  );
  const { parkers } = useOrganizationParkers({
    organizationId,
    selectedState,
    onError: setError,
  });

  function useParkerPurchases(profileId: string) {
    return useOrganizationParkersPurchases(organizationId, profileId);
  }

  return (
    <VerticalSpace size="large">
      <ParkerFilter setSelectedState={setSelectedState} />
      <ParkersList
        error={error}
        parkers={parkers}
        useParkerPurchases={useParkerPurchases}
      />
    </VerticalSpace>
  );
}
