import { useState } from "react";

import useTenantGroupParkers, {
  useTenantGroupsParkersPurchases,
} from "../hooks/useTenantGroupParkers";
import ParkerFilter from "./ParkerFilter";
import ParkersList from "./ParkersList";
import VerticalSpace from "./VerticalSpace";

export default function Parkers({ tenantGroupId }: { tenantGroupId: string }) {
  const [error, setError] = useState<Error>();
  const [selectedState, setSelectedState] = useState<"active" | "inactive">(
    "active"
  );
  const { parkers } = useTenantGroupParkers({
    tenantGroupId,
    selectedState,
    onError: setError,
  });

  function useParkerPurchases(profileId: string) {
    return useTenantGroupsParkersPurchases(tenantGroupId, profileId);
  }

  return (
    <VerticalSpace size="large">
      <ParkerFilter setSelectedState={setSelectedState} />
      <ParkersList
        error={error}
        parkers={parkers}
        useParkerPurchases={useParkerPurchases}
      />
    </VerticalSpace>
  );
}
