import {
  CaretDownOutlined,
  CaretUpOutlined,
  CarOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  Popover,
  Result,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";

import { Location } from "../hooks/useOrganizationLocations";
import { UserProfile } from "../hooks/useUserProfile";
import { Purchase } from "../hooks/useUserPurchases";
import { Vehicle } from "../hooks/useVehicles";
import notify from "../utils/notify";
import ResponsiveList from "./ResponsiveList";
import { downloadFile, getCsvContent } from "./ResponsiveList/ResponsiveList";

export interface ParkerProfile {
  user_profile: UserProfile;
  vehicles: Vehicle[];
}

interface Props {
  error: Error | undefined;
  parkers: ParkerProfile[] | undefined;
  useParkerPurchases: (profileId: string) => {
    parkerPurchases: Purchase[] | undefined;
    error: any;
    revalidate: () => Promise<Purchase[] | undefined>;
    deactivate: (purchaseId: string, profileId: string) => Promise<void>;
  };
}

export default function ParkersList(props: Readonly<Props>) {
  if (props.error) {
    return (
      <Card>
        <Result status="error" title="Could not load parkers" />
      </Card>
    );
  }

  async function exportCsvFile(rows: Readonly<ParkerProfile[]>): Promise<void> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const data = rows.map((row) => ({
          Name: row.user_profile.name,
          Email: row.user_profile.email,
          Phone: row.user_profile.number,
        }));
        downloadFile(
          getDownloadFileName(),
          getCsvContent(["Name", "Email", "Phone"], data),
          "data:text/csv;charset=utf-8;"
        );
        resolve();
      }, 500)
    );
  }

  return (
    <ResponsiveList<ParkerProfile>
      loading={!props.parkers}
      columns={[
        {
          title: "Name",
          dataIndex: ["user_profile", "name"],
          colSpan: 6,
        },
        {
          title: "Email",
          dataIndex: ["user_profile", "email"],
          colSpan: 5,
        },
        {
          title: "Phone",
          dataIndex: ["user_profile", "number"],
          colSpan: 4,
        },
        {
          title: "License Plates",
          dataIndex: "vehicles",
          colSpan: 6,
          render: (vehicles: Vehicle[]) => (
            <ParkerVehicles vehicles={vehicles} />
          ),
        },
        {
          title: "Manage",
          dataIndex: ["user_profile", "id"],
          colSpan: 3,
          render: (id: string) => (
            <Popover
              title="Active Products"
              content={
                <EditParkerContent
                  profileId={id}
                  useParkerPurchases={props.useParkerPurchases}
                />
              }
              trigger="click"
              placement="bottomLeft"
            >
              <Tag icon={<CaretDownOutlined />}>Manage Products</Tag>
            </Popover>
          ),
        },
      ]}
      dataSource={props.parkers}
      rowKey="id"
      onExport={exportCsvFile}
    />
  );
}

interface ParkerVehicleProps {
  vehicles: Vehicle[];
}

function ParkerVehicles(props: Readonly<ParkerVehicleProps>) {
  return (
    <div>
      {_.map(props.vehicles, ({ license_plate_number }) => (
        <Tag key={license_plate_number} icon={<CarOutlined />} color="blue">
          {license_plate_number}
        </Tag>
      ))}
    </div>
  );
}

interface EditParkerContentProps {
  profileId: string;
  useParkerPurchases: (profileId: string) => {
    parkerPurchases: Purchase[] | undefined;
    error: any;
    revalidate: () => Promise<Purchase[] | undefined>;
    deactivate: (purchaseId: string, profileId: string) => Promise<void>;
  };
}

function EditParkerContent(props: Readonly<EditParkerContentProps>) {
  const [showPolicy, setShowPolicy] = useState(false);

  const { parkerPurchases, revalidate, deactivate } = props.useParkerPurchases(
    props.profileId
  );

  function onDeactivate(purchaseId: string) {
    Modal.confirm({
      title: "Are you sure to deactivate the pass for the parker?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      onOk: async () => {
        try {
          await deactivate(purchaseId, props.profileId);
          notify.success(
            "Successfully deactivated the product for the parker."
          );
          revalidate();
        } catch (err) {
          notify.error("Failed to deactivate the product", err);
        }
      },
      cancelText: "No",
    });
  }

  function toggleShowPolicy() {
    setShowPolicy(!showPolicy);
  }
  return (
    <div style={{ minWidth: "16rem" }}>
      {_.map(
        parkerPurchases,
        (
          { id, valid_days_of_week, payment_at, parking_policy, price },
          idx: number
        ) => (
          <div key={id}>
            <Row justify="space-between" align="top" gutter={16}>
              {idx > 0 ? <Divider /> : null}
              <Col>
                <div>
                  <strong>{price.parking_product.name}</strong>
                </div>
                {parking_policy.location.default_address && (
                  <>
                    <div>
                      {parking_policy.location.default_address.line1},{" "}
                      {parking_policy.location.default_address.line2}
                    </div>
                    <div>
                      {parking_policy.location.default_address.city},{" "}
                      {parking_policy.location.default_address.state}{" "}
                      {parking_policy.location.default_address.postal_code}
                    </div>
                  </>
                )}
              </Col>
              <Col>
                <Space direction="vertical">
                  <Button
                    type="primary"
                    danger
                    onClick={() => onDeactivate(id)}
                  >
                    Deactivate
                  </Button>
                </Space>
              </Col>
            </Row>
            {showPolicy && (
              <div style={{ marginTop: "1rem" }}>
                <PassInfo
                  parkingDays={valid_days_of_week}
                  startDate={payment_at}
                  location={parking_policy.location}
                  purchaseId={id}
                  address_disabled={true}
                />
              </div>
            )}
            <Button type="link" onClick={() => toggleShowPolicy()}>
              {showPolicy ? "Hide" : "Show"} Parker Parking Policy
              {showPolicy ? <CaretUpOutlined /> : <CaretDownOutlined />}
            </Button>
          </div>
        )
      )}
    </div>
  );
}

function getDownloadFileName() {
  return `smartpass_parkers_${moment().format("YYYY-MM-DD_HH-mm")}.csv`;
}

interface PassInfoProps {
  parkingDays?: number[];
  startDate?: number;
  endDate?: string;
  location: Location;
  purchaseId: string;
  address_disabled?: boolean;
}

function PassInfo(props: Readonly<PassInfoProps>) {
  const { default_address } = props.location;
  return (
    <div>
      {props.parkingDays && (
        <Typography.Paragraph>
          <Typography.Text strong>Days: </Typography.Text>
          <Typography.Text>
            {props.parkingDays
              .map((d) =>
                moment()
                  .weekday(d + 1)
                  .format("ddd")
              )
              .join(", ")}
          </Typography.Text>
        </Typography.Paragraph>
      )}
      {props.startDate && (
        <Typography.Paragraph>
          <Typography.Text strong>Active From: </Typography.Text>
          <Typography.Text>
            {moment.unix(props.startDate).format("llll")}
          </Typography.Text>
        </Typography.Paragraph>
      )}
      {props.endDate && (
        <Typography.Paragraph>
          <Typography.Text strong>Active Until: </Typography.Text>
          <Typography.Text>
            {moment(props.endDate).format("llll")}
          </Typography.Text>
        </Typography.Paragraph>
      )}
      <div>
        <Typography.Paragraph>
          <Typography.Text strong>Location: </Typography.Text>
          <Typography.Text>{props.location.name}</Typography.Text>
        </Typography.Paragraph>
        {default_address && !props.address_disabled && (
          <Typography.Paragraph>
            <Typography.Text strong>Address: </Typography.Text>
            <div>
              <Typography.Text>
                {default_address.line1} {default_address.line2}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text>
                {default_address.city}, {default_address.state}{" "}
                {default_address.postal_code}
              </Typography.Text>
            </div>
          </Typography.Paragraph>
        )}
      </div>
      <div>
        <Typography.Text strong>Purchase ID: </Typography.Text>
        <Typography.Text copyable>{props.purchaseId}</Typography.Text>
      </div>
    </div>
  );
}
