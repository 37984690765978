import {
  Button,
  Card,
  Collapse,
  Form,
  Input,
  InputNumber,
  Space,
  Spin,
} from "antd";
import moment from "moment";
import * as React from "react";

import { Address } from "../../hooks/useAddress";
import { getCreditCardPreviewText, PaymentMethod } from "../../hooks/useWallet";
import notify from "../../utils/notify";
import AddressForm from "../AddressForm";

interface Props {
  paymentMethod: PaymentMethod | undefined;
  onUpdate: (values: Omit<PaymentMethod, "id">) => Promise<void>;
}

export default function UpdateCardPayment(props: Readonly<Props>) {
  const [isFetching, setIsFetching] = React.useState(false);
  const [billingAddress, setBillingAddress] =
    React.useState<Omit<Address, "id">>();

  React.useEffect(() => {
    const address =
      props.paymentMethod?.stripe_payment_method.data.billing_details?.address;
    setBillingAddress({
      name: "",
      line1: address?.line1 || "",
      line2: address?.line2 || "",
      city: address?.city || "",
      state: address?.state || "",
      postal_code: address?.postal_code || "",
      country: "",
    });
  }, [
    props.paymentMethod?.stripe_payment_method?.data?.billing_details?.address,
  ]);

  async function save(values: Omit<PaymentMethod, "id">) {
    setIsFetching(true);
    try {
      await props.onUpdate({
        ...values,
        stripe_payment_method: {
          ...values.stripe_payment_method,
          data: {
            ...values.stripe_payment_method?.data,
            billing_details: {
              ...values.stripe_payment_method?.data?.billing_details,
              address: {
                line1: billingAddress?.line1 || null,
                line2: billingAddress?.line2 || null,
                city: billingAddress?.city || null,
                state: billingAddress?.state || null,
                postal_code: billingAddress?.postal_code || null,
                country: billingAddress?.country || null,
              },
            },
          },
        },
      });
      notify.success(`Successfully updated "${values.name}"`);
    } catch (err) {
      notify.error("Failed to update the card", err);
    } finally {
      setIsFetching(false);
    }
  }

  return (
    <div style={{ maxWidth: "42rem", margin: "0 auto" }}>
      <Spin spinning={props.paymentMethod === undefined || isFetching}>
        <Card
          key={props.paymentMethod ? props.paymentMethod.id : "loading"}
          title="Update Payment Method"
        >
          <Form<Omit<PaymentMethod, "id">>
            layout="vertical"
            initialValues={props.paymentMethod}
            onFinish={save}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input
                autoFocus
                placeholder="Any name you want to give this payment method, e.g., Chase Freedom"
              />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input.TextArea placeholder="Additional descriptions about this payment method" />
            </Form.Item>

            <Collapse>
              <Collapse.Panel
                key="0"
                header={
                  props.paymentMethod
                    ? getCreditCardPreviewText(props.paymentMethod)
                    : "Loading..."
                }
              >
                <Space size="large">
                  <Form.Item
                    style={{ display: "inline-block" }}
                    label="Expire Month"
                    name={[
                      "stripe_payment_method",
                      "data",
                      "card",
                      "exp_month",
                    ]}
                    rules={[
                      {
                        type: "enum",
                        enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                        message: "Expire month must be from 1 to 12",
                      },
                    ]}
                  >
                    <InputNumber placeholder="mm" />
                  </Form.Item>
                  <Form.Item
                    style={{ display: "inline-block" }}
                    label="Expire Year"
                    name={["stripe_payment_method", "data", "card", "exp_year"]}
                    rules={[
                      {
                        type: "number",
                        min: moment().year(),
                        message: "Expire year is invalid",
                      },
                    ]}
                  >
                    <InputNumber placeholder="yyyy" />
                  </Form.Item>
                </Space>
                <div>
                  * For security reasons we do not store your card number or
                  allow you to update it. Create a new payment method if you
                  need to update it.
                </div>
              </Collapse.Panel>
            </Collapse>

            <Collapse style={{ marginTop: "1.5rem" }}>
              <Collapse.Panel header="Billing Address (Optional)" key="0">
                <AddressForm
                  address={billingAddress}
                  onChange={setBillingAddress}
                />
              </Collapse.Panel>
            </Collapse>

            <div style={{ textAlign: "center", marginTop: "2rem" }}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </Card>
      </Spin>
    </div>
  );
}
