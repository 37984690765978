import { Button, Space, Spin, Typography } from "antd";
import * as React from "react";

import notify from "../../utils/notify";

const buttonStyle = {
  width: "6rem",
  height: "6rem",
  color: "white",
  fontWeight: 700,
  fontSize: "1.5rem",
};

interface Props {
  accessCodeStatus: "READY_FOR_ENTRY" | "READY_FOR_EXIT";
  onStartSession: () => Promise<void>;
  onEndSession: () => Promise<void>;
}

export default function SessionDisplay(props: Readonly<Props>) {
  const [isUpdatingSession, setIsUpdatingSession] = React.useState(false);

  async function startSession() {
    setIsUpdatingSession(true);
    try {
      await props.onStartSession();
      notify.success("Successfully started your parking session.");
    } catch (err) {
      notify.error("Failed to start your parking session", err);
    } finally {
      setIsUpdatingSession(false);
    }
  }

  async function endSession() {
    setIsUpdatingSession(true);
    try {
      await props.onEndSession();
      notify.success("Successfully ended your parking session.");
    } catch (err) {
      notify.error("Failed to end your parking session", err);
    } finally {
      setIsUpdatingSession(false);
    }
  }

  return (
    <Spin spinning={isUpdatingSession}>
      <Space
        style={{ width: "100%", textAlign: "center" }}
        direction="vertical"
        size="large"
      >
        <Typography.Text strong>
          Tap the button below to{" "}
          {props.accessCodeStatus === "READY_FOR_ENTRY" ? "start" : "end"} your
          session:
        </Typography.Text>
        {props.accessCodeStatus === "READY_FOR_ENTRY" ? (
          <Button
            style={{ ...buttonStyle, background: "#13C2C2" }}
            shape="circle"
            onClick={startSession}
          >
            Start
          </Button>
        ) : (
          <Button
            style={{ ...buttonStyle, background: "#F5222D" }}
            shape="circle"
            onClick={endSession}
          >
            End
          </Button>
        )}
        {props.accessCodeStatus === "READY_FOR_ENTRY" && (
          <Typography.Text type="secondary">
            If you do not start your session upon parking, you may be ticketed
            or towed.
          </Typography.Text>
        )}
      </Space>
    </Spin>
  );
}
