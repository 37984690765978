import useSWR from "swr";
import logo from "./logo.png";

export interface Branding {
  id: string;
  custom_origin: string;
  logo_url: string;
  text: string;
}

const DEFAULT_BRANDING: Branding = {
  id: "",
  custom_origin: "",
  logo_url: logo,
  text: "SmartPass by Smarking",
};

const branding_api_url = "/branding";

export default function useBranding(): Branding | undefined {
  let { data, error } = useSWR<Branding | undefined>(branding_api_url);
  return error ? DEFAULT_BRANDING : data;
}
