import * as React from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { TenantGroup } from "../../hooks/useTenantGroup";
import NotFoundContent from "../NotFoundContent";
import AddTenantGroupPaymentMethod from "./AddTenantGroupPaymentMethod";
import AllTenantGroupPayments from "./AllTenantGroupPayments";
import UpdateTenantGroupBankAccount from "./UpdateTenantGroupBankAccount";
import UpdateTenantGroupCardPayment from "./UpdateTenantGroupCardPayment";
import VerifyTenantGroupBankAccount from "./VerifyTenantGroupBankAccount";

interface Props {
  tenantGroup: TenantGroup;
}

export default function TenantGroupPaymentMethods(props: Readonly<Props>) {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact strict path={path}>
        <AllTenantGroupPayments
          tenantGroupId={props.tenantGroup.id}
          baseUrl={url}
        />
      </Route>
      <Route exact strict path={`${path}/add`}>
        <AddTenantGroupPaymentMethod
          tenantGroupId={props.tenantGroup.id}
          onSuccess={() => history.push(url)}
        />
      </Route>
      <Route path={`${path}/bank-payment/:sourceId`}>
        <BankPaymentRouter baseUrl={url} />
      </Route>
      <Route path={`${path}/card-payment/:paymentMethodId`}>
        <CardPaymentRouter baseUrl={url} />
      </Route>
      <Route>
        <NotFoundContent />
      </Route>
    </Switch>
  );
}

function BankPaymentRouter(props: { baseUrl: string }) {
  const { path } = useRouteMatch();
  const { tenantGroupId, sourceId } = useParams<{
    tenantGroupId: string;
    sourceId: string;
  }>();

  return (
    <Switch>
      <Route exact path={`${path}/edit`}>
        <UpdateTenantGroupBankAccount
          tenantGroupId={tenantGroupId}
          sourceId={sourceId}
          baseUrl={props.baseUrl}
        />
      </Route>
      <Route exact path={`${path}/verify`}>
        <VerifyTenantGroupBankAccount
          tenantGroupId={tenantGroupId}
          sourceId={sourceId}
          baseUrl={props.baseUrl}
        />
      </Route>
      <Route>
        <NotFoundContent />
      </Route>
    </Switch>
  );
}

function CardPaymentRouter(props: { baseUrl: string }) {
  const { path } = useRouteMatch();
  const { tenantGroupId, paymentMethodId } = useParams<{
    tenantGroupId: string;
    paymentMethodId: string;
  }>();

  return (
    <Switch>
      <Route exact path={`${path}/edit`}>
        <UpdateTenantGroupCardPayment
          tenantGroupId={tenantGroupId}
          paymentMethodId={paymentMethodId}
          baseUrl={props.baseUrl}
        />
      </Route>
      <Route>
        <NotFoundContent />
      </Route>
    </Switch>
  );
}
