import { Button, Form, Input } from "antd";
import * as React from "react";
import { Invitation } from "../../hooks/useMembers";
import InviteSteps from "./InviteSteps";

export default function Setup({
  isLoading,
  onFinish = () => {},
}: {
  isLoading: boolean;
  onFinish: (data: Partial<Invitation>) => void;
}) {
  return (
    <InviteSteps currentStep={0} isLoading={isLoading}>
      <Form<Partial<Invitation>>
        layout="vertical"
        initialValues={{ email: "", name: "" }}
        onFinish={onFinish}
      >
        <Form.Item
          label="Email"
          name="email"
          required
          rules={[
            {
              required: true,
              type: "email",
              message: "Please provide a valid email.",
            },
          ]}
        >
          <Input autoFocus autoComplete="off" />
        </Form.Item>
        <Form.Item label="Name" name="name">
          <Input autoComplete="off" />
        </Form.Item>
        <div style={{ marginTop: "2rem", textAlign: "center" }}>
          <Button htmlType="submit" type="primary" disabled={isLoading}>
            Next
          </Button>
        </div>
      </Form>
    </InviteSteps>
  );
}
