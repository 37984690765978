import { PlusCircleOutlined } from "@ant-design/icons";
import { CardElement } from "@stripe/react-stripe-js";
import {
  Button,
  Descriptions,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
} from "antd";
import _ from "lodash";
import * as React from "react";

import { AuthorizedPayment } from "../hooks/useTenantGroupAuthorizedPayments";
import { useUserPaymentMethods } from "../hooks/useUserWallet";
import { PaymentMethod, Source } from "../hooks/useWallet";
import notify from "../utils/notify";
import { Checkout } from "./ProductCheckout";

const NEW_CREDIT_CARD_ID = "__new_credit_card";

interface Props {
  paymentMethods: PaymentMethod[];
  sources: Source[];
  authorizedPayments: AuthorizedPayment[];
  selectedPaymentId?: string;
  billingIntervalText: string;
  billingAmount: number;
  baseAmount: number;
  surchargeAmount: number;
  discountText?: string;
  recurringIterations: number | null;
  isDisabled: boolean;
  isLoading: boolean;
  onFinish: (data: any) => Promise<void>;
}

export default function PaymentDetails(props: Readonly<Props>) {
  const [selectedPaymentId, setSelectedPaymentId] = React.useState<string>();
  const [newCreditCardName, setNewCreditCardName] = React.useState<string>();
  const { createNewPaymentMethod } = useUserPaymentMethods();

  React.useEffect(() => {
    let pid = selectedPaymentId || props.selectedPaymentId;
    if (pid === undefined) {
      const defaultPayment = _.first(
        _.filter([
          ...props.paymentMethods,
          ...props.sources,
          ..._.map(props.authorizedPayments, "payment_method"),
          ..._.map(props.authorizedPayments, "source"),
        ])
      );
      pid =
        defaultPayment === undefined ? NEW_CREDIT_CARD_ID : defaultPayment.id;
    }
    setSelectedPaymentId(pid);
  }, [
    props.selectedPaymentId,
    selectedPaymentId,
    setSelectedPaymentId,
    props.paymentMethods,
    props.sources,
    props.authorizedPayments,
  ]);

  async function handleSubmit(data: Checkout) {
    let paymentMethod;
    if (selectedPaymentId === NEW_CREDIT_CARD_ID) {
      try {
        paymentMethod = await createNewPaymentMethod(
          newCreditCardName || "Unnamed Credit Card"
        );
      } catch (err) {
        notify.error("Failed to add the new card", err);
        return;
      }
    } else {
      paymentMethod = _.find(
        [
          ...props.paymentMethods,
          ..._.map(props.authorizedPayments, "payment_method"),
        ],
        { id: selectedPaymentId }
      );
    }

    const source = _.find(
      [...props.sources, ..._.map(props.authorizedPayments, "source")],
      { id: selectedPaymentId }
    );

    await props.onFinish({
      ...data,
      payment_method_id: paymentMethod?.id,
      source_id: source?.id,
    });
  }

  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Form.Item
        label="Select payment"
        required
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Please select a payment or add a new one",
          },
        ]}
      >
        <Select value={selectedPaymentId} onChange={setSelectedPaymentId}>
          {props.paymentMethods.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
          {props.sources.map((item) => (
            <Select.Option
              key={item.id}
              value={item.id}
              disabled={item.stripe_source.data.status !== "verified"}
            >
              <Tooltip title={getSourceTooltip(item)}>{item.name}</Tooltip>
            </Select.Option>
          ))}
          {_.some(
            props.authorizedPayments,
            (ap) => ap.payment_method || ap.source
          ) && (
            <Select.OptGroup label="Your parking programs">
              {props.authorizedPayments.map(getAuthorizedPaymentOption)}
            </Select.OptGroup>
          )}
          <Select.Option key={NEW_CREDIT_CARD_ID} value={NEW_CREDIT_CARD_ID}>
            <Space>
              <PlusCircleOutlined />
              Add a new credit card
            </Space>
          </Select.Option>
        </Select>
      </Form.Item>
      {selectedPaymentId === NEW_CREDIT_CARD_ID && (
        <>
          <Form.Item
            label="Name"
            name="name"
            wrapperCol={{ span: 24 }}
            required
            rules={[
              {
                required: true,
                message: "Name of the credit card is required",
              },
            ]}
          >
            <Input
              value={newCreditCardName}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                setNewCreditCardName(evt.target.value)
              }
              placeholder="Any name you want to give this credit card, e.g., Chase Freedom"
            />
          </Form.Item>
          <Form.Item label="Card info" required>
            <CardElement options={{ hidePostalCode: true }} />
          </Form.Item>
        </>
      )}
      <BillingSummary
        billingAmount={props.billingAmount}
        baseAmount={props.baseAmount}
        surchargeAmount={props.surchargeAmount}
        discountText={props.discountText}
        billingIntervalText={props.billingIntervalText}
        recurringIterations={props.recurringIterations}
      />
      <Form.Item style={{ marginTop: "1rem" }}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={props.isDisabled}
          loading={props.isLoading}
        >
          Confirm
        </Button>
      </Form.Item>
    </Form>
  );
}

function getAuthorizedPaymentOption(authorizedPayment: AuthorizedPayment) {
  if (authorizedPayment.payment_method) {
    const { payment_method } = authorizedPayment;
    return (
      <Select.Option key={payment_method.id} value={payment_method.id}>
        {payment_method.name}
      </Select.Option>
    );
  }
  if (authorizedPayment.source) {
    const { source } = authorizedPayment;
    return (
      <Select.Option
        key={source.id}
        value={source.id}
        disabled={source.stripe_source.data.status !== "verified"}
      >
        <Tooltip title={getSourceTooltip(source)}>{source.name}</Tooltip>
      </Select.Option>
    );
  }
  return null;
}

const getSourceTooltip = (source: Source) => {
  if (source.stripe_source.data.status === "verified") {
    return undefined;
  }
  return "The account needs to be verified first";
};

interface BillingSummaryProps {
  billingAmount: number;
  baseAmount: number;
  surchargeAmount: number;
  discountText?: string;
  billingIntervalText: string;
  recurringIterations: number | null;
}

function BillingSummary(props: Readonly<BillingSummaryProps>) {
  return (
    <Space direction="vertical">
      <h3>Billing Summary</h3>
      <Descriptions column={1}>
        {props.recurringIterations === null || props.recurringIterations > 1 ? (
          <>
            <Descriptions.Item label="Billing Frequency">
              {props.billingIntervalText}
            </Descriptions.Item>
            {props.surchargeAmount > 0 && (
              <>
                <Descriptions.Item label="Parking Cost">
                  {getTextForAmount(props.baseAmount)}
                </Descriptions.Item>
                <Descriptions.Item label="Processing Cost">
                  {getTextForAmount(props.surchargeAmount)}
                </Descriptions.Item>
              </>
            )}
            <Descriptions.Item label="Total Recurring Charge">
              {`${getTextForAmount(
                props.billingAmount
              )} ${props.billingIntervalText.toLowerCase()}.`}
            </Descriptions.Item>
            {props.recurringIterations && (
              <Descriptions.Item>
                Subscription and charges end after {props.recurringIterations}{" "}
                billings.
              </Descriptions.Item>
            )}
          </>
        ) : (
          <>
            {props.surchargeAmount > 0 && (
              <>
                <Descriptions.Item label="Parking Cost">
                  {getTextForAmount(props.baseAmount)}
                </Descriptions.Item>
                <Descriptions.Item label="Processing Cost">
                  {getTextForAmount(props.surchargeAmount)}
                </Descriptions.Item>
              </>
            )}
            <Descriptions.Item label="Total Charge">
              {getTextForAmount(props.billingAmount)}
            </Descriptions.Item>
          </>
        )}
        {props.discountText && (
          <Descriptions.Item label="Discount">
            {props.discountText}
          </Descriptions.Item>
        )}
      </Descriptions>
    </Space>
  );
}

function getTextForAmount(amount: number): string {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}
