import {
  ApartmentOutlined,
  GlobalOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Card, Form, Input, List, Result, Space } from "antd";
import React, { useState } from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { useParkerTenantGroups } from "../hooks/useParkerTenantGroups";
import notify from "../utils/notify";
import HeaderContent from "./Layouts/HeaderContent";
import NavBar from "./NavBar";
import NotFoundContent from "./NotFoundContent";

const ParkerTenantGroups = () => {
  const { path } = useRouteMatch();
  return (
    <HeaderContent navbar={<NavBar />}>
      <Switch>
        <Route exact path={`${path}`}>
          <ParkerTenantGroupsList />
        </Route>
        <Route path={`${path}/new`}>
          <ParkerTenantGroupCreate />
        </Route>
        <Route>
          <NotFoundContent />
        </Route>
      </Switch>
    </HeaderContent>
  );
};

const ParkerTenantGroupsList = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [error, setError] = useState<Error>();
  const { tenantGroups } = useParkerTenantGroups({ onError: setError });
  if (error)
    return (
      <Card>
        <Result
          status="warning"
          title="Sorry, could not load your Tenant Groups."
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/me")}
            >
              Home
            </Button>
          }
        />
      </Card>
    );
  if (tenantGroups?.length === 0)
    return (
      <Card>
        <Result
          icon={<GlobalOutlined />}
          title="You aren't a member of any Tenant Groups."
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => history.push(`${url}/new`)}
            >
              Create a Tenant Group
            </Button>
          }
        />
      </Card>
    );
  if (tenantGroups?.length)
    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push(`${url}/new`)}
        >
          Create Tenant Group
        </Button>
        <Card>
          <List
            itemLayout="horizontal"
            dataSource={tenantGroups}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta title={item.name} />
                <Link to={`/tenant-groups/${item.id}`}>
                  <Button type="link">
                    <ApartmentOutlined />
                    Admin View
                  </Button>
                </Link>
              </List.Item>
            )}
          />
        </Card>
      </Space>
    );
  return null;
};
const ParkerTenantGroupCreate = () => {
  const history = useHistory();
  const [createError, setCreateError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const { create } = useParkerTenantGroups({ onError: setCreateError });
  if (createError)
    return (
      <Card>
        <Result
          status="warning"
          title="Sorry, something went wrong."
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => setCreateError(undefined)}
            >
              Try Again
            </Button>
          }
        />
      </Card>
    );
  return (
    <Card title="Create a new Tenant Group">
      <Form
        layout="vertical"
        onFinish={async (data) => {
          try {
            setIsLoading(true);
            const tenantGroup = await create(data);
            notify.success("Successfully created new tenant group");
            setTimeout(() => {
              history.push(`/tenant-groups/${tenantGroup.id}`);
            }, 100);
          } catch (err) {
            notify.error("Fail to create new tenant group", err);
          } finally {
            setIsLoading(false);
          }
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Name is required." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ xs: { span: 24 } }}>
          <Button
            disabled={isLoading}
            loading={isLoading}
            type="primary"
            htmlType="submit"
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
export default ParkerTenantGroups;
