import {
  CreateTokenBankAccountData,
  PaymentMethodCreateParams,
} from "@stripe/stripe-js";
import { Card, Form, Input, Select, Spin } from "antd";
import * as React from "react";

import BankPayment from "./BankPayment";
import CardPayment from "./CardPayment";

type PaymentType = "card" | "bank";

interface Props {
  onCreateCardPayment: (
    name: string,
    description: string,
    billingAddress: PaymentMethodCreateParams.BillingDetails
  ) => Promise<void>;
  onCreateBankPayment: (
    name: string,
    description: string,
    bankData: CreateTokenBankAccountData
  ) => Promise<void>;
  CancelButton?: React.ReactElement;
}

export default function AddPaymentMethod(props: Readonly<Props>) {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [paymentType, setPaymentType] = React.useState<PaymentType>("card");
  const [isFetching, setIsFetching] = React.useState(false);

  function handleNameChange(evt: React.ChangeEvent<HTMLInputElement>) {
    setName(evt.target.value);
  }

  function handleDescriptionChange(
    evt: React.ChangeEvent<HTMLTextAreaElement>
  ) {
    setDescription(evt.target.value);
  }

  async function createCardPayment(
    billingAddress: PaymentMethodCreateParams.BillingDetails
  ) {
    setIsFetching(true);
    try {
      await props.onCreateCardPayment(name, description, billingAddress);
    } finally {
      setIsFetching(false);
    }
  }

  async function createBankPayment(bankData: CreateTokenBankAccountData) {
    setIsFetching(true);
    try {
      await props.onCreateBankPayment(name, description, bankData);
    } finally {
      setIsFetching(false);
    }
  }

  return (
    <div style={{ maxWidth: "42rem", margin: "0 auto" }}>
      <Spin spinning={isFetching}>
        <Card title="Add Payment Method">
          <Form.Item
            label="Name"
            wrapperCol={{ span: 24 }}
            required
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input
              autoFocus
              value={name}
              onChange={handleNameChange}
              placeholder="Any name you want to give this payment method, e.g., Chase Freedom"
            />
          </Form.Item>
          <Form.Item label="Description" wrapperCol={{ span: 24 }}>
            <Input.TextArea
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Additional descriptions about this payment method"
            />
          </Form.Item>

          <Form.Item label="Payment Type" required wrapperCol={{ span: 24 }}>
            <Select<PaymentType> value={paymentType} onChange={setPaymentType}>
              <Select.Option key="card" value="card">
                Credit or Debit Card
              </Select.Option>
              <Select.Option key="bank" value="bank">
                Bank Account
              </Select.Option>
            </Select>
          </Form.Item>

          {paymentType === "card" && (
            <CardPayment
              onCreateCardPayment={createCardPayment}
              CancelButton={props.CancelButton}
            />
          )}
          {paymentType === "bank" && (
            <BankPayment
              onCreateBankPayment={createBankPayment}
              CancelButton={props.CancelButton}
            />
          )}
        </Card>
      </Spin>
    </div>
  );
}
