import {
  BankOutlined,
  FormOutlined,
  NumberOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Empty, Result, Row, Skeleton, Space } from "antd";
import _ from "lodash";
import * as React from "react";
import { useHistory } from "react-router-dom";

import {
  Location,
  useOrganizationLocations,
} from "../../hooks/useOrganizationLocations";
import VerticalSpace from "../VerticalSpace";

interface AllLocationsProps {
  organizationId: string;
}

export default function AllLocations(props: Readonly<AllLocationsProps>) {
  const history = useHistory();
  const [error, setError] = React.useState<Error>();
  const { locations } = useOrganizationLocations({
    organizationId: props.organizationId,
    onError: setError,
  });

  if (error) {
    return (
      <Result
        status="error"
        title="Failed to load locations"
        subTitle={error.message}
      />
    );
  }

  return (
    <VerticalSpace size="large">
      <Card>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push(`${history.location.pathname}/new`)}
        >
          Add Location
        </Button>
      </Card>
      <LocationsList locations={locations} />
    </VerticalSpace>
  );
}

interface LocationListProps {
  locations: Location[] | undefined;
}

function LocationsList(props: Readonly<LocationListProps>) {
  if (props.locations === undefined) {
    return <Skeleton loading />;
  }

  if (props.locations.length === 0) {
    return <Empty description="No active locations." />;
  }

  return (
    <Row gutter={16}>
      {_.sortBy(props.locations, "name").map((location: Location) => (
        <Col
          key={location.id}
          style={{ marginTop: "2rem" }}
          xs={24}
          md={12}
          xl={8}
          xxl={6}
        >
          <LocationCard location={location} />
        </Col>
      ))}
    </Row>
  );
}

interface LocationCardProps {
  location: Location;
}

function LocationCard(props: Readonly<LocationCardProps>) {
  const [editedLocation, setEditedLocation] = React.useState(props.location);

  const history = useHistory();

  React.useEffect(() => {
    setEditedLocation(props.location);
  }, [props.location]);

  function editLocation(): void {
    history.push(`${history.location.pathname}/${props.location.id}`);
  }

  const { default_address } = props.location;
  return (
    <Card title={editedLocation.name || "[Unnamed Location]"}>
      <Space style={{ width: "100%" }} direction="vertical">
        <Row gutter={16}>
          <Col flex="6rem">
            <Space>
              <BankOutlined />
              <span>Address</span>
            </Space>
          </Col>
          <Col>
            {default_address &&
            default_address?.city?.length > 0 &&
            default_address?.postal_code?.length > 0 ? (
              <div>
                <div>
                  {default_address.line1} {default_address.line2}
                </div>
                <div>
                  {default_address.city}, {default_address.state}{" "}
                  {default_address.postal_code}
                </div>
              </div>
            ) : (
              "No address information."
            )}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col flex="6rem">
            <Space>
              <NumberOutlined />
              <span>Capacity</span>
            </Space>
          </Col>
          <Col>{props.location.capacity || "No capacity information."}</Col>
        </Row>
        <div style={{ marginTop: "1rem", textAlign: "center" }}>
          <Button icon={<FormOutlined />} onClick={editLocation}>
            Edit
          </Button>
        </div>
      </Space>
    </Card>
  );
}
