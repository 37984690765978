import { Button, Card, Result } from "antd";
import * as React from "react";
import { Link } from "react-router-dom";

interface Props {
  error: Error;
}

export default function ErrorResult(props: Readonly<Props>) {
  return (
    <Card>
      <Result
        status="error"
        title={props.error.message}
        subTitle="If you believe this is an error, please email smartpass@smarking.com"
        extra={
          <Button type="primary">
            <Link to="/">Take me home</Link>
          </Button>
        }
      />
    </Card>
  );
}
