import { Button, Card, Form, Spin } from "antd";
import * as React from "react";

import useVehicles, { Vehicle } from "../../hooks/useVehicles";
import notify from "../../utils/notify";
import VehicleInfoFormItems from "./VehicleInfoFormItems";

interface Props {
  onSuccess: () => void;
}

export default function AddParkerVehicle(props: Readonly<Props>) {
  const [isFetching, setIsFetching] = React.useState(false);
  const { createVehicle } = useVehicles();

  async function create(values: Omit<Vehicle, "id">) {
    setIsFetching(true);
    try {
      await createVehicle(values);
      notify.success(`Successfully created new vehicle ${values.name}.`);
      props.onSuccess();
    } catch (err) {
      notify.error("Failed to add the vehicle", err);
    } finally {
      setIsFetching(false);
    }
  }

  return (
    <div style={{ maxWidth: "42rem", margin: "0 auto" }}>
      <Spin spinning={isFetching}>
        <Card title="Add New Vehicle">
          <Form layout="vertical" onFinish={create}>
            <VehicleInfoFormItems />
            <div style={{ textAlign: "center", marginTop: "2rem" }}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </Card>
      </Spin>
    </div>
  );
}
