import { Layout } from "antd";
import * as React from "react";

import { LAYOUT_CONTENT_ID } from "./HeaderContent";

interface Props {
  navbar: React.ReactNode;
  sidebar: React.ReactNode;
  children: React.ReactNode;
}

export default function HeaderSidebarContent(
  props: Readonly<Props>
): React.FunctionComponentElement<Props> {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Header>{props.navbar}</Layout.Header>
      <Layout>
        <Layout.Sider collapsible breakpoint="lg">
          {props.sidebar}
        </Layout.Sider>
        <Layout.Content
          id={LAYOUT_CONTENT_ID}
          style={{
            height: "calc(100vh - 64px)",
            overflow: "auto",
            padding: "3vh 3vw",
          }}
        >
          {props.children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
