import useSWR from "swr";

import useIdsFetch from "./useIdsFetch";

export interface Vehicle {
  id: string;
  name: string;
  license_plate_number: string;
  user_id: string;
}

export const VEHICLES_API_PREFIX = "/me/vehicles";

export default function useVehicles() {
  const idsFetch = useIdsFetch();
  const {
    data: vehicles,
    error,
    mutate,
  } = useSWR<Vehicle[]>(VEHICLES_API_PREFIX);

  async function createVehicle(vehicleData: Omit<Vehicle, "id" | "user_id">) {
    const vehicle: Vehicle = await idsFetch(VEHICLES_API_PREFIX, {
      method: "POST",
      body: vehicleData,
    });
    await mutate();
    return vehicle;
  }

  async function updateVehicle(
    vehicleId: string,
    vehicleData: Omit<Vehicle, "id" | "user_id">
  ) {
    const vehicle: Vehicle = await idsFetch(
      `${VEHICLES_API_PREFIX}/${vehicleId}`,
      {
        method: "PUT",
        body: vehicleData,
      }
    );
    await mutate();
    return vehicle;
  }

  async function deleteVehicle(vehicleId: string) {
    await idsFetch(`${VEHICLES_API_PREFIX}/${vehicleId}`, {
      method: "DELETE",
    });
    await mutate();
  }

  return {
    vehicles,
    error,
    createVehicle,
    updateVehicle,
    deleteVehicle,
  };
}
