import { Button, Col, Result, Row, Space, Typography } from "antd";
import * as React from "react";
import { useHistory } from "react-router";
import useSWR from "swr";

import { getInvitationLink } from "../../hooks/useInvitation";
import { Invitation } from "../../hooks/useOrganizationParkingProducts";
import InvitationFrame from "./InvitationFrame";

interface Props {
  organizationId: string;
  invitationId: string;
  onNext: () => void;
}

export default function InvitationShare(props: Readonly<Props>) {
  const history = useHistory();
  const { data: invitation, error } = useSWR<Invitation>(
    `/organizations/${props.organizationId}/parking-products/invitations/${props.invitationId}/`
  );

  if (error) {
    return (
      <InvitationFrame currentStep={1} isLoading={false}>
        <Result
          status="error"
          title="Failed to load invitation"
          extra={[
            <Button type="primary" onClick={() => history.goBack()}>
              Go back
            </Button>,
          ]}
        />
      </InvitationFrame>
    );
  }

  return (
    <InvitationFrame currentStep={1} isLoading={invitation === undefined}>
      <Space style={{ width: "100%", marginTop: "1rem" }} direction="vertical">
        <Space style={{ width: "100%" }} direction="vertical">
          <Row>
            <Col xs={24} md={3}>
              <strong>Email:</strong>
            </Col>
            <Col xs={24} md={21}>
              {invitation ? invitation.email : "Loading..."}
            </Col>
          </Row>
          {invitation && invitation.name && (
            <Row>
              <Col xs={24} md={3}>
                <strong>Name:</strong>
              </Col>
              <Col xs={24} md={21}>
                {invitation.name}
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={24} md={3}>
              <strong>Link:</strong>
            </Col>
            <Col xs={24} md={21}>
              <Typography.Text copyable>
                {invitation ? getInvitationLink(invitation.id) : "Loading..."}
              </Typography.Text>
            </Col>
          </Row>
          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            <Space>
              <Button type="primary">
                <a href={invitation ? getMailToLink(invitation) : "#"}>
                  Send Email
                </a>
              </Button>
              <Button onClick={props.onNext}>Next</Button>
            </Space>
          </div>
        </Space>
      </Space>
    </InvitationFrame>
  );
}

function getMailToLink(invitation: Invitation) {
  const subject =
    "Please join SmartPass as tenant group admin for flexible monthly parking";
  const firstName =
    invitation.name && invitation.name.length > 0
      ? invitation.name.split(" ")[0]
      : null;
  const body = encodeURIComponent(`${firstName ? `Hi ${firstName},` : "Hi,"}

Please use the following link to accept your invitation:

    ${getInvitationLink(invitation.id)}

Reply to this email if you have any questions. Thank you!`);
  return `mailto:${invitation.email}?subject=${subject}&body=${body}`;
}
