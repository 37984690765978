import {
  CarOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Modal, Result, Row, Space, Spin } from "antd";
import _ from "lodash";
import * as React from "react";
import { useHistory } from "react-router";

import useVehicles, { Vehicle } from "../../hooks/useVehicles";
import notify from "../../utils/notify";
import VerticalSpace from "../VerticalSpace";

export default function AllParkerVehicles() {
  const history = useHistory();
  const { vehicles, error, deleteVehicle } = useVehicles();
  const [isDeleting, setIsDeleting] = React.useState(false);

  async function onEditVehicle(vehicle: Vehicle) {
    history.push(`/me/vehicles/${vehicle.id}/edit`);
  }

  async function onDeleteVehicle(vehicle: Vehicle) {
    const { name } = vehicle;
    Modal.confirm({
      title: `Are you sure to delete "${name}"?`,
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        setIsDeleting(true);
        try {
          await deleteVehicle(vehicle.id);
          notify.success(`Successfully deleted "${name}".`);
        } catch (err) {
          notify.error("Failed to delete the vehicle", err);
        } finally {
          setIsDeleting(false);
        }
      },
    });
  }

  if (error) {
    return (
      <Card>
        <Result
          status="error"
          title="Failed to load vehicles"
          subTitle={error.message}
        />
      </Card>
    );
  }

  if (vehicles?.length === 0) {
    return (
      <Card>
        <Result
          title="No vehicles yet."
          icon={<CarOutlined />}
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => history.push("/me/vehicles/add")}
            >
              Add Your First Vehicle
            </Button>
          }
        />
      </Card>
    );
  }

  return (
    <Spin spinning={vehicles === undefined || isDeleting}>
      <VerticalSpace size="large">
        <Card>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => history.push("/me/vehicles/add")}
          >
            Add Vehicle
          </Button>
        </Card>
        <Row gutter={16}>
          {_.sortBy(vehicles, "name").map((vehicle) => (
            <Col
              key={vehicle.id}
              xs={24}
              md={12}
              lg={12}
              xl={8}
              style={{ marginBottom: "2rem" }}
            >
              <VehicleCard
                vehicle={vehicle}
                onEdit={onEditVehicle}
                onDelete={onDeleteVehicle}
              />
            </Col>
          ))}
        </Row>
      </VerticalSpace>
    </Spin>
  );
}

interface VehicleCardProps {
  vehicle: Vehicle;
  onEdit: (vehicle: Vehicle) => void;
  onDelete: (vehicle: Vehicle) => void;
}

function VehicleCard(props: Readonly<VehicleCardProps>) {
  return (
    <Card
      title={props.vehicle.name}
      style={{ width: "100%" }}
      actions={[
        <Button
          key="edit"
          type="link"
          onClick={() => props.onEdit(props.vehicle)}
        >
          <EditOutlined />
          Edit
        </Button>,
        <Button
          key="delete"
          danger
          type="link"
          onClick={() => props.onDelete(props.vehicle)}
        >
          <DeleteOutlined />
          Delete
        </Button>,
      ]}
    >
      <Space>
        License Plate:
        {props.vehicle.license_plate_number}
      </Space>
    </Card>
  );
}
