import useSwr from "swr";

import { ParkerProfile } from "../components/ParkersList";
import useIdsFetch from "./useIdsFetch";
import { Purchase } from "./useUserPurchases";

const pathList = (organizationId: string, selectedState: string) =>
  `/organizations/${organizationId}/parkers/?status=${selectedState}`;
const pathPurchaseCancel = (
  organizationId: string,
  profileId: string,
  purchaseId: string
) =>
  `/organizations/${organizationId}/parkers/${profileId}/purchases/${purchaseId}/cancel/`;
const pathParkerPurchase = (organizationId: string, profileId: string) =>
  `/organizations/${organizationId}/parkers/${profileId}/purchases/`;
const useOrganizationParkers = ({
  organizationId,
  selectedState,
  onError = () => {},
}: {
  organizationId: string;
  selectedState: string;
  onError?: (err: Error) => void;
}) => {
  const { data: parkers, mutate } = useSwr<ParkerProfile[]>(
    pathList(organizationId, selectedState),
    { onError }
  );
  const revalidate = () => mutate();
  return {
    parkers,
    revalidate,
  };
};

export function useOrganizationParkersPurchases(
  organizationId: string,
  profileId: string
) {
  const {
    data: parkerPurchases,
    error,
    mutate,
  } = useSwr<Purchase[]>(pathParkerPurchase(organizationId, profileId));
  const idsFetch = useIdsFetch();
  const revalidate = () => mutate();
  const deactivate = async (purchaseId: string) => {
    await idsFetch(pathPurchaseCancel(organizationId, profileId, purchaseId), {
      method: "POST",
    });
  };
  return {
    parkerPurchases,
    error,
    revalidate,
    deactivate,
  };
}

export default useOrganizationParkers;
