import { Button, Space, Typography } from "antd";
import * as React from "react";

import { getInvitationLink } from "../../hooks/useInvitation";
import useUserProfile from "../../hooks/useUserProfile";
import InviteSteps from "./InviteSteps";

import type { Invitation } from "../../hooks/useMembers";

export default function Invite({
  invitation,
  onNext,
}: {
  invitation: Invitation;
  onNext: () => void;
}) {
  const { profile: userProfile } = useUserProfile({});
  return (
    <InviteSteps currentStep={1} isLoading={!userProfile}>
      <Space style={{ width: "100%" }} direction="vertical">
        <h3>
          Send {invitation?.name || invitation?.email} the following link.
        </h3>
        <Typography.Text copyable>
          {invitation ? getInvitationLink(invitation.id) : "Generating..."}
        </Typography.Text>
        <div style={{ textAlign: "center" }}>
          <Space style={{ marginTop: "1.5rem" }}>
            <Button type="primary" disabled={!invitation}>
              <a
                href={
                  invitation
                    ? getMailToLink(
                        invitation,
                        invitation.name || invitation.email,
                        userProfile?.name || ""
                      )
                    : "#"
                }
              >
                Send Email
              </a>
            </Button>
            <Button disabled={!invitation} onClick={onNext}>
              Next
            </Button>
          </Space>
        </div>
      </Space>
    </InviteSteps>
  );
}

function getMailToLink(
  invitation: Invitation,
  nameOfInvitee: string,
  nameOfInviter: string
) {
  const subject =
    "Please join SmartPass as admin for monthly parker management";
  const body = encodeURIComponent(`Hi ${nameOfInvitee},

I have invited you to set up an account as a SmartPass admin - please visit the following link to sign up and activate your account:

    ${getInvitationLink(invitation.id)}

Thank you, and please let me know if you have any questions.

Best,
${nameOfInviter}`);
  return `mailto:${invitation.email}?subject=${subject}&body=${body}`;
}
