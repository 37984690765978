import { Card, Col, Row, Select } from "antd";
import _ from "lodash";
import { Dispatch, SetStateAction } from "react";

interface FilterParkerProps {
  setSelectedState: Dispatch<SetStateAction<"active" | "inactive">>;
}

function ParkerFilter(props: Readonly<FilterParkerProps>) {
  return (
    <Card>
      <Row gutter={[12, 12]} align="middle">
        <Col flex="none">
          <strong>Select Parker Status:</strong>
        </Col>
        <Col flex="none">
          <Select
            defaultValue={"active"}
            placeholder="Status"
            filterOption={(input, option) =>
              _.some(
                _.map(_.values(option), (value) =>
                  value.toLowerCase().includes(input.toLowerCase())
                ),
                Boolean
              )
            }
            options={["Active", "Inactive"].map((state) => ({
              label: state,
              value: state.toLocaleLowerCase(),
            }))}
            onChange={props.setSelectedState}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default ParkerFilter;
