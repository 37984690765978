import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga4";
import { History } from "history";
import React, { useEffect } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { SWRConfig } from "swr";
import OrganizationApp from "./components/Organization";
import AppWelcome from "./components/AppWelcome";
import InvitationClaim from "./components/InvitationClaim";
import Login from "./components/Login";
import Logout from "./components/Logout";
import NotFoundContent from "./components/NotFoundContent";
import NotFoundFullPage from "./components/NotFoundFullPage";
import ParkerApp, { parkerViewPath } from "./components/Parker";
import ProductCheckout from "./components/ProductCheckout";
import TenantGroupApp from "./components/TenantGroup";
import useIdsFetch from "./hooks/useIdsFetch";
import {
  organizationViewPath,
  invitationClaimPath,
  tenantGroupViewPath,
} from "./routes";
import type { Organization } from "./hooks/useOrganization";
import { UserProfile } from "./hooks/useUserProfile";

const initialGlobalState = {
  userProfile: {
    data: undefined as UserProfile | undefined,
    isLoading: false,
    error: undefined as Error | undefined,
  },
  organization: {
    data: undefined as Organization | undefined,
    isLoading: false,
    error: undefined as Error | undefined,
  },
};

export const { useGlobalState } = createGlobalState(initialGlobalState);

export default function App({ history }: { history: History }) {
  const { user, isAuthenticated, isLoading } = useAuth0();
  useEffect(() => {
    if (user && (window as any).Intercom) {
      (window as any).Intercom("boot", {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        email: user?.email,
        user_id: user.sub,
      });
    }
  }, [user]);
  if (user) {
    Sentry.setUser({
      email: user.email,
      id: user.sub,
      username: user.nickname,
    });
    ReactGA.set({ userId: user.sub });
  }
  const fetcher = useIdsFetch();
  if (isLoading) return null;
  return (
    <SWRConfig
      value={{
        fetcher,
        onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
          if (retryCount) return;
        },
      }}
    >
      <Router history={history}>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/logout">
            <Logout />
          </Route>
          <Route exact path="/">
            {isAuthenticated ? (
              <Redirect from="/" to={parkerViewPath} />
            ) : (
              <AppWelcome />
            )}
          </Route>
          <Route path={parkerViewPath}>
            {isAuthenticated ? <ParkerApp /> : <Login />}
          </Route>
          <Route path={organizationViewPath}>
            {isAuthenticated ? <OrganizationApp /> : <Login />}
          </Route>
          <Route path={tenantGroupViewPath}>
            {isAuthenticated ? <TenantGroupApp /> : <Login />}
          </Route>
          <Route
            path="/products/:productId"
            render={({ match }) => (
              <ProductCheckout productId={match.params.productId} />
            )}
          ></Route>
          <Route path={invitationClaimPath}>
            {isAuthenticated ? <InvitationClaim /> : <Login />}
          </Route>
          <Route>
            {isAuthenticated ? <NotFoundFullPage /> : <NotFoundContent />}
          </Route>
        </Switch>
      </Router>
    </SWRConfig>
  );
}
