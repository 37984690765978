import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { Organization } from "../../hooks/useOrganization";
import {
  useParkingProductInvitations,
  useParkingProducts,
} from "../../hooks/useOrganizationParkingProducts";
import NotFoundContent from "../NotFoundContent";
import CreateParkingProduct from "./CreateParkingProduct";
import EditParkingProduct from "./EditParkingProduct";
import InviteDone from "./InviteDone";
import InviteSetup from "./InviteSetup";
import InviteShare from "./InviteShare";
import ParkingProductsList from "./ParkingProductsList";

const OrganizationParkingProducts = ({
  organization,
}: {
  organization: Organization;
}) => {
  const organizationId = organization.id;
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const { createParkingProduct } = useParkingProducts({
    organizationId: organization.id,
  });
  return (
    <Switch>
      <Route exact strict path={path}>
        <Redirect to={`${url}/active`} />
      </Route>
      <Route
        exact
        strict
        path={`${path}/:tab(active|disabled|pending-review)`}
        render={({ match }) => (
          <ParkingProductsList
            organizationId={organization.id}
            tab={match.params.tab}
            onTabChange={(newTab: string) => history.push(`${url}/${newTab}`)}
            onCreate={() => history.push(`${url}/new`)}
            onCreateEvent={() => history.push(`${url}/events/new`)}
            onEdit={(parkingProductId: string) =>
              history.push(`${url}/${parkingProductId}/edit`)
            }
            onInvite={(parkingProductId: string) =>
              history.push(`${url}/${parkingProductId}/invite`)
            }
          />
        )}
      ></Route>
      <Route exact path={`${path}/new`}>
        <CreateParkingProduct
          organizationId={organizationId}
          createParkingProduct={createParkingProduct}
          onCreateSuccess={() => history.push(url)}
        />
      </Route>
      <Route
        exact
        strict
        path={`${path}/:parkingProductId/edit`}
        render={({ match }) => (
          <EditParkingProduct
            parkingProductId={match.params.parkingProductId}
            organizationId={organizationId}
            onUpdateSuccess={() => history.push(url)}
          />
        )}
      ></Route>
      <Route
        path={`${path}/:parkingProductId/invite`}
        render={({ match }) => (
          <InviteTenantGroupsRouter
            organizationId={organizationId}
            parkingProductId={match.params.parkingProductId}
            onBackToParkingProducts={() => history.push(url)}
          />
        )}
      ></Route>
      <Route>
        <NotFoundContent />
      </Route>
    </Switch>
  );
};

interface InviteTenantGroupsRouterProps {
  organizationId: string;
  parkingProductId: string;
  onBackToParkingProducts: () => void;
}

function InviteTenantGroupsRouter(
  props: Readonly<InviteTenantGroupsRouterProps>
) {
  const history = useHistory();
  const { url, path } = useRouteMatch();
  const { create } = useParkingProductInvitations({
    organizationId: props.organizationId,
  });

  return (
    <Switch>
      <Route exact strict path={path}>
        <InviteSetup
          onSubmit={create(props.parkingProductId)}
          onSuccess={(invitationId: string) => {
            setTimeout(() => {
              history.push(`${url}/share/${invitationId}`);
            }, 200);
          }}
        />
      </Route>
      <Route
        exact
        strict
        path={`${path}/share/:invitationId`}
        render={({ match }) => (
          <InviteShare
            organizationId={props.organizationId}
            invitationId={match.params.invitationId}
            onNext={() => history.push(`${url}/done`)}
          />
        )}
      />
      <Route exact strict path={`${path}/done`}>
        <InviteDone
          onInviteSomeoneElse={() => history.push(url)}
          onBackToParkingProducts={props.onBackToParkingProducts}
        />
      </Route>
      <Route>
        <NotFoundContent />
      </Route>
    </Switch>
  );
}

export default OrganizationParkingProducts;
