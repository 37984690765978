import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Result,
  Row,
  Select,
  Skeleton,
  Space,
  Tooltip,
} from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { TenantGroup, useParkingProducts } from "../hooks/useTenantGroup";
import {
  AuthorizedPayment,
  useAuthorizedPayment,
  useAuthorizedPaymentInvitations,
  useAuthorizedPayments,
} from "../hooks/useTenantGroupAuthorizedPayments";
import {
  useTenantGroupPaymentMethods,
  useTenantGroupSources,
} from "../hooks/useTenantGroupWallet";
import notify from "../utils/notify";
import Invitation from "./Invitation";
import responsiveCardList from "./Layouts/ResponsiveCards";
import NotFoundContent from "./NotFoundContent";
import { ProductDetails } from "./ProductCheckout";

export const TenantGroupParkingPrograms = ({
  tenantGroup,
}: {
  tenantGroup: TenantGroup;
}) => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { tenantGroupId } = useParams<{ tenantGroupId: string }>();

  const urlNew = `${url}/new`;
  const urlEdit = (id: string) => `${url}/${id}/edit`;
  const urlInvite = (id: string) => `${url}/${id}/invite`;
  const { authorizedPayments, error, revalidate, create } =
    useAuthorizedPayments({ tenantGroupId: tenantGroup.id });

  if (error) {
    return (
      <Card>
        <Result status="warning" title="Could not load parking programs." />
      </Card>
    );
  }

  if (authorizedPayments === undefined) {
    return null;
  }

  return (
    <Switch>
      <Route exact strict path={path}>
        <ParkingPrograms
          tenantGroupId={tenantGroupId}
          authorizedPayments={authorizedPayments}
          onNew={() => history.push(urlNew)}
          onEdit={(id) => history.push(urlEdit(id))}
          onInvite={(id) => history.push(urlInvite(id))}
          onDeleteSuccess={() => revalidate()}
        />
      </Route>
      <Route exact strict path={`${path}/new`}>
        <ParkingProgramCreate
          tenantGroupId={tenantGroupId}
          action="Create"
          onFormSubmit={create}
          onCancel={() => history.push(url)}
          onSuccess={() => history.push(url)}
          onError={() => history.push(url)}
        />
      </Route>
      <Route
        exact
        strict
        path={`${path}/:programId`}
        render={({ match }) => {
          const selected = _.find(authorizedPayments, {
            id: match.params.programId,
          });
          return selected ? (
            <ParkingProgramItem
              tenantGroupId={tenantGroupId}
              programId={match.params.programId}
              onEdit={() => history.push(urlEdit(selected.id))}
              onInvite={() => history.push(urlInvite(selected.id))}
              onDeleteSuccess={() => history.push(url)}
            />
          ) : (
            <NotFoundContent />
          );
        }}
      />
      <Route
        exact
        strict
        path={`${path}/:programId/edit`}
        render={({ match }) => {
          const selected = _.find(authorizedPayments, {
            id: match.params.programId,
          });
          return selected ? (
            <ParkingProgramEdit
              tenantGroupId={tenantGroupId}
              programId={match.params.programId}
              action="Update"
              parkingProgram={selected}
              onCancel={() => history.push(url)}
              onSuccess={() => history.push(url)}
              onError={() => history.push(url)}
            />
          ) : (
            <NotFoundContent />
          );
        }}
      />
      <Route
        exact
        strict
        path={`${path}/:programId/invite`}
        render={({ match }) => {
          const selected = _.find(authorizedPayments, {
            id: match.params.programId,
          });
          return selected ? (
            <ParkingProgramInvite
              tenantGroupId={tenantGroupId}
              programId={match.params.programId}
              onSuccess={() => history.push(url)}
            />
          ) : (
            <NotFoundContent />
          );
        }}
      ></Route>
      <Route>
        <NotFoundContent />
      </Route>
    </Switch>
  );
};

const ParkingPrograms = ({
  tenantGroupId,
  authorizedPayments,
  onNew,
  onEdit,
  onInvite,
  onDeleteSuccess,
}: {
  tenantGroupId: string;
  authorizedPayments: AuthorizedPayment[];
  onNew: () => void;
  onEdit: (id: string) => void;
  onInvite: (id: string) => void;
  onDeleteSuccess: () => void;
}) => {
  if (authorizedPayments.length === 0) {
    return (
      <Card>
        <Result
          icon={<ShoppingCartOutlined />}
          title="Add your first parking program"
          extra={
            <Button type="primary" key="console" onClick={() => onNew()}>
              Add Parking Program
            </Button>
          }
        />
      </Card>
    );
  }
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => onNew()}>
        Add Parking Program
      </Button>
      <Row gutter={16}>
        {authorizedPayments.map((item) => (
          <Col {...responsiveCardList} key={item.id}>
            <ParkingProgramItem
              parkingProgram={item}
              tenantGroupId={tenantGroupId}
              programId={item.id}
              onEdit={() => onEdit(item.id)}
              onInvite={() => onInvite(item.id)}
              onDeleteSuccess={() => onDeleteSuccess()}
            />
          </Col>
        ))}
      </Row>
    </Space>
  );
};

const ParkingProgramEdit = ({
  tenantGroupId,
  programId,
  parkingProgram,
  action,
  onCancel,
  onSuccess,
  onError,
}: {
  tenantGroupId: string;
  programId: string;
  parkingProgram?: AuthorizedPayment;
  action: string;
  onCancel: () => void;
  onSuccess: () => void;
  onError: () => void;
}) => {
  const { authorizedPayment, modify } = useAuthorizedPayment({
    authorizedPayment: parkingProgram,
    tenantGroupId,
    authorizedPaymentId: programId,
  });
  if (!authorizedPayment) return null;
  return (
    <ParkingProgramForm
      parkingProgram={authorizedPayment}
      tenantGroupId={tenantGroupId}
      action={action}
      onFormSubmit={modify}
      onCancel={onCancel}
      onSuccess={onSuccess}
      onError={onError}
    />
  );
};
const ParkingProgramCreate = ({
  tenantGroupId,
  action,
  onFormSubmit,
  onCancel,
  onSuccess,
  onError,
}: {
  tenantGroupId: string;
  action: string;
  onFormSubmit: (
    data: Partial<AuthorizedPayment>
  ) => Promise<AuthorizedPayment>;
  onCancel: () => void;
  onSuccess: () => void;
  onError: () => void;
}) => {
  return (
    <ParkingProgramForm
      tenantGroupId={tenantGroupId}
      action="Create"
      onFormSubmit={onFormSubmit}
      onCancel={onCancel}
      onSuccess={onSuccess}
      onError={onError}
    />
  );
};

const ParkingProgramForm = ({
  tenantGroupId,
  parkingProgram,
  action,
  onFormSubmit,
  onCancel,
  onSuccess,
  onError,
}: {
  tenantGroupId: string;
  parkingProgram?: Partial<AuthorizedPayment>;
  action: string;
  onFormSubmit: (
    data: Partial<AuthorizedPayment>
  ) => Promise<AuthorizedPayment>;
  onCancel: () => void;
  onSuccess: () => void;
  onError: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [selectedParkingProductId, setSelectedParkingProductId] = useState(
    parkingProgram?.parking_product?.id
  );
  const { data: paymentMethods } = useTenantGroupPaymentMethods(tenantGroupId);
  const { data: sources } = useTenantGroupSources(tenantGroupId);
  const { parkingProducts } = useParkingProducts({
    tenantGroupId,
    onError: setError,
  });
  const onSave = onFormSubmit;
  const isLoadingDependencies = !paymentMethods || !sources || !parkingProducts;
  const firstParkingProduct = _.first(parkingProducts);
  const initialValues = parkingProgram
    ? parkingProgram
    : {
        parking_product: firstParkingProduct,
      };
  useEffect(() => {
    setSelectedParkingProductId(initialValues?.parking_product?.id);
  }, [initialValues?.parking_product?.id]);
  if (error)
    return (
      <Card>
        <Result
          status="warning"
          title="Something went wrong"
          extra={[
            <Button
              type="primary"
              key="again"
              onClick={() => setError(undefined)}
            >
              Try Again
            </Button>,
            <Button key="back" onClick={() => onError()}>
              Back
            </Button>,
          ]}
        />
      </Card>
    );
  const onFinish = async (data: AuthorizedPayment) => {
    try {
      setIsLoading(true);
      await onSave(data);
      setTimeout(() => {
        onSuccess();
      }, 100);
    } catch (err) {
      notify.error("Failed to create parking program", err);
    } finally {
      setIsLoading(false);
    }
  };
  const parkingProductOptions = parkingProducts
    ? parkingProducts.map((item) => (
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      ))
    : null;
  const parkingProduct = _.find(parkingProducts, {
    id: selectedParkingProductId,
  });
  const price = _.first(parkingProduct?.prices);
  return (
    <Card>
      {isLoading || isLoadingDependencies ? (
        <Skeleton />
      ) : (
        <Form
          layout="vertical"
          scrollToFirstError={true}
          onFinish={onFinish}
          onValuesChange={(changed, { parking_product: { id } }) =>
            setSelectedParkingProductId(id)
          }
          initialValues={initialValues}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Name is required." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name={["parking_product", "id"]}
            label="Parking product"
            rules={[
              { required: true, message: "Parking Product is required." },
            ]}
          >
            <Select>{parkingProductOptions}</Select>
          </Form.Item>
          {parkingProduct && price && (
            <Card
              title={`Product: ${parkingProduct.name}`}
              style={{ marginBottom: "1em" }}
            >
              <ProductDetails
                parkingProduct={parkingProduct}
                defaultPrice={price}
              />
            </Card>
          )}
          {paymentMethods && (
            <Form.Item
              name={["payment_method", "id"]}
              label="Payment card"
              dependencies={["source", "id"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && getFieldValue(["source", "id"])) {
                      return Promise.reject(
                        new Error(
                          "Please choose either a card or a bank account, not both."
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select allowClear>
                {paymentMethods.map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {sources && (
            <Form.Item
              name={["source", "id"]}
              label="Payment bank account"
              dependencies={["payment_method", "id"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && getFieldValue(["payment_method", "id"])) {
                      return Promise.reject(
                        new Error(
                          "Please choose either a card or a bank account, not both."
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select allowClear>
                {sources.map((item) => (
                  <Select.Option
                    key={item.id}
                    value={item.id}
                    disabled={item.stripe_source.data.status !== "verified"}
                  >
                    <Tooltip
                      title={
                        item.stripe_source.data.status !== "verified"
                          ? "The account needs to be verified first"
                          : undefined
                      }
                    >
                      {item.name}
                    </Tooltip>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {paymentMethods && sources && (
            <p>
              Note: Select either a card or a bank account, or neither. Do not
              select both.
            </p>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {action}
            </Button>
            <Button type="link" htmlType="button" onClick={() => onCancel()}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      )}
    </Card>
  );
};

const ParkingProgramInvite = ({
  tenantGroupId,
  programId,
  onSuccess,
}: {
  tenantGroupId: string;
  programId: string;
  onSuccess: () => void;
}) => {
  const { create } = useAuthorizedPaymentInvitations({
    tenantGroupId,
    authorizedPaymentId: programId,
  });
  return (
    <Invitation
      onInviteSubmit={create}
      onDone={onSuccess}
      emailSubject="Invitation to Parking Program"
      title="Invite to Parking Program"
    />
  );
};

const ParkingProgramItem = ({
  tenantGroupId,
  programId,
  parkingProgram,
  onEdit,
  onInvite,
  onDeleteSuccess,
}: {
  parkingProgram?: AuthorizedPayment;
  tenantGroupId: string;
  programId: string;
  onEdit: () => void;
  onInvite: () => void;
  onDeleteSuccess: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const { authorizedPayment, remove } = useAuthorizedPayment({
    onError: setError,
    authorizedPayment: parkingProgram,
    tenantGroupId,
    authorizedPaymentId: programId,
  });
  const parkingProduct = authorizedPayment?.parking_product;
  const price = _.first(parkingProduct?.prices);
  const { confirm } = Modal;
  const showConfirm = () => {
    confirm({
      title: "Are you sure you want to delete this Parking Program?",
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          setIsLoading(true);
          await remove();
          setTimeout(() => {
            onDeleteSuccess();
          }, 100);
        } catch (err) {
          notify.error("Failed to delete parking program", err);
        } finally {
          setIsLoading(false);
        }
      },
    });
  };
  if (error)
    return (
      <Card>
        <Result
          status="warning"
          title="Something went wrong"
          extra={[
            <Button
              type="primary"
              key="again"
              onClick={() => setError(undefined)}
            >
              Try Again
            </Button>,
          ]}
        />
      </Card>
    );
  return (
    <>
      {isLoading || !authorizedPayment ? (
        <Card>
          <Skeleton />
        </Card>
      ) : (
        <Card
          title={authorizedPayment?.name}
          actions={[
            <Button key="edit" type="link" onClick={onEdit}>
              <EditOutlined />
              Edit
            </Button>,
            <Button key="invite" type="link" onClick={onInvite}>
              <UserAddOutlined />
              Invite
            </Button>,
            <Button key="delete" danger type="link" onClick={showConfirm}>
              <DeleteOutlined />
              Delete
            </Button>,
          ]}
          style={{ width: "100%" }}
        >
          <Space direction="vertical" size="large">
            {authorizedPayment.description?.trim().length > 0 && (
              <Space direction="vertical">
                <strong>Description</strong>
                <div style={{ whiteSpace: "pre-wrap" }}>
                  {authorizedPayment.description}
                </div>
              </Space>
            )}
            <Space direction="vertical">
              <strong>
                Parking Product: {authorizedPayment.parking_product.name}
              </strong>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {parkingProduct && price && (
                  <ProductDetails
                    parkingProduct={parkingProduct}
                    defaultPrice={price}
                  />
                )}
              </div>
            </Space>
            <Space direction="vertical">
              <strong>
                Location: {authorizedPayment.parking_product.location.name}
              </strong>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {authorizedPayment.parking_product.location.description}
              </div>
            </Space>
            {authorizedPayment.payment_method && (
              <Space direction="vertical">
                <strong>
                  Payment: {authorizedPayment.payment_method.name}
                </strong>
                <div style={{ whiteSpace: "pre-wrap" }}>
                  {authorizedPayment.payment_method.description}
                </div>
              </Space>
            )}
            {authorizedPayment.source && (
              <Space direction="vertical">
                <strong>Payment: {authorizedPayment.source.name}</strong>
                <div style={{ whiteSpace: "pre-wrap" }}>
                  {authorizedPayment.source.description}
                </div>
              </Space>
            )}
          </Space>
        </Card>
      )}
    </>
  );
};
