import { Collapse } from "antd";
import * as React from "react";
import { useHistory } from "react-router-dom";

import { Location, LocationData } from "../../hooks/useOrganizationLocations";
import LocationAccess from "./LocationAccess";
import LocationInfo from "./LocationInfo";
import OnsiteIntegration from "./OnsiteIntegration";

interface Props {
  organizationId: string;
  locationId?: string;
  onSubmitLocationInfo: (data: Partial<LocationData>) => Promise<Location>;
  onDeleteLocation?: () => Promise<void>;
}

export default function LocationForms(props: Readonly<Props>) {
  const history = useHistory();
  const [activeKey, setActiveKey] = React.useState<string | string[]>("1");

  return (
    <Collapse accordion activeKey={activeKey} onChange={setActiveKey}>
      <Collapse.Panel key="1" header="Location Information">
        <LocationInfo
          organizationId={props.organizationId}
          locationId={props.locationId}
          next={async (data: LocationData) => {
            await props.onSubmitLocationInfo(data);
            setActiveKey("2");
          }}
          onDeleteLocation={props.onDeleteLocation}
        />
      </Collapse.Panel>
      <Collapse.Panel
        key="2"
        header="Location Access"
        collapsible={props.locationId ? "header" : "disabled"}
      >
        <LocationAccess
          organizationId={props.organizationId}
          locationId={props.locationId || ""}
          next={() => setActiveKey("3")}
        />
      </Collapse.Panel>
      <Collapse.Panel
        key="3"
        header="On-Site Integration"
        collapsible={props.locationId ? "header" : "disabled"}
      >
        <OnsiteIntegration
          organizationId={props.organizationId}
          locationId={props.locationId || ""}
          done={() => {
            history.push(`/organizations/${props.organizationId}/locations`);
          }}
        />
      </Collapse.Panel>
    </Collapse>
  );
}
