import _ from "lodash";
import { useState } from "react";
import useSWR from "swr";

import useIdsFetch from "../hooks/useIdsFetch";

import type { ParkingPolicy, Purchase } from "./useUserPurchases";

export const cartsPath = `/me/carts/`;

export interface Cart {
  id: string;
  price_item: {
    parking_policy: ParkingPolicy;
    subscription_amount: [base: number, surchage: number];
  };
}
export const useCart = (priceId?: string, onError?: (err: any) => void) => {
  const [cartId, setCartId] = useState<string>();
  const apiPath = cartsPath;
  const idsFetch = useIdsFetch();
  const { data, mutate } = useSWR<Cart>(priceId ? apiPath : null, {
    onError,
    fetcher: async (url) => {
      const carts = await idsFetch(url);
      const existingCart = _.find(
        carts,
        _.matchesProperty("price_item.price.id", priceId)
      );
      const cart = existingCart
        ? existingCart
        : await idsFetch("/me/carts/", {
            method: "POST",
            body: { price_id: priceId },
          });
      setCartId(cart.id);
      return cart;
    },
  });
  const update = async (
    recurrence: string,
    maxDays?: number,
    maxSessions?: number
  ) => {
    return await idsFetch(`/me/carts/${cartId}/`, {
      method: "PUT",
      body: {
        recurrence,
        max_days_per_billing_cycle: maxDays,
        max_sessions_per_billing_cycle: maxSessions,
      },
    });
  };
  return { cart: data, update, revalidate: () => mutate() };
};

export const useCheckout = (cartId?: string) => {
  const idsFetch = useIdsFetch();
  const checkout = async ({
    vehicle_id,
    payment_method_id,
    source_id,
    stripe_payment_method_id,
    save_to_wallet,
  }: {
    vehicle_id: string;
    payment_method_id?: string;
    source_id?: string;
    stripe_payment_method_id?: string;
    save_to_wallet?: boolean;
  }): Promise<Purchase> =>
    await idsFetch(`/me/carts/${cartId}/checkout/`, {
      method: "POST",
      body: {
        vehicle_id,
        payment_method_id,
        source_id,
        stripe_payment_method_id,
        save_to_wallet,
      },
    });
  return checkout;
};
