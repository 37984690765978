import { Button, Card, Result, Spin } from "antd";
import * as React from "react";

import { useOrganizationLocations } from "../../hooks/useOrganizationLocations";
import {
  ParkingProduct,
  Price,
} from "../../hooks/useOrganizationParkingProducts";
import { usePayoutAccounts } from "../../hooks/usePayoutAccount";
import notify from "../../utils/notify";
import ParkingProductForm from "./ParkingProductForm";

interface Props {
  organizationId: string;
  createParkingProduct: (
    parkingProduct: Partial<ParkingProduct>,
    price: Partial<Price>
  ) => Promise<ParkingProduct>;
  onCreateSuccess: () => void;
}

export default function CreateParkingProduct(props: Readonly<Props>) {
  const [error, setError] = React.useState<Error>();
  const [isFetching, setIsFetching] = React.useState(false);

  const { locations, revalidate: revalidateLocations } =
    useOrganizationLocations({
      organizationId: props.organizationId,
      onError: setError,
    });

  const { payoutAccounts, revalidate: revalidatePayoutAccounts } =
    usePayoutAccounts({
      organizationId: props.organizationId,
      onError: setError,
    });

  async function onCreate(
    parkingProduct: Partial<ParkingProduct>,
    price: Partial<Price>
  ) {
    try {
      setIsFetching(true);
      await props.createParkingProduct(parkingProduct, price);
      notify.success("Successfully created new product.");
      setTimeout(() => {
        props.onCreateSuccess();
      }, 200);
    } catch (err) {
      notify.error("Failed to create new product", err);
    } finally {
      setIsFetching(false);
    }
  }

  if (error) {
    return (
      <Card>
        <Result
          status="warning"
          title="Oops, an error occurred."
          subTitle={error.message}
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => {
                setError(undefined);
                revalidateLocations();
                revalidatePayoutAccounts();
              }}
            >
              Try Again
            </Button>
          }
        />
      </Card>
    );
  }

  return (
    <Spin spinning={!locations || !payoutAccounts || isFetching}>
      <Card>
        <ParkingProductForm
          isCreate
          locations={locations || []}
          payoutAccounts={payoutAccounts || []}
          onSubmit={onCreate}
        />
      </Card>
    </Spin>
  );
}
