import { Button, Card, Col, Result, Row, Select, Spin } from "antd";
import { ColumnType } from "antd/lib/table";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";

import useOrganizationLocationEnforcement, {
  EnforcementSession,
} from "../hooks/useOrganizationLocationEnforcement";
import { useOrganizationLocations } from "../hooks/useOrganizationLocations";
import ResponsiveList from "./ResponsiveList";
import VerticalSpace from "./VerticalSpace";

interface Props {
  organizationId: string;
}

export default function OrganizationEnforcement(props: Readonly<Props>) {
  const [error, setError] = useState<Error>();
  const [selectedLocationId, setSelectedLocationId] = useState<string>();
  const { locations, revalidate } = useOrganizationLocations({
    organizationId: props.organizationId,
    onError: setError,
  });
  const { parkingSessions } = useOrganizationLocationEnforcement({
    organizationId: props.organizationId,
    locationId: selectedLocationId,
    onError: setError,
  });
  const isLoadingParkingSessions = !!selectedLocationId && !parkingSessions;

  const parkingSessionsFiltered = _(parkingSessions)
    .groupBy((session) => session.parking_policy.id)
    .values()
    .map((sessions) => {
      // Show ready for exit session, if any
      const readyForExitSessions = _.filter(sessions, {
        status: "READY_FOR_EXIT",
      });
      if (readyForExitSessions.length > 0) {
        return _.head(_.sortBy(readyForExitSessions, "code"));
      }
      // Do not show sessions that are not started by parkers if parkers are required to start/end sessions
      const { parking_product } = sessions[0];
      if (parking_product.access_mode === "parker_session") {
        return undefined;
      }
      // Show ready for entry session, if any
      const readyForEntrySessions = _.filter(sessions, {
        status: "READY_FOR_ENTRY",
      });
      return _.head(_.sortBy(readyForEntrySessions, "code"));
    })
    // Remove the ones without any valid session
    .filter()
    .value() as EnforcementSession[];

  const columns: ColumnType<EnforcementSession>[] = [
    {
      title: "License Plate",
      dataIndex: ["parking_policy", "vehicle", "license_plate_number"],
      colSpan: 3,
      render: (licensePlateNumber: string | undefined) =>
        licensePlateNumber?.toUpperCase() || "--",
    },
    {
      title: "Name",
      dataIndex: ["user_profile", "name"],
      colSpan: 3,
    },
    {
      title: "Email",
      dataIndex: ["user_profile", "email"],
      colSpan: 4,
      render: (email: string | undefined) =>
        email ? <a href={email}>{email}</a> : "--",
    },
    {
      title: "Valid Until",
      dataIndex: "valid_to",
      colSpan: 4,
      render: (validTo: string) => {
        const location = _.find(locations, { id: selectedLocationId });
        const tz = location?.time_zone || "utc";
        return moment.utc(validTo).tz(tz).format("lll");
      },
    },
    {
      title: "Parking Product",
      dataIndex: ["parking_product", "name"],
      colSpan: 6,
    },
    {
      title: "Time Of Use",
      colSpan: 4,
      dataIndex: ["parking_policy", "allowed_days"],
      render: (allowed_days: number[]) =>
        allowed_days.map((day) => moment.weekdaysShort(day + 1)).join(", "),
    },
  ];

  if (error) {
    return (
      <Card>
        <Result
          status="error"
          title="Could not load locations"
          subTitle={error.toString()}
          extra={
            <Button
              onClick={() => {
                setError(undefined);
                revalidate();
              }}
            >
              Try Again
            </Button>
          }
        />
      </Card>
    );
  }

  return (
    <Spin spinning={isLoadingParkingSessions}>
      <VerticalSpace size="large">
        <Card>
          <Row gutter={[12, 12]} align="middle">
            <Col flex="none">
              <strong>Select Location:</strong>
            </Col>
            <Col flex="none">
              <Select
                placeholder="Select a location"
                loading={!locations}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  _.some(
                    _.map(_.values(option), (value) =>
                      value.toLowerCase().includes(input.toLowerCase())
                    ),
                    Boolean
                  )
                }
                options={_.map(locations, ({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
                value={selectedLocationId}
                onChange={setSelectedLocationId}
              />
            </Col>
          </Row>
        </Card>
        <ResponsiveList<EnforcementSession>
          loading={isLoadingParkingSessions}
          columns={columns}
          rowKey="id"
          dataSource={parkingSessionsFiltered}
        />
      </VerticalSpace>
    </Spin>
  );
}
