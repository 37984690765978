import { CardElement } from "@stripe/react-stripe-js";
import { PaymentMethodCreateParams } from "@stripe/stripe-js";
import { Button, Collapse, Form, Space } from "antd";
import _ from "lodash";
import * as React from "react";

import { Address } from "../../hooks/useAddress";
import { PaymentMethod } from "../../hooks/useWallet";
import AddressForm from "../AddressForm";

interface Props {
  onCreateCardPayment: (
    billingAddress: PaymentMethodCreateParams.BillingDetails
  ) => Promise<void>;
  CancelButton?: React.ReactElement;
}

export default function CardPayment(props: Readonly<Props>) {
  const [billingAddress, setBillingAddress] =
    React.useState<Omit<Address, "id">>();

  async function createCardPayment(values: Omit<PaymentMethod, "id">) {
    await props.onCreateCardPayment({
      address: _.pick(billingAddress, [
        "line1",
        "line2",
        "city",
        "state",
        "postal_code",
      ]),
    });
  }

  return (
    <Form layout="vertical" onFinish={createCardPayment}>
      <Form.Item label="Card Info" required>
        <CardElement options={{ hidePostalCode: true }} />
      </Form.Item>

      <Collapse style={{ marginTop: "1.5rem" }}>
        <Collapse.Panel header="Billing Address (Optional)" key="0">
          <AddressForm address={billingAddress} onChange={setBillingAddress} />
        </Collapse.Panel>
      </Collapse>

      <div style={{ textAlign: "center", marginTop: "2rem" }}>
        <Space size="middle">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
          {props.CancelButton && props.CancelButton}
        </Space>
      </div>
    </Form>
  );
}
