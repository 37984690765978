import { Col, List, Row } from "antd";
import { ColumnType } from "antd/lib/table";
import * as React from "react";

import { borderRadius, getColumnKey, rowPadding } from "./ResponsiveList";
import TooltipCell from "./TooltipCell";

interface Props<T> {
  columns: ColumnType<T>[];
}

export default function HeaderRow<T extends object>(props: Readonly<Props<T>>) {
  return (
    <List.Item
      style={{
        padding: rowPadding,
        backgroundColor: "#77879a",
        color: "white",
        fontWeight: 600,
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
      }}
    >
      <Row align="middle">
        {props.columns.map((column: ColumnType<T>, idx: number) => (
          <Col key={getColumnKey(column, idx)} span={column.colSpan}>
            <TooltipCell>{column.title}</TooltipCell>
          </Col>
        ))}
      </Row>
    </List.Item>
  );
}
