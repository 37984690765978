import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import useSWR from "swr";

import useIdsFetch, { ResponseError } from "./useIdsFetch";

const apiUrl = "/me/user-profile";

export interface UserProfile {
  id: string;
  name: string;
  email: string;
  number: string;
  user_id: string;
}

export default function useUserProfile({
  onError,
}: {
  onError?: (err: Error) => void;
}) {
  const { user } = useAuth0();
  const idsFetch = useIdsFetch();
  const { data, mutate } = useSWR<UserProfile>(
    user ? apiUrl : null,
    () => createUserProfileIfNotExist(user!),
    { onError }
  );
  const revalidate = useCallback(() => mutate(), [mutate]);
  const createUserProfile = useCallback(
    async ({ email, name, number = "" }: Partial<UserProfile>) =>
      await idsFetch(apiUrl, {
        method: "POST",
        body: {
          email,
          name,
          number,
        },
      }),
    [idsFetch]
  );
  const updateUserProfile = useCallback(
    async ({ email, name, number }: Partial<UserProfile>) =>
      idsFetch(apiUrl, {
        method: "PUT",
        body: { email, name, number },
      }),
    [idsFetch]
  );
  const createUserProfileIfNotExist = useCallback(
    async (data: Partial<UserProfile>): Promise<UserProfile> => {
      try {
        return await idsFetch(apiUrl);
      } catch (error) {
        if (error instanceof ResponseError && error.response.status === 404) {
          return await createUserProfile(data);
        } else {
          throw error;
        }
      }
    },
    [idsFetch, createUserProfile]
  );
  return { profile: data, update: updateUserProfile, revalidate };
}
