import "./antd.less";
import "./index.scss";

import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";

import App from "./App";
import ErrorFallback from "./components/ErrorFallback";
import initIntercom from "./intercom";
import * as serviceWorker from "./serviceWorker";

const history = createBrowserHistory();
const onRedirectCallback = (appState: any) => {
  history.replace(appState?.targetUrl ?? window.location.pathname);
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

if (
  process.env.REACT_APP_MODE &&
  ["production", "demo", "staging"].includes(process.env.REACT_APP_MODE)
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_RELEASE,
    environment: process.env.REACT_APP_MODE,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}
const ProfiledApp = Sentry.withProfiler(App);

// Currently disabled intercom in production to reduce support load.
if (
  process.env.REACT_APP_MODE &&
  [/*"production",*/ "demo", "staging"].includes(process.env.REACT_APP_MODE)
) {
  initIntercom();
}

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
}
history.listen(() => {
  ReactGA.send("pageview");
});

// Fail fast if required Auth0 environment variables are not set.
if (process.env.REACT_APP_AUTH0_DOMAIN === undefined) {
  throw new Error("REACT_APP_AUTH0_DOMAIN not set.");
}
if (process.env.REACT_APP_AUTH0_CLIENT_ID === undefined) {
  throw new Error("REACT_APP_AUTH0_CLIENT_ID not set.");
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <Elements stripe={stripePromise}>
        <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
          <ProfiledApp history={history} />
        </Sentry.ErrorBoundary>
      </Elements>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
