import useIdsFetch from "./useIdsFetch";

export interface Address {
  id: string;
  name: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
}

export default function useAddress(apiUrlPrefix: string) {
  const idsFetch = useIdsFetch();

  async function createDefaultAddress(defaultAddress: Omit<Address, "id">) {
    const address = await idsFetch(`${apiUrlPrefix}/addresses`, {
      method: "POST",
      body: defaultAddress,
    });
    await idsFetch(`${apiUrlPrefix}/default-address`, {
      method: "POST",
      body: { id: address.id },
    });
  }

  async function updateDefaultAddress(
    defaultAddressId: string,
    defaultAddress: Omit<Address, "id">
  ) {
    await idsFetch(`${apiUrlPrefix}/addresses/${defaultAddressId}`, {
      method: "PUT",
      body: defaultAddress,
    });
  }

  return { createDefaultAddress, updateDefaultAddress };
}
