import { Col, Form, Input, Row } from "antd";
import * as React from "react";

import { Address } from "../hooks/useAddress";

const EMPTY_ADDRESS = {
  name: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  postal_code: "",
  country: "",
};

const FORM_LAYOUT = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

interface Props {
  disabled?: boolean;
  required?: boolean;
  address?: Omit<Address, "id"> | undefined;
  onChange: (newAddress: Omit<Address, "id">) => void;
}

export default function AddressForm(props: Readonly<Props>) {
  function getOnChangeForField(field: keyof Address) {
    return function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
      props.onChange({
        ...EMPTY_ADDRESS,
        ...props.address,
        [field]: evt.target.value,
      });
    };
  }

  return (
    <div>
      <Row gutter={16}>
        <Col xs={24} sm={16}>
          <Form.Item label="Street" required={props.required} {...FORM_LAYOUT}>
            <Input
              value={props.address?.line1}
              disabled={props.disabled}
              onChange={getOnChangeForField("line1")}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item label="Apt / Unit / Suite" {...FORM_LAYOUT}>
            <Input
              value={props.address?.line2}
              disabled={props.disabled}
              onChange={getOnChangeForField("line2")}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item label="City" required={props.required} {...FORM_LAYOUT}>
            <Input
              value={props.address?.city}
              disabled={props.disabled}
              onChange={getOnChangeForField("city")}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={4}>
          <Form.Item label="State" required={props.required} {...FORM_LAYOUT}>
            <Input
              value={props.address?.state}
              disabled={props.disabled}
              onChange={getOnChangeForField("state")}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Zip Code"
            required={props.required}
            {...FORM_LAYOUT}
          >
            <Input
              value={props.address?.postal_code}
              disabled={props.disabled}
              onChange={getOnChangeForField("postal_code")}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
