import moment from "moment";
import useSWR from "swr";

import { ParkingProduct } from "./useOrganizationParkingProducts";
import { UserProfile } from "./useUserProfile";
import { ParkingPolicy } from "./useUserPurchases";

const organizationLocationEnforcementPath = (
  organizationId: string,
  locationId: string,
  after: Date,
  before: Date
) =>
  `/organizations/${organizationId}/locations/${locationId}/parking-sessions/?after=${Math.floor(
    after.valueOf() / 1000
  )}&before=${Math.floor(before.valueOf() / 1000)}`;

export interface EnforcementSession {
  code: string;
  status: string;
  valid_from: string;
  valid_to: string;
  user_profile: UserProfile;
  parking_product: ParkingProduct;
  parking_policy: ParkingPolicy;
}

export default function useOrganizationLocationEnforcement({
  organizationId,
  locationId,
  after = moment().startOf("day").toDate(),
  before = moment().endOf("day").toDate(),
  onError,
}: {
  organizationId: string;
  locationId?: string;
  after?: Date;
  before?: Date;
  onError?: (err: Error) => void;
}) {
  const apiPath =
    organizationId && locationId
      ? organizationLocationEnforcementPath(
          organizationId,
          locationId,
          after,
          before
        )
      : null;
  const { data: parkingSessions } = useSWR<EnforcementSession[]>(apiPath, {
    onError,
  });
  return { parkingSessions };
}
