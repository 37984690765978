import { Menu } from "antd";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

const keyToLabel = (key: React.Key) =>
  key
    .toString()
    .split("-")
    .map((_) => {
      return _[0].toUpperCase() + _.substring(1);
    })
    .join(" ");
interface MenuItem {
  key: string;
  icon: React.ReactNode;
  children?: MenuItem[];
}

const Sidebar = ({
  baseUrl,
  items,
}: {
  baseUrl: string;
  items: MenuItem[];
}) => {
  const match = useRouteMatch<{ key: string }>(`${baseUrl}/:key`);
  return (
    <Menu
      style={{ fontSize: "1rem" }}
      theme="dark"
      mode="inline"
      selectedKeys={[match?.params?.key || "no-match"]}
      selectable={false}
      items={items.map(getItem)}
    />
  );

  function getItem(item: MenuItem): MenuItem {
    return getMenuItem(
      item.key,
      item.icon,
      item.children ? item.children.map(getItem) : undefined
    );
  }

  function getMenuItem(
    key: React.Key,
    icon: React.ReactNode,
    children?: MenuItem[] | undefined,
    type?: "group"
  ): MenuItem {
    const label = children ? (
      `${keyToLabel(key)}`
    ) : (
      <Link to={`${baseUrl}/${key}`}>{keyToLabel(key)}</Link>
    );
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }
};

export default Sidebar;
