import { Button, Card, Collapse, Form, Input, Spin } from "antd";
import * as React from "react";

import { getBankAccountPreviewText, Source } from "../../hooks/useWallet";
import notify from "../../utils/notify";

interface Props {
  source: Source | undefined;
  onUpdate: (name: string, description: string) => Promise<void>;
}

export default function UpdateBankPayment(props: Readonly<Props>) {
  const [isFetching, setIsFetching] = React.useState(false);

  async function save(values: { name: string; description: string }) {
    setIsFetching(true);
    try {
      await props.onUpdate(values.name, values.description);
      notify.success(`Successfully updated "${values.name}"`);
    } catch (err) {
      notify.error("Failed to update the account", err);
    } finally {
      setIsFetching(false);
    }
  }

  return (
    <div style={{ maxWidth: "42rem", margin: "0 auto" }}>
      <Spin spinning={props.source === undefined || isFetching}>
        <Card
          key={props.source ? props.source.id : "loading"}
          title="Update Payment Method"
        >
          <Form layout="vertical" initialValues={props.source} onFinish={save}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input
                autoFocus
                placeholder="Any name you want to give this payment method, e.g., Chase Checking"
              />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input.TextArea placeholder="Additional descriptions about this payment method" />
            </Form.Item>

            <Collapse>
              <Collapse.Panel
                key="0"
                header={
                  props.source
                    ? getBankAccountPreviewText(props.source)
                    : "Loading..."
                }
              >
                <Form.Item label="Bank">
                  <Input
                    value={props.source?.stripe_source.data.bank_name || ""}
                    disabled
                  />
                </Form.Item>
                <Form.Item label="Account Holder Name">
                  <Input
                    value={
                      props.source?.stripe_source.data.account_holder_name || ""
                    }
                    disabled
                  />
                </Form.Item>
                <Form.Item label="Routing Number">
                  <Input
                    value={
                      props.source?.stripe_source.data.routing_number || ""
                    }
                    disabled
                  />
                </Form.Item>
                <Form.Item label="Account Number">
                  <Input
                    value={`**********${props.source?.stripe_source.data.last4}`}
                    disabled
                  />
                </Form.Item>
                <div>
                  * For security reasons we do not store your account number or
                  allow you to update your bank info. Create a new payment
                  method if you need to update it.
                </div>
              </Collapse.Panel>
            </Collapse>
            <div style={{ textAlign: "center", marginTop: "2rem" }}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </Card>
      </Spin>
    </div>
  );
}
