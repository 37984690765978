import { Button, Card, Form, Result, Spin } from "antd";
import * as React from "react";
import useSWR from "swr";

import useVehicles, {
  Vehicle,
  VEHICLES_API_PREFIX,
} from "../../hooks/useVehicles";
import notify from "../../utils/notify";
import VehicleInfoFormItems from "./VehicleInfoFormItems";

interface Props {
  vehicleId: string;
  onError: () => void;
  onSuccess: () => void;
}

export default function EditParkerVehicle(props: Readonly<Props>) {
  const {
    data: vehicle,
    error,
    mutate,
  } = useSWR<Vehicle>(`${VEHICLES_API_PREFIX}/${props.vehicleId}`);
  const [isFetching, setIsFetching] = React.useState(false);
  const { updateVehicle } = useVehicles();

  async function edit(values: Omit<Vehicle, "id">) {
    setIsFetching(true);
    try {
      await updateVehicle(props.vehicleId, values);
      notify.success(`Successfully updated vehicle ${values.name}.`);
      await mutate();
      props.onSuccess();
    } catch (err) {
      notify.error("Failed to update the vehicle", err);
    } finally {
      setIsFetching(false);
    }
  }

  if (error) {
    return (
      <Card>
        <Result
          status="error"
          title="Failed to load vehicle"
          subTitle={error.message}
          extra={[
            <Button type="primary" key="console" onClick={props.onError}>
              Back to All Vehicles
            </Button>,
          ]}
        />
      </Card>
    );
  }

  return (
    <div style={{ maxWidth: "42rem", margin: "0 auto" }}>
      <Spin spinning={vehicle === undefined || isFetching}>
        <Card key={vehicle?.id || "loading"} title="Edit Vehicle">
          <Form layout="vertical" initialValues={vehicle} onFinish={edit}>
            <VehicleInfoFormItems />
            <div style={{ textAlign: "center", marginTop: "2rem" }}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </Card>
      </Spin>
    </div>
  );
}
