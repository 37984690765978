import { useCallback } from "react";

import {
  Invitation,
  useInvitation,
  useInvitations,
  useMembership,
  useMemberships,
} from "./useMembers";

export const tenantGroupMembershipsPath = (tenantGroupId: string) =>
  `/tenant-groups/${tenantGroupId}/members/`;
export const tenantGroupInvitationsPath = (tenantGroupId: string) =>
  `/tenant-groups/${tenantGroupId}/invitations/`;
export const tenantGroupMembershipPath = (
  tenantGroupId: string,
  membershipId: string
) => `${tenantGroupMembershipsPath(tenantGroupId)}${membershipId}`;
export const tenantGroupInvitationPath = (
  tenantGroupId: string,
  invitationId: string
) => `${tenantGroupInvitationsPath(tenantGroupId)}${invitationId}`;

export const useTenantGroupMemberships = ({
  tenantGroupId,
  onError = () => {},
}: {
  tenantGroupId: string;
  onError?: (err: Error) => void;
}) => {
  const apiUrl = tenantGroupMembershipsPath(tenantGroupId);
  return useMemberships({ apiUrl, onError });
};

export const useTenantGroupMembership = ({
  tenantGroupId,
  membershipId,
  onError = () => {},
}: {
  tenantGroupId?: string;
  membershipId?: string;
  onError?: (err: Error) => void;
}) => {
  const apiUrl =
    tenantGroupId && membershipId
      ? tenantGroupMembershipPath(tenantGroupId, membershipId)
      : undefined;
  return useMembership({ apiUrl, onError });
};

export const useTenantGroupInvitations = ({
  tenantGroupId,
  onError = () => {},
}: {
  tenantGroupId: string;
  onError?: (err: Error) => void;
}) => {
  const apiUrl = tenantGroupInvitationsPath(tenantGroupId);
  return useInvitations({ apiUrl, onError });
};

export const useTenantGroupInvitation = ({
  tenantGroupId,
  invitationId,
  invitation,

  onError = () => {},
}: {
  tenantGroupId: string;
  invitationId?: string;
  invitation?: Invitation;

  onError?: (err: Error) => void;
}) => {
  const apiUrl = invitationId
    ? tenantGroupInvitationPath(tenantGroupId, invitationId)
    : null;
  const { update, ...rest } = useInvitation({ apiUrl, onError });
  const updateById = useCallback(
    (data: Partial<Invitation>, invitationId: string) =>
      update(data, tenantGroupInvitationPath(tenantGroupId, invitationId)),
    [tenantGroupId, update]
  );
  return {
    ...rest,
    update: updateById,
  };
};
