import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentMethodCreateParams } from "@stripe/stripe-js";
import useSWR from "swr";

import useIdsFetch from "./useIdsFetch";
import { AuthorizedPayment } from "./useTenantGroupAuthorizedPayments";
import { PaymentMethod, Source } from "./useWallet";

const walletPath = `/me/wallet/`;
const paymentMethodsPath = `${walletPath}payment-methods/`;
const sourcesPath = `${walletPath}sources/`;
const authorizedPaymentsPath = `${walletPath}authorized-payments/`;

export const useUserWallet = ({
  onError,
}: {
  onError?: (err: Error) => void;
} = {}) => {
  const idsFetch = useIdsFetch();
  const { data, mutate } = useSWR(walletPath, { onError });
  const create = () => idsFetch(walletPath, { method: "POST" });
  return { wallet: data, revalidate: () => mutate(), create };
};

export const useUserPaymentMethods = ({
  onError,
}: {
  onError?: (err: Error) => void;
} = {}) => {
  const { data: paymentMethods, mutate } = useSWR<PaymentMethod[]>(
    paymentMethodsPath,
    {
      onError,
    }
  );

  const stripe = useStripe();
  const elements = useElements();
  const idsFetch = useIdsFetch();

  async function createNewPaymentMethod(
    name: string,
    description: string = "",
    billingAddress?: PaymentMethodCreateParams.BillingDetails
  ) {
    if (stripe === null) {
      throw new Error("BUG: stripe is null");
    }

    const cardElement = elements?.getElement(CardElement);
    if (!cardElement) {
      throw new Error("BUG: could not find stripe card element");
    }

    const res = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: billingAddress,
    });
    if (res.error) {
      throw res.error;
    }

    const paymentMethod = await idsFetch("/me/wallet/payment-methods/", {
      method: "POST",
      body: {
        name,
        description,
        stripe_payment_method: {
          data: res.paymentMethod,
        },
      },
    });
    mutate();
    return paymentMethod;
  }

  return { paymentMethods, createNewPaymentMethod, mutate };
};

export const useUserSources = ({
  onError,
}: {
  onError?: (err: Error) => void;
} = {}) => {
  const { data: sources, mutate } = useSWR<Source[]>(sourcesPath, {
    onError,
  });
  return { sources, mutate };
};

export const useUserAuthorizedPayments = ({
  onError,
}: {
  onError?: (err: Error) => void;
} = {}) => {
  const { data: authorizedPayments, mutate } = useSWR<AuthorizedPayment[]>(
    authorizedPaymentsPath,
    { onError }
  );
  return { authorizedPayments, mutate };
};
