import _ from "lodash";
import useSWR from "swr";

import useIdsFetch from "./useIdsFetch";
import { Location } from "./useOrganizationLocations";
import { UserProfile } from "./useUserProfile";

export interface Invitation {
  id: string;
  email: string;
  name?: string;
  claimed_at?: string;
  role_names: string[];
}

export interface Membership {
  id: string;
  user_id: string;
  user_profile: UserProfile;
  role_names: string[];
  allowed_locations: Pick<Location, "id">[];
  has_locations_filtered: boolean;
}

export const useMemberships = ({
  apiUrl,
  onError = () => {},
}: {
  apiUrl: string;
  onError?: (err: Error) => void;
}) => {
  const { data, mutate } = useSWR<Membership[]>(apiUrl, { onError });
  return {
    memberships: data,
    revalidate: () => mutate(),
  };
};

export const useMembership = ({
  apiUrl,
  onError = () => {},
}: {
  apiUrl?: string;
  onError?: (err: Error) => void;
}) => {
  const { data, mutate } = useSWR<Membership>(apiUrl ? apiUrl : null, {
    onError,
  });
  const idsFetch = useIdsFetch();
  const remove = async (path?: string) => {
    if (!path && !apiUrl) throw new Error("either path or apiUrl is required.");
    await idsFetch(path || apiUrl!, { method: "DELETE" });
    mutate();
  };
  const update = async ({
    role_names,
    allowed_locations,
  }: Pick<Membership, "role_names" | "allowed_locations">) => {
    if (!apiUrl) throw new Error("apiUrl is required.");
    const res = await idsFetch(apiUrl, {
      method: "PUT",
      body: { role_names: _.compact(role_names), allowed_locations },
    });
    mutate(res);
    return res;
  };
  return {
    membership: data,
    revalidate: () => mutate(),
    remove,
    update,
  };
};

export const useInvitations = ({
  apiUrl,
  onError = () => {},
}: {
  apiUrl: string;
  onError?: (err: Error) => void;
}) => {
  const idsFetch = useIdsFetch();
  const { data, mutate } = useSWR<Invitation[]>(apiUrl, { onError });
  const create = async (data: Partial<Invitation>) => {
    return idsFetch(apiUrl, { method: "POST", body: data });
  };
  return {
    memberships: data,
    create,
    revalidate: () => mutate(),
  };
};

export const useInvitation = ({
  apiUrl,
  onError = () => {},
}: {
  apiUrl: string | null;
  onError?: (err: Error) => void;
}) => {
  const idsFetch = useIdsFetch();
  const { data, mutate } = useSWR<Invitation>(apiUrl, { onError });
  const update = async (data: Partial<Invitation>, apiUrl: string) => {
    const invitation = await idsFetch(apiUrl, { method: "PUT", body: data });
    mutate(invitation);
    return invitation;
  };
  return {
    invitation: data,
    update,
    revalidate: () => mutate(),
  };
};
