import {
  BellOutlined,
  BookOutlined,
  DeploymentUnitOutlined,
  DollarOutlined,
  HistoryOutlined,
  HomeOutlined,
  IdcardOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import Sidebar from "./Sidebar";

const OrganizationSidebar = ({ baseUrl }: { baseUrl: string }) => {
  return (
    <Sidebar
      baseUrl={baseUrl}
      items={[
        { key: "locations", icon: <HomeOutlined /> },
        { key: "enforcement", icon: <BellOutlined /> },
        {
          key: "parking-products",
          icon: <ShoppingCartOutlined />,
        },
        {
          key: "tenant-groups",
          icon: <DeploymentUnitOutlined />,
        },
        {
          key: "parkers",
          icon: <TeamOutlined />,
          children: [
            { key: "parker-details", icon: <IdcardOutlined /> },
            { key: "parker-history", icon: <HistoryOutlined /> },
          ],
        },
        {
          key: "payout-accounts",
          icon: <DollarOutlined />,
        },
        { key: "accounting", icon: <BookOutlined /> },
        { key: "members", icon: <TeamOutlined /> },
        { key: "settings", icon: <SettingOutlined /> },
      ]}
    />
  );
};

export default OrganizationSidebar;
