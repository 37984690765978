import _ from "lodash";
import useSWR, { mutate } from "swr";

import useIdsFetch from "./useIdsFetch";
import { Location } from "./useOrganizationLocations";
import { TenantGroup } from "./useTenantGroup";
import { TimeOfUseRule } from "./useUserPurchases";

export interface Price {
  id: string;
  name: string;
  minimum_amount?: number;
  maximum_amount?: number;
  recurring_interval: string;
  recurring_count: number;
  recurring_iterations: number | null;
  is_time_of_use_customizable: boolean;
  is_max_days_customizable: boolean;
  is_max_sessions_customizable: boolean;
  days_in_billing_period: number;
  allowed_time_of_use: Partial<TimeOfUseRule>[];
  allowed_days: number[];
  parking_product: ParkingProduct;
  maximum_active_purchases?: number;
  maximum_session_duration_minutes?: number;
  active_subscriptions_count: number;
  has_avaliable_inventory: boolean;
  discounted_phase: {
    coupon: Coupon;
    iterations: number;
  };
  rates: Rate[];
  amount_per_day: Record<number, number>;
  amount_per_session: number;
}

export interface Rate {
  id: string;
  currency: string;
  amount: number;
  time_of_use_rules: TimeOfUseRule[];
  time_of_use_freqs: string[];
  days_of_week: number[];
}

export interface Coupon {
  id: string;
  creation_kwargs?: {
    percent_off?: number;
    amount_off?: number;
    currency?: string;
  };
}

export interface ParkingProduct {
  id: string;
  name: string;
  description: string;
  restrictions: string;
  isDeleted?: boolean;
  is_purchaseable: boolean;
  is_for_direct_sale: boolean;
  access_mode: "qr_code" | "parker_session";
  is_pending_review: boolean;
  is_disabled: boolean;
  disabled_at: string;
  disabled_until: string;
  location: Location;
  prices: Price[];
}

export interface Event {
  id: string;
  name: string;
  start_at: string;
  end_at: string;
  parking_products: ParkingProduct[];
}
export const centsToDollars = (val: number) => (val / 100).toFixed(2) || null;
export const dollarsToCents = (val: string) =>
  (parseFloat(val) * 100).toFixed() || null;

export const fullMonthly = "Full Monthly";
export const flexibleMonthlyDaysOfWeek =
  "Flexible Monthly - Recurring Days of Week";
export const flexibleMonthlyNumberOfDays =
  "Flexible Monthly - Recurring Number of Days";
export const prepaidNumberOfDays = "Prepaid Vouchers - Number of Days";
export const prepaidNumberOfSessions = "Prepaid Vouchers - Number of Sessions";

export const defaultParkingPrices = [
  {
    name: fullMonthly,
    recurring_interval: "month",
    recurring_count: 1,
    recurring_iterations: undefined,
    is_time_of_use_customizable: false,
  },
  {
    name: flexibleMonthlyDaysOfWeek,
    recurring_interval: "week",
    recurring_count: 4,
    recurring_iterations: undefined,
    is_time_of_use_customizable: true,
  },
  {
    name: flexibleMonthlyNumberOfDays,
    recurring_interval: "week",
    recurring_count: 4,
    recurring_iterations: undefined,
    is_time_of_use_customizable: false,
    is_max_days_customizable: true,
  },
  {
    name: prepaidNumberOfDays,
    recurring_interval: "week",
    recurring_count: 4,
    recurring_iterations: 1,
    is_time_of_use_customizable: false,
    is_max_days_customizable: true,
  },
  {
    name: prepaidNumberOfSessions,
    recurring_interval: "week",
    recurring_count: 4,
    recurring_iterations: 1,
    is_time_of_use_customizable: false,
    is_max_sessions_customizable: true,
    maximum_session_duration_minutes: 360,
  },
];

const parkingProductsPath = (organizationId: string) =>
  `/organizations/${organizationId}/parking-products/`;
const parkingProductPath = (organizationId: string, parkingProductId: string) =>
  `${parkingProductsPath(organizationId)}${parkingProductId}/`;
const parkingProductPricesPath = (
  organizationId: string,
  parkingProductId: string
) => `${parkingProductPath(organizationId, parkingProductId)}prices/`;
const parkingProductPricePath = (
  organizationId: string,
  parkingProductId: string,
  priceId: string
) => `${parkingProductPricesPath(organizationId, parkingProductId)}${priceId}/`;

const eventsPath = (organizationId: string) =>
  `/organizations/${organizationId}/parking-products/events/`;
const eventPath = (organizationId: string, eventId: string) =>
  `${eventsPath(organizationId)}${eventId}/`;

export const useParkingProductPrices = ({
  organizationId,
  parkingProductId,
  onError,
}: {
  organizationId: string;
  parkingProductId: string;
  onError?: (err: Error) => void;
}) => {
  const apiPath = parkingProductPricesPath(organizationId, parkingProductId);
  const { data, mutate } = useSWR<Price[]>(apiPath, { onError });
  return { prices: data, revalidate: () => mutate() };
};

export const useParkingProductPrice = ({
  organizationId,
  parkingProductId,
  priceId,
  onError,
}: {
  organizationId: string;
  parkingProductId: string;
  priceId?: string;
  onError?: (err: Error) => void;
}) => {
  const idsFetch = useIdsFetch();
  const apiPath = priceId
    ? parkingProductPricePath(organizationId, parkingProductId, priceId)
    : null;
  const { data, mutate } = useSWR<Price>(apiPath, { onError });
  const updatePrice = async (data: Partial<Price>): Promise<Price> => {
    if (!apiPath) {
      throw new Error("PriceId is required");
    }
    const updated = await idsFetch(apiPath, { method: "PUT", body: data });
    mutate(updated);
    return updated;
  };
  return { price: data, revalidate: () => mutate(), updatePrice };
};

export const useParkingProducts = ({
  organizationId,
  onError,
}: {
  organizationId: string;
  onError?: (err: Error) => void;
}) => {
  const idsFetch = useIdsFetch();
  const apiPath = parkingProductsPath(organizationId);
  const { data, error, mutate } = useSWR<ParkingProduct[]>(apiPath, {
    onError,
  });
  const createParkingProduct = async (
    parkingProduct: Partial<ParkingProduct>,
    price: Partial<Price>
  ): Promise<ParkingProduct> => {
    const created = await idsFetch(apiPath, {
      method: "POST",
      body: { ...parkingProduct, prices: [price] },
    });
    mutate();
    return created;
  };
  const deleteParkingProduct = async (productId: string) => {
    await idsFetch(parkingProductPath(organizationId, productId), {
      method: "DELETE",
    });
    await mutate();
  };
  return {
    parkingProducts: data,
    error,
    revalidate: () => mutate,
    createParkingProduct,
    deleteParkingProduct,
  };
};

export const useEvents = ({
  organizationId,
  onError,
}: {
  organizationId: string;
  onError?: (err: Error) => void;
}) => {
  const idsFetch = useIdsFetch();
  const apiPath = eventsPath(organizationId);
  const { data, error, mutate } = useSWR<Event[]>(apiPath, {
    onError,
  });
  const createEvent = async (
    event: Partial<Omit<Event, "id">>
  ): Promise<Event> => {
    const created = await idsFetch(apiPath, {
      method: "POST",
      body: { ...event },
    });
    mutate();
    return created;
  };
  const deleteEvent = async (eventId: string) => {
    await idsFetch(eventPath(organizationId, eventId), {
      method: "DELETE",
    });
    await mutate();
  };
  return {
    events: data,
    error,
    revalidate: () => mutate,
    createEvent,
    deleteEvent,
  };
};

export const useParkingProduct = ({
  organizationId,
  parkingProductId,
  onError,
}: {
  organizationId: string;
  parkingProductId: string;
  onError?: (err: Error) => void;
}) => {
  const idsFetch = useIdsFetch();
  const { prices } = useParkingProductPrices({
    organizationId,
    parkingProductId,
    onError,
  });
  const defaultPrice = _.first(prices);
  const { updatePrice } = useParkingProductPrice({
    organizationId,
    parkingProductId,
    priceId: defaultPrice?.id,
    onError,
  });
  const apiPath = parkingProductPath(organizationId, parkingProductId);
  const { data, mutate: mutateItem } = useSWR<ParkingProduct>(apiPath, {
    onError,
  });
  const deleteParkingProduct = async () => {
    await idsFetch(apiPath, {
      method: "DELETE",
    });
    mutateItem();
    mutate(parkingProductsPath(organizationId), (existing: ParkingProduct[]) =>
      _.reject(existing, _.matches({ id: parkingProductId }))
    );
  };
  const updateParkingProduct = async (
    data: Partial<ParkingProduct>
  ): Promise<ParkingProduct> => {
    const updated = await idsFetch(apiPath, {
      method: "PUT",
      body: data,
    });
    mutateItem(updated);
    return updated;
  };
  return {
    parkingProduct: data,
    defaultPrice,
    updatePrice,
    revalidate: () => mutateItem(),
    deleteParkingProduct,
    updateParkingProduct,
  };
};

export interface Invitation {
  id: string;
  email: string;
  name?: string;
  parking_product: ParkingProduct;
}
export const useParkingProductInvitations = ({
  organizationId,
  onError = () => {},
}: {
  organizationId: string;
  onError?: (err: Error) => void;
}) => {
  const idsFetch = useIdsFetch();
  const path = `/organizations/${organizationId}/parking-products/invitations/`;
  const pathItem = (invitationId: string) => `${path}${invitationId}/`;
  const { data: invitations, mutate } = useSWR<Invitation[]>(path, { onError });
  const createInvitation =
    (parkingProductId?: string) =>
    async (data: Partial<Invitation>): Promise<Invitation> => {
      return await idsFetch(path, {
        method: "POST",
        body: { ...data, parking_product: { id: parkingProductId } },
      });
    };
  const deleteInvitation = async (invitationId: string) => {
    await idsFetch(pathItem(invitationId), { method: "DELETE" });
    mutate();
  };
  return {
    invitations,
    revalidate: () => mutate(),
    create: createInvitation,
    remove: deleteInvitation,
  };
};

export interface ParkingProductMembership {
  id: string;
  from_tenant_group: TenantGroup;
  parking_product: ParkingProduct;
}
export const useParkingProductMemberships = ({
  organizationId,
  onError,
}: {
  organizationId: string;
  onError: (err: Error) => void;
}) => {
  const idsFetch = useIdsFetch();
  const path = `/organizations/${organizationId}/parking-products/memberships/`;
  const pathItem = (membershipId: string) => `${path}${membershipId}/`;
  const { data: members, mutate } = useSWR<ParkingProductMembership[]>(path, {
    onError,
  });
  const remove = async (membershipId: string) => {
    await idsFetch(pathItem(membershipId), { method: "DELETE" });
    mutate();
  };
  return {
    members,
    remove,
    revalidate: () => mutate(),
  };
};
