import { ExportOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, ButtonProps, Col, Empty, Input, List, Row, Spin } from "antd";
import { ColumnType, TableProps } from "antd/lib/table";
import { GetRowKey } from "antd/lib/table/interface";
import _ from "lodash";
import Papa from "papaparse";
import pluralize from "pluralize";
import queryString from "query-string";
import * as React from "react";
import { useHistory } from "react-router-dom";

import VerticalSpace from "../VerticalSpace";
import DataRow from "./DataRow";
import HeaderRow from "./HeaderRow";

export const borderRadius = 5;
export const rowPadding = "0.5rem 1rem";

const SEARCH_PARAM_KEY = "q";

interface ResponsiveListProps<T> extends TableProps<T> {
  noDataDescription?: React.ReactNode;
  onExport?: (dataSources: Readonly<T[]>) => Promise<void>;
}

export default function ResponsiveList<T extends object>(
  props: Readonly<ResponsiveListProps<T>>
) {
  const history = useHistory();
  const [search, setSearch] = React.useState("");

  const getSearchStringFromUrl = React.useCallback(() => {
    const queries = queryString.parse(history.location.search);
    const values = queries[SEARCH_PARAM_KEY];
    const value = _.isArray(values) ? values[0] : values;
    return value || "";
  }, [history.location.search]);

  const updateSearchInUrl = React.useCallback(
    (search: string) => {
      if (search === getSearchStringFromUrl()) {
        return;
      }
      const queries = queryString.parse(history.location.search);
      if (_.isEmpty(search)) {
        delete queries[SEARCH_PARAM_KEY];
      } else {
        queries[SEARCH_PARAM_KEY] = search;
      }
      history.push(
        queryString.stringifyUrl({
          url: history.location.pathname,
          query: queries,
        })
      );
    },
    [getSearchStringFromUrl, history]
  );

  React.useEffect(() => {
    setSearch(getSearchStringFromUrl());
  }, [history.location.search, getSearchStringFromUrl]);

  const timer = React.useRef<NodeJS.Timeout>();
  React.useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      updateSearchInUrl(search);
    }, 1000);
    return function cancelUrlUpdate() {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [search, updateSearchInUrl]);

  // Compute and cache a string for each row for search matching.
  const dataSourceSearchStrings = React.useMemo(
    () =>
      _.map(props.dataSource, (row: T, rowIndex: number) =>
        _.map(props.columns, (column: ColumnType<T>) =>
          getSearchString(row, rowIndex, column)
        ).join(" ")
      ),
    [props.columns, props.dataSource]
  );

  // Cache rows filtered by search string.
  const filteredRows = React.useMemo(() => {
    if (_.isEmpty(search)) {
      return props.dataSource;
    }
    const regex = getSearchRegexp(search);
    return _.filter(
      props.dataSource,
      (_row: T, rowIndex: number) =>
        dataSourceSearchStrings[rowIndex].match(regex) !== null
    );
  }, [search, props.dataSource, dataSourceSearchStrings]);

  return (
    <VerticalSpace>
      <Row gutter={10}>
        <Col flex="auto">
          <Input
            prefix={<SearchOutlined />}
            allowClear
            autoFocus
            suffix={
              <span style={{ color: "#aaa" }}>
                {pluralize("row", filteredRows?.length || 0, true)}
              </span>
            }
            placeholder="Search for anything in the list below. Use double quotes for exact match."
            value={search}
            onChange={(evt) => setSearch(evt.target.value)}
          />
        </Col>
        {props.onExport && (
          <Col flex="none">
            <ExportButton
              style={{ float: "right" }}
              onExport={() => props.onExport!(filteredRows || [])}
              disabled={filteredRows === undefined || filteredRows.length <= 0}
            />
          </Col>
        )}
      </Row>
      <List
        style={{
          backgroundColor: "white",
          boxShadow: "0 1px 1px rgba(51, 65, 78, 0.3)",
          borderRadius,
          ...props.style,
        }}
        itemLayout="vertical"
      >
        <Col key="hide-for-mobile" xs={0} md={24}>
          {props.columns && <HeaderRow columns={props.columns} />}
        </Col>
        <Spin spinning={!!props.loading} size="large">
          {props.loading ||
          props.dataSource === undefined ||
          props.dataSource.length <= 0 ? (
            <Empty
              style={{ padding: "5rem 1rem" }}
              description={
                props.loading
                  ? "Loading..."
                  : props.noDataDescription || "No data."
              }
            />
          ) : filteredRows === undefined || filteredRows.length <= 0 ? (
            <Empty style={{ padding: "5rem 1rem" }} description="No data." />
          ) : (
            filteredRows.map((record: T, rowIndex: number) => (
              <DataRow
                key={getRowKey(record, props.rowKey, rowIndex)}
                rowKeyString={getRowKey(record, props.rowKey, rowIndex)}
                record={record}
                rowIndex={rowIndex}
                columns={props.columns!}
              />
            ))
          )}
        </Spin>
      </List>
    </VerticalSpace>
  );
}

export function getColumnKey<T extends object>(
  column: ColumnType<T>,
  columnIndex: number
): React.Key {
  if (column.key !== undefined) {
    return column.key;
  }
  const { dataIndex } = column;
  if (typeof dataIndex === "string" || typeof dataIndex === "number") {
    return dataIndex;
  }
  if (dataIndex !== undefined) {
    return dataIndex.join("|");
  }
  return columnIndex;
}

export function getRowKey<T extends object>(
  record: T,
  rowKey: string | GetRowKey<T> | undefined,
  rowIndex: number
): React.Key {
  if (rowKey === undefined) {
    return rowIndex;
  }
  if (typeof rowKey === "string") {
    return _.toString(record[rowKey as keyof T]);
  }
  return rowKey(record, rowIndex);
}

function getSearchString<T extends object>(
  row: T,
  rowIndex: number,
  column: ColumnType<T>
): string {
  const cellValue = _.get(row, column.dataIndex as keyof T);

  let cellContent;
  if (_.isFunction(column.render)) {
    cellContent = column.render(cellValue, row, rowIndex);
  } else {
    cellContent = cellValue;
  }

  let cellString: string;
  if (_.isObject(cellContent)) {
    try {
      cellString = JSON.stringify(cellContent);
    } catch {
      cellString = _.toString(cellValue);
    }
  } else {
    cellString = _.toString(cellContent);
  }

  // Get rid of line breaks so regexp searches can cross lines.
  return cellString.split("\n").join(" ");
}

function getSearchRegexp(searchString: string): RegExp {
  let str = searchString.trim();

  // If search string includes uppercase letters, do case-sensitive match,
  // otherwise do case-insensitive match.
  const flag = str.toLowerCase() === str ? "i" : "";

  // Exact match
  if (str.startsWith('"')) {
    str = str.slice(1);
    if (str.endsWith('"')) {
      const exactSearch = _.escapeRegExp(str.slice(0, str.length - 1));
      return new RegExp(`(^|\\W)${exactSearch}(\\W|$)`, flag);
    }
    // Assume the intention is exact match, so remove the starting quote for now.
    return new RegExp(_.escapeRegExp(str), flag);
  }

  // Regular expression
  if (str.startsWith("\\")) {
    str = str.slice(1);
    while (str.length > 0) {
      try {
        return new RegExp(str, flag);
      } catch {
        // Failed to parse regexp. Maybe input is still in progress.
        // Remove potential illegal characters near the end and try again.
        str = str.slice(0, str.length - 1);
      }
    }
  }

  return new RegExp(_.escapeRegExp(str), flag);
}

interface ExportButtonProps extends ButtonProps {
  onExport?: () => Promise<void>;
}

function ExportButton(props: Readonly<ExportButtonProps>) {
  const [isDownloading, setIsDownloading] = React.useState(false);

  async function download() {
    if (props.onExport === undefined) {
      return;
    }
    setIsDownloading(true);
    try {
      await props.onExport();
    } finally {
      setIsDownloading(false);
    }
  }

  return (
    <Button
      icon={<ExportOutlined />}
      onClick={download}
      loading={isDownloading}
      disabled={props.disabled || props.onExport === undefined || isDownloading}
      {...props}
    >
      Detailed Export
    </Button>
  );
}

export function downloadFile(
  fileName: string,
  fileContent: string,
  // Type of the file content, e.g., "data:text/csv;charset=utf-8;" for csv.
  contentType: string
) {
  // See https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
  var blob = new Blob([fileContent], { type: contentType });
  var objectUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.setAttribute("href", objectUrl);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);

  link.click();
}

export function getCsvContent(
  fields: string[],
  data: { [key: string]: string | number }[]
): string {
  if (data === undefined || data.length <= 0) {
    return "";
  }
  return Papa.unparse({ fields, data });
}
