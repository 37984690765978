import useSWR from "swr";

import useIdsFetch from "./useIdsFetch";

interface StripeAccountData {
  past_due: string[];
  details_submitted: boolean;
  charges_enabled: boolean;
  business_type: string | null;
  external_accounts: {
    total_count: number;
  };
  requirements: {
    currently_due: string[];
  };
  capabilities: {
    card_payments: "active" | "inactive";
    transfers: "active" | "inactive";
  };
}

interface StripeAccount {
  id: string;
  account_id: string;
  data: StripeAccountData;
}

export interface PayoutAccount {
  id: string;
  name: string;
  description: string;
  isDeleted?: boolean;
  stripe_account: StripeAccount;
}

const accountsPath = ({ organizationId }: { organizationId: string }) =>
  `/organizations/${organizationId}/payout-accounts/`;
const accountPath = ({
  organizationId,
  payoutAccountId,
}: {
  organizationId: string;
  payoutAccountId: string;
}) => `${accountsPath({ organizationId })}${payoutAccountId}/`;

export const usePayoutAccounts = ({
  organizationId,
  onError,
}: {
  organizationId: string;
  onError?: (err: Error) => void;
}) => {
  const apiUrl = accountsPath({ organizationId });
  const idsFetch = useIdsFetch();
  const { data, mutate } = useSWR<PayoutAccount[]>(apiUrl, { onError });
  const create = async (values: Partial<PayoutAccount>) => {
    const created = await idsFetch(apiUrl, { method: "POST", body: values });
    mutate();
    return created;
  };
  return { payoutAccounts: data, revalidate: () => mutate(), create };
};

export const usePayoutAccount = ({
  organizationId,
  payoutAccountId,
  onError,
}: {
  organizationId: string;
  payoutAccountId?: string;
  onError?: (err: Error) => void;
}) => {
  const idsFetch = useIdsFetch();
  const apiPath = (id?: string) => {
    if (!id && !payoutAccountId) throw new Error("payoutAccountId is required");
    return accountPath({
      organizationId,
      payoutAccountId: id || payoutAccountId || "",
    });
  };
  const { data, mutate } = useSWR<PayoutAccount>(
    payoutAccountId ? accountPath({ organizationId, payoutAccountId }) : null,
    { onError }
  );
  const remove = async (id?: string) => {
    const apiUrl = apiPath(id);
    await idsFetch(apiUrl, { method: "DELETE" });
    mutate();
  };
  const update = async (values: Partial<PayoutAccount>) => {
    const apiUrl = apiPath();
    const updated = await idsFetch(apiUrl, {
      method: "PUT",
      body: values,
    });
    mutate();
    return updated;
  };
  const createLoginLink = async ({
    id,
    redirectUrl,
  }: {
    id?: string;
    redirectUrl: string;
  }) => {
    const apiUrl = apiPath(id);
    return await idsFetch(`${apiUrl}login-link/`, {
      method: "POST",
      body: { redirect_url: redirectUrl },
    });
  };
  const createAccountLink = async ({
    type,
    id,
    refreshUrl,
    returnUrl,
  }: {
    type: string;
    id?: string;
    refreshUrl: string;
    returnUrl: string;
  }) => {
    const apiUrl = apiPath(id);
    return await idsFetch(`${apiUrl}account-link/`, {
      method: "POST",
      body: {
        refresh_url: refreshUrl,
        return_url: returnUrl,
        type,
      },
    });
  };
  return {
    payoutAccount: data,
    revalidate: () => mutate(),
    remove,
    update,
    createAccountLink,
    createLoginLink,
  };
};
