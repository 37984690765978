import * as React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

import NotFoundContent from "../NotFoundContent";
import AddParkerPaymentMethod from "./AddParkerPaymentMethod";
import PaymentMethodItem from "./PaymentMethodItem";
import UpdateParkerBankAccount from "./UpdateParkerBankAccount";
import UpdateParkerCardPayment from "./UpdateParkerCardPayment";
import VerifyParkerBankAccount from "./VerifyParkerBankAccount";
import WalletView from "./WalletView";

export default function ParkerWallet() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <WalletView />
      </Route>
      <Route exact path={`${path}/add`}>
        <AddParkerPaymentMethod />
      </Route>
      <Route path={`${path}/card-payment/:paymentMethodId`}>
        <CardPaymentItemRouter />
      </Route>
      <Route path={`${path}/bank-payment/:sourceId`}>
        <BankPaymentItemRouter />
      </Route>
      <Route>
        <NotFoundContent />
      </Route>
    </Switch>
  );
}

function CardPaymentItemRouter() {
  const { path } = useRouteMatch();
  const { paymentMethodId } = useParams<{ paymentMethodId: string }>();

  return (
    <Switch>
      <Route exact path={path}>
        <PaymentMethodItem paymentMethodId={paymentMethodId} />
      </Route>
      <Route exact path={`${path}/edit`}>
        <UpdateParkerCardPayment paymentMethodId={paymentMethodId} />
      </Route>
      <Route>
        <NotFoundContent />
      </Route>
    </Switch>
  );
}

function BankPaymentItemRouter() {
  const { path } = useRouteMatch();
  const { sourceId } = useParams<{ sourceId: string }>();

  return (
    <Switch>
      <Route exact path={`${path}/edit`}>
        <UpdateParkerBankAccount sourceId={sourceId} />
      </Route>
      <Route exact path={`${path}/verify`}>
        <VerifyParkerBankAccount sourceId={sourceId} />
      </Route>
      <Route>
        <NotFoundContent />
      </Route>
    </Switch>
  );
}
