import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export default function NotFoundContent() {
  return (
    <Result
      status="404"
      title="404 Page Not Found"
      subTitle="Got lost in your parking lot?"
      extra={
        <Button type="primary">
          <Link to="/">Take me home</Link>
        </Button>
      }
    />
  );
}
