import useSwr from "swr";
import useIdsFetch from "./useIdsFetch";
import { ParkingProduct } from "./useOrganizationParkingProducts";
import { UserProfile } from "./useUserProfile";

export interface TenantGroup {
  id: string;
  name: string;
  representative: UserProfile;
}

export const useTenantGroup = ({
  tenantGroupId,
}: {
  tenantGroupId?: string;
}) => {
  const idsFetch = useIdsFetch();
  const path = `/tenant-groups/${tenantGroupId}/`;
  const key = path ? path : null;
  const { data: tenantGroup, error, mutate } = useSwr<TenantGroup>(key);
  const modifyTenantGroup = async (data: TenantGroup) => {
    const tenantGroup = await idsFetch(path, {
      method: "PUT",
      body: data,
    });
    mutate(tenantGroup);
    return tenantGroup;
  };
  return {
    tenantGroup,
    error: !key ? new Error("tenantGroupId is required.") : error,
    modify: modifyTenantGroup,
  };
};

export const useParkingProducts = ({
  tenantGroupId,
  onError,
}: {
  tenantGroupId?: string;
  onError?: (err: Error) => void;
}) => {
  const path = `/tenant-groups/${tenantGroupId}/parking-products/`;
  const { data: parkingProducts, mutate } = useSwr<ParkingProduct[]>(path, {
    onError,
  });
  return {
    parkingProducts,
    revalidate: () => mutate(),
  };
};
