import { Card, Col, Modal, PageHeader, Row } from "antd";
import React, { useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Invitation } from "../hooks/useMembers";
import {
  tenantGroupMembershipPath,
  useTenantGroupInvitation,
  useTenantGroupInvitations,
  useTenantGroupMembership,
  useTenantGroupMemberships,
} from "../hooks/useTenantGroupMemberships";
import notify from "../utils/notify";
import { Done, Invite, Members, Setup } from "./Memberships";
import NotFoundContent from "./NotFoundContent";

const TenantGroupMembersList = ({
  tenantGroupId,
  onCreateClick,
}: {
  tenantGroupId: string;
  onCreateClick: () => void;
}) => {
  const { memberships, revalidate } = useTenantGroupMemberships({
    tenantGroupId,
  });
  const { remove } = useTenantGroupMembership({});
  return (
    <Members
      members={memberships}
      isLoading={memberships ? false : true}
      onDeactivate={async (membershipId: string) => {
        await remove(tenantGroupMembershipPath(tenantGroupId, membershipId));
        await revalidate();
      }}
      onManageRole={() =>
        Modal.info({
          title: "Member Roles",
          content: (
            <div>
              <p>Member Roles are not supported for Tenant Groups.</p>
            </div>
          ),
        })
      }
      onCreate={onCreateClick}
    />
  );
};

const TenantGroupMemberInvite = ({
  create,
  update,
  onDone,
}: {
  create: (data: Partial<Invitation>) => Promise<Invitation>;
  update: (
    data: Partial<Invitation>,
    invitationId: string
  ) => Promise<Invitation>;
  onDone: () => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invitation, setInvitation] = useState<Invitation>();
  const [done, setDone] = useState<boolean>();

  const createInvitation = async (data: Partial<Invitation>) => {
    try {
      setIsLoading(true);
      const invitation = await create(data);
      setInvitation(invitation);
    } catch (err) {
      notify.error("Failed to create invitation", err);
    } finally {
      setIsLoading(false);
    }
  };

  if (done) {
    return (
      <Done
        onDone={onDone}
        onNewInvitation={() => {
          setInvitation(undefined);
          setDone(undefined);
        }}
      />
    );
  }

  if (invitation) {
    return <Invite invitation={invitation} onNext={() => setDone(true)} />;
  }

  return <Setup isLoading={isLoading} onFinish={createInvitation} />;
};

const TenantGroupMembers = ({ tenantGroupId }: { tenantGroupId: string }) => {
  const { path, url } = useRouteMatch();
  const { create } = useTenantGroupInvitations({ tenantGroupId });
  const { update } = useTenantGroupInvitation({ tenantGroupId });
  return (
    <Switch>
      <Route
        exact
        path={path}
        render={({ history }) => (
          <TenantGroupMembersList
            tenantGroupId={tenantGroupId}
            onCreateClick={() => history.push(`${url}/invite`)}
          />
        )}
      ></Route>
      <Route
        exact
        path={`${path}/invite`}
        render={({ history }) => (
          <Card
            title={
              <PageHeader
                title="Invite Member"
                onBack={() => history.push(url)}
              />
            }
          >
            <Row>
              <Col xl={18} lg={24} md={24} sm={24} xs={24}>
                <TenantGroupMemberInvite
                  create={create}
                  update={update}
                  onDone={() => history.push(url)}
                />
              </Col>
            </Row>
          </Card>
        )}
      />
      <Route>
        <NotFoundContent />
      </Route>
    </Switch>
  );
};

export default TenantGroupMembers;
