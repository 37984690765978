import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { useTenantGroup } from "../hooks/useTenantGroup";
import { getTenantGroupViewUrl } from "../routes";
import HeaderSidebarContent from "./Layouts/HeaderSidebarContent";
import TenantGroupSidebar from "./Layouts/TenantGroupSidebar";
import NavBar from "./NavBar";
import NotFoundContent from "./NotFoundContent";
import NotFoundFullPage from "./NotFoundFullPage";
import TenantGroupAccounting from "./TenantGroupAccounting";
import TenantGroupMembers from "./TenantGroupMembers";
import TenantGroupParkers from "./TenantGroupParkers";
import { TenantGroupParkingPrograms } from "./TenantGroupParkingPrograms";
import TenantGroupPaymentMethods from "./TenantGroupPaymentMethods";
import TenantGroupSettings from "./TenantGroupSettings";

export default function TenantGroups() {
  const { path, url } = useRouteMatch();
  const { tenantGroupId } = useParams<{ tenantGroupId: string }>();
  const { tenantGroup, error } = useTenantGroup({ tenantGroupId });
  const baseUrl = getTenantGroupViewUrl(tenantGroupId);

  if (error) {
    return <NotFoundFullPage />;
  }

  if (tenantGroup === undefined) {
    return null;
  }

  return (
    <Switch>
      <HeaderSidebarContent
        navbar={<NavBar name={tenantGroup.name} to={url} />}
        sidebar={<TenantGroupSidebar baseUrl={baseUrl} />}
      >
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${baseUrl}/parking-programs`} />
          </Route>
          <Route path={`${path}/parking-programs`}>
            <TenantGroupParkingPrograms tenantGroup={tenantGroup} />
          </Route>
          <Route exact path={`${path}/parkers`}>
            <TenantGroupParkers tenantGroupId={tenantGroupId} />
          </Route>
          <Route path={`${path}/payment-methods`}>
            <TenantGroupPaymentMethods tenantGroup={tenantGroup} />
          </Route>
          <Route exact path={`${path}/accounting`}>
            <TenantGroupAccounting tenantGroupId={tenantGroupId} />
          </Route>
          <Route path={`${path}/members`}>
            <TenantGroupMembers tenantGroupId={tenantGroupId} />
          </Route>
          <Route exact path={`${path}/settings`}>
            <TenantGroupSettings tenantGroupId={tenantGroupId} />
          </Route>
          <Route>
            <NotFoundContent />
          </Route>
        </Switch>
      </HeaderSidebarContent>
    </Switch>
  );
}
