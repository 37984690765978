import useSWR from "swr";

import type { ParkingProduct } from "./useOrganizationParkingProducts";

export type { ParkingProduct } from "./useOrganizationParkingProducts";

export const parkingProductPath = (id: string) =>
  `/sellables/parking-products/${id}/`;

export const useParkingProduct = (id: string, onError?: (err: any) => void) => {
  const apiPath = parkingProductPath(id);
  const { data } = useSWR<ParkingProduct>(apiPath, { onError });
  const [price] = data?.prices || [];
  return {
    parkingProduct: data,
    defaultPrice: price,
  };
};
