import { UserOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  List,
  Popover,
  Result,
  Row,
  Skeleton,
  Space,
} from "antd";
import _ from "lodash";
import React, { useState } from "react";

import {
  Invitation,
  ParkingProduct,
  ParkingProductMembership,
  useParkingProductInvitations,
  useParkingProductMemberships,
} from "../hooks/useOrganizationParkingProducts";
import notify from "../utils/notify";
import { getMailToLink } from "./Invitation";
import ResponsiveList from "./ResponsiveList";

import type { Organization } from "../hooks/useOrganization";
interface TenantGroupManagement {
  key: string;
  tenantGroup: {
    name?: string;
  };
  representative: {
    name?: string;
    email: string;
    phone?: string;
  };
}
const OrganizationTenantGroups = ({
  organization,
}: {
  organization: Organization;
}) => {
  const [error, setError] = useState<Error>();
  const [isFetchingItem, setIsFetchingItem] = useState<string>();
  const {
    members,
    remove: removeMembership,
    revalidate: revalidateMembers,
  } = useParkingProductMemberships({
    organizationId: organization.id,
    onError: setError,
  });
  const {
    invitations,
    remove: removeInvitation,
    revalidate: revalidateInvitations,
  } = useParkingProductInvitations({
    organizationId: organization.id,
    onError: setError,
  });
  const onRemoveItem = async (
    id: string,
    remover: (id: string) => Promise<any>
  ) => {
    try {
      setIsFetchingItem(id);
      await remover(id);
    } catch (err) {
      notify.error("Sorry, something went wrong", err);
    } finally {
      setIsFetchingItem(undefined);
    }
  };
  if (error)
    return (
      <Card>
        <Result
          status="warning"
          title="Could not load tenant groups"
          extra={[
            <Button
              onClick={() => {
                setError(undefined);
                revalidateMembers();
                revalidateInvitations();
              }}
            >
              Try Again
            </Button>,
          ]}
        />
      </Card>
    );
  if (!members && !invitations)
    return (
      <List>
        <Skeleton active />
      </List>
    );
  if (members?.length === 0 && invitations?.length === 0)
    return (
      <Card>
        <Result
          icon={<UserOutlined />}
          title="No Tenant Groups have been authorized to distribute parking products.."
        />
      </Card>
    );
  if (members && invitations) {
    let data: TenantGroupManagement[] = [];
    const tenantGroups = _(members)
      .filter("from_tenant_group")
      .uniqBy("from_tenant_group.id")
      .value();
    const membershipsByTenantGroupId = _.flow((members) =>
      _.groupBy(members, "from_tenant_group.id")
    )(members);
    const uniqInvitationEmails = _.uniqBy(invitations, "email");
    const invitationsByEmail = _.groupBy(invitations, "email");
    for (let { from_tenant_group: tenantGroup } of tenantGroups) {
      const item = {
        key: tenantGroup.id,
        tenantGroup: { name: tenantGroup.name },
        representative: tenantGroup.representative,
      };
      data.push(item);
    }
    for (let { email, name } of uniqInvitationEmails) {
      const item = {
        key: email,
        tenantGroup: {},
        representative: { email, name },
      };
      data.push(item);
    }
    return (
      <ResponsiveList<TenantGroupManagement>
        columns={[
          {
            title: "Tenant Group Representative",
            dataIndex: ["representative", "name"],
            colSpan: 6,
          },
          {
            title: "Tenant Group",
            dataIndex: ["tenantGroup", "name"],
            colSpan: 4,
          },
          {
            title: "Email",
            dataIndex: ["representative", "email"],
            colSpan: 4,
          },
          {
            title: "Phone",
            dataIndex: ["representative", "number"],
            colSpan: 4,
          },
          {
            title: "Manage",
            dataIndex: "id",
            colSpan: 6,
            render: (id: string, { key, tenantGroup }) => {
              return tenantGroup.name ? (
                <MembershipActions
                  isFetchingItem={isFetchingItem}
                  memberships={membershipsByTenantGroupId[key]}
                  onClick={(membershipId: string) =>
                    onRemoveItem(membershipId, removeMembership)
                  }
                />
              ) : (
                <InvitationActions
                  isFetchingItem={isFetchingItem}
                  invitations={invitationsByEmail[key]}
                  onClick={(invitationId: string) =>
                    onRemoveItem(invitationId, removeInvitation)
                  }
                />
              );
            },
          },
        ]}
        dataSource={data}
        rowKey="id"
      />
    );
  }
  return null;
};

const MembershipActions = ({
  isFetchingItem,
  memberships,
  onClick,
}: {
  isFetchingItem?: string;
  memberships: ParkingProductMembership[];
  onClick: (id: string) => void;
}) => {
  return (
    <Popover
      title="Authorized Products"
      content={
        <div style={{ minWidth: "16rem" }}>
          {_.map(memberships, ({ id, parking_product }, idx: number) => (
            <ManageParkingProduct
              offering={parking_product}
              actions={[
                <Button
                  type="primary"
                  danger
                  block
                  loading={isFetchingItem === id}
                  disabled={!!isFetchingItem}
                  onClick={() => onClick(id)}
                >
                  Deauthorize
                </Button>,
              ]}
              divider={idx > 0}
            />
          ))}
        </div>
      }
      trigger="click"
      placement="bottomLeft"
    >
      <Button block>Manage Products</Button>
    </Popover>
  );
};

const InvitationActions = ({
  isFetchingItem,
  invitations,
  onClick,
}: {
  isFetchingItem?: string;
  invitations: Invitation[];
  onClick: (id: string) => void;
}) => {
  return (
    <Popover
      title="Invitations"
      content={
        <div style={{ minWidth: "16rem" }}>
          {_.map(invitations, (item, idx: number) => (
            <ManageParkingProduct
              offering={item.parking_product}
              actions={[
                <Button
                  type="primary"
                  danger
                  block
                  loading={isFetchingItem === item.id}
                  disabled={!!isFetchingItem}
                  onClick={() => onClick(item.id)}
                >
                  Withdraw
                </Button>,
                <Button type="primary" block disabled={!!isFetchingItem}>
                  <a href={getMailToLink(item, "")}>Resend</a>
                </Button>,
              ]}
              divider={idx > 0}
            />
          ))}
        </div>
      }
      trigger="click"
      placement="bottomLeft"
    >
      <Button block>Manage Invitations</Button>
    </Popover>
  );
};

const ManageParkingProduct = ({
  offering,
  actions,
  divider,
}: {
  actions: JSX.Element[];
  offering: ParkingProduct;
  divider: boolean;
}) => {
  return (
    <Row justify="space-between" align="top" gutter={16}>
      {divider ? <Divider /> : null}
      <Col>
        <div>
          <strong>{offering.name}</strong>
        </div>
        <div>
          {offering.location.default_address?.line1}{" "}
          {offering.location.default_address?.line2}
        </div>
        <div>
          {offering.location.default_address?.city},{" "}
          {offering.location.default_address?.state}{" "}
          {offering.location.default_address?.postal_code}
        </div>
      </Col>
      <Col>
        <Space direction="vertical">{actions}</Space>
      </Col>
    </Row>
  );
};

export default OrganizationTenantGroups;
