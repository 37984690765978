import { Button, Card, Col, Result, Row } from "antd";
import React from "react";

import HeaderContent from "./Layouts/HeaderContent";

const ErrorFallback = () => {
  return (
    <HeaderContent navbar={null}>
      <Row style={{ width: "100%" }} justify="center">
        <Col span={12}>
          <Card>
            <Result
              status="500"
              title="Oops, something went wrong."
              subTitle="Our team has been notified, please try again later."
              extra={
                <Button type="primary" key="console">
                  <a href={`${window.location.origin}`}>Home</a>
                </Button>
              }
            />
          </Card>
        </Col>
      </Row>
    </HeaderContent>
  );
};

export default ErrorFallback;
