import {
  Alert,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Space,
  Spin,
} from "antd";
import * as React from "react";

const SIMPLE_REFUND_REASONS = ["Customer is double charged."];

const EXTRA_REFUND_REASONS = [
  "Customer is charged wrong amount.",
  "Customer cannot get access to the location.",
  "Customer requests to cancel.",
  "Other",
];

export interface RefundFormValues {
  amount?: number;
  reason?: string;
  details?: string;
}

interface Props {
  form: FormInstance<RefundFormValues>;
  userName: string;
  maxRefundAmount: number;
  isLoading: boolean;
}

export default function RefundForm(props: Readonly<Props>) {
  const [isDetailsRequired, setIsDetailsRequired] = React.useState(false);
  return (
    <Spin spinning={props.isLoading}>
      <Space style={{ width: "100%" }} direction="vertical" size="large">
        <Alert
          type="info"
          message="Refunds take 5-10 days to appear on a customer's statement."
        />
        <Form<RefundFormValues>
          form={props.form}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          initialValues={{
            amount: props.maxRefundAmount,
            reason: undefined,
            details: undefined,
          }}
        >
          <Form.Item label="Customer">{props.userName}</Form.Item>
          <Form.Item
            name="amount"
            label="Refund"
            required
            rules={[
              { required: true, message: "Please input refund amount" },
              {
                type: "number",
                min: 1,
                message: "Please input a positive refund amount",
              },
              {
                type: "number",
                max: props.maxRefundAmount,
                message: `Max refund is ${getFormattedAmount(
                  props.maxRefundAmount
                )}`,
              },
            ]}
            extra={`Refund up to ${getFormattedAmount(
              props.maxRefundAmount
            )} for this charge`}
            validateTrigger="onBlur"
          >
            <InputNumber
              style={{ width: "100%" }}
              precision={2}
              addonBefore="$"
              onFocus={(evt: React.FocusEvent<HTMLInputElement>) =>
                evt.target.select()
              }
              formatter={(value: number | undefined, info) =>
                info.userTyping
                  ? info.input
                  : value === undefined
                  ? ""
                  : (value / 100).toFixed(2)
              }
              parser={(input: string | undefined) =>
                input === undefined ? 0 : Math.round(parseFloat(input) * 100)
              }
            />
          </Form.Item>
          <Form.Item
            label="Reason"
            name="reason"
            rules={[
              {
                required: true,
                message: "Please choose a reason for this refund",
              },
            ]}
            validateTrigger={["onChange", "onBlur"]}
          >
            <Select
              options={[...SIMPLE_REFUND_REASONS, ...EXTRA_REFUND_REASONS].map(
                (reason) => ({
                  label: reason,
                  value: reason,
                })
              )}
              onSelect={(value: string) => {
                if (EXTRA_REFUND_REASONS.includes(value)) {
                  setIsDetailsRequired(true);
                  props.form.getFieldInstance("details").focus();
                } else {
                  setIsDetailsRequired(false);
                  props.form.validateFields();
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Details"
            name="details"
            rules={[
              {
                required: isDetailsRequired,
                message: "Please provide details for this refund",
              },
            ]}
          >
            <Input.TextArea
              autoSize={{ minRows: 5 }}
              placeholder="Please provide details of this refund for accounting and audits."
            />
          </Form.Item>
        </Form>
      </Space>
    </Spin>
  );
}

function getFormattedAmount(amount: number, currency: string = "usd") {
  return (amount / 100).toLocaleString(undefined, {
    style: "currency",
    currency,
  });
}
