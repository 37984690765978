import { Col, List, Row } from "antd";
import { ColumnType } from "antd/lib/table";
import _ from "lodash";
import * as React from "react";

import { getColumnKey, rowPadding } from "./ResponsiveList";
import TooltipCell from "./TooltipCell";

const verticalMargin = "0.2rem";

interface Props<T> {
  rowKeyString: React.Key;
  record: T;
  rowIndex: number;
  columns: ColumnType<T>[];
}

export default function DataRow<T extends object>(props: Readonly<Props<T>>) {
  return (
    <List.Item style={{ padding: rowPadding }}>
      <Row style={{ marginTop: `-${verticalMargin}` }} align="middle">
        {props.columns.map((column: ColumnType<T>, colIdx: number) => (
          <DataCell
            key={`${props.rowKeyString}|${getColumnKey(column, colIdx)}`}
            record={props.record}
            rowIndex={props.rowIndex}
            column={column}
          />
        ))}
      </Row>
    </List.Item>
  );
}

interface DataCellProps<T> {
  record: T;
  rowIndex: number;
  column: ColumnType<T>;
}

function DataCell<T extends object>(props: Readonly<DataCellProps<T>>) {
  function getCellValue(): React.ReactNode {
    return _.get(props.record, props.column.dataIndex || "key");
  }

  return (
    <Col
      style={{ marginTop: verticalMargin }}
      xs={24}
      md={props.column.colSpan || 24}
    >
      <Row>
        <Col key="label-for-mobile" xs={8} md={0}>
          {props.column.title && (
            <TooltipCell style={{ fontWeight: 600 }}>
              {props.column.title}
            </TooltipCell>
          )}
        </Col>
        <Col key="data" xs={16} md={24}>
          <TooltipCell>
            {_.isFunction(props.column.render)
              ? props.column.render(
                  getCellValue(),
                  props.record,
                  props.rowIndex
                )
              : _.toString(getCellValue())}
          </TooltipCell>
        </Col>
      </Row>
    </Col>
  );
}
