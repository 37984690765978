import { BankOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Result, Row, Space } from "antd";
import _ from "lodash";
import * as React from "react";
import { useHistory } from "react-router";

import {
  useTenantGroupPaymentMethods,
  useTenantGroupSources,
} from "../../hooks/useTenantGroupWallet";
import { PaymentMethod, Source } from "../../hooks/useWallet";
import responsiveCardList from "../Layouts/ResponsiveCards";
import NotFoundContent from "../NotFoundContent";
import BankPaymentPreview from "../PaymentMethods/BankPaymentPreview";
import CardPaymentPreview from "../PaymentMethods/CardPaymentPreview";

interface Props {
  tenantGroupId: string;
  baseUrl: string;
}

export default function AllTenantGroupPayments(props: Readonly<Props>) {
  const history = useHistory();

  const {
    data: paymentMethods,
    error: paymentMethodsError,
    remove: removePaymentMethod,
  } = useTenantGroupPaymentMethods(props.tenantGroupId);
  const {
    data: sources,
    error: sourcesError,
    remove: removeSource,
  } = useTenantGroupSources(props.tenantGroupId);

  if (sourcesError || paymentMethodsError) {
    return <NotFoundContent />;
  }

  const allMethods: (PaymentMethod | Source)[] = [
    ...(paymentMethods || []),
    ...(sources || []),
  ];

  if (paymentMethods && sources && allMethods.length === 0) {
    return (
      <Card>
        <Result
          icon={<BankOutlined />}
          title="Add your first payment method"
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => history.push(`${props.baseUrl}/add`)}
            >
              Add Payment Method
            </Button>
          }
        />
      </Card>
    );
  }

  return (
    <Space size="large" direction="vertical" style={{ width: "100%" }}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => history.push(`${props.baseUrl}/add`)}
      >
        Add Payment Method
      </Button>
      <Row gutter={16}>
        {_.sortBy(allMethods, "name").map((method) => (
          <Col key={method.id} {...responsiveCardList}>
            {_.includes(paymentMethods, method) ? (
              <CardPaymentPreview
                paymentMethod={method as PaymentMethod}
                onEdit={(paymentMethod: PaymentMethod) =>
                  history.push(
                    `${props.baseUrl}/card-payment/${paymentMethod.id}/edit`
                  )
                }
                onDelete={async (paymentMethod: PaymentMethod) => {
                  await removePaymentMethod(paymentMethod.id);
                }}
              />
            ) : (
              <BankPaymentPreview
                source={method as Source}
                onEdit={(source: Source) =>
                  history.push(
                    `${props.baseUrl}/bank-payment/${source.id}/edit`
                  )
                }
                onVerify={(source: Source) =>
                  history.push(
                    `${props.baseUrl}/bank-payment/${source.id}/verify`
                  )
                }
                onDelete={async (source: Source) => {
                  await removeSource(source.id);
                }}
              />
            )}
          </Col>
        ))}
      </Row>
    </Space>
  );
}
