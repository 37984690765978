import useSWR from "swr";

import { Address } from "./useAddress";
import useIdsFetch from "./useIdsFetch";

export interface Location {
  id: string;
  name: string;
  capacity: number | null;
  time_zone: string | null;
  description: string;
  support_phone_number: string;
  support_email: string;
  default_address: Address | null;
  owner: {
    id: string;
    name: string;
  };
}

export type LocationData = Omit<
  Location,
  "id" | "owner" | "default_address"
> & {
  default_address: Omit<Address, "id"> | null;
};

const locationsPath = (organizationId: string) =>
  `/organizations/${organizationId}/locations`;

const locationPath = (locationId: string) => `/locations/${locationId}`;

export const useOrganizationLocations = ({
  organizationId,
  onError,
}: {
  organizationId: string;
  onError?: (err: Error) => void;
}) => {
  const idsFetch = useIdsFetch();
  const { data, error, mutate } = useSWR<Location[]>(
    locationsPath(organizationId),
    { onError }
  );

  async function createLocation(
    locationData: Partial<LocationData>
  ): Promise<Location> {
    const newLocation: Location = await idsFetch(
      locationsPath(organizationId),
      {
        method: "POST",
        body: locationData,
      }
    );
    await mutate();
    return newLocation;
  }

  async function updateLocation(
    locationId: string,
    locationData: Partial<LocationData>
  ): Promise<Location> {
    const api = locationPath(locationId);
    const updatedLocation: Location = await idsFetch(api, {
      method: "PUT",
      body: locationData,
    });
    await mutate();
    return updatedLocation;
  }

  async function deleteLocation(locationId: string): Promise<void> {
    const url = `${locationsPath(organizationId)}/${locationId}`;
    await idsFetch(url, { method: "DELETE" });
    await mutate();
  }

  return {
    locations: data,
    error,
    createLocation,
    updateLocation,
    deleteLocation,
    revalidate: () => mutate,
  };
};
