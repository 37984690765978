import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect, useLocation } from "react-router-dom";

export default function Login() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();
  if (!isAuthenticated) {
    loginWithRedirect({ appState: { targetUrl: location.pathname } });
    return null;
  } else {
    return <Redirect to="/" />;
  }
}
