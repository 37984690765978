import { Divider, Grid, Spin, Steps } from "antd";
import * as React from "react";

interface Props {
  currentStep: 0 | 1 | 2;
  isLoading: boolean;
  children: React.ReactNode;
}

export default function InviteSteps(
  props: Readonly<Props>
): React.FunctionComponentElement<Props> {
  const screens = Grid.useBreakpoint();
  const isVertical = !screens.md && (screens.sm || screens.xs);
  return (
    <div>
      <Steps
        current={props.currentStep}
        style={{ maxWidth: "42rem", margin: "1rem auto" }}
        direction={isVertical ? "vertical" : "horizontal"}
      >
        <Steps.Step title="Setup" />
        <Steps.Step title="Invite" />
        <Steps.Step title="Done" />
      </Steps>
      <Divider />
      <Spin spinning={props.isLoading}>{props.children}</Spin>
    </div>
  );
}
