import { useCallback } from "react";

import {
  Invitation,
  useInvitation,
  useInvitations,
  useMembership,
  useMemberships,
} from "./useMembers";

export const organizationMembershipsPath = (organizationId: string) =>
  `/organizations/${organizationId}/members/`;
export const organizationInvitationsPath = (organizationId: string) =>
  `/organizations/${organizationId}/invitations/`;
export const organizationMembershipPath = (
  organizationId: string,
  membershipId: string
) => `${organizationMembershipsPath(organizationId)}${membershipId}/`;
export const organizationInvitationPath = (
  organizationId: string,
  invitationId: string
) => `${organizationInvitationsPath(organizationId)}${invitationId}/`;

export const useOrganizationMemberships = ({
  organizationId,
  onError = () => {},
}: {
  organizationId: string;
  onError?: (err: Error) => void;
}) => {
  const apiUrl = organizationMembershipsPath(organizationId);
  return useMemberships({ apiUrl, onError });
};

export const useOrganizationMembership = ({
  organizationId,
  membershipId,
  onError = () => {},
}: {
  organizationId?: string;
  membershipId?: string;
  onError?: (err: Error) => void;
}) => {
  const apiUrl =
    organizationId && membershipId
      ? organizationMembershipPath(organizationId, membershipId)
      : undefined;
  return useMembership({ apiUrl, onError });
};

export const useOrganizationInvitations = ({
  organizationId,
  onError = () => {},
}: {
  organizationId: string;
  onError?: (err: Error) => void;
}) => {
  const apiUrl = organizationInvitationsPath(organizationId);
  return useInvitations({ apiUrl, onError });
};

export const useOrganizationInvitation = ({
  organizationId,
  invitationId,
  invitation,
  onError = () => {},
}: {
  organizationId: string;
  invitationId?: string;
  invitation?: Invitation;
  onError?: (err: Error) => void;
}) => {
  const apiUrl = invitationId
    ? organizationInvitationPath(organizationId, invitationId)
    : null;
  const { update, ...rest } = useInvitation({ apiUrl, onError });
  const updateById = useCallback(
    (data: Partial<Invitation>, invitationId: string) =>
      update(data, organizationInvitationPath(organizationId, invitationId)),
    [organizationId, update]
  );
  return {
    ...rest,
    update: updateById,
  };
};
