import {
  ContainerOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Badge, Button, Card, Modal, Spin } from "antd";
import * as React from "react";

import { getBankAccountPreviewText, Source } from "../../hooks/useWallet";
import notify from "../../utils/notify";

interface Props {
  source: Source;
  onEdit: (source: Source) => void | Promise<void>;
  onVerify: (source: Source) => void | Promise<void>;
  onDelete: (source: Source) => void | Promise<void>;
}

export default function BankPaymentPreview(props: Readonly<Props>) {
  const [isDeleting, setIsDeleting] = React.useState(false);

  function isVerified() {
    return props.source.stripe_source.data.status === "verified";
  }

  const handleDelete = () => {
    const { name } = props.source;
    Modal.confirm({
      title: `Are you sure to delete the "${name}" account?`,
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        setIsDeleting(true);
        try {
          await props.onDelete(props.source);
          notify.success(`Successfully deleted the "${name}" account.`);
        } catch (err) {
          notify.error("Failed to delete the account", err);
        } finally {
          setIsDeleting(false);
        }
      },
    });
  };

  return (
    <Spin spinning={isDeleting}>
      <Badge.Ribbon
        text={isVerified() ? "Ready" : "Need to Verify"}
        color={isVerified() ? "green" : "red"}
      >
        <Card
          title={props.source.name}
          style={{ width: "100%" }}
          actions={[
            <Button
              key="edit"
              type="link"
              onClick={() => props.onEdit(props.source)}
            >
              <EditOutlined />
              Edit
            </Button>,
            isVerified() ? null : (
              <Button
                key="verify"
                type="link"
                onClick={() => props.onVerify(props.source)}
              >
                <ContainerOutlined />
                Verify
              </Button>
            ),
            <Button key="delete" danger type="link" onClick={handleDelete}>
              <DeleteOutlined />
              Delete
            </Button>,
          ].filter(Boolean)}
        >
          {getBankAccountPreviewText(props.source)}
        </Card>
      </Badge.Ribbon>
    </Spin>
  );
}
