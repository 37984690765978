import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
} from "antd";
import _ from "lodash";
import moment, { Moment } from "moment";
import { Location } from "../../hooks/useOrganizationLocations";
import {
  dollarsToCents,
  Event,
  ParkingProduct,
  Price,
  Rate,
} from "../../hooks/useOrganizationParkingProducts";
import { PayoutAccount } from "../../hooks/usePayoutAccount";

interface Props {
  isCreate?: boolean;
  locations: Location[];
  payoutAccounts: PayoutAccount[];
  onSubmit: (event: Partial<Event>) => void;
}

const API_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSSS";
const DTSTART_TIME_FORMAT = "YYYYMMDDTHHmmss";
const { RangePicker } = DatePicker;

const rangeConfig = {
  rules: [
    {
      required: true,
      message: "Event Time is required!",
    },
  ],
};

export default function EventForm(props: Readonly<Props>) {
  const locationOptions = props.locations.map((location) => (
    <Select.Option value={location.id} key={location.id}>
      {location.name || location.id}
    </Select.Option>
  ));

  const payoutAccountOptions = props.payoutAccounts.map((account) => (
    <Select.Option value={account.stripe_account.id} key={account.id}>
      {account.name || account.id}
    </Select.Option>
  ));

  const convertFormToRate = (rate: Rate) => {
    _.set(rate, "time_of_use_rules", []);
    if (rate.amount) _.update(rate, "amount", dollarsToCents);
    return rate;
  };

  const convertFormToPrice = (
    price: Price,
    start_at: Moment,
    end_at: Moment
  ) => {
    _.set(price, "name", "Event Pass");
    _.set(price, "recurring_interval", "week");
    _.set(price, "recurring_count", 4);
    _.set(price, "is_time_of_use_customizable", false);
    _.set(price, "discounted_phase", null);
    _.set(price, "recurring_iterations", 1);
    _.set(price, "is_max_sessions_customizable", true);
    _.set(
      price,
      "maximum_session_duration_minutes",
      end_at.diff(start_at) / 60000
    );
    _.set(price, "allowed_time_of_use", [
      {
        recurrence: `DTSTART:${moment(start_at)
          .startOf("day")
          .format(DTSTART_TIME_FORMAT)}Z RRULE:FREQ=DAILY;COUNT=1`,
        start_at: start_at.format(API_TIME_FORMAT),
        end_at: end_at.format(API_TIME_FORMAT),
      },
    ]);
    _.update(price, "rates", (rates) => {
      return rates.map((rate: Rate) => convertFormToRate(rate));
    });
    return price;
  };

  const convertFormToProduct = (
    parking_product: ParkingProduct,
    event_name: string,
    start: Moment,
    end: Moment
  ) => {
    _.set(parking_product, "name", `${event_name} - Event Pass`);
    _.set(parking_product, "description", `Event Pass for ${event_name}`);
    _.set(
      parking_product,
      "restrictions",
      `Can only park between the times of ${start.format(
        "LLLL"
      )} and ${end.format("LLLL")}`
    );
    return parking_product;
  };

  const convertFormToEvent = (event: Event, start: Moment, end: Moment) => {
    _.set(event, "start_at", start.format(API_TIME_FORMAT));
    _.set(event, "end_at", end.format(API_TIME_FORMAT));
    return event;
  };

  const onFinish = ({
    event,
    parking_product,
    price,
    range,
  }: {
    event: Event;
    parking_product: ParkingProduct;
    price: Price;
    range: Moment[];
  }) => {
    const start = range[0].startOf("minute");
    const end = range[1].startOf("minute");

    parking_product.prices = [convertFormToPrice(price, start, end)];
    event.parking_products = [
      convertFormToProduct(parking_product, event.name, start, end),
    ];
    convertFormToEvent(event, start, end);
    props.onSubmit(event);
  };

  return (
    <Form layout="vertical" scrollToFirstError={true} onFinish={onFinish}>
      <Form.Item
        label="Name"
        name={["event", "name"]}
        rules={[{ required: true, message: "Name is required." }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["parking_product", "location", "id"]}
        label="Location"
        rules={[{ required: true, message: "Location is required." }]}
      >
        <Select disabled={!props.isCreate}>{locationOptions}</Select>
      </Form.Item>
      <Form.Item
        name={["parking_product", "stripe_account", "id"]}
        label="Payout account"
        rules={[{ required: true, message: "Payout Account is required." }]}
      >
        <Select disabled={!props.isCreate}>{payoutAccountOptions}</Select>
      </Form.Item>

      <Form.Item name="range" label="Event Parking Time" {...rangeConfig}>
        <RangePicker
          showTime
          format="YYYY/MM/DD HH:mm"
          placeholder={["Earliest Entry Time", "Required Exit Time"]}
        />
      </Form.Item>

      <Form.Item label="Rate" required={true}>
        <Form.List name={["price", "rates"]} initialValue={[{ amount: "" }]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "amount"]}
                    rules={[{ required: true, message: "Amount is required" }]}
                  >
                    <InputNumber placeholder="Amount" addonBefore="$" min={0} />
                  </Form.Item>
                </Space>
              ))}
            </>
          )}
        </Form.List>

        <Form.Item label="Limit" name={["price", "maximum_active_purchases"]}>
          <InputNumber min={0} />
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {props.isCreate ? "Create" : "Update"}
        </Button>
      </Form.Item>
    </Form>
  );
}
