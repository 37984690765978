import { CarOutlined } from "@ant-design/icons";
import { Button, Divider, Space, Typography } from "antd";
import * as React from "react";

interface Props {
  noAccessReason: string;
  explanation?: string;
  isDayPassPurchasable: boolean;
  onGetDayPass: () => Promise<void>;
}

export default function NoAccessDisplay(props: Readonly<Props>) {
  return (
    <Space
      style={{ width: "100%", textAlign: "center" }}
      direction="vertical"
      size="middle"
    >
      <Typography.Text type="danger">
        <CarOutlined style={{ fontSize: 72 }} />
      </Typography.Text>
      <div>
        <Typography.Title level={4}>{props.noAccessReason}</Typography.Title>
        {props.explanation && (
          <Typography.Text>{props.explanation}</Typography.Text>
        )}
      </div>
      {props.isDayPassPurchasable && (
        <>
          <Divider style={{ margin: "0.5rem" }} />
          <Space direction="vertical" size="middle">
            <Typography.Text>
              Get a single day pass for the same day rate:
            </Typography.Text>
            <Button type="primary" onClick={props.onGetDayPass}>
              Get a Single Day Pass
            </Button>
            <Typography.Text type="secondary">
              Note: All restrictions of this parking product applies to the
              single day pass.
            </Typography.Text>
          </Space>
        </>
      )}
    </Space>
  );
}
