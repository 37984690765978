import {
  CarOutlined,
  HistoryOutlined,
  QrcodeOutlined,
  SettingOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import Sidebar from "./Sidebar";

const ParkerSidebar = ({ baseUrl }: { baseUrl: string }) => {
  return (
    <Sidebar
      baseUrl={baseUrl}
      items={[
        { key: "passes", icon: <QrcodeOutlined /> },
        { key: "wallet", icon: <WalletOutlined /> },
        { key: "vehicles", icon: <CarOutlined /> },
        { key: "history", icon: <HistoryOutlined /> },
        { key: "settings", icon: <SettingOutlined /> },
      ]}
    />
  );
};

export default ParkerSidebar;
