import useSWR from "swr";
import { CodeStatus } from "../utils/code_status";

import useIdsFetch from "./useIdsFetch";

export interface ConnectionDataField {
  id: string;
  field_key: string;
  field_label: string;
  field_value_type: "string" | "secret" | "number" | "boolean" | "options";
  field_value_configs: Record<string, any>;
  is_location_specific: boolean;
}

export interface Vendor {
  id: string;
  name: string;
  connection_data_fields: ConnectionDataField[];
  integration_steps: CodeStatus[];
  documentation_url?: string;
}

export interface GateAccess {
  vendor_id: string;
  connection_data: Record<string, any>;
}

export function useVendors() {
  const { data: vendors, error } = useSWR<Vendor[]>("/vendors");
  return { vendors, error };
}

export function useLocationGateAccess(
  organizationId: string,
  locationId: string
) {
  const idsFetch = useIdsFetch();
  const url = `/organizations/${organizationId}/locations/${locationId}/gate-access`;
  const { data: locationGateAccess, mutate, error } = useSWR<GateAccess>(url);

  async function updateLocationGateAccess(newGateAccess: {
    vendor_id: string;
    connection_data: Record<string, any>;
  }) {
    return await idsFetch(url, { method: "PUT", body: newGateAccess });
  }

  return { locationGateAccess, updateLocationGateAccess, mutate, error };
}
