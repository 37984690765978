import {
  CopyOutlined,
  DeleteOutlined,
  DeploymentUnitOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  Result,
  Row,
  Space,
  Tabs,
  Tooltip,
} from "antd";
import _ from "lodash";
import * as React from "react";

import {
  ParkingProduct,
  Price,
  useParkingProducts,
} from "../../hooks/useOrganizationParkingProducts";
import notify from "../../utils/notify";
import ResponsiveList from "../ResponsiveList";
import VerticalSpace from "../VerticalSpace";

interface Props {
  organizationId: string;
  tab: string;
  onTabChange: (tabKey: string) => void;
  onCreate: () => void;
  onCreateEvent: () => void;
  onEdit: (id: string) => void;
  onInvite: (id: string) => void;
}

export default function ParkingProductsList(props: Readonly<Props>) {
  const [error, setError] = React.useState<Error>();
  const { parkingProducts, deleteParkingProduct, revalidate } =
    useParkingProducts({
      organizationId: props.organizationId,
      onError: setError,
    });

  function getActive() {
    return _.filter(
      parkingProducts,
      (p) => !p.is_disabled && !p.is_pending_review
    );
  }

  function getDisabled() {
    return _.filter(parkingProducts, "is_disabled");
  }

  function getPendingReview() {
    return _.filter(parkingProducts, "is_pending_review");
  }

  async function onDelete(productId: string) {
    try {
      await deleteParkingProduct(productId);
      notify.success("Successfully deleted the product.");
    } catch (err) {
      notify.error("Failed to delete the product", err);
    }
  }

  function showDeleteConfirm(productionId: string) {
    Modal.confirm({
      title: "Are you sure delete this product?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => onDelete(productionId),
    });
  }

  if (error) {
    return (
      <Card>
        <Result
          status="error"
          title="Could not load parking products"
          extra={[
            <Button
              type="primary"
              onClick={() => {
                setError(undefined);
                revalidate();
              }}
            >
              Try Again
            </Button>,
          ]}
        />
      </Card>
    );
  }

  if (parkingProducts?.length === 0) {
    return (
      <Card>
        <Result
          icon={<ShoppingCartOutlined />}
          title="Add your first parking product."
          extra={
            <Button type="primary" onClick={props.onCreate}>
              Add Parking Product
            </Button>
          }
        />
      </Card>
    );
  }

  return (
    <VerticalSpace size="large">
      <Card>
        <Row gutter={[12, 12]} align="middle">
          <Col>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={props.onCreate}
            >
              Create Parking Product
            </Button>
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={props.onCreateEvent}
            >
              Create Event Pass
            </Button>
          </Col>
        </Row>
      </Card>
      <Tabs activeKey={props.tab} onChange={props.onTabChange}>
        <Tabs.TabPane
          key="active"
          tab={
            <TabTitle
              text="Active"
              count={getActive().length}
              nonZeroColor="#56bcff"
            />
          }
        >
          <ProductsList
            isLoading={parkingProducts === undefined}
            products={getActive()}
            onInvite={props.onInvite}
            onEdit={props.onEdit}
            onDelete={showDeleteConfirm}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="disabled"
          tab={
            <TabTitle
              text="Disabled"
              count={getDisabled().length}
              nonZeroColor="grey"
            />
          }
        >
          <ProductsList
            isLoading={parkingProducts === undefined}
            products={getDisabled()}
            onInvite={props.onInvite}
            onEdit={props.onEdit}
            onDelete={showDeleteConfirm}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="pending-review"
          tab={
            <TabTitle
              text="Pending Review"
              count={getPendingReview().length}
              nonZeroColor={"orange"}
            />
          }
        >
          <ProductsList
            isLoading={parkingProducts === undefined}
            products={getPendingReview()}
            onInvite={props.onInvite}
            onEdit={props.onEdit}
            onDelete={showDeleteConfirm}
          />
        </Tabs.TabPane>
      </Tabs>
    </VerticalSpace>
  );
}

interface TabTitleProps {
  text: string;
  count: number;
  nonZeroColor: string;
}

function TabTitle(props: Readonly<TabTitleProps>) {
  return (
    <Space>
      {props.text}
      <Badge
        style={{
          backgroundColor: props.count > 0 ? props.nonZeroColor : "lightgrey",
        }}
        count={props.count}
        showZero
      />
    </Space>
  );
}

interface ProductsListProps {
  isLoading: boolean;
  products: ParkingProduct[] | undefined;
  onInvite: (id: string) => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

function ProductsList(props: Readonly<ProductsListProps>) {
  return (
    <ResponsiveList<ParkingProduct>
      loading={props.isLoading}
      columns={[
        {
          title: "Location",
          dataIndex: ["location", "name"],
          colSpan: 5,
        },
        {
          title: "Product Name",
          dataIndex: "name",
          colSpan: 6,
        },
        {
          title: "Product Type",
          dataIndex: "prices",
          colSpan: 5,
          render: (prices: Price[]) =>
            prices.length > 0 ? prices[0].name : "--",
        },
        {
          key: "link-buttons",
          title: "",
          dataIndex: "id",
          colSpan: 6,
          render: (id: string, product: ParkingProduct) => {
            if (product.is_for_direct_sale) {
              return (
                <ButtonsForDirectSale
                  productId={id}
                  productName={product.name}
                  isDisabled={!product.is_purchaseable}
                />
              );
            }
            return (
              <Button
                icon={<DeploymentUnitOutlined />}
                onClick={() => props.onInvite(id)}
                disabled={!product.is_purchaseable}
              >
                Invite tenant group
              </Button>
            );
          },
        },
        {
          key: "action-buttons",
          title: "",
          dataIndex: "id",
          colSpan: 2,
          render: (id: string, product: ParkingProduct) => (
            <Space>
              <Tooltip
                title={
                  product.prices[0].name.includes("Event Pass")
                    ? "Edit Disabled for Event Pass"
                    : "Edit"
                }
              >
                <Button
                  key="edit"
                  type="link"
                  onClick={() => props.onEdit(id)}
                  disabled={product.prices[0].name.includes("Event Pass")}
                  icon={<EditOutlined />}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <Button
                  key="delete"
                  danger
                  type="link"
                  onClick={() => props.onDelete(id)}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Space>
          ),
        },
      ]}
      rowKey="id"
      dataSource={props.products}
    />
  );
}

interface ButtonsForDirectSaleProps {
  productId: string;
  productName: string;
  isDisabled: boolean;
}

function ButtonsForDirectSale(props: Readonly<ButtonsForDirectSaleProps>) {
  const productUrl = `${window.location.origin}/products/${props.productId}`;
  return (
    <Space>
      <Button
        href={`mailto:?subject=${props.productName}&body=${productUrl}`}
        icon={<MailOutlined />}
        disabled={props.isDisabled}
      >
        Email link
      </Button>
      <Button
        icon={<CopyOutlined />}
        onClick={async () => {
          await navigator.clipboard.writeText(productUrl);
          notify.success("Successfully copied product link to clipboard.");
        }}
        disabled={props.isDisabled}
      >
        Copy link
      </Button>
    </Space>
  );
}
