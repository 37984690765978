import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Badge, Button, Card, Modal, Spin, Tooltip } from "antd";
import * as React from "react";

import { getCreditCardPreviewText, PaymentMethod } from "../../hooks/useWallet";
import notify from "../../utils/notify";

interface Props {
  paymentMethod: PaymentMethod;
  onEdit: (paymentMethod: PaymentMethod) => void | Promise<void>;
  onDelete: (paymentMethod: PaymentMethod) => void | Promise<void>;
}

export default function CardPaymentPreview(props: Readonly<Props>) {
  const [isDeleting, setIsDeleting] = React.useState(false);

  function handleDelete() {
    const { name } = props.paymentMethod;
    Modal.confirm({
      title: `Are you sure to delete the "${name}" card?`,
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        setIsDeleting(true);
        try {
          await props.onDelete(props.paymentMethod);
          notify.success(`Successfully deleted the "${name}" card.`);
        } catch (err) {
          notify.error("Failed to delete the card", err);
        } finally {
          setIsDeleting(false);
        }
      },
    });
  }

  return (
    <Spin spinning={isDeleting}>
      <Badge.Ribbon text="Ready" color="green">
        <Card
          style={{ width: "100%", minWidth: "300px" }}
          title={
            <Tooltip title={props.paymentMethod.description}>
              {props.paymentMethod.name}
            </Tooltip>
          }
          loading={isDeleting}
          actions={[
            <Button
              key="edit"
              type="link"
              onClick={() => props.onEdit(props.paymentMethod)}
            >
              <EditOutlined />
              Edit
            </Button>,
            <Button danger type="link" onClick={handleDelete}>
              <DeleteOutlined />
              Delete
            </Button>,
          ]}
        >
          {getCreditCardPreviewText(props.paymentMethod)}
        </Card>
      </Badge.Ribbon>
    </Spin>
  );
}
