import { Form, Input } from "antd";
import * as React from "react";

import { Vehicle } from "../../hooks/useVehicles";

const DEFAULT_VALUES: Omit<Vehicle, "id" | "user_id"> = {
  name: "",
  license_plate_number: "",
};

interface Props {
  // The form items are controlled when values and onValuesChange are provided, otherwise uncontrolled.
  values?: Omit<Vehicle, "id" | "user_id"> | undefined;
  onValuesChange?: (values: Omit<Vehicle, "id" | "user_id">) => void;
}

export default function VehicleInfoFormItems(props: Readonly<Props>) {
  function onChangeForField(field: keyof Omit<Vehicle, "id" | "user_id">) {
    return function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
      if (!props.onValuesChange) {
        return;
      }
      const newValues: Omit<Vehicle, "id" | "user_id"> = {
        ...DEFAULT_VALUES,
        ...props.values,
        [field]: evt.target.value,
      };
      props.onValuesChange(newValues);
    };
  }

  return (
    <>
      <Form.Item
        name="name"
        label="Vehicle name"
        tooltip="Any name you like to give this vehicle, such as Black Ford SUV"
        required
        rules={[{ required: true, message: "Vehicle name is required" }]}
      >
        <Input
          value={props.values?.name}
          onChange={onChangeForField("name")}
          placeholder="Any name you like to give this vehicle, such as Black Ford SUV"
        />
      </Form.Item>
      <Form.Item
        name="license_plate_number"
        label="License plate number"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        required
        rules={[
          { required: true, message: "License plate number is required" },
        ]}
      >
        <Input
          value={props.values?.license_plate_number}
          onChange={onChangeForField("license_plate_number")}
        />
      </Form.Item>
    </>
  );
}
