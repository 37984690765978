import { Card, Space, Spin, Steps } from "antd";
import * as React from "react";

interface Props {
  currentStep: 0 | 1 | 2;
  isLoading: boolean;
  children: React.ReactNode;
}

export default function InvitationFrame(
  props: Readonly<Props>
): React.FunctionComponentElement<Props> {
  return (
    <Card
      style={{ maxWidth: "42rem", margin: "1rem auto" }}
      title="Invite Tenant Group Parker"
    >
      <Space style={{ width: "100%" }} size="large" direction="vertical">
        <Steps current={props.currentStep}>
          <Steps.Step title="Create" description="Invite by email" />
          <Steps.Step title="Share" description="Email invitation" />
          <Steps.Step
            title="Done"
            description="All set!"
            status={props.currentStep === 2 ? "finish" : "wait"}
          />
        </Steps>
        <Spin spinning={props.isLoading}>{props.children}</Spin>
      </Space>
    </Card>
  );
}
