import { Button, Card, Result } from "antd";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";

import { useTenantGroupSource } from "../../hooks/useTenantGroupWallet";
import UpdateBankPayment from "../PaymentMethods/UpdateBankPayment";

interface Props {
  tenantGroupId: string;
  sourceId: string;
  baseUrl: string;
}

export default function UpdateTenantGroupBankAccount(props: Readonly<Props>) {
  const history = useHistory();
  const {
    payment: source,
    error,
    modify,
  } = useTenantGroupSource(props.tenantGroupId, props.sourceId);

  async function updateSource(name: string, description: string) {
    await modify({ name, description });
    setTimeout(() => {
      history.push(props.baseUrl);
    }, 200);
  }

  if (error) {
    return (
      <Card>
        <Result
          status="error"
          title="Failed to load payment method"
          extra={
            <Button type="primary" key="console">
              <Link to={`${props.baseUrl}`}>Go to Wallet</Link>
            </Button>
          }
        />
      </Card>
    );
  }

  return <UpdateBankPayment source={source} onUpdate={updateSource} />;
}
