import { Spin } from "antd";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import useOrganization from "../hooks/useOrganization";
import { getOrganizationViewUrl } from "../routes";
import OrganizationSettings from "./OrganizationSettings";
import OrganizationSidebar from "./Layouts/OrganizationSidebar";
import HeaderSidebarContent from "./Layouts/HeaderSidebarContent";
import NavBar from "./NavBar";
import NotFoundContent from "./NotFoundContent";
import NotFoundFullPage from "./NotFoundFullPage";
import OrganizationAccounting from "./OrganizationAccounting";
import OrganizationMembers from "./OrganizationMembers";
import OrganizationLocations from "./OrganizationLocations";
import OrganizationParkingProducts from "./OrganizationParkingProducts";
import OrganizationPayoutAccounts from "./OrganizationPayoutAccounts";
import OrganizationTenantGroups from "./OrganizationTenantGroups";
import OrganizationEnforcement from "./OrganizationEnforcement";
import OrganizationEvents from "./OrganizationParkingProducts/OrganizationEvents";
import OrganizationParkerHistory from "./OrganizationParkerHistory";
import OrganizationParkerDetails from "./OrganizationParkerDetails";

export default function AdminView() {
  const { path, url } = useRouteMatch();
  const { organizationId } = useParams<{ organizationId: string }>();
  const baseUrl = getOrganizationViewUrl(organizationId);
  const { data: organization, isLoading, error } = useOrganization();

  if (error) {
    return <NotFoundFullPage />;
  }

  if (!organization) {
    return null;
  }

  return (
    <Spin spinning={isLoading}>
      <Switch>
        <HeaderSidebarContent
          navbar={<NavBar name={organization.name} to={url} />}
          sidebar={<OrganizationSidebar baseUrl={baseUrl} />}
        >
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${baseUrl}/locations`} />
            </Route>
            <Route path={`${path}/locations`}>
              <OrganizationLocations organizationId={organizationId} />
            </Route>
            <Route path={`${path}/parking-products/events`}>
              <OrganizationEvents organization={organization} />
            </Route>
            <Route path={`${path}/parking-products`}>
              <OrganizationParkingProducts organization={organization} />
            </Route>
            <Route path={`${path}/tenant-groups`}>
              <OrganizationTenantGroups organization={organization} />
            </Route>
            <Route exact path={`${path}/parker-details`}>
              <OrganizationParkerDetails organization={organization} />
            </Route>
            <Route exact path={`${path}/parker-history`}>
              <OrganizationParkerHistory organization={organization} />
            </Route>
            <Route path={`${path}/payout-accounts`}>
              <OrganizationPayoutAccounts organization={organization} />
            </Route>
            <Route exact path={`${path}/accounting`}>
              <OrganizationAccounting organizationId={organizationId} />
            </Route>
            <Route path={`${path}/members`}>
              <OrganizationMembers organizationId={organizationId} />
            </Route>
            <Route exact path={`${path}/settings`}>
              <OrganizationSettings organizationId={organizationId} />
            </Route>
            <Route exact path={`${path}/enforcement`}>
              <OrganizationEnforcement organizationId={organizationId} />
            </Route>

            <Route>
              <NotFoundContent />
            </Route>
          </Switch>
        </HeaderSidebarContent>
      </Switch>
    </Spin>
  );
}
