import {
  BookOutlined,
  DollarOutlined,
  ShoppingCartOutlined,
  SettingOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import Sidebar from "./Sidebar";

const TenantGroupSidebar = ({ baseUrl }: { baseUrl: string }) => {
  return (
    <Sidebar
      baseUrl={baseUrl}
      items={[
        { key: "parking-programs", icon: <ShoppingCartOutlined /> },
        { key: "parkers", icon: <TeamOutlined /> },
        { key: "payment-methods", icon: <DollarOutlined /> },
        { key: "accounting", icon: <BookOutlined /> },
        { key: "members", icon: <TeamOutlined /> },
        { key: "settings", icon: <SettingOutlined /> },
      ]}
    />
  );
};

export default TenantGroupSidebar;
