import { useAuth0 } from "@auth0/auth0-react";
import { Button, Result } from "antd";
import * as React from "react";
import { Link } from "react-router-dom";

import useIdsFetch, { ResponseError } from "../../hooks/useIdsFetch";
import { getOrganizationViewUrl } from "../../routes";
import ErrorResult from "./ErrorResult";

interface Props {
  invitationId: string;
  organizationId: string;
}

export default function OrganizationClaim(props: Readonly<Props>) {
  const [error, setError] = React.useState<Error>();

  const { getIdTokenClaims } = useAuth0();
  const idsFetch = useIdsFetch();

  React.useEffect(() => {
    async function claimInvitation() {
      const claims = await getIdTokenClaims();
      if (claims === undefined) {
        setError(new Error("Failed to get auth token"));
        return;
      }
      try {
        await idsFetch(`/me/invitations/${props.invitationId}/claim`, {
          method: "POST",
          body: {
            id_token: claims.__raw,
          },
        });
      } catch (err) {
        setError(err as ResponseError);
      }
    }
    claimInvitation();
  }, [props.invitationId, getIdTokenClaims, idsFetch]);

  if (error) {
    return <ErrorResult error={error} />;
  }

  return (
    <Result
      status="success"
      title="Your organization admin account has been successfully activated!"
      extra={
        <Button type="primary">
          <Link to={getOrganizationViewUrl(props.organizationId)}>
            Take me to admin page
          </Link>
        </Button>
      }
    />
  );
}
