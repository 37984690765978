import {
  CheckCircleFilled,
  QrcodeOutlined,
  ScanOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Image, Result, Row, Spin, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import QRCode from "qrcode";
import * as React from "react";

import { useLocationGateAccess, useVendors } from "../../hooks/useGateAccess";
import useOrganizationLocationIntegration, {
  IntegrationSession,
  ParkingSessionEvent,
} from "../../hooks/useOrganizationLocationIntegration";
import { useOrganizationLocations } from "../../hooks/useOrganizationLocations";
import { CODE_STATUS } from "../../utils/code_status";
import notify from "../../utils/notify";
import VerticalSpace from "../VerticalSpace";

const CONFIRM_ERROR = new Error(
  "Please try to confirm again in a couple of minutes. If it still doesn't work, double check your inputs in the Location Access section, or contact smartpass@smarking.com for help."
);

interface Props {
  organizationId: string;
  locationId: string;
  done: () => void;
}

export default function OnsiteIntegration(props: Readonly<Props>) {
  const { locations, error: locationsError } = useOrganizationLocations({
    organizationId: props.organizationId,
  });
  const { locationGateAccess, error: locationGateAccessError } =
    useLocationGateAccess(props.organizationId, props.locationId);
  const { vendors, error: vendorsError } = useVendors();
  const {
    integrationSession,
    error: integrationSessionError,
    createIntegrationSession,
    refreshIntegrationSession,
  } = useOrganizationLocationIntegration(
    props.organizationId,
    props.locationId
  );

  async function confirmEntry() {
    if (!integrationSession) {
      throw new Error(
        "Unexpected call to confirm entry when there is no integration session yet."
      );
    }
    const updatedSession = await refreshIntegrationSession(
      integrationSession.id
    );
    const entryEvent = _.find(updatedSession.parking_session_events, {
      to_status: CODE_STATUS.READY_FOR_EXIT,
    });
    if (entryEvent === undefined) {
      notify.error("Entry test failed", CONFIRM_ERROR);
    }
  }

  async function confirmExit() {
    if (!integrationSession) {
      throw new Error(
        "Unexpected call to confirm exit when there is no integration session yet."
      );
    }
    const updatedSession = await refreshIntegrationSession(
      integrationSession.id
    );
    const exitEvent = _.find(updatedSession.parking_session_events, {
      to_status: CODE_STATUS.CLOSED,
    });
    if (exitEvent === undefined) {
      notify.error("Exit test failed", CONFIRM_ERROR);
    }
  }

  if (
    locationsError ||
    locationGateAccessError ||
    vendorsError ||
    integrationSessionError
  ) {
    return (
      <Result
        status="error"
        title="Failed to load"
        subTitle="Please contact smartpass@smarking.com for help."
      />
    );
  }

  if (
    locations === undefined ||
    locationGateAccess === undefined ||
    vendors === undefined ||
    integrationSession === undefined
  ) {
    return <Spin style={{ width: "100%", textAlign: "center" }} size="large" />;
  }

  const location = _.find(locations, { id: props.locationId });
  const timezone = location?.time_zone || "utc";
  const vendor = _.find(vendors, { id: locationGateAccess?.vendor_id });
  const integrationSteps = vendor?.integration_steps || [];

  const events = integrationSession?.parking_session_events;
  const entryEvent = _.find(events, { to_status: CODE_STATUS.READY_FOR_EXIT });
  const exitEvent = _.find(events, { to_status: CODE_STATUS.CLOSED });

  if (_.isEmpty(integrationSteps)) {
    return (
      <Typography style={{ width: "100%", textAlign: "center" }}>
        <Typography.Title level={4}>
          Integration Tests Unavailable
        </Typography.Title>
        <Typography.Paragraph>
          <Typography.Text>
            Integration tests for this vendor is not set up yet. Please contact
            smartpass@smarking.com for help.
          </Typography.Text>
        </Typography.Paragraph>
      </Typography>
    );
  }

  return (
    <Row gutter={[16, 16]}>
      {_.includes(integrationSteps, CODE_STATUS.READY_FOR_ENTRY) && (
        <QrCodeCard
          code={integrationSession?.code}
          onGenerateNewCode={createIntegrationSession}
        />
      )}
      {_.includes(integrationSteps, CODE_STATUS.READY_FOR_EXIT) && (
        <EntryExitCard
          cardType="entry"
          targetEvent={entryEvent}
          timezone={timezone}
          isDisabled={integrationSession === null}
          onConfirm={confirmEntry}
        />
      )}
      {_.includes(integrationSteps, CODE_STATUS.CLOSED) && (
        <EntryExitCard
          cardType="exit"
          targetEvent={exitEvent}
          timezone={timezone}
          isDisabled={
            integrationSession === null ||
            (_.includes(integrationSteps, CODE_STATUS.READY_FOR_EXIT) &&
              !entryEvent)
          }
          onConfirm={confirmExit}
        />
      )}
      <DoneCard
        integrationSession={integrationSession}
        integrationSteps={integrationSteps}
      />
    </Row>
  );
}

const CARD_HEIGHT = 240;
const QRCODE_SIZE = 0.6 * CARD_HEIGHT;
const ICON_FONT_SIZE = 0.2 * CARD_HEIGHT;

interface IntegrationStepCardProps {
  isDisabled?: boolean;
  children: React.ReactNode;
}

function IntegrationStepCard(props: Readonly<IntegrationStepCardProps>) {
  return (
    <Col xs={24} sm={12} lg={8} xl={6}>
      <Card style={{ height: CARD_HEIGHT, textAlign: "center" }}>
        {props.isDisabled && (
          // A mask to show disabled state for a card
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(240, 240, 240, 0.8)",
              zIndex: 99,
            }}
          />
        )}
        {props.children}
      </Card>
    </Col>
  );
}

interface QrCodeCardProps {
  code: string | undefined;
  onGenerateNewCode: () => Promise<IntegrationSession>;
}

function QrCodeCard(props: Readonly<QrCodeCardProps>) {
  const [qrCode, setQrCode] = React.useState<string>();
  const [isGeneratingNewCode, setIsGeneratingNewCode] = React.useState(false);

  React.useEffect(() => {
    setQrCode(undefined);
    if (props.code === undefined) {
      return;
    }
    QRCode.toDataURL(
      props.code,
      { width: QRCODE_SIZE, margin: 1 },
      (err, url) => {
        if (err) {
          notify.error("Failed to generate QR code", err);
        } else if (url) {
          setQrCode(url);
        }
      }
    );
  }, [props.code, setQrCode]);

  async function generateNewCode() {
    setIsGeneratingNewCode(true);
    try {
      await props.onGenerateNewCode();
    } catch (err) {
      notify.error(
        "Failed to generate new code. Double check your inputs in the Location Access section, or contact smartpass@smarking.com for help",
        err
      );
    } finally {
      setIsGeneratingNewCode(false);
    }
  }

  return (
    <IntegrationStepCard>
      <div>
        {qrCode === undefined ? (
          <>
            <QrcodeOutlined
              style={{ marginBottom: "1rem", fontSize: 72, color: "orange" }}
            />
            <div>Press the button below to generate a new test code.</div>
          </>
        ) : (
          <Image preview={false} src={qrCode} />
        )}
      </div>
      <Button
        style={{ marginTop: "1.2rem" }}
        type="primary"
        onClick={generateNewCode}
        loading={isGeneratingNewCode}
        disabled={isGeneratingNewCode}
      >
        Generate new code
      </Button>
    </IntegrationStepCard>
  );
}

interface EntryExitCardProps {
  cardType: "entry" | "exit";
  targetEvent: ParkingSessionEvent | undefined;
  timezone: string;
  isDisabled: boolean;
  onConfirm: () => Promise<void>;
}

function EntryExitCard(props: Readonly<EntryExitCardProps>) {
  const [isConfirming, setIsConfirming] = React.useState(false);

  async function confirm() {
    setIsConfirming(true);
    try {
      await props.onConfirm();
    } catch (err) {
      notify.error(
        `Failed to confirm ${props.cardType}, please try again`,
        err
      );
    } finally {
      setIsConfirming(false);
    }
  }

  if (props.targetEvent === undefined) {
    return (
      <IntegrationStepCard isDisabled={props.isDisabled}>
        <Typography.Title level={4}>
          <strong>{_.capitalize(props.cardType)} Test</strong>
        </Typography.Title>
        <Typography.Paragraph>
          <ScanOutlined style={{ fontSize: ICON_FONT_SIZE, color: "orange" }} />
        </Typography.Paragraph>
        <Typography.Paragraph>
          After scanning QR code at {props.cardType}, press the button below.
        </Typography.Paragraph>
        <Button
          onClick={confirm}
          loading={isConfirming}
          disabled={props.isDisabled || isConfirming}
        >
          Confirm
        </Button>
      </IntegrationStepCard>
    );
  }

  return (
    <IntegrationStepCard isDisabled={props.isDisabled}>
      <VerticalSpace>
        <Typography.Title level={4}>
          <strong>{_.capitalize(props.cardType)} Test Successful</strong>
        </Typography.Title>
        <Typography.Paragraph>
          <CheckCircleFilled
            style={{ fontSize: ICON_FONT_SIZE, color: "green" }}
          />
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Typography.Text type="secondary">
            Verified at
            <br />
            {moment
              .utc(props.targetEvent.event_time)
              .tz(props.timezone)
              .format("llll")}
          </Typography.Text>
        </Typography.Paragraph>
      </VerticalSpace>
    </IntegrationStepCard>
  );
}

interface DoneCardProps {
  integrationSession: IntegrationSession | null;
  integrationSteps: string[];
}

function DoneCard(props: Readonly<DoneCardProps>) {
  function areAllTestsSuccessful() {
    if (props.integrationSession === null) {
      return false;
    }
    const toStatuses = _.map(
      props.integrationSession.parking_session_events,
      "to_status"
    );
    return _.every(props.integrationSteps, (step: string) =>
      toStatuses.includes(step)
    );
  }

  if (areAllTestsSuccessful()) {
    return (
      <IntegrationStepCard>
        <VerticalSpace>
          <Typography.Title level={4}>
            <strong>All Tests Done</strong>
          </Typography.Title>
          <Typography.Paragraph>
            <CheckCircleFilled
              style={{ fontSize: ICON_FONT_SIZE, color: "green" }}
            />
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Typography.Text type="secondary">
              Location is now ready for parker access.
            </Typography.Text>
          </Typography.Paragraph>
        </VerticalSpace>
      </IntegrationStepCard>
    );
  }

  return (
    <IntegrationStepCard isDisabled>
      <Typography.Title
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        level={4}
      >
        Tests in Progress
      </Typography.Title>
    </IntegrationStepCard>
  );
}
