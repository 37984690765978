import { Button, Card, Result } from "antd";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import useSWR from "swr";

import useIdsFetch from "../../hooks/useIdsFetch";
import { Source } from "../../hooks/useWallet";
import UpdateBankPayment from "../PaymentMethods/UpdateBankPayment";

interface Props {
  sourceId: string;
}

export default function UpdateParkerBankAccount(props: Readonly<Props>) {
  const history = useHistory();
  const idsFetch = useIdsFetch();
  const {
    data: source,
    error,
    mutate,
  } = useSWR<Source>(`/me/wallet/sources/${props.sourceId}/`);

  async function updateSource(name: string, description: string) {
    await idsFetch(`/me/wallet/sources/${props.sourceId}/`, {
      method: "PUT",
      body: { name, description },
    });
    mutate();
    setTimeout(() => {
      history.push("/me/wallet");
    }, 200);
  }

  if (error) {
    return (
      <Card>
        <Result
          status="error"
          title="Failed to load payment method"
          extra={
            <Button type="primary" key="console">
              <Link to="/me/wallet">Go to Wallet</Link>
            </Button>
          }
        />
      </Card>
    );
  }

  return <UpdateBankPayment source={source} onUpdate={updateSource} />;
}
