import {
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  TimePicker,
  Tooltip,
} from "antd";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { RRule } from "rrule";

import { Location } from "../../hooks/useOrganizationLocations";
import {
  centsToDollars,
  defaultParkingPrices,
  dollarsToCents,
  fullMonthly,
  ParkingProduct,
  Price,
  Rate,
} from "../../hooks/useOrganizationParkingProducts";
import { PayoutAccount } from "../../hooks/usePayoutAccount";
import { TimeOfUseRule } from "../../hooks/useUserPurchases";

interface TimeOfUseRuleClient extends TimeOfUseRule {
  start_at_and_end_at: [moment.Moment, moment.Moment];
}

const NO_DISCOUNT = "no_discount";

const numberRules = [
  {
    validator: async (rule: Record<string, any>, value: string) => {
      if (!rule.required && !value) return;
      if (isNaN(parseInt(value, 10))) throw new Error("Not a valid number");
      if (parseInt(value, 10) < 0)
        throw new Error("Negative numbers are not allowed");
    },
  },
];

const numberRulesOptional = numberRules.map((rule) => ({
  ...rule,
  required: false,
}));

const numberRulesRequired = numberRules.map((rule) => ({
  ...rule,
  required: true,
}));

interface Props {
  isCreate?: boolean;
  parkingProduct?: ParkingProduct;
  price?: Price;
  locations: Location[];
  payoutAccounts: PayoutAccount[];
  onSubmit: (
    parkingProduct: Partial<ParkingProduct>,
    price: Partial<Price>
  ) => void;
}

const accessHourDayChoices = [
  {
    label: "Daily (All Days of the Week)",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY",
  },
  {
    label: "Weekdays (Monday - Friday)",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR",
  },
  {
    label: "Weekends (Saturday - Sunday)",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=SA,SU",
  },
  {
    label: "Monday",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=MO",
  },
  {
    label: "Tuesday",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=TU",
  },
  {
    label: "Wednesday",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=WE",
  },
  {
    label: "Thursday",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=TH",
  },
  {
    label: "Friday",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=FR",
  },
  {
    label: "Saturday",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=SA",
  },
  {
    label: "Sunday",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=SU",
  },
];

const rateChoicesDaysOfWeek = [
  {
    mutualExclusionKeys: [0, 1, 2, 3, 4, 5, 6],
    label: "Monthly",
    disabled: false,
    recurrence: "RRULE:FREQ=MONTHLY",
  },
  {
    mutualExclusionKeys: [0, 1, 2, 3, 4, 5, 6],
    label: "Daily",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY",
  },
  {
    mutualExclusionKeys: [0, 1, 2, 3, 4],
    label: "Weekdays (Monday - Friday)",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR",
  },
  {
    mutualExclusionKeys: [5, 6],
    label: "Weekends (Saturday - Sunday)",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=SA,SU",
  },
  {
    mutualExclusionKeys: [0],
    label: "Monday",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=MO",
  },
  {
    mutualExclusionKeys: [1],
    label: "Tuesday",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=TU",
  },
  {
    mutualExclusionKeys: [2],
    label: "Wednesday",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=WE",
  },
  {
    mutualExclusionKeys: [3],
    label: "Thursday",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=TH",
  },
  {
    mutualExclusionKeys: [4],
    label: "Friday",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=FR",
  },
  {
    mutualExclusionKeys: [5],
    label: "Saturday",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=SA",
  },
  {
    mutualExclusionKeys: [6],
    label: "Sunday",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY;BYDAY=SU",
  },
];

const rateChoicesDayVouchers = [
  {
    label: "Daily",
    disabled: false,
    recurrence: "RRULE:FREQ=DAILY",
  },
];

const rateChoicesSessionVouchers = [
  {
    label: "Per Session",
    disabled: false,
    recurrence: "session-rate",
  },
];

interface RateForm extends Rate {
  recurrence: string;
}

interface PriceForm extends Partial<Price> {
  purchase_option: string;
  purchase_customization: boolean;
}

const rateChoicesForPurchaseOption = (option?: string) => {
  if (option === "days_of_week") {
    return rateChoicesDaysOfWeek;
  } else if (option === "day_vouchers") {
    return rateChoicesDayVouchers;
  } else if (option === "session_vouchers") {
    return rateChoicesSessionVouchers;
  } else {
    return [];
  }
};

const convertRateToForm = (rate: Partial<Rate>) => {
  if (rate.amount) _.update(rate, "amount", centsToDollars);
  const firstRule = _.first(rate.time_of_use_rules);
  _.set(rate, "mutualExclusionKeys", firstRule ? firstRule?.days_of_week : []);
  _.set(rate, "recurrence", firstRule ? firstRule.recurrence : "session-rate");
  return rate;
};

const convertFormToRate = (data: RateForm) => {
  if (data.amount) _.update(data, "amount", dollarsToCents);
  if (data.recurrence === "session-rate") {
    _.set(data, "time_of_use_rules", []);
  } else if (data.recurrence) {
    _.set(data, "time_of_use_rules", [{ recurrence: data.recurrence }]);
  }
  return _.pickBy(
    data,
    (value, key) =>
      !["recurrence", "mutualExclusionKeys", "label", "disabled"].includes(key)
  );
};

const convertPriceToForm = (price: Partial<Price>): PriceForm => {
  let priceCopy = _.assign(_.cloneDeep(price), {
    purchase_option: "",
    purchase_customization: true,
  });
  if (priceCopy?.minimum_amount)
    _.update(priceCopy, "minimum_amount", centsToDollars);
  if (priceCopy?.discounted_phase?.coupon?.creation_kwargs?.amount_off)
    _.update(
      priceCopy,
      "discounted_phase.coupon.creation_kwargs.amount_off",
      centsToDollars
    );
  if (priceCopy?.allowed_time_of_use) {
    _.update(priceCopy, "allowed_time_of_use", (rules) => {
      return rules.map((rule: Record<string, any>) => {
        const startAt = rule.start_at || moment().startOf("day");
        const endAt = rule.end_at || moment().endOf("day");
        rule.start_at_and_end_at = [moment(startAt), moment(endAt)];
        rule.mutualExclusionKeys = rule.days_of_week;
        return rule;
      });
    });
  }
  if (priceCopy?.maximum_session_duration_minutes) {
    _.update(priceCopy, "maximum_session_duration_minutes", (minutes: number) =>
      moment().startOf("day").add(minutes, "minutes")
    );
  }
  if (priceCopy?.rates) {
    _.update(priceCopy, "rates", (rates) => {
      return rates.map((rate: Rate) => convertRateToForm(rate));
    });
  }

  if (priceCopy?.is_max_sessions_customizable) {
    _.set(priceCopy, "purchase_option", "session_vouchers");
  } else if (priceCopy?.is_max_days_customizable) {
    _.set(priceCopy, "purchase_option", "day_vouchers");
  } else {
    _.set(priceCopy, "purchase_option", "days_of_week");
    _.set(
      priceCopy,
      "purchase_customization",
      !!priceCopy?.is_time_of_use_customizable
    );
  }
  return priceCopy;
};

const convertFormToPrice = (
  price: Partial<PriceForm>,
  discountType: string
) => {
  if (price.minimum_amount) _.update(price, "minimum_amount", dollarsToCents);
  if (price.discounted_phase?.coupon?.creation_kwargs?.amount_off)
    _.update(
      price,
      "discounted_phase.coupon.creation_kwargs.amount_off",
      dollarsToCents
    );
  if (
    discountType === NO_DISCOUNT ||
    (!price.discounted_phase?.coupon?.creation_kwargs?.amount_off &&
      !price.discounted_phase?.coupon?.creation_kwargs?.percent_off)
  ) {
    _.set(price, "discounted_phase", null);
  }
  if (price.allowed_time_of_use) {
    _.update(price, "allowed_time_of_use", (rules) => {
      return rules.map((rule: TimeOfUseRuleClient) => {
        const {
          start_at_and_end_at: [start_at, end_at],
          recurrence,
        } = rule;
        _.set(rule, "recurrence", recurrence);
        _.set(rule, "start_at", start_at.utc(true).toISOString());
        _.set(rule, "end_at", end_at.utc(true).toISOString());
        return _.pickBy(
          rule,
          (value, key) =>
            !["mutualExclusionKeys", "start_at_and_end_at"].includes(key)
        );
      });
    });
  }
  if (price.maximum_session_duration_minutes) {
    _.update(price, "maximum_session_duration_minutes", (date: moment.Moment) =>
      date.diff(moment().startOf("day"), "minutes")
    );
  }
  if (price.rates) {
    _.update(price, "rates", (rates) => {
      return rates.map((rate: RateForm) => convertFormToRate(rate));
    });
  }
  if (price.purchase_option === "session_vouchers") {
    _.set(price, "is_max_sessions_customizable", true);
  } else if (price.purchase_option === "day_vouchers") {
    _.set(price, "is_max_days_customizable", true);
  } else {
    _.set(price, "is_time_of_use_customizable", price.purchase_customization);
  }
  _.set(price, "purchase_option", undefined);
  _.set(price, "purchase_customization", undefined);
  return price;
};

const convertParkingProductToForm = (data: ParkingProduct) => {
  if (data.disabled_at) {
    _.update(data, "disabled_at", moment);
  }
  if (data.disabled_until) {
    _.update(data, "disabled_until", moment);
  }
  return data;
};

export default function ParkingProductForm(props: Readonly<Props>) {
  const isPendingReview =
    props.parkingProduct !== undefined &&
    props.parkingProduct.is_pending_review;
  const [initialPrice, setInitialPrice] = React.useState(() => {
    const defaultValues = _.find(defaultParkingPrices, {
      name: fullMonthly,
    });
    if (props.price) return convertPriceToForm(props.price);
    if (defaultValues) return convertPriceToForm(defaultValues);
  });
  const [discountType, setDiscountType] = React.useState<string>(() => {
    const amount_off =
      props.price?.discounted_phase?.coupon.creation_kwargs?.amount_off;
    const percent_off =
      props.price?.discounted_phase?.coupon.creation_kwargs?.percent_off;
    if (amount_off) return "amount_off";
    if (percent_off) return "percent_off";
    return NO_DISCOUNT;
  });
  const [daySelectOptions, setDaySelectOptions] =
    React.useState(accessHourDayChoices);
  const [rateSelectOptions, setRateSelectOptions] = React.useState(
    rateChoicesForPurchaseOption(initialPrice?.purchase_option)
  );
  const initialParkingProduct = props.parkingProduct
    ? convertParkingProductToForm(props.parkingProduct)
    : {
        disabled_at: null,
        disabled_until: null,
        location: { id: props.locations[0]?.id },
        stripe_account: { id: props.payoutAccounts[0]?.stripe_account.id },
        is_for_direct_sale: true,
        access_mode: "qr_code",
      };
  const onFinish = ({
    parkingProduct,
    price: formPrice,
  }: {
    parkingProduct: ParkingProduct;
    price: Price;
  }) => {
    const price = convertFormToPrice(
      { ...initialPrice, ...formPrice },
      discountType
    );
    props.onSubmit(parkingProduct, price);
  };
  const maximumActivePurchaseValidator = props.price
    ? (value: number) => {
        if (
          props.price?.active_subscriptions_count &&
          props.price.active_subscriptions_count > value
        )
          throw new Error(
            `${props.price?.active_subscriptions_count} passes are currently active. Set this value greater than ${props.price?.active_subscriptions_count} to allow more purchases.`
          );
      }
    : () => true;
  const priceNameOptions = defaultParkingPrices.map(({ name }) => (
    <Select.Option value={name} key={name}>
      {name}
    </Select.Option>
  ));
  const locationOptions = props.locations.map((location) => (
    <Select.Option value={location.id} key={location.id}>
      {location.name || location.id}
    </Select.Option>
  ));
  const payoutAccountOptions = props.payoutAccounts.map((account) => (
    <Select.Option value={account.stripe_account.id} key={account.id}>
      {account.name || account.id}
    </Select.Option>
  ));
  const [form] = Form.useForm();
  const updateDaySelectionOptions = React.useCallback(
    (values, setter, choices) => {
      const exclusionKeysFromChoices = _(choices)
        .filter(({ recurrence }) =>
          _(values).map("recurrence").includes(recurrence)
        )
        .flatMap("mutualExclusionKeys")
        .uniq()
        .value();
      const exclusionKeysFromValues = _(values)
        .flatMap("mutualExclusionKeys")
        .uniq()
        .value();
      const existingDayChoices = _.uniq([
        ...exclusionKeysFromChoices,
        ...exclusionKeysFromValues,
      ]);
      const dayChoicesNonOverlapping = choices.map(
        (choice: typeof accessHourDayChoices[number]) => {
          const disabled = !_.isEmpty(
            _.intersection(
              _.get(choice, "mutualExclusionKeys", []),
              existingDayChoices
            )
          );
          return _.assign(choice, { disabled });
        }
      );
      const otherDayChoices = _(values)
        .filter("recurrence")
        .filter(({ recurrence }) => recurrence !== "session-rate")
        .differenceBy(choices, "recurrence")
        .map((rule) => _.set(rule, "disabled", true))
        .map((rule) =>
          _.set(
            rule,
            "label",
            _.startCase(RRule.fromString(rule.recurrence).toText())
          )
        )
        .value();
      setter([...dayChoicesNonOverlapping, ...otherDayChoices]);
    },
    []
  );
  const setDisabledUntil = React.useCallback(
    // called when disable_at changed
    (form) => {
      const disabled_at = form.getFieldValue(["parkingProduct", "disabled_at"]);
      const disabled_until = form.getFieldValue([
        "parkingProduct",
        "disabled_until",
      ]);

      // if the expiry date is after resume date, clear disable_until date
      if (disabled_until && disabled_until.isBefore(disabled_at)) {
        form.setFieldsValue({ parkingProduct: { disabled_until: null } });
      }
    },
    []
  );
  const setDisabledAt = React.useCallback(
    // called when disable_until changed
    (form) => {
      const disabled_at = form.getFieldValue(["parkingProduct", "disabled_at"]);
      const disabled_until = form.getFieldValue([
        "parkingProduct",
        "disabled_until",
      ]);

      // if the expiry date is after resume date, set expiry date to just before the resume date
      if (disabled_until && disabled_until.isBefore(disabled_at)) {
        form.setFieldsValue({
          parkingProduct: {
            disabled_at: disabled_until.subtract(1, "seconds"),
          },
        });
      }
    },
    []
  );
  React.useEffect(() => {
    if (props.price !== undefined) {
      form.setFieldsValue({ price: convertPriceToForm(props.price) });
    }
    updateDaySelectionOptions(
      form.getFieldValue(["price", "allowed_time_of_use"]),
      setDaySelectOptions,
      accessHourDayChoices
    );
    updateDaySelectionOptions(
      form.getFieldValue(["price", "rates"]),
      setRateSelectOptions,
      rateChoicesForPurchaseOption(
        form.getFieldValue(["price", "purchase_option"])
      )
    );
  }, [form, props.price, updateDaySelectionOptions]);
  return (
    <Form
      form={form}
      layout="vertical"
      scrollToFirstError={true}
      onFinish={onFinish}
      onValuesChange={(changed, { price: { name } }) => {
        _.property("price.name")(changed) &&
          setInitialPrice((existingPrice) => {
            const defaultValues = _.find(defaultParkingPrices, { name });
            if (name !== existingPrice?.name && defaultValues) {
              form.setFieldsValue({ price: convertPriceToForm(defaultValues) });
              return convertPriceToForm(defaultValues);
            } else {
              return existingPrice;
            }
          });
        updateDaySelectionOptions(
          form.getFieldValue(["price", "allowed_time_of_use"]),
          setDaySelectOptions,
          accessHourDayChoices
        );
        _.property("price.rates")(changed) &&
          updateDaySelectionOptions(
            form.getFieldValue(["price", "rates"]),
            setRateSelectOptions,
            rateChoicesForPurchaseOption(
              form.getFieldValue(["price", "purchase_option"])
            )
          );
        _.property("parkingProduct.disabled_at")(changed) &&
          setDisabledUntil(form);
        _.property("parkingProduct.disabled_until")(changed) &&
          setDisabledAt(form);
        if (_.property("price.purchase_option")(changed)) {
          form.setFieldsValue({ price: { rates: [] } });
        }
      }}
      initialValues={{
        parkingProduct: initialParkingProduct,
        price: initialPrice,
      }}
    >
      <Form.Item label="Product type" name={["price", "name"]}>
        <Select disabled={!props.isCreate}>{priceNameOptions}</Select>
      </Form.Item>

      <Form.Item
        label="Name"
        name={["parkingProduct", "name"]}
        rules={[{ required: true, message: "Name is required." }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Description"
        name={["parkingProduct", "description"]}
        rules={[{ required: true, message: "Description is required." }]}
      >
        <Input.TextArea autoSize={{ maxRows: 8, minRows: 3 }} />
      </Form.Item>

      <Form.Item
        label="Restrictions"
        name={["parkingProduct", "restrictions"]}
        rules={[{ required: true, message: "Restrictions is required." }]}
      >
        <Input.TextArea autoSize={{ maxRows: 8, minRows: 3 }} />
      </Form.Item>

      <Form.Item
        label="Parking Type"
        name={["price", "purchase_option"]}
        required
      >
        <Radio.Group disabled={!props.isCreate}>
          <Radio value="days_of_week">
            <Space>
              Prepaid Days of the Week
              <Tooltip title="Park based on days of the week">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          </Radio>
          <Radio value="day_vouchers">
            <Space>
              Prepaid Day Vouchers
              <Tooltip title="Specify the number of days per billing period">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          </Radio>
          <Radio value="session_vouchers">
            <Space>
              Prepaid Session Vouchers
              <Tooltip title="Specify the number of parking sessions per billing period">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Parking Customization"
        name={["price", "purchase_customization"]}
        required
      >
        <Radio.Group disabled={!props.isCreate}>
          <Radio value={true}>
            <Space>
              Flexible
              <Tooltip title="Parker can choose how much parking they want to pay for.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          </Radio>
          <Radio
            value={false}
            disabled={
              form.getFieldValue(["price", "purchase_option"]) !==
              "days_of_week"
            }
          >
            <Space>
              Fixed
              <Tooltip title="Parker cannot modify the amount of parking they pay for.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Subscription Configuration"
        required
        tooltip="Set the billing cycle for recurring payments."
        extra={
          form.getFieldValue(["price", "recurring_count"])
            ? `The parker will be billed every ${form.getFieldValue([
                "price",
                "recurring_count",
              ])} ${form.getFieldValue(["price", "recurring_interval"])}s`
            : `Configure how often the subscription is billed.`
        }
      >
        <Input.Group compact>
          <Form.Item
            name={["price", "recurring_count"]}
            noStyle
            rules={numberRulesRequired}
          >
            <InputNumber
              disabled={!props.isCreate}
              style={{ width: "10%" }}
              placeholder="how many intervals"
            />
          </Form.Item>
          <Form.Item
            name={["price", "recurring_interval"]}
            noStyle
            rules={[{ required: true, message: "Interval is required" }]}
          >
            <Select
              placeholder="Select interval"
              style={{ width: "10%" }}
              disabled={!props.isCreate}
            >
              <Select.Option value="month">Months</Select.Option>
              <Select.Option value="week">Weeks</Select.Option>
            </Select>
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item
        label="Cancel after billing cycles count"
        name={["price", "recurring_iterations"]}
        rules={numberRulesOptional}
        tooltip="Use this value to automatically cancel the subscription after a certain number of billing cycles. Leave this value empty and the subscription will continue indefinitely."
        extra={
          form.getFieldValue(["price", "recurring_iterations"])
            ? `Subscriptions will end after ${form.getFieldValue([
                "price",
                "recurring_iterations",
              ])} billing cycles.`
            : `Subscriptions will continue indefinitely.`
        }
      >
        <InputNumber style={{ width: "25%" }} disabled={!props.isCreate} />
      </Form.Item>

      <Form.Item label="Rates" required={true}>
        <Form.List name={["price", "rates"]}>
          {(fields, { add, remove }) => {
            const ratesDisabled = !props.isCreate && !isPendingReview;
            return (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        key="select-rate"
                        {...restField}
                        name={[name, "recurrence"]}
                        rules={[
                          {
                            required: true,
                            message: "Selection is required",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "200px" }}
                          placeholder="Select Rate"
                          disabled={ratesDisabled}
                        >
                          {_.map(
                            rateSelectOptions,
                            ({ recurrence, label, disabled }) => (
                              <Select.Option
                                key={recurrence}
                                value={recurrence}
                                disabled={disabled}
                              >
                                {label}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        key="amount"
                        name={[name, "amount"]}
                        rules={numberRulesRequired}
                      >
                        <Input prefix="$" disabled={ratesDisabled} />
                      </Form.Item>
                      {!ratesDisabled ? (
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      ) : null}
                    </Space>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    disabled={
                      !_.find(rateSelectOptions, { disabled: false }) ||
                      ratesDisabled
                    }
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Rate
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
      </Form.Item>
      <Form.Item
        label="Minimum payment amount per parker per subscription period"
        name={["price", "minimum_amount"]}
        validateFirst={true}
        rules={numberRulesOptional}
      >
        <Input prefix="$" disabled={!props.isCreate} />
      </Form.Item>

      <Form.Item
        label="Maximum active parkers for current product"
        name={["price", "maximum_active_purchases"]}
        rules={[
          {
            validator: async (rule, value) => {
              if (value && isNaN(parseInt(value, 10)))
                throw new Error(`"${value}" is not a number`);
              if (value) maximumActivePurchaseValidator(value);
            },
          },
        ]}
      >
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item label="Pause At" name={["parkingProduct", "disabled_at"]}>
        <DatePicker
          format={"YYYY/MM/DD HH:mm"}
          showTime={{ format: "HH:mm" }}
        />
      </Form.Item>

      <Form.Item label="Resume At" name={["parkingProduct", "disabled_until"]}>
        <DatePicker
          format={"YYYY/MM/DD HH:mm"}
          showTime={{ format: "HH:mm" }}
        />
      </Form.Item>

      {form.getFieldValue(["price", "purchase_option"]) ===
      "session_vouchers" ? (
        <>
          <Form.Item
            label="Maximum Session Duration"
            name={["price", "maximum_session_duration_minutes"]}
            validateFirst={true}
            rules={[{ required: true, message: "Required" }]}
          >
            <TimePicker
              format={"HH:mm"}
              showNow={false}
              placeholder="Duration"
            />
          </Form.Item>
        </>
      ) : null}
      <Space align="baseline" size="large" style={{ width: "100%" }}>
        <Form.Item
          label="Preset Discount"
          tooltip={{
            title:
              "Build in a discount to this parking product for your parkers.",
          }}
        >
          <Select
            style={{ width: "100%" }}
            defaultValue={discountType}
            onChange={(value: string) => setDiscountType(value)}
          >
            <Select.Option value="percent_off">Percentage</Select.Option>
            <Select.Option value="amount_off">Dollar</Select.Option>
            <Select.Option value={NO_DISCOUNT}>No Discount</Select.Option>
          </Select>
        </Form.Item>
        {discountType === "percent_off" && (
          <Form.Item
            name={[
              "price",
              "discounted_phase",
              "coupon",
              "creation_kwargs",
              "percent_off",
            ]}
            label="Amount"
            rules={[{ required: true, message: "Amount is required" }]}
          >
            <Input suffix="%" />
          </Form.Item>
        )}
        {discountType === "amount_off" && (
          <Form.Item
            name={[
              "price",
              "discounted_phase",
              "coupon",
              "creation_kwargs",
              "amount_off",
            ]}
            label="Amount"
            rules={[{ required: true, message: "Amount is required" }]}
          >
            <Input prefix="$" />
          </Form.Item>
        )}
        {discountType !== NO_DISCOUNT && (
          <Form.Item
            name={["price", "discounted_phase", "iterations"]}
            label="Duration"
            rules={[{ required: true, message: "Duration is required" }]}
            style={{ width: "200px" }}
          >
            <Select>
              <Select.Option value={1}>
                one-time / first subscription period
              </Select.Option>
            </Select>
          </Form.Item>
        )}
      </Space>

      <Form.Item
        name={["parkingProduct", "location", "id"]}
        label="Location"
        rules={[{ required: true, message: "Location is required." }]}
      >
        <Select disabled={!props.isCreate}>{locationOptions}</Select>
      </Form.Item>
      <Form.Item
        name={["parkingProduct", "access_mode"]}
        label="Access Mode"
        rules={[{ required: true, message: "Please specify access mode" }]}
      >
        <Radio.Group>
          <Radio value="qr_code">
            <Tooltip title="For locations that are gated with QR code scanners or with attendants to check QR codes">
              Use QR code
            </Tooltip>
          </Radio>
          <Radio value="parker_session">
            <Tooltip title="For surface lots or ungated locations">
              Require parkers to manually start and end their parking sessions
            </Tooltip>
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Access Hours">
        <Form.List name={["price", "allowed_time_of_use"]}>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "recurrence"]}
                        rules={[
                          {
                            required: true,
                            message: "Day selection is required",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "200px" }}
                          placeholder="Select days"
                        >
                          {daySelectOptions.map(
                            ({ recurrence, label, disabled }) => (
                              <Select.Option
                                key={recurrence}
                                value={recurrence}
                                disabled={disabled}
                              >
                                {label}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "start_at_and_end_at"]}
                        rules={[
                          {
                            required: true,
                            message: "Start Time and End Time are required",
                          },
                        ]}
                      >
                        <TimePicker.RangePicker use12Hours format="h:mm a" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    disabled={!_.find(daySelectOptions, { disabled: false })}
                    onClick={() =>
                      add({
                        start_at_and_end_at: [
                          moment.utc({ hour: 0, minute: 0, second: 0 }),
                          moment.utc({ hour: 23, minute: 59, second: 59 }),
                        ],
                      })
                    }
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Access Hours
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
      </Form.Item>
      <Form.Item
        name={["parkingProduct", "stripe_account", "id"]}
        label="Payout account"
        rules={[{ required: true, message: "Payout Account is required." }]}
      >
        <Select disabled={!props.isCreate && !isPendingReview}>
          {payoutAccountOptions}
        </Select>
      </Form.Item>
      <Form.Item
        name={["parkingProduct", "is_for_direct_sale"]}
        label="Purchase availability"
        rules={[{ required: true, message: "Specify purchase availability" }]}
      >
        <Radio.Group>
          <Radio value={true}>Anyone can purchase</Radio>
          <Radio value={false}>
            Exclusive distribution through Tenant Group
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {props.isCreate ? "Create" : "Update"}
        </Button>
      </Form.Item>
    </Form>
  );
}
