import { Space } from "antd";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { useEvents } from "../../hooks/useOrganizationParkingProducts";
import { Organization } from "../../hooks/useOrganization";
import CreateEvent from "./CreateEvent";

const OrganizationEvents = ({
  organization,
}: {
  organization: Organization;
}) => {
  const organizationId = organization.id;
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const { createEvent } = useEvents({
    organizationId: organization.id,
  });
  return (
    <Switch>
      <Route exact strict path={path}>
        <Redirect to={`${url}/new`} />
      </Route>
      <Route
        exact
        strict
        path={`${path}/active`}
        render={() => <Space>ACTIVE PAGE</Space>}
      />

      <Route
        exact
        path={`${path}/new`}
        render={() => (
          <CreateEvent
            organizationId={organizationId}
            createEvent={createEvent}
            onCreateSuccess={() => history.goBack()}
          />
        )}
      />
    </Switch>
  );
};

export default OrganizationEvents;
