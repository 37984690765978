import { ShoppingTwoTone } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  Radio,
  Result,
  Row,
  Skeleton,
  Space,
  Spin,
  Steps,
  Tooltip,
  Typography,
} from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import _ from "lodash";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import useInvitation from "../../hooks/useInvitation";
import { ParkingProduct } from "../../hooks/useParkingProduct";
import { TenantGroup } from "../../hooks/useTenantGroup";
import { useAuthorizedPayments } from "../../hooks/useTenantGroupAuthorizedPayments";
import useUserTenantGroups from "../../hooks/useUserTenantGroups";
import { getTenantGroupViewUrl } from "../../routes";
import AddTenantGroupPaymentMethod from "../TenantGroupPaymentMethods/AddTenantGroupPaymentMethod";
import ErrorResult from "./ErrorResult";

export default function ParkingProductClaim({
  invitationId,
  parkingProduct,
}: {
  invitationId: string;
  parkingProduct: ParkingProduct;
}) {
  const [error, setError] = React.useState<Error>();
  const [isFetching, setIsFetching] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState<TenantGroup>();
  const [isClaimedBy, setIsClaimedBy] = React.useState<string>();
  const { create: createParkingProgram } = useAuthorizedPayments({});
  const { claim } = useInvitation({ invitationId });
  const { tenantGroups } = useUserTenantGroups({ onError: setError });
  React.useEffect(() => {
    if (tenantGroups?.length && !selectedGroup) {
      setSelectedGroup(_.sortBy(tenantGroups, "name")[0]);
    }
  }, [tenantGroups, selectedGroup]);
  async function claimInvitation(tenantGroupId: string) {
    setIsFetching(true);
    try {
      await claim({ tenant_group_id: tenantGroupId });
      await createParkingProgram(
        {
          name: "[New Parking Program]",
          parking_product: parkingProduct,
        },
        tenantGroupId
      );
      setIsFetching(false);
      setIsClaimedBy(tenantGroupId);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsFetching(false);
    }
  }
  if (error) return <ErrorResult error={error} />;
  if (isClaimedBy) return <TenantGroupSuccess tenantGroupId={isClaimedBy} />;
  return (
    <Spin spinning={!tenantGroups || isFetching}>
      <Card
        title={
          <h1>
            <ShoppingTwoTone /> Product: {parkingProduct.name}
          </h1>
        }
      >
        {tenantGroups?.length && selectedGroup && (
          <>
            <Space
              direction="vertical"
              style={{ marginBottom: "1rem", width: "100%" }}
            >
              <Typography.Title level={5}>
                Add product to existing tenant group
              </Typography.Title>
              <SelectTenantGroup
                tenantGroups={tenantGroups}
                selectedGroup={selectedGroup}
                onSelectChange={setSelectedGroup}
              />
            </Space>
            <Row justify="center">
              <Col>
                <Button
                  type="primary"
                  disabled={!selectedGroup || isFetching}
                  loading={isFetching}
                  onClick={() => claimInvitation(selectedGroup.id)}
                >
                  Add Product to Tenant Group
                </Button>
              </Col>
            </Row>
            <Divider>Or</Divider>
          </>
        )}
        <Space
          direction="vertical"
          style={{ marginBottom: "1rem", width: "100%" }}
        >
          <Typography.Title level={5}>
            Create a new tenant group
          </Typography.Title>

          <TenantGroupOnBoard
            disabled={isFetching}
            onFinish={(created) => claimInvitation(created.id)}
          />
        </Space>
      </Card>
    </Spin>
  );
}

function TenantGroupSuccess({ tenantGroupId }: { tenantGroupId: string }) {
  return (
    <Card>
      <Result
        status="success"
        title="The product has been added to your tenant group!"
        extra={
          <Button type="primary">
            <Link to={getTenantGroupViewUrl(tenantGroupId)}>
              Continue to Parking Programs
            </Link>
          </Button>
        }
      />
    </Card>
  );
}

interface ChooseTenantGroupProps {
  tenantGroups: TenantGroup[] | undefined;
  selectedGroup: TenantGroup | undefined;
  onSelectChange: (tenantGroup: TenantGroup | undefined) => void;
}

function SelectTenantGroup(props: Readonly<ChooseTenantGroupProps>) {
  function handleSelectChange(evt: RadioChangeEvent) {
    const selected = _.find(props.tenantGroups, { id: evt.target.value });
    props.onSelectChange(selected);
  }

  if (props.tenantGroups === undefined) {
    return <Skeleton active />;
  }

  if (props.tenantGroups.length <= 0) {
    return (
      <Empty
        description={
          <>
            Oops, you are not a member of any tenant groups. Please create a new
            tenant group.
            <br />
            <br />
            If you are an individual parker, please create a tenant group in the
            following format: Name - Individual Parker (i.e. John Doe -
            Individual Parker).
          </>
        }
      />
    );
  }

  return (
    <Radio.Group value={props.selectedGroup?.id} onChange={handleSelectChange}>
      {_.sortBy(props.tenantGroups, "name").map((tg: TenantGroup) => (
        <Radio
          key={tg.id}
          value={tg.id}
          style={{ display: "block", marginBottom: "0.5rem" }}
        >
          {tg.name}
        </Radio>
      ))}
    </Radio.Group>
  );
}

function TenantGroupOnBoard({
  disabled = false,
  onFinish,
}: {
  disabled: boolean;
  onFinish: (group: TenantGroup) => void;
}) {
  const [step, setStep] = useState(0);
  const [error, setError] = useState<Error>();
  const [created, setCreated] = useState<TenantGroup>();

  return (
    <>
      <Steps
        style={{ marginBottom: "1rem" }}
        current={step}
        size="small"
        progressDot
        status={error ? "error" : undefined}
      >
        <Steps.Step title="Create" />
        <Steps.Step title="Add Payment" />
        <Steps.Step title="Finish" />
      </Steps>
      {step === 0 && (
        <TenantGroupCreate
          onCreated={(created) => {
            setCreated(created);
            setStep(1);
          }}
          onError={setError}
        />
      )}
      {step === 1 && created && (
        <AddTenantGroupPaymentMethod
          tenantGroupId={created.id}
          onSuccess={() => setStep(2)}
          onError={setError}
          CancelButton={
            <Tooltip title="You can add payment methods later in your tenant group admin page.">
              <Button onClick={() => setStep(2)}>Skip</Button>
            </Tooltip>
          }
        />
      )}
      {step === 2 && created && (
        <Result
          status="success"
          title={`Your tenant group "${created.name}" has been created`}
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => onFinish(created)}
            >
              Add Product to Tenant Group
            </Button>
          }
        />
      )}
    </>
  );
}

function TenantGroupCreate({
  onCreated,
  onError,
}: {
  onCreated: (created: TenantGroup) => void;
  onError: (err: Error) => void;
}) {
  const [isFetching, setIsFetching] = React.useState(false);
  const { create: createTenantGroup } = useUserTenantGroups();
  async function createNewTenantGroup(values: Partial<TenantGroup>) {
    setIsFetching(true);
    try {
      const newTenantGroup = await createTenantGroup(values);
      onCreated(newTenantGroup);
    } catch (err) {
      onError(err as Error);
    } finally {
      setIsFetching(false);
    }
  }

  return (
    <Form onFinish={createNewTenantGroup}>
      <Form.Item
        name="name"
        label="Name"
        required
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Please provide a name.",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Row justify="center">
        <Col>
          <Button
            htmlType="submit"
            type="primary"
            disabled={isFetching}
            loading={isFetching}
          >
            Next
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
