import { notification } from "antd";

function success(message: string) {
  notification.success({ message });
}

function error(message: string, error?: any) {
  const description = error instanceof Error ? error.message : undefined;
  notification.error({
    message,
    description,
    duration: null,
  });
}

const notify = {
  success,
  error,
};

export default notify;
