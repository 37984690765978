import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Modal, Result } from "antd";

import { Membership } from "../../hooks/useMembers";
import notify from "../../utils/notify";
import ResponsiveList from "../ResponsiveList";
import VerticalSpace from "../VerticalSpace";

export default function MembersList({
  members,
  error,
  isLoading,
  onCreate,
  onDeactivate,
  onManageRole,
}: {
  members?: Membership[];
  error?: Error;
  isLoading: boolean;
  onCreate: () => void;
  onDeactivate: (id: string) => Promise<void>;
  onManageRole: (id: string) => void;
}) {
  if (error !== undefined) {
    return (
      <Card>
        <Result status="error" title="Oops, something went wrong." />
      </Card>
    );
  }

  return (
    <VerticalSpace size="large">
      <Card>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={onCreate}
          disabled={isLoading}
        >
          Invite Member
        </Button>
      </Card>
      <ResponsiveList<Membership>
        loading={isLoading}
        columns={[
          {
            title: "Name",
            dataIndex: ["user_profile", "name"],
            colSpan: 4,
          },
          {
            title: "Email",
            dataIndex: ["user_profile", "email"],
            colSpan: 6,
          },
          {
            title: "Number",
            dataIndex: ["user_profile", "number"],
            colSpan: 5,
          },
          {
            title: "Settings",
            colSpan: 4,
            render: (id: string, membership: Membership) => (
              <Button onClick={() => onManageRole(membership.id)}>
                Manage
              </Button>
            ),
          },
          {
            title: "Membership",
            dataIndex: "id",
            colSpan: 4,
            render: (id: string, adminUser: Membership) => (
              <Button
                style={{ margin: "0 auto" }}
                danger
                onClick={() => {
                  Modal.confirm({
                    title: `Are you sure you want to deactivate ${adminUser.user_profile.name}?`,
                    async onOk() {
                      try {
                        await onDeactivate(id);
                        notify.success("Successfully deactivated");
                      } catch (err) {
                        notify.error("Failed to deactivate", err);
                      }
                    },
                  });
                }}
              >
                Deactivate
              </Button>
            ),
          },
        ]}
        dataSource={members}
        rowKey="id"
      />
    </VerticalSpace>
  );
}
